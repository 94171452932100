<dx-data-grid [dataSource]="dataSourceUsers" [allowColumnReordering]="true" [allowColumnResizing]="true">
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="MyAccountUsersStateNewUIV2"></dxo-state-storing>
    <dxo-editing mode="popup" refreshMode="full" [selectTextOnEditStart]="true" allowUpdating="canEdit()"
        [allowDeleting]="loginService.isManager" [allowAdding]="canAddUser()">
        <dxo-popup title="User" [showTitle]="true"></dxo-popup>
        <dxo-form>
            <dxi-item dataField="email"></dxi-item>
            <dxi-item dataField="firstName"></dxi-item>
            <dxi-item dataField="lastName"></dxi-item>
            <dxi-item dataField="currentClaim"></dxi-item>
        </dxo-form>
    </dxo-editing>

    <dxi-column dataField="email" caption="{{ 'Email' | translate }}">
        <dxi-validation-rule type="required"></dxi-validation-rule>
        <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="firstName" caption="{{ 'Vorname' | translate }}"></dxi-column>
    <dxi-column dataField="lastName" caption="{{ 'Nachname' | translate }}"></dxi-column>
    <dxi-column dataField="currentClaim" caption="{{ 'Role' | translate }}" [allowEditing]="loginService.isManager">
        <dxo-lookup [dataSource]="claims" searchEnabled="true"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column *ngIf="loginService.isAdmin" dataField="lastLogin"
        caption="{{ 'letzter Login' | translate }}"></dxi-column>


</dx-data-grid>
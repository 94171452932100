<div class="dx-fieldset">
  <dx-pie-chart style="margin-top:20px;"
                type="doughnut"
                [startAngle]="90"
                title="Produktkategorien"
                [resolveLabelOverlapping]="'shift'"
                [palette]="colorPalette"
                [dataSource]="dataSource">
    <dxi-series argumentField="category">
      <dxo-label [visible]="true"
                 [customizeText]="customizeLabel">
        <dxo-connector [visible]="true"></dxo-connector>
      </dxo-label>
    </dxi-series>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-legend [margin]="0"
                horizontalAlignment="left"
                verticalAlignment="top"></dxo-legend>
    <dxo-tooltip [enabled]="true"
                 format="largeNumber">
    </dxo-tooltip>
  </dx-pie-chart>
</div>

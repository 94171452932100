<div class="d-block h-100 w-100">
  <div *ngIf="model.changeInfo==null">
    <div *ngIf="hasAddonVALIDATION && !isPrint">
      <div id="val6" (mouseenter)="ttt(6)" (mouseleave)="ttt(6)">
        <dx-bar-gauge id="gauge"
                      [(values)]="validationService.validationResult.validateNumbers"
                      [startValue]="0"
                      [endValue]="100"
                      [palette]="['#191970', '#008000', '#FFA500', '#D26915', '#A52A2A']"
                      (click)="validateProductForm()">
          <dxo-label [visible]="false"></dxo-label>
        </dx-bar-gauge>
        <dx-tooltip target="#val6" [(visible)]="v[6]">
          <div *dxTemplate="let data = data of 'content'">
            <p>{{ 'Validierungsinformation zum Produkt' | translate }}</p>
            <p><span class="dx-switch-handle color1" style="margin-right: 10px;"></span> {{ 'Informationen zu Stammdaten' | translate }}.</p>
            <p><span class="dx-switch-handle color2" style="margin-right: 10px;"></span> {{ 'Informationen zu Suchbegriffen' | translate }}.</p>
            <p><span class="dx-switch-handle color3" style="margin-right: 10px;"></span> {{ 'Informationen zu Produktmedien' | translate }}.</p>
            <p><span class="dx-switch-handle color4" style="margin-right: 10px;"></span> {{ 'Informationen zu Produktmerkmalen' | translate }}.</p>
            <p><span class="dx-switch-handle color5" style="margin-right: 10px;"></span> {{ 'Informationen zu Zusatzinformationen' | translate }}.</p>
            <p> {{ 'Auf das Diagramm klicken zur Validierung' | translate }}.</p>
          </div>
        </dx-tooltip>
      </div>
      <dx-linear-gauge [value]="validationService.validationResult.validGroup" id="linearGauge">
        <dxo-scale [startValue]="0"
                   [endValue]="validationService.validationResult.totalGroupCount"
                   [minorTickInterval]="1">
          <dxo-label></dxo-label>
          <dxo-minor-tick [visible]="true"></dxo-minor-tick>
        </dxo-scale>
        <dxo-range-container>
          <dxi-range *ngFor="let color of validationService.validationResult.validationGroupColors; let i = index"
                     [startValue]="i"
                     [endValue]="i+1"
                     [color]="color">
          </dxi-range>
        </dxo-range-container>
      </dx-linear-gauge>
      <dx-tooltip target="#linearGauge" showEvent="mouseenter" hideEvent="mouseleave">
        <div *dxTemplate="let data = data of 'content'">
          <b>{{ 'Das Produkt ist: ' | translate }}{{ validationService.validationResult.validGroupName }}.</b><br /><br />
            <div *ngIf="validationService.validationResult.missingForNextGroup && validationService.validationResult.missingForNextGroup.length > 0">
              {{ 'Für die nächste Stufe muss folgendes Ergänzt werden' | translate }}:<br />
              <div *ngFor="let print of validationService.validationResult.missingForNextGroup;">
                • {{ print }}
              </div>
            </div>
          <div *ngIf="validationService.validationResult.missingForNextGroup && validationService.validationResult.missingForNextGroup.length == 0">
            {{ 'Herzlichen Grlückwunsch' | translate }}.<br />
            {{ 'Es gibt keine weiteren Hinweise' | translate }}
          </div>
        </div>
      </dx-tooltip>
    </div>    
  </div>
  <div *ngIf="model.changeInfo!=null">
    <div class="headerValidation">
      {{ 'Produktänderung' | translate }}<br>
      <ul>
        <li *ngIf="model.changeInfo.isNew">
          {{ 'Neues Produkt' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isDeleted">
          {{ 'Produkt entfernt' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isBaseProductChanged">
          {{ 'Produktinformationen' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isMimeChanged">
          {{ 'Bilder' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isLogisticChanged">
          {{ 'Logistik' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isFeatureChanged">
          {{ 'Features' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isUdxChanged">
          {{ 'UDX' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isOrderDetailChanged">
          {{ 'OrderDetails' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isKeywordChanged">
          {{ 'Keywords' | translate }}
        </li>
        <li *ngIf="model.changeInfo.isReferencesChanged">
          {{ 'Referencen' | translate }}
        </li>
      </ul>
    </div>
  </div>
</div>

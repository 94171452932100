import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { Product } from "../../Model/Catalog/product";
import { Message } from "../../Model/System/Message";
import { TranslateService } from '@ngx-translate/core';
import { AddClassification } from '../../Model/classification/AddClassification';
import { FeatureSystem } from '../../Model/Catalog/FeatureModels/FeatureSystem';
import { FeatureValueContainer } from '../../Model/classification/FeatureValueContainer';
import { UserManagementService } from "../../Services/userManagment.service";
import { MassDataChangeService } from "../../Services/CatalogManagement/massDataChange.service";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { Output, EventEmitter } from '@angular/core';
import { InvoiceService } from "../../Services/invoice.service";
import { TeachingSet } from "../../Model/OpenAi/teachingset";
import { InvoiceSettingDto } from "../../Model/Dto/InvoiceSettingDto";
import { TeachingSetService } from "../../Services/teachingSet.service";
import { Catalog } from "../../Model/Catalog/Catalog";
import { ValidationService } from "../../Services/Validation/validation.service";

@Component({
  selector: "productDetail",
  templateUrl: "productDetail.component.html",
  styleUrls: ["./../shared/detail.css", "productDetail.component.css"]
})
export class ProductDetailComponent implements OnInit {
  @Output() onMassDataEdit = new EventEmitter<Product>();

  confirmVisible: boolean = false;
  invoiceSetting: InvoiceSettingDto;
  teachingSet: TeachingSet;
  teachingSets: TeachingSet[];
  generateHTML = false;

  constructor(public modelService: ModelService,
    public translate: TranslateService,
    public validationService: ValidationService,
    public userManagement: UserManagementService,
    public massDataService: MassDataChangeService,
    public catalogService: CatalogService,
    public invoiceService: InvoiceService,
    public teachingSetService: TeachingSetService) {
    this.goToSourceProduct = this.goToSourceProduct.bind(this);
    this.openMassDataChange = this.openMassDataChange.bind(this);
    this.isMaster = this.isMaster.bind(this);
    this.generateKiText = this.generateKiText.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
    this.confirmGenerate = this.confirmGenerate.bind(this);
    this.share = this.share.bind(this);
  }

  ngOnInit(): void {
    this.translateTexts();
    this.reset();
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  hasSourceProduct(): boolean {
    return this.model.sourceProductId != undefined;
  }

  thinkingVisible: boolean = false;
  txtProdukt: string;
  txtMedien: string;
  txtUdx: string;
  txtFeatures: string;
  txtPreislisten: string;
  txtSEO: string;
  txtLogistik: string;
  txtReferenzen: string;
  txtProduktUnbekannt: string;
  txtCategories: string;
  txtChange: string;
  txtLegal: string;
  txtOrderDetail: string;
  txtProductAsJson: string;
  txtSuppliers: string;
  txtElectronicSales: string;

  translateTexts() {
    this.txtProdukt = this.translate.instant("Produkt");
    this.txtMedien = this.translate.instant("Medien");
    this.txtUdx = this.translate.instant("Udx");
    this.txtFeatures = this.translate.instant("Features");
    this.txtPreislisten = this.translate.instant("Preislisten");
    this.txtOrderDetail = this.translate.instant("Bestelldetails");
    this.txtSEO = this.translate.instant("SEO - SEA");
    this.txtLogistik = this.translate.instant("Logistik");
    this.txtReferenzen = this.translate.instant("Referenzen");
    this.txtProduktUnbekannt = this.translate.instant("Produkt unbekannt");
    this.txtCategories = this.translate.instant("Kategorien");
    this.txtLegal = this.translate.instant("Recht");
    this.txtProductAsJson = this.translate.instant("JSON");
    this.txtSuppliers = this.translate.instant("Lieferanten");
    this.txtElectronicSales = this.translate.instant('ElectronicSales');
  }

  reset() {
    if (this.modelService.catalogService.catalog.usePrintView) {
      this.pages = [
        { id: 1, title: this.txtProdukt, template: "productTemplate", type: "MAIN" },
        { id: 2, title: this.txtMedien, template: "medienTemplate", type: "MIME" },
        { id: 3, title: this.txtFeatures, template: "featuresTemplate", type: "FEAT" },
        { id: 4, title: this.txtOrderDetail, template: "orderDetailTemplate", type: "ORDER" },
        { id: 5, title: this.txtPreislisten, template: "preislistenTemplate", type: "PRICE" },
        { id: 9, title: this.txtCategories, template: "categoriesTemplate", type: "CAT" },
      ];
    } else {
      this.pages = [
        { id: 1, title: this.txtProdukt, template: "productTemplate", type: "MAIN" },
        { id: 2, title: this.txtMedien, template: "medienTemplate", type: "MIME" },
        { id: 3, title: this.txtFeatures, template: "featuresTemplate", type: "FEAT" },
        { id: 4, title: this.txtOrderDetail, template: "orderDetailTemplate", type: "ORDER" },
        { id: 5, title: this.txtPreislisten, template: "preislistenTemplate", type: "PRICE" },
        { id: 6, title: this.txtLogistik, template: "logistikTemplate", type: "LOGI" },
        { id: 7, title: this.txtReferenzen, template: "referenceTemplate", type: "REFE" },
        { id: 8, title: this.txtCategories, template: "categoriesTemplate", type: "CAT" }
      ];

      if (this.modelService.loginService.hasAddonUDX) {
        this.pages.push({ id: 9, title: this.txtUdx, template: "udxTemplate", type: "UDX" });
      }
      if (this.modelService.loginService.hasAddonSEO) {
        this.pages.push({ id: 10, title: this.txtSEO, template: "seoTemplate", type: "SEO" });
      }
      if (this.modelService.loginService.hasAddonLEGALITY) {
        this.pages.push({ id: 11, title: this.txtLegal, template: "legalTemplate", type: "LEGAL" });
      }

      if (this.modelService.loginService.hasAddonElectronicSales) {
        this.pages.push({ id: 12, title: this.txtElectronicSales, template: "electronicSalesTemplate", type: "ESALES" });
      }
      if (this.modelService.loginService.hasAddonWawiErp) {
        this.pages.push({ id: 13, title: this.modelService.loginService.wawiSettings.name, template: "wawiTemplate", type: "WAWI" });
      }

      if (this.modelService.loginService.hasAddonDEVELOPMENT) {
        this.pages.push({ id: 14, title: this.txtProductAsJson, template: "productAsJsonTemplate", type: "JSON" });
      }
      if (this.modelService.loginService.hasAddonSUPPLIERS) {
        this.pages.push({ id: 15, title: this.txtSuppliers, template: "productSuppliersTemplate", type: "SUPP" });
      }
    }
  }


  hasCatalogMappings(model: Product): boolean {

    if (model.catalogMappings && model.catalogMappings[1])
      return true;

    return false;

  }

  pages: { id: number; title: string; template: string; type: string; }[] = [];
  currentPageType: string = "MAIN";

  get model(): Product {
    return this.modelService.catalogService.product;
  }

  set model(product: Product) {
    this.modelService.catalogService.product = product;
    this.validationService.validateProduct(this.modelService.catalogService.product);

  }

  //ShopView
  shopViewUpdate(event) {
    if (event.value == true) {
      this.modelService.hazmatClassService.loadProductHazmat(this.model);
      this.modelService.warrantyClassService.loadProductWarranty(this.model);
      this.validationService.validateProduct(this.modelService.catalogService.product);
    }
  }

  addClassificationFeatures(event) {
    if (this.modelService.classificationService.selectedClassId == "") return;
    let dto = new AddClassification();
    dto.classificationClassId = this.modelService.classificationService.selectedClassId;
    dto.classificationSystemId = this.modelService.classificationService.selectedSystem.id;
    dto.customerId = this.modelService.catalogService.product.customerId;
    dto.productId = this.modelService.catalogService.product.id;

    this.modelService.classificationService.addClassification(dto).subscribe(
      (result: FeatureSystem[]) => {
        this.modelService.catalogService.product.featureSystems = new Array<FeatureSystem>();

        let that = this;
        result.forEach(function (r) {
          let fs = Object.assign(new FeatureSystem(), r);
          that.modelService.catalogService.product.featureSystems.push(fs);
        });
      });
  }



  get showShop(): boolean {
    return this.modelService.systemService.showShop;
  }

  set showShop(value) {
    this.modelService.systemService.showShop = value;
  }

  get templateLukas(): boolean {
    return this.modelService.systemService.templateLukas;
  }

  set templateLukas(value) {
    this.modelService.systemService.templateLukas = value;
  }

  get shopViewModel() {
    if (this.modelService.translationService.productTranslation != null)
      return this.modelService.translationService.productTranslation;
    return this.model;
  }

  public tabChange(event) {
    if (this.model.hintsActiv) {
      this.modelService.catalogService.validateProductForm(event.itemData.type);
    }
    this.currentPageType = event.itemData.type;
  }

  //ProductInfos
  public translationUpdateFunctions(params) {
    if (params.txtOrg == "" || params.txtOrg == null) {
      this.translate.get("Ohne deutschen Eintrag, ist keine Übersetzung möglich").subscribe((text: string) => {
        this.modelService.systemService.notify(new Message(text, "error"));
      });

      return;
    }
    this.modelService.translationService.translationUpdate(params.txtOrg, params.txtTran);
  }

  //Reload Product z.b. für Mimes
  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService.getProduct(this.modelService.catalogService.product.id, this.modelService.loginService.currentUser.customerId, this.modelService.catalogService.catalog.id).subscribe(() => {
      this.validationService.validateProduct(this.modelService.catalogService.product);
    });
  }

  productUpdate(event = null) {
    try {
      this.modelService.catalogService.updateProduct(event).then(() => {
        this.validationService.validateProductPromise(this.model).then(() => {
        });
      }).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log("error" + error);
    }
  }

  get productMainImage(): string {
    if (this.modelService.catalogService.product != null && this.modelService.catalogService.product.mimes && this.modelService.catalogService.product.mimes.length > 0) {

      for (mime of this.modelService.catalogService.product.mimes) {
        if (mime.mimePurpose == "normal")
          if (mime.normUri) {
            return mime.normUri;
          }
      }

      var mime = this.modelService.catalogService.product.mimes[0];
      if (mime.normUri) {
        return mime.normUri;
      }

    }
    return null;
  }

  //Referencen nachladen
  onReferencePrepare(event) {
    if (event.columnIndex == 3 && event.rowType == "data") {
      if (event.data.artIdTo != null) {
        this.modelService.catalogService.getProductBySupplierPid(
          event.data.artIdTo,
          this.modelService.loginService.currentUser.customerId,
          this.modelService.catalogService.catalog.id
        ).subscribe(
          (result) => {
            event.data.descriptionShort = result.descriptionShort;
            for (let mime of result.mimes.sort((a, b) => (a.mimeOrder > b.mimeOrder) ? 1 : -1)) {
              if (mime.mimePurpose == "normal" && mime.normUri != null) {
                event.data.normUri = mime.normUri;
              }
            }

          },
          error => {
            event.data.descriptionShort = "";
            event.data.normUri = "";
          }
        );
      } else {
        event.data.descriptionShort = "";
        event.data.normUri = "";
      }
    }
  }

  public validateProductForm() {
    this.modelService.catalogService.validateProductForm(this.currentPageType);
  }

  public openProductView() {
    //let catalogId = "";
    //if (this.model.catalogMappings.length > 0) {
    //  catalogId = this.model.catalogMappings[0].catalogId;
    //} else {
    let catalogId = this.modelService.catalogService.selectedCatalogId;
    //}

    //this.modelService.router.navigate([
    //  "/getProduct", {
    //    queryParams: { catalogId: catalogId, customerId: this.model.customerId, supplierPid: this.model.supplierPid }
    //  }]);         
    window.open("/getProduct?catalogId=" + catalogId + "&customerId=" + this.model.customerId + "&supplierPid=" + this.model.supplierPid);
  }

  get bigHeader() {
    if (window) {
      return (window.innerHeight > 1000);
    }
    return true;
  }

  addEcommerceFeatures() {
    this.modelService.catalogService.addEcommerceFeatures(this.model).subscribe(
      (product: Product) => {
        this.model.featureSystems = product.featureSystems;
        //this.isClassificationVisible = false;
      });
  }

  public classificationFeatureValues: FeatureValueContainer = {};

  public loadClassificationFeatures(params) {
    this.modelService.classificationService.getFeatureValues(params.name, params.valueSource).subscribe(result => {
      this.classificationFeatureValues[params.name] = result;
    });
  }



  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }

  isMaster(): boolean {
    return this.model.productCount > 0;
  }

  openMassDataChange() {
    this.massDataService.getEmtpyProductByMasterProduct(this.catalogService.catalog.id, this.modelService.catalogService.product.id).subscribe((result: Product) => {
      this.onMassDataEdit.emit(this.modelService.productService.getProductFromJson(result, this.modelService.loginService.wawiSettings));
    });
  }

  generateKiText() {
    this.thinkingVisible = true;
    this.invoiceService.GetInvoiceSetting(this.modelService.loginService.currentCustomer.id).subscribe(setting => {
      if (setting.usedLimit + setting.pricePerOpenAi > setting.limit) {
        this.modelService.systemService.notifyWarning("Diese Operation würde ihr festgelegtes Limit übersteigen");
        this.thinkingVisible = false;
        return;
      }
      let teachingset = this.teachingSet != null ? this.teachingSet.id : "";
      this.modelService.catalogService.getProductWithGeneratedText(
        this.modelService.catalogService.product, 
        this.modelService.catalogService.catalog.catalogName, 
        teachingset,
        this.generateHTML
      ).subscribe((result: Product) => {
        this.reload();
        this.thinkingVisible = false;
      });
    });
  }

  goToSourceProduct() {
    var currentProduct = this.modelService.catalogService.product;

    if (this.hasSourceProduct()) {
      this.catalogService.getProduct(this.model.sourceProductId, this.model.customerId, "").subscribe((product: Product) => {
        for (var catalogMapping of product.catalogMappings) {
          this.modelService.catalogService.getCatalogNoSet(catalogMapping.catalogId, this.model.customerId).subscribe((catalog: Catalog) => {
            if (!catalog.isVirtual) {
              var navigateString = "/catalog/" + this.model.customerId + "/" + catalog.id + "/product/" + product.id;
              this.modelService.catalogService.selectedCatalogId = catalog.id;
              this.modelService.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.modelService.router.navigate([navigateString]));
              return;
            }
          });
        }
      });
    } else {
      for (var catalogMapping of currentProduct.catalogMappings) {
        this.modelService.catalogService.getCatalogNoSet(catalogMapping.catalogId, this.model.customerId).subscribe((catalog: Catalog) => {
          if (!catalog.isVirtual) {
            var navigateString = "/catalog/" + this.model.customerId + "/" + catalog.id + "/product/" + currentProduct.id;
            this.modelService.catalogService.selectedCatalogId = catalog.id;
            this.modelService.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.modelService.router.navigate([navigateString]));
            return;
          }
        });
      }
    }
  }

  openConfirm() {

    this.invoiceService.GetInvoiceSetting(this.modelService.loginService.currentCustomer.id).subscribe(setting => {
      this.invoiceSetting = setting;
      this.teachingSetService.getAllTrained().subscribe(sets => {
        this.teachingSets = sets;
        this.teachingSets.push({ name: "Standard", id: "", customerId: this.modelService.loginService.currentCustomer.id.toString(), samples: [], state: 2 } as TeachingSet);

        if (this.teachingSets.length > 0)
          this.teachingSet = this.teachingSets[0];

        this.confirmVisible = true;
      });
    });
  }

  confirmGenerate() {
    this.generateKiText();
    this.confirmVisible = false;
  }

  share() {
    let catalogId = this.modelService.catalogService.selectedCatalogId;
    let categoryId = "";
    if (this.model.catalogMappings.length > 0) {
      categoryId = this.model.catalogMappings[0].categoryId;
    }
    //this.clipboard.copy("/catalog/" + catalogId + "/" + this.model.supplierPid);
    window.open("/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + catalogId + "/" + categoryId + "/" + this.model.id);
  }
}

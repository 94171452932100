<dx-toolbar *ngIf="!templateView && hasLines() && canView('WAWI_CreateLines')">
  <dxi-item location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Bezeichnungen erzeugen' | translate, onClick: createLines }"></dxi-item>
</dx-toolbar>

<div class="dx-fieldset" *ngIf="model">

  <div class="np-field">
    <np-inputControl *ngIf="modelService.loginService.wawiSettings.hasLine1"
                     [(model)]="model"
                     [showLinkButtons]="showLinkButtons"
                     [showPlaceLabel]="model?.wawi?.line1?.length > 0"
                     [(selectedElements)]="selectedElements"
                     (elementSelected)="elementSelectFunction($event)"
                     (onValueChanged)="update($event)"
                     labelText="{{ 'Bezeichnung' | translate }} 1"
                     field="LINE_1"
                     [showDragHandle]="showDragHandle"
                     [dragFilter]="dragFilter"
                     (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.wawi.line1"
                     [readOnly]="readOnlyForLinkedFields('LINE_1')|| readOnly('WAWI_LINE_1')"
                     [showClearButton]="!readOnlyForLinkedFields('LINE_1')"
                     (onValueChanged)="update($event, 'LINE_1')"
                     [maxLength]="modelService.loginService.wawiSettings.line1Length">
          <dx-validator *ngIf="!templateView" validationGroup="WAWI">
            <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('LINE_1')"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>

  <div class="np-field">
    <np-inputControl *ngIf="modelService.loginService.wawiSettings.hasLine2"
                     [(model)]="model"
                     [showLinkButtons]="showLinkButtons"
                     [showPlaceLabel]="model?.wawi?.line2?.length > 0"
                     [(selectedElements)]="selectedElements"
                     (elementSelected)="elementSelectFunction($event)"
                     (onValueChanged)="update($event)"
                     labelText="{{ 'Bezeichnung' | translate }} 2"
                     field="LINE_2"
                     [showDragHandle]="showDragHandle"
                     [dragFilter]="dragFilter"
                     (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.wawi.line2"
                     [maxLength]="modelService.loginService.wawiSettings.line2Length"
                     [readOnly]="readOnlyForLinkedFields('LINE_2') || readOnly('WAWI_LINE_2')"
                     [showClearButton]="!readOnlyForLinkedFields('LINE_1')"
                     (onValueChanged)="update($event, 'LINE_2')">
          <dx-validator *ngIf="!templateView" validationGroup="WAWI">
            <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('LINE_2')"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>

  <div class="np-field">
    <np-inputControl *ngIf="modelService.loginService.wawiSettings.hasLine3"
                     [(model)]="model"
                     [showLinkButtons]="showLinkButtons"
                     [showPlaceLabel]="model?.wawi?.line3?.length > 0"
                     [(selectedElements)]="selectedElements"
                     (elementSelected)="elementSelectFunction($event)"
                     (onValueChanged)="update($event)"
                     labelText="{{ 'Bezeichnung' | translate }} 3"
                     field="LINE_3"
                     [showDragHandle]="showDragHandle"
                     [dragFilter]="dragFilter"
                     (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.wawi.line3"
                     [maxLength]="modelService.loginService.wawiSettings.line3Length"
                     [readOnly]="readOnlyForLinkedFields('LINE_3')|| readOnly('WAWI_LINE_3')"
                     [showClearButton]="!readOnlyForLinkedFields('LINE_3')"
                     (onValueChanged)="update($event, 'LINE_3')">
          <dx-validator *ngIf="!templateView" validationGroup="WAWI">
            <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('LINE_3')"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>

  <div class="np-field">
    <np-inputControl *ngIf="modelService.loginService.wawiSettings.hasLine4"
                     [(model)]="model"
                     [maxLength]="modelService.loginService.wawiSettings.line4Length"
                     [showLinkButtons]="showLinkButtons"
                     [showPlaceLabel]="model?.wawi?.line4?.length > 0"
                     [(selectedElements)]="selectedElements"
                     (elementSelected)="elementSelectFunction($event)"
                     (onValueChanged)="update($event)"
                     labelText="{{ 'Bezeichnung' | translate }} 4"
                     field="LINE_4"
                     [showDragHandle]="showDragHandle"
                     [dragFilter]="dragFilter"
                     (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.wawi.line4"
                     [maxLength]="modelService.loginService.wawiSettings.line4Length"
                     [readOnly]="readOnlyForLinkedFields('LINE_4')|| readOnly('WAWI_LINE_4')"
                     [showClearButton]="!readOnlyForLinkedFields('LINE_4')"
                     (onValueChanged)="update($event, 'LINE_4')">
          <dx-validator *ngIf="!templateView" validationGroup="WAWI">
            <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('LINE_4')"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>
</div>

<div *ngFor="let customField of model.wawi.customFields">
  <div class="dx-fieldset">
    <div [ngSwitch]="getFieldType(customField.order)">
      <div class="np-field">
        <np-inputControl *ngSwitchCase="'String'"
                         [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="customField?.stringValue?.length > 0"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event, customField.order.toString())"
                         (onValueChanged)="update($event)"
                         [labelText]="getLabel(customField.order)"
                         field="CUSTOM_FIELD"
                         [system]="customField.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="customField.stringValue"
                         [readOnly]="readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())|| readOnly('CUSTOM_FIELD')"
                         [showClearButton]="!readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())"
                         (onValueChanged)="update($event, 'CUSTOM_FIELD', customField.order.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="WAWI">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField(getLabel(customField.order))"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </np-inputControl>

        <np-inputControl *ngSwitchCase="'Number'"
                         [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="customField?.numberValue != null"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event, customField.order.toString())"
                         (onValueChanged)="update($event)"
                         [labelText]="getLabel(customField.order)"
                         field="CUSTOM_FIELD"
                         [system]="customField.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-number-box [(value)]="customField.numberValue"
                           [readOnly]="readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())|| readOnly('CUSTOM_FIELD')"
                           [showClearButton]="!readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())"
                           (onValueChanged)="update($event, 'CUSTOM_FIELD', customField.order.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="WAWI">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField(getLabel(customField.order))"></dxi-validation-rule>
              </dx-validator>
            </dx-number-box>
          </div>
        </np-inputControl>

        <np-inputControl *ngSwitchCase="'Boolean'"
                         [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [suppressPlaceLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event, customField.order.toString())"
                         (onValueChanged)="update($event)"
                         [labelText]="getLabel(customField.order)"
                         field="CUSTOM_FIELD"
                         [system]="customField.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot np-others">
            <dx-switch (onValueChanged)="update($event, 'CUSTOM_FIELD', customField.order.toString())"
                       [(value)]="customField.boolValue"
                       [readOnly]="readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString()) || readOnly('CUSTOM_FIELD')"
                       switchedOffText="{{ 'Nein' | translate }}"
                       switchedOnText="{{ 'Ja' | translate }}">
              <dx-validator *ngIf="!templateView" validationGroup="WAWI">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField(getLabel(customField.order))"></dxi-validation-rule>
              </dx-validator>
            </dx-switch>
          </div>
        </np-inputControl>

        <np-inputControl *ngSwitchCase="'Mapping'"
                         [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="customField?.listId != undefined"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event, customField.order.toString())"
                         (onValueChanged)="update($event)"
                         [labelText]="getLabel(customField.order)"
                         field="CUSTOM_FIELD"
                         [system]="customField.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         [suppressPlaceLabel]="false"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-select-box [dataSource]="wawiListDataSource"
                           [(value)]="customField.listId"
                           displayExpr="listName"
                           valueExpr="id"
                           placeholder=""
                           [searchEnabled]="true"
                           [readOnly]="readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())|| readOnly('CUSTOM_FIELD')"
                           [showClearButton]="!readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())"
                           (onValueChanged)="update($event, 'CUSTOM_FIELD', customField.order.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="WAWI">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField(getLabel(customField.order))"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </np-inputControl>

        <np-inputControl *ngSwitchCase="'List'"
                         [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="customField?.elementId != undefined"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event, customField.order.toString())"
                         (onValueChanged)="update($event)"
                         [labelText]="getLabel(customField.order)"
                         field="CUSTOM_FIELD"
                         [system]="customField.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         [suppressPlaceLabel]="false"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-select-box [dataSource]="getListElements(customField)"
                           [(value)]="customField.elementId"
                           displayExpr="value"
                           valueExpr="id"
                           placeholder=""
                           [searchEnabled]="true"
                           [readOnly]="readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())|| readOnly('CUSTOM_FIELD')"
                           [showClearButton]="!readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())"
                           (onValueChanged)="update($event, 'CUSTOM_FIELD', customField.order.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="WAWI">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField(getLabel(customField.order))"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>
        </np-inputControl>

        <np-inputControl *ngSwitchCase="'Html'"
                         [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="customField?.stringValue?.length > 0"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event, customField.order.toString())"
                         (onValueChanged)="update($event)"
                         [labelText]="getLabel(customField.order)"
                         field="CUSTOM_FIELD"
                         [system]="customField.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-html-editor class="w-100"
                            [(value)]="customField.stringValue"
                            [readOnly]="readOnlyForLinkedFields('CUSTOM_FIELD', customField.order.toString())|| readOnly('CUSTOM_FIELD')"
                            (onFocusOut)="update($event, 'CUSTOM_FIELD', customField.order.toString())"
                            allowSoftLineBreak="true">
              <dxo-toolbar>
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
              </dxo-toolbar>
              <dx-validator *ngIf="!templateView" validationGroup="WAWI">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField(getLabel(customField.order))"></dxi-validation-rule>
              </dx-validator>
            </dx-html-editor>
          </div>
        </np-inputControl>


      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { Product } from "../../../Model/Catalog/product";
import { CatalogService } from "../../../Services/CatalogManagement/catalog.service";

@Component({
  selector: "productAsJson",
  templateUrl: "productAsJson.component.html",
  styleUrls: ["../template.css"]
})

export class ProductAsJsonComponent implements OnInit {
  productDto: Product;
  product;

  constructor(catalogService: CatalogService) {
    catalogService.getProduct(catalogService.product.id, catalogService.loginService.currentCustomer.id, catalogService.catalog.id)
      .subscribe(result => {
        this.productDto = result;
      });
    catalogService.getRawProduct(catalogService.product.id, catalogService.loginService.currentCustomer.id)
      .subscribe(result => {
        this.product = result;
      });
  }

  ngOnInit(): void { }
}

import { Input, Output, EventEmitter, Component } from "@angular/core";
import { Product } from '../../Model/Catalog/product';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { ValidationService } from "../../Services/Validation/validation.service";

//TODO: validateProductForm

@Component({
  selector: "productDetailValidation",
  templateUrl: "productDetailValidation.component.html",
  styleUrls: ["./productDetailValidation.component.css"]
})
export class ProductDetailValidationComponent{
  @Input() model: Product = new Product;
  @Input() showToolTips: Boolean = true;
  @Input() hasAddonVALIDATION: Boolean = true;
  @Input() isPrint: Boolean = false;
  @Input() biggerHeader: Boolean = null;

  @Output() validateProduct = new EventEmitter<any>();

  validationGroupColors: string[];

  constructor(public validationService: ValidationService, public catalogService: CatalogService) {
  }

  get bigHeader() {
    if (this.biggerHeader != null) {
      return this.biggerHeader;
    }
    if (window) {
      return (window.innerHeight > 1000);
    }
    return true;
  }

  public validateProductForm() {
    this.validationService.validateProduct(this.catalogService.product);
    this.validateProduct.emit();
  }

  private emptyArray = [false, false, false, false, false, false];
  public v: boolean[] = this.emptyArray;

  //USABILITY

  ttt(id) {
    if (this.showToolTips) {
      if (!this.v[id]) {
        this.v = this.emptyArray;
      }
      this.v[id] = !this.v[id];
    }
  }
}



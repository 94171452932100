<div>
  <div class="dx-fieldset" [ngStyle]="{ margin: '20px 30px' }">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Medien exportieren' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.includeMime"></dx-switch>
      </div>
    </div>
    <div class="dx-field" *ngIf="!exportParameter.includeMime">
      <div class="dx-field-label">{{ 'Medien als Url verwenden' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.useMediaUrl"></dx-switch>
      </div>
    </div>

    <div class="dx-field" *ngIf="exportParameter.includeMime">
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Original verwenden' | translate }}</div>
          <div class="dx-field-value">
            <dx-switch [(value)]="exportParameter.orignialSize"></dx-switch>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Quadratische Ansicht' | translate }}</div>
          <div
            class="dx-field-value {{
              exportParameter.imageSquared ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder'
            }}"
          >
            <dx-switch [(value)]="exportParameter.imageSquared"></dx-switch>
          </div>
        </div>
        <div class="dx-field" *ngIf="exportParameter.imageSquared">
          <div class="dx-field-label">{{ 'Hintergrundfarbe wählen' | translate }}</div>
          <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
            <label class="placeLabel {{ exportParameter.imageBgColor ? 'placeLabelSmall' : '' }}">
              BACKGROUND COLOR
            </label>
            <dx-color-box [(value)]="exportParameter.imageBgColor"></dx-color-box>
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">{{ 'Maximale Größe (mb) (0 = kein Maximum)' | translate }}</div>
          <div class="dx-field-value">
            <dx-number-box
              [min]="0"
              [max]="100000000"
              [(value)]="exportParameter.maxMediaSize"
              [showClearButton]="true"
            ></dx-number-box>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!exportParameter.orignialSize">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Maximale Seitenlänge' | translate }}</div>
        <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ maxResolution > 0 ? 'placeLabelSmall' : '' }}">MAX RESOLUTION</label>
          <dx-number-box
            [min]="16"
            [max]="8192"
            [(value)]="exportParameter.maxResolution"
            [showClearButton]="true"
          ></dx-number-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">{{ 'Dateityp' | translate }}</div>
        <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
          <label class="placeLabel {{ imageType?.length > 0 ? 'placeLabelSmall' : '' }}">FILE TYPE</label>
          <dx-select-box
            [(value)]="exportParameter.imageType"
            [items]="imageTypes"
            [searchEnabled]="true"
            placeholder=""
          ></dx-select-box>
        </div>
      </div>
    </div>

    <div *ngIf="modelService.loginService.hasAddonLEGALITY" class="dx-field">
      <div class="dx-field-label">{{ 'Rechtliche Zusatzinformationen exportieren' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.includeLegal"></dx-switch>
      </div>
    </div>

    <div *ngIf="modelService.loginService.hasAddonLEGALITY" class="dx-field">
      <div class="dx-field-label">{{ 'Gefahrstoffklassen an Beschreibung anhängen' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="exportParameter.includeHazmatClassesInDescription"></dx-switch>
      </div>
    </div>
  </div>
</div>

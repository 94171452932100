
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { ClassificationSystem } from "../Model/classification/ClassificationSystem";
import { ClassificationClassTree } from "../Model/classification/ClassificationClassTree";
import { ClassificationClass } from "../Model/classification/ClassificationClass";
import { Product } from '../Model/Catalog/product';
import { AddClassification } from '../Model/classification/AddClassification';
import { map } from 'rxjs/operators';
import { FeatureSystem } from '../Model/Catalog/FeatureModels/FeatureSystem';
import { FeatureValue } from '../Model/classification/FeatureValue';


@Injectable()
export class ClassificationService {


  selectedSystem: ClassificationSystem;
  selectedClass: ClassificationClass;
  _selectedClassId: string = "";

  get selectedClassId(): string {
    return this._selectedClassId;
  }

  set selectedClassId(value: string) {
    if (value != this.selectedClassId && value != "") {
      this.getClass(value).subscribe(
        (cs: ClassificationClass) => {
          this.selectedClass = cs;
        });
    } else if (!value) {
      this.selectedClass = undefined;
    }
    this._selectedClassId = value;
  }


  constructor(private http: HttpClient) {

  }

  classificationSystems: ClassificationSystem[] = [];

  public isClassificationSystem(name: string) {
    const key = this.classificationSystems.findIndex((classificationSystem => classificationSystem.name === name));

    return key !== -1 ? true : false;
  }

  public getSystems() {     
    this.http.get("api/Classification/getSystems").subscribe(
      (result: ClassificationSystem[]) => {
        this.classificationSystems = result;
      });
  }


  public getSystemsOfCustomer(customerId : string, language:string): Observable<ClassificationSystem[]>  {
    const url = `api/Classification/GetSystemsOfCustomer`;
    const params = new HttpParams().append("customerId", customerId).append("language", language);
    const options = { params };
    return this.http.get<ClassificationSystem[]>(url, options); 
  }

  public writeSystem(cs: ClassificationSystem): Observable<ClassificationSystem> {

    return (this.http.post("api/Classification/AddOrUpdate", cs)) as any;


  }

  public addClassification(addClassification: AddClassification): Observable<FeatureSystem[]> {

    return (this.http.post("api/Classification/AddClassification", addClassification)) as any;


  }
  deleteSystem(id) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id)
    }

    this.http.delete("api/Classification/DeleteSystem", options)
      .subscribe(
        result => { },
        err => console.error(err)
      );
  }

  getClass(id: string): Observable<ClassificationClass> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id)
    }
    return (this.http.get<ClassificationClassTree>("api/Classification/GetClassificationClass", options)) as any;
  }

  getTree(id: string): Observable<ClassificationClassTree[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("id", id)
    }
    return (this.http.get<ClassificationClassTree[]>("api/Classification/GetTree", options)) as any;
  }


  tree: ClassificationClassTree[];

  async getTreeFiltered(classificationSystemId: string, parentId: string, filter: string[]): Promise<ClassificationClassTree[]> {

    let params = new HttpParams().append("classificationSystemId", classificationSystemId).append("parentId", parentId)

    if (filter)
      params = params.append("filter", filter.join(";"));

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: params
    }

    return await this.http.get<ClassificationClassTree[]>("api/Classification/GetTreeFiltered", options).pipe(
      map((result: ClassificationClassTree[]) => this.tree = result)).toPromise();

  }








  deleteClassTree(element: ClassificationClassTree) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", element.id)
      }

      this.http.delete("api/Classification/DeleteClassTree", options)
        .subscribe(
          result => { },
          err => console.error(err)
        );
    }
  }

  updateClassTree(element: ClassificationClassTree): Observable<ClassificationClassTree> {

    return (this.http.post("api/Classification/UpdateClassTree", element)) as any;
  }

  updateClass(selectedClass: ClassificationClass): Observable<ClassificationClass> {
    return (this.http.post("api/Classification/UpdateClassificationClass", selectedClass)) as any;
  }

  getFeatureValues(featureName, classId): Observable<FeatureValue[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("featureName", featureName)
        .append("classId", classId)
    }
    return (this.http.get<FeatureValue[]>("api/Classification/GetFeatureValues", options)) as any;
  }
}

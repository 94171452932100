import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { Select } from 'app/Model/ui/Select';
import { WawiList } from 'app/Model/wawi/WawiList';

@Component({
  selector: 'np-advanced-template-fieldsettings',
  templateUrl: './advanced-template-fieldsettings.component.html',
  styleUrls: []
})
export class AdvancedTemplateFieldsettingsComponent implements OnInit {
  @Input() showPlaceHolders = true;
  @Input() templateItem: TemplateItem;
  @Input() mappings: WawiList[];
  @Output() onUpdate = new EventEmitter<void>();

  calculationImport: Select[] = [];
  calculationExport: Select[] = [];
  numberformat: Select[] = [];
  seperator: Select[] = [];
  operators: Select[] = [];

  constructor(private translate: TranslateService){}

  ngOnInit() {
    this.calculationImport = [
      new Select('', '', ''),
      new Select(this.translate.instant('g in kg'), this.translate.instant('kg in g'), 'GINKG'),
      new Select(this.translate.instant('t in kg'), this.translate.instant('kg in t'), 'TINKG'),
      new Select(this.translate.instant('mm in m'), this.translate.instant('m in mm'), 'MMINM'),
      new Select(this.translate.instant('cm in m'), this.translate.instant('m in cm'), 'CMINM'),
      new Select(this.translate.instant('Stück in C62'), this.translate.instant('C62 in Stück'), 'STKINC62'),
      new Select(this.translate.instant('Faktor'), this.translate.instant('Faktor'), 'FACTOR'),
    ];
    this.calculationExport = [
      new Select('', '', ''),
      new Select(this.translate.instant('g in kg'), this.translate.instant('kg in g'), 'GINKG'),
      new Select(this.translate.instant('t in kg'), this.translate.instant('kg in t'), 'TINKG'),
      new Select(this.translate.instant('mm in m'), this.translate.instant('m in mm'), 'MMINM'),
      new Select(this.translate.instant('cm in m'), this.translate.instant('m in cm'), 'CMINM'),
      new Select(this.translate.instant('Faktor'), this.translate.instant('Faktor'), 'FACTOR'),
    ];
    this.numberformat = [
      new Select('', '', ''),
      new Select('1.900,00', '1.900,00', ','),
      new Select('1,900.00', '1,900.00', '.')
    ];
    this.seperator = [
      new Select('', '', ''),
      new Select(this.translate.instant('Leerzeichen'), this.translate.instant('Leerzeichen'), ' '),
      new Select('_', '_', '_'),
      new Select('|', '|', '|'),
      new Select(',', ',', ','),
      new Select('.', '.', '.'),
      new Select(';', ';', ';')
    ];
    this.operators = [
      new Select('', '', 'NONE'),
      new Select(this.translate.instant('Addieren'), this.translate.instant('Addieren'), 'ADDITION'),
      new Select(this.translate.instant('Subtrahieren'), this.translate.instant('Subtrahieren'), 'SUBTRACTION'),
      new Select(this.translate.instant('Multiplizieren'), this.translate.instant('Multiplizieren'), 'MULTIPLICATION'),
      new Select(this.translate.instant('Dividieren'), this.translate.instant('Dividieren'), 'DIVISION')
    ];
  }
}

import { Component, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { OsgConnection } from "../../Model/Dto/osgConnection";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";
import { TranslateService } from "@ngx-translate/core";



@Component({
  selector: "osgCon",
  templateUrl: "osgConnection.component.html"
})
export class OsgConnectionComponent implements OnInit {
  connection2Shop: boolean;
  pages = [];
  constructor(public modelService: ModelService, public translate: TranslateService) {

    this.testShopConnectionLive = this.testShopConnectionLive.bind(this);
    this.testShopConnectionTest = this.testShopConnectionTest.bind(this);

    let testText = "";
    testText = this.translate.instant("Test");
    let liveText = "";
    liveText = this.translate.instant("Live");

    this.pages = [
      { id: 1, title: liveText, template: "liveTemplate" },
      { id: 2, title: testText, template: "testTemplate" }
     
    ];
  }




  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "USER") return false;
    return true;
  }


  get modelTest(): OsgConnection {
    return this.modelService.loginService.osgConnectionTest;
  }
  set modelTest(value: OsgConnection) {
    this.modelService.loginService.osgConnectionTest = value;
  }

  get modelLive(): OsgConnection {
    return this.modelService.loginService.osgConnection;
  }
  set modelLive(value: OsgConnection) {
    this.modelService.loginService.osgConnection = value;
  }



  updateTest() {
    this.modelService.osgConnectionService.update(this.modelTest).subscribe(
      (result: number) => {
        this.modelTest.id = result;
        this.isTestedTest = false;
      });;
  }

  updateLive() {
    this.modelService.osgConnectionService.update(this.modelLive).subscribe(
      (result: number) => {
        this.modelLive.id = result;
        this.isTestedLive = false;
      });;
  }

  public testShopConnectionLive() {

    this.modelService.osgConnectionService.testShopConnection(this.modelLive.id).subscribe((result: TestResult) => this.testShopResultLive(result));
  }
  public testShopConnectionTest() {

    this.modelService.osgConnectionService.testShopConnection(this.modelTest.id).subscribe((result: TestResult) => this.testShopResultTest(result));
  }
  private testShopResultLive(result: TestResult) {
    if (result.success) {
      this.isTestedLive = true;
      this.connection2ShopMessageLive = "";
    } else {
      this.connection2ShopMessageLive = result.message;
      this.isTestedLive = false;
      let msg = new Message();
      msg.type = "error";
      msg.message = this.connection2ShopMessageLive;
      this.modelService.systemService.notify(msg);
    }
  }

  private testShopResultTest(result: TestResult) {
    if (result.success) {
      this.isTestedTest = true;
      this.connection2ShopMessageTest = "";
    } else {
      this.connection2ShopMessageTest = result.message;
      this.isTestedTest = false;
      let msg = new Message();
      msg.type = "error";
      msg.message = this.connection2ShopMessageTest;
      this.modelService.systemService.notify(msg);
    }
  }





  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  connection2ShopMessageTest: string;
  connection2ShopMessageLive: string;
  isTestedTest: boolean;
  isTestedLive: boolean;
}



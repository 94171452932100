<!--15 Löschen per Liste-->
<dx-lookup placeholder="{{'SuchKriterium' | translate}}" [searchEnabled]="false" [items]="searchValues" [(value)]="searchValue">
</dx-lookup>



<div *ngIf="searchValue != 'Hersteller'">
  <div class="dx-text-area-coloured w-100">
    <dx-text-area [value]="inputList" (onValueChanged)="onValueChangedFormatListIgnoreSpace($event)" [height]="400">
    </dx-text-area>
  </div>
</div>


<div *ngIf="searchValue == 'Hersteller'">


  <dx-load-panel #loadPanel
                 shadingColor="rgba(0,0,0,0.4)"
                 [position]="{ of: '#list',  at: 'center' }"
                 [(visible)]="loadingVisible"
                 [showIndicator]="true"
                 [showPane]="true"
                 [shading]="true"
                 [hideOnOutsideClick]="false">
  </dx-load-panel>

  <div class="dx-text-area-coloured w-100" id="list">
    <dx-list [dataSource]="manufacturerDataSource"
             [noDataText]="geNoDataText()"
             [allowItemDeleting]="false"
             [showSelectionControls]="true"
             selectionMode="all"
             showCheckBoxesMode="selectAll"
             [(selectedItems)]="selectedManufacturer"
             [searchEnabled]="true"
             searchMode="contains"> </dx-list>
  </div>
</div>

export class ProductLogistic {

  constructor(
    public countryOfOrigin?: string,
    public customsNumber?: string,
    public depth?: string,
    public length?: string,
    public specialTreatmentClass?: string,
    public specialTreatmentClassType ?: string,
    public weight?: string,
    public width?: string,
    public hazardousMaterialClassId?: string,
    public warrantyClassId?: string,
  ) {

  }
}

import { Input, Output, EventEmitter, Component } from "@angular/core";
import { Product } from '../../Model/catalog/product';
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { UserManagementService } from "../../Services/userManagment.service";
import { ValidationService } from "../../Services/Validation/validation.service";

@Component({
  selector: "seo",
  templateUrl: "seo.html",
  styleUrls: ["./seo.css", "template.css"]
})
export class SEO {
  @Input() model: Product = new Product();
  @Input() showLinkButtons: Boolean = false;

  @Input() templateView: Boolean = false;

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() selectedElements: string[] = [""];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();

  constructor(public userManagement: UserManagementService, public catalogService: CatalogService, public validationService: ValidationService) { }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }



  //ExcelTemplate

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }



  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }



  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) { // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    //// Im Übersetzungsmodus nicht anzeigen
    //if (this.hasTranslation) {
    //  return false;
    //}

    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }



    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }



    // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
    //event.forceTreeRefresh = true;



    // SAVE
    this.update(event);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }



  canView(name: string): boolean {
    return !this.isVirtualCatalog() && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { Subject } from 'rxjs';

@Component({
  selector: 'np-advanced-template-fixed',
  templateUrl: './advanced-template-fixed.component.html',
  styleUrls: ['./advanced-template-fixed.component.css']
})
export class AdvancedTemplateFixedComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() templateItem: TemplateItem;
  @Input() customerId: string;
  @Output() onUpdate = new EventEmitter<void>();

  ngOnInit(): void {
    this.templateItem.pimFields = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

import { Component, ViewChild, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { ProductViewComponent } from '../productView/productView.component';
import { SearchResult } from "../Model/Dto/searchResults";
import { TranslateService } from "@ngx-translate/core";
import { Product } from "../Model/Catalog/product";
import { LoginService } from "../Services/login.service";
import { forEach } from "jszip";

@Component({
  selector: "searchcomponent",
  templateUrl: "./search.component.html",
})
export class SearchComponent implements OnInit {

  constructor(public modelService: ModelService, public translate: TranslateService, public loginService: LoginService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Suche");

  }
  @ViewChild(ProductViewComponent, { static: true }) productView: ProductViewComponent;

  refresh() {        
  }

  ngOnInit() { }

  public searchText: string = "";
  public resultCatalog = "";
  public resultProduct = "";
  public loadingVisible: boolean = false;

  getMimeText(mime) {
    return mime;
  }

  startSearch() {
    this.results = [];

    if (this.searchText == "")
      return;
    this.loadingVisible = true;
    this.modelService.catalogService.searchProducts(this.searchText, this.modelService.loginService.currentUser.customerId, 1, 100)
      .subscribe(
        async (result) => {
          for (let searchResult of result) {
            searchResult.catalogName = await this.getCatalogName(searchResult);  
          }
          this.results = result;
          this.loadingVisible = false;
        },
      );
  }

  public results: SearchResult[] = [];

  testSearch() {
    if (this.searchText.length >= 3 ) {
      this.startSearch();
    }
  }

  async getCatalogName(product: SearchResult): Promise<string> {
    var catalogMapping = product.catalogMappings.find(x => !x.isVirtualCatalog);

    return await this.modelService.catalogService.getCatalogName(catalogMapping.catalogId, this.loginService.currentCustomer.id);
  }


  jumpToProduct(product: Product) {
    var catalogMapping = product.catalogMappings.find(x => !x.isVirtualCatalog);
    var navigateString = "/catalog/" + this.loginService.currentCustomer.id + "/" + catalogMapping.catalogId + "/product/" + product.id;
    this.modelService.catalogService.selectedCatalogId = catalogMapping.catalogId;
    this.modelService.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.modelService.router.navigate([navigateString]));
  }
}

<dx-data-grid (onToolbarPreparing)="onSystemToolbarPreparing($event)"
              [dataSource]="featureSystemsDataSource"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
              [customizeColumns]="customizeColumns"
              (onRowRemoving)="onRowRemoving($event)"
              (onRowRemoved)="delete($event)"
              [masterDetail]="{ enabled: true, template: 'featureValues' }"
              [repaintChangesOnly]="true"
              (onContentReady)="onContentReady()">
  <dxo-editing mode="cell"
               [allowUpdating]="!readOnly('editFeatureUpdate')"
               [allowDeleting]="featureSystemEditable && !readOnly('editFeatureUpdate')"
               [useIcons]="true">
    <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="FeatureSystemsStateNewUI2"></dxo-state-storing>

  <div *dxTemplate="let toolbaritem of 'addFeatureSystemButtonTemplate'">
    <dx-button [visible]="canView('editFeatureNew')"
               stylingMode="text"
               type="default"
               text="{{ 'Neue Merkmalsgruppe' | translate }}"
               [disabled]="updateLock"
               (onClick)="addFeatureSystemButtonClicked()">
    </dx-button>
  </div>
  <div *dxTemplate="let toolbaritem of 'addClassificationButtonTemplate'">
    <dx-button [visible]="canView('editFeatureClassification')"
               stylingMode="text"
               type="default"
               text="{{ 'Klassifizieren' | translate }}"
               (onClick)="addClassificationButtonClicked()">
    </dx-button>
  </div>

  <div *dxTemplate="let toolbaritem of 'profiModeSwitchTemplate'" class="d-flex flex-row">
    <div class="dx-field-label switch-left">
      {{ 'Einfach' | translate }}
    </div>
    <dx-switch [(value)]="modelService.loginService.currentUser.featureProfiMode"
               switchedOffText="{{ 'Einfach' | translate }}"
               switchedOnText="{{ 'Fortgeschritten' | translate }}"
               hint="{{ 'Selten genutzte Felder aktivieren' | translate }}">
    </dx-switch>
    <div class="dx-field-label switch-right">
      {{ 'Fortgeschritten' | translate }}
    </div>
  </div>



  <dxi-column caption="{{ 'Order' | translate }}" width="130px" dataField="order" sortOrder="asc"
              cellTemplate="orderCellTemplate" editCellTemplate="orderCellTemplate"></dxi-column>
  <dxi-column caption="{{ 'Featuresystem Name' | translate }}" dataField="referenceFeatureSystemName"
              cellTemplate="featureSystemNameTemplate" editCellTemplate="featureSystemNameTemplate"></dxi-column>
  <dxi-column [visible]="profiModeEnabled" caption="{{ 'Featuresystem GroupID' | translate }}"
              dataField="referenceFeatureGroupId" cellTemplate="featureSystemGroupIdTemplate"
              editCellTemplate="featureSystemGroupIdTemplate"></dxi-column>
  <dxi-column [visible]="profiModeEnabled" caption="{{ 'Featuresystem Group Name' | translate }}"
              dataField="referenceFeatureGroupName" cellTemplate="featureSystemGroupNameTemplate"
              editCellTemplate="featureSystemGroupNameTemplate"></dxi-column>

  <div *dxTemplate="let column of 'orderCellTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="column?.data?.order != null"
                       [suppressLabel]="true"
                       [suppressTranslation]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(column.data)) : null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Order' | translate }}"
                       field="FS_ORDER"
                       [system]="getRelevantSystemKey(column.data)"
                       [showDragHandle]="showDragHandle && featureSystemIsValid(column.data)"
                       [dragFilter]="dragFilter">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="column.data.order"
                         [min]="1"
                         [showClearButton]="false"
                         [showSpinButtons]="!readOnlyForLinkedFields('FS_ORDER', column.data.validFeatureSystemId)"
                         [readOnly]="readOnlyForLinkedFields('FS_ORDER', column.data.validFeatureSystemId) || readOnly('editfeatureOrder') || readOnlyTemplateEditor(column.data)"
                         (onValueChanged)="update($event, column.data, 'FS_ORDER', column.data.validFeatureSystemId)">
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>

  <div *dxTemplate="let column of 'featureSystemNameTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="column?.data?.referenceFeatureSystemName?.length > 0"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(column.data)) : null"
                       (onValueChanged)="update($event)"
                       field="REFERENCE_FEATURE_SYSTEM_NAME"
                       [system]="getRelevantSystemKey(column.data)"
                       [showDragHandle]="false"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelFeatureSystemNameDroppedError()">
        <div slot="content" class="contentSlot">
          <dx-select-box [(value)]="column.data.referenceFeatureSystemName"
                         [showClearButton]="!readOnlyForLinkedFields('REFERENCE_FEATURE_SYSTEM_NAME', column.data.validFeatureSystemId) || readOnly('editfeatureSystemName')"
                         [readOnly]="hasFeatureSystemFieldLinked(column.data.validFeatureSystemId) || readOnly('editfeatureSystemName')"
                         (onValueChanged)="updateFeatureSystemNameAndUpdate($event, column.data)"
                         (validationMessageMode)="always"
                         [minSearchLength]="0"
                         [searchEnabled]="true"
                         acceptCustomValue="true"
                         placeholder=""
                         [dataSource]="featureSystemNameSource">
            <dx-validator *ngIf="!templateView" validationGroup="FEAT">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('REFERENCE_FEATURE_SYSTEM_NAME', getRelevantSystemKey(column.data))"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
      </np-inputControl>
    </div>

  </div>
  <div *dxTemplate="let column of 'featureSystemGroupIdTemplate'">
    <div class="np-field w-100">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="column?.data?.referenceFeatureGroupId?.length > 0"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(column.data)) : null"
                       (onValueChanged)="update($event)"
                       field="REFERENCE_FEATURE_GROUP_ID"
                       [system]="getRelevantSystemKey(column.data)"
                       [showDragHandle]="showDragHandle && featureSystemIsValid(column.data)"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="column.data.referenceFeatureGroupId"
                       [showClearButton]="!readOnlyForLinkedFields('REFERENCE_FEATURE_GROUP_ID', column.data.validFeatureSystemId)"
                       [readOnly]="readOnlyForLinkedFields('REFERENCE_FEATURE_GROUP_ID', column.data.validFeatureSystemId) || readOnly('editfeatureGroupId') || readOnlyTemplateEditor(column.data)"
                       (onValueChanged)="update($event, column.data, 'REFERENCE_FEATURE_GROUP_ID', column.data.validFeatureSystemId)">
            <dx-validator *ngIf="!templateView" validationGroup="FEAT">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('REFERENCE_FEATURE_GROUP_ID', getRelevantSystemKey(column.data))"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

  </div>
  <div *dxTemplate="let column of 'featureSystemGroupNameTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="column?.data?.referenceFeatureGroupName?.length > 0"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelectionEnabled ? templateSelect($event, getRelevantSystemKey(column.data)) : null"
                       (onValueChanged)="update($event)"
                       field="REFERENCE_FEATURE_GROUP_NAME"
                       [system]="getRelevantSystemKey(column.data)"
                       [showDragHandle]="showDragHandle && featureSystemIsValid(column.data)"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">
        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="column.data.referenceFeatureGroupName"
                       [showClearButton]="!readOnlyForLinkedFields('REFERENCE_FEATURE_GROUP_NAME', column.data.validFeatureSystemId)"
                       [readOnly]="readOnlyForLinkedFields('REFERENCE_FEATURE_GROUP_NAME', column.data.validFeatureSystemId) || readOnly('editfeatureGroupName') || readOnlyTemplateEditor(column.data)"
                       (onValueChanged)="update($event, column.data, 'REFERENCE_FEATURE_GROUP_NAME', column.data.validFeatureSystemId)">
            <dx-validator *ngIf="!templateView" validationGroup="FEAT">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('REFERENCE_FEATURE_GROUP_NAME', getRelevantSystemKey(column.data))"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

  </div>
  <div *dxTemplate="let featureSystem of 'featureValues'">
    <editfeaturevaluelist [(modelService)]="modelService"
                          [(featureSystem)]="featureSystem.data"
                          [(model)]="model"
                          [templateView]="templateView"
                          [(displayMode)]="displayMode"
                          [showPlaceHolders]="showPlaceHolders"
                          [showLinkButtons]="showLinkButtons"
                          [hasAddonCLASSIFICATION]="hasAddonCLASSIFICATION"
                          (onValueChanged)="update($event)"
                          [(selectedElements)]="selectedElements"
                          (elementSelected)="templateChildSelected($event)"
                          [showDragHandle]="showDragHandle"
                          [dragFilter]="dragFilter"
                          (excelElementDropped)="excelElementDrop($event)"
                          (elementDeleted)="delete($event)">
    </editfeaturevaluelist>
  </div>
</dx-data-grid>
<div class="color4Text">
  <div *ngIf="showValidationSummary">
    <table>
      <tr *ngFor="let msg of validationMessages">
        <td>
          {{ translateMessage(msg) }}
        </td>
      </tr>
    </table>
  </div>
</div>
<dx-popup [width]="850"
          [height]="750"
          [showTitle]="true"
          title="{{ 'Klassifizieren' | translate }}"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="true"
          [(visible)]="classificationPopupVisible"
          contentTemplate="popupContent">
  <div *dxTemplate="let data of 'popupContent'">
    <productClassification (windowClose)="closeClassificationPopup()"
                           (addEcommerceFeatures)="emitAddEcommerceFeatures($event)"
                           (addClassificationFeatures)="emitAddClassificationFeatures($event)">
    </productClassification>
  </div>
</dx-popup>

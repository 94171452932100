import { Component, OnInit, Input } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { OsgConnection } from "../../Model/Dto/osgConnection";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";
import { PrintCredential } from '../../Model/System/PrintCredential';
import { CreatePrintCredential } from '../../Model/Dto/CreatePrintCredential';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';
import { ProductDataSheet } from "../../Model/Dto/ProductDataSheet";
import { ProductReportingService } from "../../Services/productreporting.service";
import { LoginService } from "../../Services/login.service";



@Component({
  selector: "datasheetManagement",
  templateUrl: "datasheetManagement.component.html"
})
export class DatasheetManagementComponent implements OnInit {


  dataSource = new Array<ProductDataSheet>();
  constructor(public loginService: LoginService, public translate: TranslateService, public productReportingService: ProductReportingService) {

  }
  _customerId: string = "";
  @Input()
  set customerId(val: string) {
    this._customerId = val;
    this.productReportingService.getFiles(this._customerId).subscribe((result: ProductDataSheet[]) => {
      this.dataSource = result;

    });
  }
  get customerId(): string {
    return this._customerId;

  }




  ngOnInit(): void {
    
  }

  isManager(): boolean {
    if (this.loginService.currentUser.currentClaim == "USER") return false;
    return true;
  }
  onDeleteClicked(event) {
    this.productReportingService.deleteReport(event.data).subscribe((result: boolean) => {
      if (result == true) {
        let i = 1;
      }

    });
  }
  onSaving(event) {
    let e = event;
    if (event.changes[0]) {
      let change = event.changes[0];
      if (change.type == "update") {
        let data = new ProductDataSheet();
        data = change.key;
        data.nameNew = change.data.name;
        this.productReportingService.renameReport(data).subscribe((result: boolean) => {
          this.productReportingService.getFiles(this._customerId).subscribe((result: ProductDataSheet[]) => {
            this.dataSource = result;

          });
        });
      }
    }
  }
}




import { TranslateService } from '@ngx-translate/core';
import { fieldNames } from 'app/Model/FieldNames';
import { Item as dxListItem } from 'devextreme/ui/list';
import { LoginService } from './login.service';
import { Injectable } from '@angular/core';
import { FieldType } from 'app/Model/Catalog/TemplateItem';
import { NextPimField } from 'app/Model/Catalog/NextPimField';

export interface TemplateFieldDefinition extends dxListItem {
  hasSystemKey?: boolean;
  hasElementKey?: boolean;
}

@Injectable({ providedIn: 'root' })
export class TemplateFieldsService {
  productFields: TemplateFieldDefinition[] = [];
  mimeFields: TemplateFieldDefinition[] = [];
  featureFields: TemplateFieldDefinition[] = [];
  orderDetailFields: TemplateFieldDefinition[] = [];
  pricelistFields: TemplateFieldDefinition[] = [];
  logisticsFields: TemplateFieldDefinition[] = [];
  referenceFields: TemplateFieldDefinition[] = [];
  udxFields: TemplateFieldDefinition[] = [];
  seoFields: TemplateFieldDefinition[] = [];
  legalFields: TemplateFieldDefinition[] = [];
  miscFields: TemplateFieldDefinition[] = [];
  supplierFields: TemplateFieldDefinition[] = [];

  constructor(private loginService: LoginService, private translate: TranslateService) {
    this.createProductFields();
    this.createMimeFields();
    this.createFeatureFields();
    this.createOrderDetailFields();
    this.createPricelistFields();
    this.createLogisticsFields();
    this.createReferenceFields();
    this.createUdxFields();
    this.createSeoFields();
    this.createLegalFields();
    this.createMiscFields();
    this.createSupplierFields();
  }

  getFieldType(pimField: NextPimField): FieldType {
    let field = pimField.field;
    if (!field) return 'fixed';
    field = field.split('__')?.[0];

    if (this.featureFields.some((x) => x.key === field)) {
      return 'feature';
    } else if (this.mimeFields.some((x) => x.key === field)) {
      return 'mime';
    } else if (this.pricelistFields.some((x) => x.key === field)) {
      return 'price';
    } else if (this.legalFields.some((x) => x.key === field)) {
      return 'legal';
    } else if (this.miscFields.some((x) => x.key === field)) {
      return 'misc';
    } else if (this.referenceFields.some((x) => x.key === field)) {
      return 'reference';
    } else if (this.seoFields.some((x) => x.key === field)) {
      return 'seo';
    } else if (this.supplierFields.some((x) => x.key === field)) {
      return 'supplier';
    } else if (this.udxFields.some((x) => x.key === field)) {
      return 'udx';
    } else if (this.orderDetailFields.some((x) => x.key === field)) {
      return 'orderdetail';
    } else if (this.productFields.some((x) => x.key === field)) {
      return 'standard';
    }

    return 'fixed';
  }

  numberToExcelColumn(num: number) {
    let columnName = '';

    while (num > 0) {
      num--; // Reduzieren um 1, da Excel bei 1 startet, nicht bei 0
      let remainder = num % 26;
      columnName = String.fromCharCode(65 + remainder) + columnName;
      num = Math.floor(num / 26);
    }

    return columnName;
  }

  excelColumnToNumber(column: string) {
    column = column.toUpperCase();
    let result = 0;
    for (let i = 0; i < column.length; i++) {
      result *= 26;
      result += column.charCodeAt(i) - 64;
    }
    return result;
  }

  private createProductFields() {
    const fields = this.productFields;
    fields.push(
      ...[
        { key: fieldNames.descriptionShort, text: this.translate.instant(fieldNames.descriptionShort) },
        { key: fieldNames.supplierPid, text: this.translate.instant(fieldNames.supplierPid) },
        { key: fieldNames.supplierAltPid, text: this.translate.instant(fieldNames.supplierAltPid) },
        { key: fieldNames.buyerAid, text: this.translate.instant(fieldNames.buyerAid) },
        { key: fieldNames.descriptionLong, text: this.translate.instant(fieldNames.descriptionLong) },
        { key: fieldNames.descriptionLongWithoutHtml, text: this.translate.instant('Langbeschreibung ohne HTML') },
        {
          key: fieldNames.manufacturerTypeDescription,
          text: this.translate.instant(fieldNames.manufacturerTypeDescription)
        },
        { key: fieldNames.internatonalPid, text: this.translate.instant(fieldNames.internatonalPid) },
        { key: fieldNames.internatonalPidType, text: this.translate.instant(fieldNames.internatonalPidType) },
        { key: fieldNames.group, text: this.translate.instant(fieldNames.group) },
        { key: fieldNames.remarks, text: this.translate.instant(fieldNames.remarks) },
        { key: fieldNames.manufacturerPID, text: this.translate.instant(fieldNames.manufacturerPID) },
        { key: fieldNames.manufacturerName, text: this.translate.instant(fieldNames.manufacturerName) },
        { key: fieldNames.keywords, text: this.translate.instant(fieldNames.keywords) },
        { key: fieldNames.productOrder, text: this.translate.instant(fieldNames.productOrder) },
        { key: fieldNames.status, text: this.translate.instant(fieldNames.status) },
        { key: fieldNames.statusUpdateMode, text: this.translate.instant(fieldNames.statusUpdateMode) },
        { key: fieldNames.master, text: this.translate.instant(fieldNames.master) }
      ]
    );

    if (this.loginService.hasAddonEDENUMBERS) {
      for (let i = 1; i <= 5; i++) {
        fields.push({ key: fieldNames.edeNumber1, text: this.translate.instant('EDE Nummer') + ' ' + i });
      }
    }
  }

  private createMimeFields() {
    this.mimeFields.push(
      ...[
        { key: fieldNames.mimeType, text: this.translate.instant(fieldNames.mimeType), hasSystemKey: true },
        { key: fieldNames.mimeSource, text: this.translate.instant(fieldNames.mimeSource), hasSystemKey: true },
        {
          key: fieldNames.mimeDescription,
          text: this.translate.instant(fieldNames.mimeDescription),
          hasSystemKey: true
        },
        { key: fieldNames.mimeAlt, text: this.translate.instant(fieldNames.mimeAlt), hasSystemKey: true },
        { key: fieldNames.mimePurpose, text: this.translate.instant(fieldNames.mimePurpose), hasSystemKey: true },
        {
          key: fieldNames.mimeLinkSizeNorm,
          text: this.translate.instant(fieldNames.mimeLinkSizeNorm),
          hasSystemKey: true
        }
      ]
    );
  }

  private createFeatureFields() {
    this.featureFields.push(
      ...[
        {
          key: fieldNames.featureSystemName,
          text: this.translate.instant(fieldNames.featureSystemName),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.featureSystemGroupId,
          text: this.translate.instant(fieldNames.featureSystemGroupId),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.featureSystemGroupId2,
          text: this.translate.instant(fieldNames.featureSystemGroupId2),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.featureSystemGroupName,
          text: this.translate.instant(fieldNames.featureSystemGroupName),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.featureSystemOrder,
          text: this.translate.instant('System Sortiernummer'),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.fOrder,
          text: this.translate.instant('Feature Sortiernummer'),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.fName,
          text: this.translate.instant(fieldNames.fName),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.fValue,
          text: this.translate.instant(fieldNames.fValue),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.fUnit,
          text: this.translate.instant(fieldNames.fUnit),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.fDescription,
          text: this.translate.instant(fieldNames.fDescription),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.fValueDetails,
          text: this.translate.instant(fieldNames.fValueDetails),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.featurePrintOrder,
          text: this.translate.instant(fieldNames.featurePrintOrder),
          hasSystemKey: true,
          hasElementKey: true
        }
      ]
    );
  }

  private createOrderDetailFields() {
    this.orderDetailFields.push(
      ...[
        { key: fieldNames.deliveryTime, text: this.translate.instant(fieldNames.deliveryTime) },
        { key: fieldNames.orderUnit, text: this.translate.instant(fieldNames.orderUnit) },
        { key: fieldNames.contentUnit, text: this.translate.instant(fieldNames.contentUnit) },
        { key: fieldNames.noCuPerOu, text: this.translate.instant(fieldNames.noCuPerOu) },
        { key: fieldNames.priceQuantity, text: this.translate.instant(fieldNames.priceQuantity) },
        { key: fieldNames.quantityMin, text: this.translate.instant(fieldNames.quantityMin) },
        { key: fieldNames.quantityMax, text: this.translate.instant(fieldNames.quantityMax) },
        { key: fieldNames.quanityInterval, text: this.translate.instant(fieldNames.quanityInterval) }
      ]
    );
  }

  private createPricelistFields() {
    this.pricelistFields.push(
      ...[
        { key: fieldNames.startDate, text: this.translate.instant(fieldNames.startDate), hasSystemKey: true },
        { key: fieldNames.endDate, text: this.translate.instant(fieldNames.endDate), hasSystemKey: true },
        {
          key: fieldNames.priceType,
          text: this.translate.instant(fieldNames.priceType),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.priceAmount,
          text: this.translate.instant(fieldNames.priceAmount),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.priceCurrency,
          text: this.translate.instant(fieldNames.priceCurrency),
          hasSystemKey: true,
          hasElementKey: true
        },
        { key: fieldNames.tax, text: this.translate.instant(fieldNames.tax), hasSystemKey: true, hasElementKey: true },
        {
          key: fieldNames.priceFactor,
          text: this.translate.instant(fieldNames.priceFactor),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.lowerBound,
          text: this.translate.instant(fieldNames.lowerBound),
          hasSystemKey: true,
          hasElementKey: true
        },
        {
          key: fieldNames.territory,
          text: this.translate.instant(fieldNames.territory),
          hasSystemKey: true,
          hasElementKey: true
        }
      ]
    );
  }

  private createLogisticsFields() {
    this.logisticsFields.push(
      ...[
        { key: fieldNames.customsNumber, text: this.translate.instant(fieldNames.customsNumber) },
        { key: fieldNames.countryOfOrgin, text: this.translate.instant(fieldNames.countryOfOrgin) },
        { key: fieldNames.weight, text: this.translate.instant(fieldNames.weight) },
        { key: fieldNames.lenght, text: this.translate.instant(fieldNames.lenght) },
        { key: fieldNames.width, text: this.translate.instant(fieldNames.width) },
        { key: fieldNames.depth, text: this.translate.instant(fieldNames.depth) },
        { key: fieldNames.hazmatClass, text: this.translate.instant(fieldNames.hazmatClass) },
        { key: fieldNames.warrantyClass, text: this.translate.instant(fieldNames.warrantyClass) }
      ]
    );
  }

  private createReferenceFields() {
    this.referenceFields.push(
      ...[
        { key: fieldNames.productReferencesType, text: this.translate.instant(fieldNames.productReferencesType) },
        { key: fieldNames.productReferencesSpid, text: this.translate.instant(fieldNames.productReferencesSpid) }
      ]
    );
  }

  private createUdxFields() {
    this.udxFields.push(
      ...[
        { key: fieldNames.udxCategory, text: this.translate.instant(fieldNames.udxCategory) },
        { key: fieldNames.udxName, text: this.translate.instant(fieldNames.udxName) },
        { key: fieldNames.udxValue, text: this.translate.instant(fieldNames.udxValue) }
      ]
    );
  }

  private createSeoFields() {
    this.seoFields.push(
      ...[
        { key: fieldNames.metaTitel, text: this.translate.instant(fieldNames.metaTitel) },
        { key: fieldNames.metaDescription, text: this.translate.instant(fieldNames.metaDescription) }
      ]
    );
  }

  private createLegalFields() {
    this.legalFields.push(
      ...[
        {
          key: fieldNames.isBiocidalProduct,
          text: this.translate.instant(fieldNames.isBiocidalProduct)
        },
        { key: fieldNames.isCeGs, text: this.translate.instant(fieldNames.isCeGs) },
        {
          key: fieldNames.isSelfServiceForbidden,
          text: this.translate.instant(fieldNames.isSelfServiceForbidden)
        },
        {
          key: fieldNames.isWeeeRohsEar,
          text: this.translate.instant(fieldNames.isWeeeRohsEar)
        },
        {
          key: fieldNames.isFertigpackv,
          text: this.translate.instant(fieldNames.isFertigpackv)
        },
        { key: fieldNames.isReach, text: this.translate.instant(fieldNames.isReach) },
        {
          key: fieldNames.isOekodesignEvpg,
          text: this.translate.instant(fieldNames.isOekodesignEvpg)
        },
        {
          key: fieldNames.isVerpackungsv,
          text: this.translate.instant(fieldNames.isVerpackungsv)
        },
        {
          key: fieldNames.isCommoditiesControll,
          text: this.translate.instant(fieldNames.isCommoditiesControll)
        },
        {
          key: fieldNames.isSecurityDatasheetNeeded,
          text: this.translate.instant(fieldNames.isSecurityDatasheetNeeded)
        },
        {
          key: fieldNames.isDetergenzienv,
          text: this.translate.instant(fieldNames.isDetergenzienv)
        },
        { key: fieldNames.isDualUse, text: this.translate.instant(fieldNames.isDualUse) },
        { key: fieldNames.isKosmetikv, text: this.translate.instant(fieldNames.isKosmetikv) },
        { key: fieldNames.isBatterieV, text: this.translate.instant(fieldNames.isBatterieV) },
        { key: fieldNames.warrantyClass, text: this.translate.instant(fieldNames.warrantyClass) },
        { key: fieldNames.hazmatClass, text: this.translate.instant(fieldNames.hazmatClass) }
      ]
    );
  }

  private createMiscFields() {
    this.miscFields.push(
      ...[
        { key: fieldNames.descriptionLongWithoutHtml, text: this.translate.instant('Langbeschreibung ohne HTML') },
        { key: fieldNames.group, text: this.translate.instant(fieldNames.group) },
        { key: fieldNames.master, text: this.translate.instant(fieldNames.master) },
        { key: fieldNames.featureList, text: this.translate.instant(fieldNames.featureList) },
        { key: fieldNames.link, text: this.translate.instant('Link zum Produkt') }
      ]
    );
  }

  private createSupplierFields() {
    this.supplierFields.push(
      ...[
        {
          key: fieldNames.packingUnitCode,
          text: this.translate.instant(fieldNames.packingUnitCode),
          hasSystemKey: true
        },
        { key: fieldNames.packingUnitQuantityMax, text: this.translate.instant('Höchstmenge'), hasSystemKey: true },
        { key: fieldNames.packingUnitQuantityMin, text: this.translate.instant('Mindestmenge'), hasSystemKey: true },
        {
          key: fieldNames.packingUnitAmountContentUnit,
          text: this.translate.instant(fieldNames.packingUnitAmountContentUnit),
          hasSystemKey: true
        },
        { key: fieldNames.supplierId, text: this.translate.instant(fieldNames.supplierId), hasSystemKey: true },
        { key: fieldNames.supplierName, text: this.translate.instant(fieldNames.supplierName), hasSystemKey: true },
        {
          key: fieldNames.supplierDeliveryTime,
          text: this.translate.instant(fieldNames.supplierDeliveryTime),
          hasSystemKey: true
        },
        { key: fieldNames.supplierPrice, text: this.translate.instant(fieldNames.supplierPrice), hasSystemKey: true },
        {
          key: fieldNames.supplierValidFrom,
          text: this.translate.instant(fieldNames.supplierValidFrom),
          hasSystemKey: true
        },
        {
          key: fieldNames.supplierValidUntil,
          text: this.translate.instant(fieldNames.supplierValidUntil),
          hasSystemKey: true
        },
        { key: fieldNames.mainSupplierId, text: this.translate.instant('HauptLieferant'), hasSystemKey: true }
      ]
    );
  }
}

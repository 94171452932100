import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Contact } from "../Model/Contact";
import { RestoreBackup } from "../Model/Dto/RestoreBackup";
import { Backup } from "../Model/System/Backup";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AppUser } from "../Model/user/AppUser";

@Injectable()
export class UserService {
  constructor(private http: HttpClient,

  ) {
  }

  public getUser(userId: string, customerId: string): Observable<AppUser> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("userId", userId).append("customerId", customerId)
    }
    return (this.http.get("api/User/GetUser", options)) as any;
  }

  public create(inviterId: string, user: AppUser): Observable<AppUser> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("inviterId", inviterId)
    };
    var result = this.http.post<AppUser>("api/User/CreateUser", user, options);
    return (result);
  }

  public update(user: AppUser): Observable<AppUser> {
    var result = this.http.post<AppUser>("api/User/UpdateUser", user);
    return (result);
  }

  public delete(userId: string): Observable<string> {
    var result = this.http.post<string>("api/User/DeleteUser", userId);
    return (result);
  }

  public setLastNewsRead(userId: string, lastNewsRead: Date): Observable<AppUser> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("userId", userId)
    };

    var result = this.http.post<AppUser>("api/User/SetLastNewsRead", lastNewsRead, options);
    return (result);
  }

}

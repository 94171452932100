export class Shopware6CategoryMapping {

  constructor(
    public lastSend?: Date,
    public shopWareId?: string,
    public cmsPageId?: string,  
    
  ) {
  }
}




<div class="d-flex flex-column">
  <dx-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <dx-select-box [(value)]="selectedValidationMethod"
                       [items]="validationMethods"
                       displayExpr="name"
                       (onValueChanged)="validationMethodChanged()">
          <dxo-drop-down-options [width]="400"></dxo-drop-down-options>
        </dx-select-box>
      </div>
    </dxi-item>
    <dxi-item *ngIf="!readOnlyUser('dataQualityCalc')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Qualität berechnen' | translate, hint: 'Qualität als Job berechnen' | translate, onClick: calculate }"></dxi-item>
    <dxi-item location="after" widget="dxButton" [options]="{ type: 'default', icon: 'refresh', onClick: reload }"></dxi-item>
  </dx-toolbar>
  <dx-tab-panel class="flex-fill"
                [dataSource]="pages"
                [selectedIndex]="0"
                [loop]="false"
                [animationEnabled]="true"
                [swipeEnabled]="true">
    <div *dxTemplate="let company of 'chartTemplate'">
      <div class="dx-fieldset">
        <dx-chart id="chart"
                  title="{{ 'Datenqualität' | translate }}"
                  [dataSource]="model"
                  [palette]="['#A52A2A' , '#D26915', '#FFA500', '#008000', '#191970']">

          <dxo-value-axis [min]="0"
                          [max]="100"
                          [visible]="false">
            <dxo-tick [visible]="false"></dxo-tick>
            <dxo-grid [visible]="false"></dxo-grid>
            <dxo-label [visible]="false"></dxo-label>
          </dxo-value-axis>

          <dxo-argument-axis [visible]="false">
            <dxo-label [visible]="false"></dxo-label>
            <dxo-grid [visible]="false"></dxo-grid>
          </dxo-argument-axis>
          <dxi-series valueField="basicsResult" name="{{ 'Stammdaten' | translate }}"></dxi-series>
          <dxi-series valueField="keywordResult" name="{{ 'Suchbegriffe' | translate }}"></dxi-series>
          <dxi-series valueField="mediaResult" name="{{ 'Medien' | translate }}"></dxi-series>
          <dxi-series valueField="featureSystemResult" name="{{ 'Merkmale' | translate }}"></dxi-series>
          <dxi-series valueField="additionalsResult" name="{{ 'Zusatzinformationen' | translate }}"></dxi-series>

          <dxo-common-series-settings argumentField="catalog"
                                      type="bar"
                                      hoverMode="allArgumentPoints"
                                      selectionMode="allArgumentPoints">
            <dxo-label [visible]="true"
                       [customizeText]="customizeText"></dxo-label>
          </dxo-common-series-settings>
          <dxo-legend [margin]="0"
                      verticalAlignment="top"
                      horizontalAlignment="left"></dxo-legend>
          <dxo-export [enabled]="true"></dxo-export>
        </dx-chart>
      </div>

    </div>
    <div *dxTemplate="let company of 'groupsTemplate'">
      <dataQualityGroups [modelChangedEvent]="modelChangedEvent"></dataQualityGroups>
    </div>
    <div *dxTemplate="let company of 'statisticTemplate'">
      <dataQualityStatistic [modelChangedEvent]="modelChangedEvent"></dataQualityStatistic>
    </div>
    <div *dxTemplate="let company of 'factsTemplate'">
      <dataQualityFacts></dataQualityFacts>
    </div>
  </dx-tab-panel>
</div>

import { Component, ViewChild } from "@angular/core";
import { Product } from "../Model/Catalog/product";
import { ShopView } from '../catalog/product/shopView.component';
import { ModelService } from '../Services/model.service';
import { AppUser } from '../Model/user/AppUser';
import { map } from 'rxjs/operators';
import { ValidationService } from "../Services/Validation/validation.service";

@Component({
  selector: "productView",
  templateUrl: "./productView.component.html",
})
export class ProductViewComponent {
  @ViewChild(ShopView)
  shopview: ShopView;
  public customerId: string = "";
  public supplierpid: string = "";
  public catalogid: string = "";

  constructor(public modelService: ModelService, public validationService: ValidationService) {
    var params = this.getAllUrlParams();
    if (this.modelService.loginService.currentUser == undefined) {
      this.modelService.loginService.currentUser = new AppUser();
    }


    this.customerId = params["customerid"];
    this.supplierpid = params["supplierpid"];
    this.catalogid = params["catalogid"];

    this.modelService.loginService.currentUser.frontEndLanguage = "de";
    this.modelService.loginService.CheckAndSetFrontEndLanguage();

    if (params["layout"]) {
      switch (params["layout"]) {
        case "lukas":
          this.layout = "lukas";
          break;
      }
    }
    this.loadProduct();

  }

  loadProduct() {
    if (this.catalogid != undefined && this.customerId != undefined && this.supplierpid != undefined) {
      this.modelService.loginService.currentUser.customerId = this.customerId;

      this.modelService.catalogService.getProductContent(this.supplierpid, this.customerId, this.catalogid).pipe(map(response => {
        this.setProduct(response);
      })).subscribe(response => { }, error => { this.handleError(error); });
    }
  }


  layout: string = "default";
  product: Product;
  errorMessage: string = "";

  setProduct(response) {
    //if (response.ok) {
    this.modelService.catalogService.product = this.modelService.productService.getProductFromJson(response, this.modelService.loginService.wawiSettings);
    this.modelService.hazmatClassService.loadProductHazmat(this.modelService.catalogService.product);
    this.modelService.warrantyClassService.loadProductWarranty(this.modelService.catalogService.product);

    //this.validationService.validateProduct(this.modelService.catalogService.product);
    //}
  }

  handleError(error) {
    console.log(error);
  }

  public getAllUrlParams(url: string = "") {

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    var obj = [];

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      var arr = queryString.split('&');

      for (var i = 0; i < arr.length; i++) {
        // separate the keys and the values
        var a = arr[i].split('=');

        // set parameter name and value (use 'true' if empty)
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        //if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

        // if the paramName ends with square brackets, e.g. colors[] or colors[2]
        if (paramName.match(/\[(\d+)?\]$/)) {

          // create key if it doesn't exist
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          // if it's an indexed array e.g. colors[2]
          if (paramName.match(/\[\d+\]$/)) {
            // get the index value and add the entry at the appropriate position
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            // otherwise add the value to the end of the array
            obj[key].push(paramValue);
          }
        } else {
          // we're dealing with a string
          if (!obj[paramName]) {
            // if it doesn't exist, create property
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string') {
            // if property does exist and it's a string, convert it to an array
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            // otherwise add the property
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  }

}

import { Component, OnInit, Input } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { OsgConnection } from "../../Model/Dto/osgConnection";
import { Message } from "../../Model/System/Message";
import { TestResult } from "../../Model/Dto/TestResult";
import { PrintCredential } from '../../Model/System/PrintCredential';
import { CreatePrintCredential } from '../../Model/Dto/CreatePrintCredential';
import { TranslateService } from '@ngx-translate/core';
import { custom } from 'devextreme/ui/dialog';



@Component({
  selector: "easyCatalog",
  templateUrl: "easyCatalog.component.html"
})
export class EasyCatalogComponent implements OnInit {

  _customerId : string ="";
  @Input()
  set customerId(val: string) {
    this._customerId = val;
    this.modelService.printService.getPrintCredentials(this.customerId).subscribe((result: PrintCredential) => {
      this.model = result;
    });
  }
  get customerId(): string {
    return this._customerId;

  }

  

  connection2Shop: boolean;
  model : PrintCredential = new PrintCredential();

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.createCredentials = this.createCredentials.bind(this);
  }


  ngOnInit(): void { 
    if (this.modelService.loginService.currentUser == null) {
      
      this.modelService.router.navigate(["/"]);
      return;
    } 
  }

  isManager(): boolean {
    if (this.modelService.loginService.currentUser.currentClaim == "USER") return false;
    return true;
  }

  createCredentials() {

    let txtYes: string;
    let txtNo: string;
    let txtMsg: string;
    let txtTitle: string;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("txtEasyCatalogCreateCredentialsText").subscribe((text: string) => {
      txtMsg = text;
    });
    this.translate.get("txtEasyCatalogCreateCredentialsTitle").subscribe((text: string) => {
      txtTitle = text;
    });

    let myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [{
        text: txtYes,
        onClick: (e) => {
          return { buttonText: true }
        }
      },
      {
        text: txtNo,
        onClick: (e) => {
          return { buttonText: false }
        }
      }
      ]
    });

    myDialog.show().then((dialogResult) => {
      if (dialogResult.buttonText == true) {
        let dto = new CreatePrintCredential();
        dto.customerId = this.customerId;
        this.modelService.printService.createCredentials(dto).subscribe((result: PrintCredential) => {
          this.model = result;
        });
      }
    });

  }
  
}



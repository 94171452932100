import { Component } from "@angular/core";
import { ModelService } from "../../../../Services/model.service";
import { StartAction } from "../../../../Model/Dto/StartAction";
import  { UserActionBaseComponent}  from "../userAction-base.component";
import { lastValueFrom } from "rxjs";
import { ValidFeatureService } from "../../../../Services/validFeature.service";
import { ValidFeature } from "../../../../Model/ValidFeature";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "userAction-deleteFeatures",
  templateUrl: "userAction-deleteFeatures.component.html"
})
export class UserActionDeleteFeaturesComponent extends UserActionBaseComponent  {
  featuresLoaded: boolean = false;
  features: ValidFeature[];
  selectedFeatures: ValidFeature[];

  constructor(public modelService: ModelService, private validFeatureService: ValidFeatureService, private translate: TranslateService) {
    super(modelService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadFeatureNames();
    this.featuresLoaded = true;
  }

  async loadFeatureNames() {
    this.features = await lastValueFrom(this.validFeatureService.getCatalogFeatures(
        this.modelService.catalogService.selectedCatalogId,
        this.modelService.loginService.currentUser.customerId));
  }

  startMacro() {
    if (this.selectedFeatures == null || this.selectedFeatures.length == 0) {
      this.modelService.systemService.notifyInfo(this.translate.instant("Mindestens ein Feature auswählen"));
      return;
    }

    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "DeleteFeatures";
    startAction.actionParameter = this.createParameterStringAsJsonString();

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.userActionStarted.emit(next);
      });
  }

  createParameterStringAsJsonString() {
    return JSON.stringify(this.selectedFeatures);
  }


}

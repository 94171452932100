import { Input, Output, EventEmitter, Component, OnInit } from "@angular/core";
import { Product } from '../../../Model/Catalog/product';
import { ModelService } from '../../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { HazmatClass } from "../../../Model/Catalog/HazmatClass";
import { WarrantyClass } from "../../../Model/Catalog/WarrantyClass";
import { ValidationService } from "../../../Services/Validation/validation.service";
import { ExcelElement } from "../../../Model/ui/ExcelElement";

@Component({
  selector: "templateProduct",
  templateUrl: "templateProduct.component.html",
  styleUrls: ["template.css"]
})
export class TemplateProductComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() selectedElements: string[] = [""];
  @Input() exportMode: Boolean = false;
  @Input() showDragHandle: Boolean = true;
  @Input() excelModel: ExcelElement[];

  @Output() elementDeleted = new EventEmitter<any>();
  @Output() elementSelected = new EventEmitter<string>();
  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() onValueChanged = new EventEmitter<any>();



  constructor(public modelService: ModelService, public translate: TranslateService, public validationService: ValidationService) { }

  ngOnInit(): void {
    this.modelService.productService.createWawiIfNeeded(this.model, this.modelService.loginService.wawiSettings);
    if (this.modelService.loginService.hasAddonLEGALITY) {
      this.modelService.hazmatClassService.getClasses().subscribe(
        (result: HazmatClass[]) => {
          this.modelService.hazmatClassService.hazmatClasses = result;
        });

      this.modelService.warrantyClassService.getClasses().subscribe(
        (result: WarrantyClass[]) => {
          this.modelService.warrantyClassService.warrantyClasses = result;
        });
    }

    this.translateTexts();
    this.reset();
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  pages: { id: number; title: string; template: string; type: string; }[] = [];

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  txtProdukt: string;
  txtMedien: string;
  txtUdx: string;
  txtFeatures: string;
  txtPreislisten: string;
  txtECommerce: string;
  txtSEO: string;
  txtLogistik: string;
  txtReferenzen: string;
  txtMisc: string;
  txtOrderDetail: string;
  txtLegal: string;
  txtSuppliers: string;
  txtElectronicSales: string;

  translateTexts() {
    this.txtProdukt = this.translate.instant("Produkt");
    this.txtMedien = this.translate.instant("Medien");
    this.txtFeatures = this.translate.instant("Features");
    this.txtOrderDetail = this.translate.instant("Bestelldetails");
    this.txtPreislisten = this.translate.instant("Preislisten");
    this.txtLogistik = this.translate.instant("Logistik");
    this.txtReferenzen = this.translate.instant("Referenzen");
    this.txtUdx = this.translate.instant("Udx");
    this.txtElectronicSales = this.translate.instant("SEO - SEA");
    this.txtSEO = this.translate.instant("SEO - SEA");
    this.txtLegal = this.translate.instant("Recht");
    this.txtMisc = this.translate.instant("MISC");
    this.txtSuppliers = this.translate.instant("Lieferanten");
    this.txtElectronicSales = this.translate.instant('ElectronicSales');
  }

  reset() {
    this.pages = [
      { id: 1, title: this.txtProdukt, template: "productTemplate", type: "MAIN" },
      { id: 2, title: this.txtMedien, template: "medienTemplate", type: "MIME" },
      { id: 3, title: this.txtFeatures, template: "featuresTemplate", type: "FEAT" },
      { id: 4, title: this.txtOrderDetail, template: "orderDetailTemplate", type: "ORDER" },
      { id: 5, title: this.txtPreislisten, template: "preislistenTemplate", type: "PRICE" },
      { id: 6, title: this.txtLogistik, template: "logistikTemplate", type: "LOGI" },
      { id: 7, title: this.txtReferenzen, template: "referenceTemplate", type: "REFE" }
    ];

    if (this.modelService.loginService.hasAddonUDX) {
      this.pages.push({ id: 8, title: this.txtUdx, template: "udxTemplate", type: "0" });
    }

    if (this.modelService.loginService.hasAddonElectronicSales) {
      this.pages.push({ id: 9, title: this.txtElectronicSales, template: "electronicSalesTemplate", type: "0" });
    }

    if (this.modelService.loginService.hasAddonSEO) {
      this.pages.push({ id: 10, title: this.txtSEO, template: "seoTemplate", type: "0" });
    }

    if (this.modelService.loginService.hasAddonLEGALITY) {
      this.pages.push({ id: 11, title: this.txtLegal, template: "legalTemplate", type: "LEGAL" });
    }

    this.pages.push({ id: 12, title: this.txtMisc, template: "miscTemplate", type: "MISC" });

    if (this.modelService.loginService.hasAddonWawiErp) {
      this.modelService.productService.createWawiIfNeeded(this.model, this.modelService.loginService.wawiSettings);
      this.pages.push({ id: 13, title: this.modelService.loginService.wawiSettings.name, template: "wawiTemplate", type: "0" });
    }

    if (this.modelService.loginService.hasAddonSUPPLIERS) {
      this.pages.push({ id: 14, title: this.txtSuppliers, template: "productSuppliersTemplate", type: "0" });
    }
  }

  //Referencen nachladen
  onReferencePrepare(event) {
    if (event.columnIndex == 4 && event.rowType == "data") {
      if (event.data.artIdTo != null) {
        this.modelService.catalogService.getProductBySupplierPid(
          event.data.artIdTo,
          this.modelService.loginService.currentUser.customerId,
          this.modelService.catalogService.catalog.id
        ).subscribe(
          (result) => {
            event.data.descriptionShort = result.descriptionShort;
            for (let mime of result.mimes.sort((a, b) => (a.mimeOrder > b.mimeOrder) ? 1 : -1)) {
              if (mime.mimePurpose == "normal" && mime.normUri != null) {
                event.data.normUri = mime.normUri;
              }
            }
          },
          error => {
            event.data.descriptionShort = "";
            event.data.normUri = "";
          }
        );
      } else {
        event.data.descriptionShort = "";
        event.data.normUri = "";
      }
    }
  }

  // wie in c:\workspaces\nextpim\test\Pim\ClientApp\src\app\catalog\product\productDetail.component.ts
  addEcommerceFeatures() {
    let dummy = this.modelService.productService.getProductFromJson(new Product(), this.modelService.loginService.wawiSettings);
    dummy.featureSystems = this.model.featureSystems;

    this.modelService.catalogService.addEcommerceFeatures(dummy).subscribe(
      (result: Product) => {
        this.model.featureSystems = result.featureSystems;
      });
  }

  public update(event = null) {
    if (event == null || event == undefined) {  //|| event.value == null
      return;
    }

    this.onValueChanged.emit(event);
  }

  onTitleClick(event = null) {
    this.elementSelected.emit("");
  }



  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public elementDelete(e) {
    this.elementDeleted.emit(e);
  }



  private _mimeUpdateMode: string = "NORMAL";

  get mimeUpdateMode(): string {
    return this._mimeUpdateMode;
  }

  @Input()
  set mimeUpdateMode(value: string) {
    this._mimeUpdateMode = value;
  }

  @Output() onMimeUpdateModeChanged = new EventEmitter<string>();

  mimeUpdateModeChanged(e) {
    this.onMimeUpdateModeChanged.emit(e);
  }



  private _featureUpdateMode: string = "NORMAL";

  get featureUpdateMode(): string {
    return this._featureUpdateMode;
  }

  @Input()
  set featureUpdateMode(value: string) {
    this._featureUpdateMode = value;
  }

  @Output() onFeatureUpdateModeChanged = new EventEmitter<string>();

  featureUpdateModeChanged(e) {
    this.onFeatureUpdateModeChanged.emit(e);
  }



  private _priceUpdateMode: string = "NORMAL";

  get priceUpdateMode(): string {
    return this._priceUpdateMode;
  }

  @Input()
  set priceUpdateMode(value: string) {
    this._priceUpdateMode = value;
  }

  @Output() onPriceUpdateModeChanged = new EventEmitter<string>();

  priceUpdateModeChanged(e) {
    this.onPriceUpdateModeChanged.emit(e);
  }



  private _udxUpdateMode: string = "NORMAL";

  get udxUpdateMode(): string {
    return this._udxUpdateMode;
  }

  @Input()
  set udxUpdateMode(value: string) {
    this._udxUpdateMode = value;
  }

  @Output() onUdxUpdateModeChanged = new EventEmitter<string>();

  udxUpdateModeChanged(e) {
    this.onUdxUpdateModeChanged.emit(e);
  }



  private _supplierUpdateMode: string = "NORMAL";

  get supplierUpdateMode(): string {
    return this._supplierUpdateMode;
  }

  @Input()
  set supplierUpdateMode(value: string) {
    this._supplierUpdateMode = value;
  }

  @Output() onSupplierUpdateModeChanged = new EventEmitter<string>();

  supplierUpdateModeChanged(e) {
    this.onSupplierUpdateModeChanged.emit(e);
  }



  private _categoryUpdateMode: string = "NORMAL";

  get categoryUpdateMode(): string {
    return this._categoryUpdateMode;
  }

  @Input()
  set categoryUpdateMode(value: string) {
    this._categoryUpdateMode = value;
  }

  @Output() onCategoryUpdateModeChanged = new EventEmitter<string>();

  categoryUpdateModeChanged(e) {
    this.onCategoryUpdateModeChanged.emit(e);
  }
}

import { Input, Output, EventEmitter, Component, OnInit } from "@angular/core";
import { Product } from '../../Model/Catalog/product';
import { ViewService } from '../../Services/view.service';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../Services/model.service';
import { UserManagementService } from "../../Services/userManagment.service";
import { ValidationService } from "../../Services/Validation/validation.service";


@Component({
  selector: "orderDetail",
  templateUrl: "orderDetail.component.html",
  styleUrls: ["./orderDetail.css", "template.css"]
})

export class OrderDetailComponent implements OnInit {
  @Input() model: Product = new Product();
  @Input() showLinkButtons: Boolean = false;
  @Input() hasAddonLEGALITY: Boolean = false;
  @Input() hasAddonNordwest: Boolean = false;
  @Input() currentCustomerDefaultLanguage: String = "deu";

  @Output() onValueChanged = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [""];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();

  public unitList: string[] = [];
  public units: string[] = [];

  constructor(public validationService: ValidationService, public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.modelService?.productService?.getOrderUnitList().subscribe((unitList) => {
      this.unitList = unitList;
      this.initializeUnits();
    });
  }

  initializeUnits() {
    if (this.unitList && this.unitList.length >= 8) {
      for (let i = 0; i < 7; i++) {
        this.units.push(this.unitList[i]);
      }
      this.units.push("");
    }
  }

  toggleExtendedList() {

    let index = this.unitList.findIndex(x => x.startsWith("_"));
    this.unitList[index] = this.unitList[index].replace("_", "");

    this.units = this.unitList;
  }

  ngOnInit(): void { }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, field?: string, system: string = '-1', element: string = '-1') {
    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  updateFromAutocomplete(event = null, field?: string, system: string = '-1', element: string = '-1') {
    if (event.event == undefined) {
      event.event = new Object(); // für den template editor erforderlich, da sonst keine default-items erstellt werden
    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;

      event.value = event.selectedItem; // value ist in diesem fall nicht gesetzt, darauf baut aber der template editor
    }

    this.onValueChanged.emit(event);
  }



  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }

  get allowDeleting() {
    return !this.templateView;
  }




  get priceFactors() { return ViewService.dropDownPriceFactors; }
  get lowerBounds() { return ViewService.dropDownLowerBounds; }
  get territories() { return ViewService.dropDownTerritories; }
  get zero() { return ViewService.dropDownZero; }
  get one() { return ViewService.dropDownOne; }

  removeBasicPriceDuty() {
    this.model.orderDetail.hasBasicPriceDuty = undefined;
    this.update();
  }

  removeIsTruckageCompanyGood() {
    this.model.orderDetail.isTruckageCompanyGood = undefined;
    this.update();
  }

  removeIsBulkyGood() {
    this.model.orderDetail.isBulkyGood = undefined;
    this.update();
  }

  //ExcelTemplate

  public elementSelectFunction(event) {
    this.elementSelected.emit(event);
  }

  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }

  public readOnlyForLinkedFields(field: string, system: string = '-1', element: string = '-1'): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (field != null) {
      var isLinked = this.model.isFieldLinked(field, system, element);
      if (isLinked && this.model.isChild != null && this.model.isChild) { // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    return this.model.hasMasterOrChild();
  }

  public isFieldLinked(field: string, system: string = '-1', element: string = '-1'): boolean {
    return this.model.isFieldLinked(field, system, element);
  }

  public toggleLinkedField(field: string, system: string = '-1', element: string = '-1', event = null) {
    var changed = this.model.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }



    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.model.getClassForLinkedField(isNowLinked);

    if (this.model.isChild && isNowLinked) {
      event.forceReload = true;
    }



    // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
    //event.forceTreeRefresh = true;



    // SAVE
    this.update(event);                                                                                     //, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = '-1', element: string = '-1'): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.model.getClassForLinkedField(isNowLinked);
  }



  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }
}


  <div class="dx-fieldset" *ngIf="model">
    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beispiel Produkt 0 - Nicht Bestellbar' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" style="width:calc(60% - 50px)">
        <label class="placeLabel {{ model.exampleProduct0?.length>0 ? 'placeLabelSmall' : '' }}">EXAMPLE PRODUCT 0</label>
        <dx-text-box [(value)]="model.exampleProduct0" [readOnly]="readOnlyUser('dataQualityExample')" [showClearButton]="true" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
      <div style="float: right;margin: 7px;">
        <dx-check-box [(value)]="model.useProduct0Example" [readOnly]="readOnlyUser('dataQualityExample')" (onValueChanged)="catalogChanged($event)"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beispiel Produkt 1 - Bestellbar' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" style="width:calc(60% - 50px)">
        <label class="placeLabel {{ model.exampleProduct1?.length>0 ? 'placeLabelSmall' : '' }}">EXAMPLE PRODUCT 1</label>
        <dx-text-box [(value)]="model.exampleProduct1" [readOnly]="readOnlyUser('dataQualityExample')" [showClearButton]="true" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
      <div style="float: right;margin: 7px;">
        <dx-check-box [(value)]="model.useProduct1Example" [readOnly]="readOnlyUser('dataQualityExample')" (onValueChanged)="catalogChanged($event)"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beispiel Produkt 2 - Stationär OK' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" style="width:calc(60% - 50px)">
        <label class="placeLabel {{ model.exampleProduct2?.length>0 ? 'placeLabelSmall' : '' }}">EXAMPLE PRODUCT 2</label>
        <dx-text-box [(value)]="model.exampleProduct2" [readOnly]="readOnlyUser('dataQualityExample')" [showClearButton]="true" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
      <div style="float: right;margin: 7px;">
        <dx-check-box [(value)]="model.useProduct2Example" [readOnly]="readOnlyUser('dataQualityExample')" (onValueChanged)="catalogChanged($event)"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beispiel Produkt 3 - ECommerce OK' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" style="width:calc(60% - 50px)">
        <label class="placeLabel {{ model.exampleProduct3?.length>0 ? 'placeLabelSmall' : '' }}">EXAMPLE PRODUCT 3</label>
        <dx-text-box [(value)]="model.exampleProduct3" [readOnly]="readOnlyUser('dataQualityExample')" [showClearButton]="true" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
      <div style="float: right;margin: 7px;">
        <dx-check-box [(value)]="model.useProduct3Example" [readOnly]="readOnlyUser('dataQualityExample')" (onValueChanged)="catalogChanged($event)"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beispiel Produkt 4 - Rechtssicher' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" style="width:calc(60% - 50px)">
        <label class="placeLabel {{ model.exampleProduct4?.length>0 ? 'placeLabelSmall' : '' }}">EXAMPLE PRODUCT 4</label>
        <dx-text-box [(value)]="model.exampleProduct4" [readOnly]="readOnlyUser('dataQualityExample')" [showClearButton]="true" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
      <div style="float: right;margin: 7px;">
        <dx-check-box [(value)]="model.useProduct4Example" [readOnly]="readOnlyUser('dataQualityExample')" (onValueChanged)="catalogChanged($event)"></dx-check-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Beispiel Produkt 5 - Perfekt' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" style="width:calc(60% - 50px)">
        <label class="placeLabel {{ model.exampleProduct5?.length>0 ? 'placeLabelSmall' : '' }}">EXAMPLE PRODUCT 5</label>
        <dx-text-box [(value)]="model.exampleProduct5" [readOnly]="readOnlyUser('dataQualityExample')" [showClearButton]="true" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
      <div style="float: right;margin: 7px;">
        <dx-check-box [(value)]="model.useProduct5Example" [readOnly]="readOnlyUser('dataQualityExample')" (onValueChanged)="catalogChanged($event)"></dx-check-box>
      </div>
    </div>
  </div>

<dx-toolbar>
  <dxi-item *ngIf="!readOnlyUser('templateUpdate')" location="before" widget="dxButton" [options]="{ type: 'default', icon: 'refresh', text: 'Produkte aktualisieren' | translate, hint: 'Die Vorlagen werden auf alle Produkte im Katalog übertragen' | translate, onClick: applyTemplate }"></dxi-item>
</dx-toolbar>
<dx-tab-panel [dataSource]="pages" [selectedIndex]="0" class="p-2" [swipeEnabled]="false">

  <div *dxTemplate="let company of 'featureTemplate'">
    <!--<features *ngIf="model"
              [(catalog)]="model"
              [(showToolTips)]="showToolTips"
              [(showProToolTips)]="showProToolTips"
              [(showPlaceHolders)]="showPlaceHolders"
              [(hasAddonCLASSIFICATION)]="modelService.loginService.hasAddonCLASSIFICATION"
              [(hasAddonECOMMERCE)]="modelService.loginService.hasAddonECOMMERCE"
              [(classificationSystems)]="modelService.classificationService.classificationSystems"
              [(modelService)]="modelService"
              [(classificationFeatureValues)]="classificationFeatureValues"
              (addEcommerceFeatures)="addEcommerceFeatures($event)"
              (addClassificationFeatures)="addClassificationFeatures($event)"
              (loadClassificationFeatures)="loadClassificationFeatures($event)"
              (onValueChanged)="categoryUpdate($event)">
    </features>-->
    <editfeaturesystemlist *ngIf="model"
          displayMode="edit"
          [(featureSystems)]="model.featureTemplate"
          [(showToolTips)]="showToolTips"
          [(showProToolTips)]="showProToolTips"
          [(showPlaceHolders)]="showPlaceHolders"
          [(hasAddonCLASSIFICATION)]="modelService.loginService.hasAddonCLASSIFICATION"
          [(hasAddonECOMMERCE)]="modelService.loginService.hasAddonECOMMERCE"
          [(modelService)]="modelService"
          (addEcommerceFeatures)="addEcommerceFeatures($event)"
          (addClassificationFeatures)="addClassificationFeatures($event)"
          (onValueChanged)="categoryUpdate($event)">
    </editfeaturesystemlist>
  </div>
  <div *dxTemplate="let company of 'mediaTemplate'">
    <mimes *ngIf="model" [(model)]="model.mimeTemplate"
           [(showToolTips)]="showToolTips"
           [(showProToolTips)]="showProToolTips"
           [isTemplate]="true"
           [(showPlaceHolders)]="showPlaceHolders"
           [(catalogId)]="modelService.catalogService.catalog.id"
           [(customerId)]="modelService.loginService.currentUser.customerId"
           (reloadNedded)="reload()"
           (onValueChanged)="categoryUpdate($event)"
           [(modelService)]="modelService">
    </mimes>
  </div>

  <div *dxTemplate="let company of 'wawiTemplate'">
    <wawi [(model)]="wawiDummyProduct"
          (onValueChanged)="updateWawi($event)">
    </wawi>
  </div>
</dx-tab-panel>

import { Input, Output, EventEmitter, Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { CatalogMapping } from "../../Model/Catalog/CatalogMapping";
import { TreeItem } from "../../Model/Dto/TreeItem";
import { Category } from "../../Model/Catalog/Category";
import { Catalog } from "../../Model/Catalog/Catalog";
import { Product } from "../../Model/Catalog/Product";
import { ModelService } from "../../Services/model.service";
import { UserManagementService } from "../../Services/userManagment.service";
import { DxTreeViewComponent } from "devextreme-angular/ui/tree-view";
import { ExportFilterService } from "../../Services/exportFilter.service";
import { lastValueFrom } from "rxjs";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { LoginService } from "../../Services/login.service";
import { CategoryFilterTreeItem } from "../../Model/Catalog/exportFilter/categoryFilterTreeItem";
import { Router } from "@angular/router";

@Component({
  selector: "categories",
  templateUrl: "categories.component.html",
  styleUrls: ["template.css"]
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @Input() product: Product = null;
  @Input() showToolTips: Boolean = false;
  @Input() showProToolTips: Boolean = false;
  @Input() showPlaceHolders: Boolean = true;
  @Input() exportMode: Boolean = false;

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [""];

  @Output() elementSelected = new EventEmitter<string>();
  @Output() onValueChanged = new EventEmitter<any>();
  @Output() reloadNedded = new EventEmitter<any>();

  catalogId: string = "";
  customerId: string = "";

  _catalogMappings: CatalogMapping[];
  _allowDeleting: boolean = false;

  isCategoriesLoaded: boolean = false;
  popupVisible: boolean = false;
  categoryFilters: CategoryFilterTreeItem[] = [];
  checkedCategories: string[] = [];

  constructor(public userManagement: UserManagementService, public exportFilterController: ExportFilterService,
    public catalogService: CatalogService, public loginService: LoginService, public router: Router) {
    this.addCategories = this.addCategories.bind(this);
    this.jumpToCategory = this.jumpToCategory.bind(this);
  }

  ngOnInit(): void {
    this.catalogId = this.catalogService.catalog.id;
    this.customerId = this.loginService.currentUser.customerId;
    this.catalogMappings = this.product.catalogMappings;
  }

  ngOnDestroy(): void {
    this.catalogId = "";
    this.customerId = "";
    this.catalogMappings = new Array<CatalogMapping>();;
  }

  set catalogMappings(value: CatalogMapping[]) {
    this._catalogMappings = value;
    for (var i = 0; i < value.length; i++) {
      const _i = i;
      this.catalogService.getCategoryFullname(this._catalogMappings[i].categoryId, this.loginService.currentUser.customerId)
        .subscribe((category: Category) => {
          if (category.mimes.length > 0) {
            this._catalogMappings[_i].categoryIconUrl = category.mimes[0].normUri;
          }
          this._catalogMappings[_i].categoryName = category.groupName;
        });

      this.catalogService.getCatalogNoSet(this._catalogMappings[i].catalogId, this.loginService.currentUser.customerId)
        .subscribe((catalog: Catalog) => {
          this._catalogMappings[_i].catalogName = catalog.catalogName;
        });
    }
  }

  get catalogMappings() {
    return this._catalogMappings;
  }

  get allowDeleting(): boolean {
    if (this._catalogMappings.length > 1 && !this.product.isChild)
      this._allowDeleting = true;
    else
      this._allowDeleting = false;
    return this._allowDeleting;
  }
  set allowDeleting(value: boolean) {
    this._allowDeleting = value;
  }

  isVirtualCatalog(): boolean {
    return this.catalogService?.catalog?.isVirtual;
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newCategoryButtonTemplate'
    });
  }

  update(event = null) {
    this.onValueChanged.emit(event);
    this.catalogService.treeRefresh();
  }

  delete(e) {
    for (let i = 0; i < this.catalogMappings.length; i++) {
      if (e.data.id == this.catalogMappings[i].categoryId) {
        this.catalogMappings.splice(i, 1);
        break;
      }
    }

    this.onValueChanged.emit(event);
    this.catalogService.treeRefresh();
  }

  async showPopup() {
    if (this.isCategoriesLoaded == false) {
      this.refreshCategoryFilter();
    }
    this.popupVisible = true;
  }



  async refreshCategoryFilter() {
    this.categoryFilters = await lastValueFrom(this.exportFilterController.getCategoryFilterTreeElements(this.catalogService.selectedCatalogId, this.loginService.currentUser.customerId));
    this.isCategoriesLoaded = true;
  }

  reload() {
    this.reloadNedded.emit();
  }

  onRowPrepared(e) {
    if (e.rowType == "data") {
      if (e.data.selected == true) {
        e.rowElement.style.backgroundColor = "#D6F5F3";
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  // Standard-Klasse wegräumen, sonst greif es nur auf Nicht-Alternating-Rows
      }
    }
  }

  isAddCategoryVisible(): boolean {
    if (this.checkedCategories == undefined) {
      this.checkedCategories = [];
    }
    if (this.checkedCategories.length > 0)
      return true;
    return false;
  }

  async addCategories() {    
    let catalog = await lastValueFrom(this.catalogService.getCatalogNoSet(this.catalogService.selectedCatalogId, this.loginService.currentUser.customerId));

    for (const checkedCat of this.checkedCategories) {
      if (this._catalogMappings.some(x => x.categoryId == checkedCat))
        continue;

      let category = await lastValueFrom(this.catalogService.getCategoryFullname(checkedCat, this.loginService.currentUser.customerId));

      let mapping = new CatalogMapping();
      mapping.categoryName = category.groupName;
      mapping.order = Math.max(...this._catalogMappings.map(x => x.order || 0)) + 1;
      mapping.catalogId = this.catalogService.selectedCatalogId;
      mapping.categoryId = checkedCat;
      mapping.isVirtualCatalog = false;
      mapping.catalogName = catalog.catalogName; 
      if (category.mimes.length > 0)
        mapping.categoryIconUrl = category.mimes[0].normUri;
              
      this._catalogMappings.push(mapping);
    }                                  

    this.checkedCategories = [];
    this.popupVisible = false;
    this.update();
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return this.isVirtualCatalog() || !this.userManagement.canEdit(name);
  }

  jumpToCategory(e) {        
    var catalogMapping = this.catalogMappings.find(x => x.categoryId == e.categoryId);
    var navigateString = "/catalog/" + this.customerId + "/" + catalogMapping.catalogId + "/category/" + catalogMapping.categoryId;
    this.catalogService.selectedCatalogId = catalogMapping.catalogId;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([navigateString]));
  }
}

<dx-validation-group id="REFE">
  <dx-data-grid (onToolbarPreparing)="onGridToolbarPreparing($event)"
                [dataSource]="model?.references"
                [remoteOperations]="false"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                noDataText="{{ 'keine Referenzen' | translate }}"
                [showBorders]="true"
                (onCellPrepared)="onReferencePrepared($event)"
                (onRowUpdated)="update($event)"
                (onRowRemoving)="onRowRemoving($event)"
                (onRowRemoved)="delete($event)"
                [customizeColumns]="customizeColumns">
    <div *dxTemplate="let toolbaritem of 'newReferenceButtonTemplate'">
      <dx-button [visible]="!readOnly('referenceNew')" stylingMode="text" type="default" text="{{ 'Neue Reference' | translate }}" (onClick)="addReference()" hint="{{ 'Neue Referenz anlegen' | translate }}"></dx-button>
    </div>

    <div *dxTemplate="let toolbaritem of 'groupReferencesSwitchTemplate'" class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ 'Referenzen gruppieren' | translate }}</div>
      <dx-switch [(value)]="showReferenceDetail" switchedOffText="No" switchedOnText="Yes" hint="{{ 'Referenzen gruppieren' | translate }}"></dx-switch>
      <div class="dx-field-label switch-right"></div>
    </div>
    <div *dxTemplate="let toolbaritem of 'helpIconTemplate'">
      <span class="dx-icon dx-icon-help" (mouseenter)="ttt(2)" (mouseleave)="ttt(2)" id="tr2" style="font: 24px DXIcons;"></span>
    </div>

    <dxo-editing mode="cell" [allowUpdating]="!readOnly('referenceUpdate')" [allowDeleting]="!readOnly('referenceDelete')" [useIcons]="true">
      <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
    </dxo-editing>

    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>

    <dxo-group-panel [visible]="false" [emptyPanelText]="'Gruppierung'"></dxo-group-panel>

    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>

    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="ReferencesStateNewUIV2"></dxo-state-storing>

    <dxi-column dataField="type" caption="Typ" [allowEditing]="true" cellTemplate="referenceTypTemplate" editCellTemplate="referenceTypTemplate" [width]="200"></dxi-column>
    <div *dxTemplate="let data of 'referenceTypTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.type?.length > 0"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.order.toString())"
                         (onValueChanged)="update($event)"
                         field="PRODUCT_REFERENCES_TYPE"
                         [system]="data.data.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-select-box [items]="referenceTypes"
                           placeholder=""
                           [showClearButton]="false"
                           [readOnly]="readOnlyForLinkedFields('PRODUCT_REFERENCES_TYPE', data.data.order.toString()) || readOnly('referenceType')"
                           [(value)]="data.data.type"
                           (onValueChanged)="update($event, 'PRODUCT_REFERENCES_TYPE', data.data.order.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="REFE">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRODUCT_REFERENCES_TYPE', data.data.order.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-select-box>
          </div>

        </np-inputControl>
      </div>

    </div>

    <dxi-column dataField="artIdTo" caption="{{ 'Produktnummer' | translate }}" cellTemplate="referenceSpidTemplate" editCellTemplate="referenceSpidTemplate" [width]="200"></dxi-column>
    <div *dxTemplate="let data of 'referenceSpidTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.artIdTo?.length > 0"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.order.toString())"
                         (onValueChanged)="update($event)"
                         field="PRODUCT_REFERENCES_SPID"
                         [system]="data.data.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="data.data.artIdTo"
                         [showClearButton]="!readOnlyForLinkedFields('PRODUCT_REFERENCES_SPID', data.data.order.toString())"
                         [readOnly]="readOnlyForLinkedFields('PRODUCT_REFERENCES_SPID', data.data.order.toString()) || readOnly('referenceSPID')"
                         (onValueChanged)="update($event, 'PRODUCT_REFERENCES_SPID', data.data.order.toString())">
              <dx-validator *ngIf="!templateView" validationGroup="REFE">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRODUCT_REFERENCES_SPID', data.data.order.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>

        </np-inputControl>
      </div>

    </div>

    <dxi-column dataField="normUri" caption="" [allowEditing]="false" editCellTemplate="imageCellTemplate" cellTemplate="imageCellTemplate" [width]="70"></dxi-column>
    <div *dxTemplate="let data of 'imageCellTemplate'" class="w-100">
      <div>
        <img *ngIf="!data.value" src="images/ikon_article_tree_invert.png" class="catlistImage" />
        <img *ngIf="data.value" (mouseenter)="showImage(data.value,data.rowIndex)" (mouseleave)="hideImage()" src="{{data.value}}" class="catlistImage" id="link{{data.rowIndex}}" />
      </div>
    </div>

    <dxi-column dataField="descriptionShort" [allowEditing]="false" caption="{{ 'Produkt' | translate }}" cellTemplate="referenceDescriptionShortTemplate" [allowSearch]="false" [allowSorting]="false"></dxi-column>
    <div *dxTemplate="let data of 'referenceDescriptionShortTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="model"
                         [showLinkButtons]="false"
                         [showPlaceLabel]="false"
                         [suppressLabel]="true"
                         [suppressLinkButton]="true"
                         [suppressTranslation]="true"
                         [suppressPlaceLabel]="true"
                         [suppressToolTip]="true"
                         [suppressDragHandle]="true"
                         field="READ_ONLY_FIELD"
                         [showDragHandle]="false"
                         [dragFilter]="dragFilter">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="data.data.descriptionShort"
                         [showClearButton]="false"
                         [readOnly]="true">
            </dx-text-box>
          </div>

        </np-inputControl>
      </div>

    </div>

  </dx-data-grid>
</dx-validation-group>
<div class="color5Text">
  <div *ngIf="model?.hintsActiv && !validationService.validationResult?.productReferenceValid">
    <table>
      <tr *ngFor="let msg of validationService.validationResult?.referenceMessages">
        <td>
          {{ translateMessage(msg) }}
        </td>
      </tr>
    </table>
  </div>

  <dx-tooltip target="#ref1" class="toolTip toolTipField" [(visible)]="v[1]">
    <div *dxTemplate="let data = data of 'content'">
      {{'Die Referenzen sind für das CrossSelling' | translate }}.
      {{'CrossSelling kann den Umsatz erheblich steigern' | translate }}.
    </div>
  </dx-tooltip>
</div>

<dx-popover [(target)]="target"
            position="bottom"
            [width]="300"
            [showTitle]="false"
            [(visible)]="imageVisable">
  <div *dxTemplate="let data = model of 'content'">
    <img src="{{source}}{{mimedate()}}" style="height: 244px;" />
  </div>
</dx-popover>
<dx-tooltip target="#tr2" class="toolTip toolTipField" [(visible)]="v[2]">
  <div *dxTemplate="let data = data of 'content'">
    {{'Das Feld TYPE beschreibt, in welcher Beziehung die beiden Produkte zu einander stehen' | translate }}.<br /><br /><br />
    {{'Ein SPAREPART ist ein Ersatzteil zu diesem Quellprodukt. Ein Ersatzteil ist ein Teil des Produktes, welches im Rahmen von Wartung und Reparatur separat ausgewechselt werden kann' | translate }}.<br /><br />
    {{'Ein SIMILAR ist ein ähnliches Produkt zu diesem Quellprodukt. Ein ähnliches Produkt ist ein Produkt, welches ähnliche Ziele und Funktionen wie das Quellprodukt hat und evtl. alternativ genutzt werden kann' | translate }}.<br /><br />
    {{'Ein als MANDATORY aufgeführtes Bezugsprodukt ist ein notwendiges Zusatzprodukt, das auf jeden Fall mit bestellt werden muss. Das beschriebene Quellprodukt kann nicht alleine bestellt werden. Sind mehrere Produkte als "mandatory" gekennzeichnet, so müssen diese alle mitbestellt werden' | translate }}.<br /><br />
    {{'Ein als SELECT aufgeführtes Bezugsprodukt ist ein notwendiges Auswahlprodukt. Das beschriebene Bezugsprodukt kann nicht alleine bestellt werden' | translate }}.<br /><br />
    {{'Ein als DIFF_ORDERUNIT aufgeführtes Bezugsprodukt besteht aus dem selben Basisprodukt wie das Quellprodukt. Das Quellprodukt liegt jedoch in einer anderen Verpackungseinheit vor' | translate }}.<br /><br />
    {{'Ein als ACCESSORIES aufgeführtes Bezugsprodukt ist ein Zubehörteil zu diesem Quellprodukt. Ein Zubehörteil erweitert die Funktionalität des Quellproduktes' | translate }}.<br /><br />
    {{'Ein als CONSISTS_OF aufgeführtes Bezugsprodukt ist Teil dieses Quellprodukt. Dieser Verweistyp kann genutzt werden, um Stücklisten aufzubauen. Es wird dabei immer von dem übergeordneten Teil auf die enthaltenen Teile verwiesen' | translate }}.<br /><br />
    {{'OTHERS kann genutzt werden, falls keine der anderen Verweisarten die Beziehung Bezugs- und Quellprodukt ausreichend beschreibt' | translate }}.<br /><br />
  </div>
</dx-tooltip>

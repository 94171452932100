<!--78 setWawiSupplierPids-->
<div class="dx-field">
  <div class="dx-field-label">{{ 'Bestehenden Wert auf SUPPLIER_ALT_PID übertragen' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ "Nein" | translate }}</div>
      <dx-switch [(value)]="fillAlt"
                 switchedOffText="Nein"
                 switchedOnText="Ja"></dx-switch>
      <div class="dx-field-label switch-right">{{ "Ja" | translate }}</div>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'Lieferant ist auch Hersteller' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <div class="dx-field-label switch-left">{{ "Nein" | translate }}</div>
      <dx-switch [(value)]="supplierIsManufacturer"
                 switchedOffText="Nein"
                 switchedOnText="Ja"></dx-switch>
      <div class="dx-field-label switch-right">{{ "Ja" | translate }}</div>
    </div>
  </div>
</div>

<div class="dx-field">
  <div class="dx-field-label">{{ 'ProduktStatus der gesetzt wird, wenn neues Mapping erstellt wurde' | translate }}</div>
  <div class="dx-field-value">
    <div class="d-flex flex-row">
      <dx-text-box [(value)]="newProductStatus" [showClearButton]="true"></dx-text-box>
    </div>
  </div>
</div>

<div *ngIf="!catalogHasSupplier()" class="accordion-body-warning">
  <div>{{ 'Im Katalogkopf wurden keine Lieferanteninformationen gepflegt.' | translate }}</div>
</div>
<div *ngIf="catalogHasSupplier()" class="accordion-body-warning">
  <div>{{ 'Im Katalogkopf wurden Lieferanteninformationen gepflegt. Es werden die entsprechenden Lieferanteninformationen aus den Mappings benutzt.' | translate }}</div>
</div>

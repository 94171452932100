<!-- <div class="row" >
<div class="col-sm-6">-->
<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Lieferantenname' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.supplierName?.length>0 ? 'placeLabelSmall' : '' }}">SUPPLIER_NAME</label>
      <dx-text-box [(value)]="model.supplierName" [readOnly]="readOnlyUser('dataQuality')" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Ansprechpartner' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.contact?.length>0 ? 'placeLabelSmall' : '' }}">CONTACT</label>
      <dx-text-box [(value)]="model.contact" [readOnly]="readOnlyUser('dataQuality')" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Position' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.position?.length>0 ? 'placeLabelSmall' : '' }}">POSITION</label>
      <dx-text-box [(value)]="model.position" [readOnly]="readOnlyUser('dataQuality')" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Telefon' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.phone?.length>0 ? 'placeLabelSmall' : '' }}">PHONE</label>
      <dx-text-box [(value)]="model.phone" [readOnly]="readOnlyUser('dataQuality')" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Email' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.email?.length>0 ? 'placeLabelSmall' : '' }}">EMAIL</label>
      <dx-text-box [(value)]="model.email" [readOnly]="readOnlyUser('dataQuality')" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
      </dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Geliefertes Format' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.format?.length>0 ? 'placeLabelSmall' : '' }}">FORMAT</label>
      <dx-autocomplete [minSearchLength]="0"
                       [dataSource]="formats"
                       [showClearButton]="true"
                       [showDropDownButton]="true"
                       [(value)]="model.format"
                       valueChangeEvent="focusout"
                       (onValueChanged)="catalogChanged($event)"
                       [readOnly]="readOnlyUser('dataQuality')">
      </dx-autocomplete>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Zugang' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.access?.length>0 ? 'placeLabelSmall' : '' }}">ACCESS</label>
      <dx-autocomplete [minSearchLength]="0"
                       [dataSource]="accesses"
                       [showClearButton]="true"
                       [showDropDownButton]="true"
                       [(value)]="model.access"
                       valueChangeEvent="focusout"
                       (onValueChanged)="catalogChanged($event)"
                       [readOnly]="readOnlyUser('dataQuality')">
      </dx-autocomplete>
    </div>

  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Wartezeit' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.waitingPeriod?.length>0 ? 'placeLabelSmall' : '' }}">WAITING_PERIOD</label>
      <dx-autocomplete [minSearchLength]="0"
                       [dataSource]="timeSpans"
                       [showClearButton]="true"
                       [showDropDownButton]="true"
                       [(value)]="model.waitingPeriod"
                       valueChangeEvent="focusout"
                       (onValueChanged)="catalogChanged($event)"
                       [readOnly]="readOnlyUser('dataQuality')">
      </dx-autocomplete>
    </div>

  </div>

  <div class="dx-field">
    <div class="dx-field-label">{{ 'Bearbeitungszeit' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ model.processingPeriod?.length>0 ? 'placeLabelSmall' : '' }}">PROCESSING_PERIOD</label>
      <dx-autocomplete [minSearchLength]="0"
                       [dataSource]="timeSpans"
                       [showClearButton]="true"
                       [showDropDownButton]="true"
                       [(value)]="model.processingPeriod"
                       valueChangeEvent="focusout"
                       (onValueChanged)="catalogChanged($event)"
                       [readOnly]="readOnlyUser('dataQuality')">
      </dx-autocomplete>
    </div>
  </div>
</div>






















  <!--</div>-->
  <!--</div>-->
  <!--<div class="col-sm-6">
  <div class="dx-fieldset" *ngIf="model">

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Größe Gesamtsortiment' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ model.assortmentSize?.length > 0 ? 'placeLabelSmall' : '' }}">ASSORTMENT_SIZE</label>
        <dx-text-box [(value)]="model.assortmentSize" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Anzahl Produkte' | translate }}</div>
      <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
        <label class="placeLabel {{ model.productCount?.length > 0 ? 'placeLabelSmall' : '' }}">PRODUCT_COUNT</label>
        <dx-text-box [(value)]="model.productCount" [showClearButton]="true" valueChangeEvent="focusout" (onValueChanged)="catalogChanged($event)">
        </dx-text-box>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Eigene Artikelnummern für Packungsgrößen' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch (onValueChanged)="catalogChanged($event)" [(value)]="model.pidForPackingSize" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Kundenspezifischer Preis' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch (onValueChanged)="catalogChanged($event)" [(value)]="model.customerSpezificPrice" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
      </div>
    </div>

    <div class="dx-field">
      <div class="dx-field-label">{{ 'Varianten nötig' | translate }}</div>
      <div class="dx-field-value">
        <dx-switch (onValueChanged)="catalogChanged($event)" [(value)]="model.variantsRequired" switchedOffText="{{ 'Nein' | translate }}" switchedOnText="{{ 'Ja' | translate }}"></dx-switch>
      </div>
    </div>
    -->
  
<!--</div>
</div>-->

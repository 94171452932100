import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, of, tap } from 'rxjs';
import { ValidFeatureSystem } from '../Model/ValidFeatureSystem';
import { ValidFeature } from '../Model/ValidFeature';
import { ValidFeatureValue } from '../Model/ValidFeatureValue';
import { FeatureSystem } from '../Model/Catalog/FeatureModels/FeatureSystem';
import { StringResponse } from '../Model/Dto/StringResponse';
import { ValidFeatureUsageReport } from '../Model/ValidFeatureUsageReport';

@Injectable()
export class ValidFeatureService {
  private systemsMap = new Map<string, ValidFeatureSystem[]>();
  private featuresMap = new Map<string, ValidFeature[]>();

  constructor(private http: HttpClient) {}

  async getValidIdForFeature(
    name: string,
    unit: string,
    validFeatureSystemId: string,
    customerId: string
  ): Promise<string> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams()
        .append('customerId', customerId)
        .append('name', name)
        .append('unit', unit)
        .append('validFeatureSystemId', validFeatureSystemId)
    };
    const x = await lastValueFrom(this.http.get<StringResponse>('api/ValidFeature/GetValidIdForFeature', options));
    return x.value;
  }

  async getValidIdForSystemName(name: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('name', name)
    };
    const x = await lastValueFrom(this.http.get<StringResponse>('api/ValidFeature/GetValidIdForSystemName', options));
    return x.value;
  }

  async getValidFeatureUnitForId(id: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('id', id)
    };
    const x = await lastValueFrom(this.http.get<StringResponse>('api/ValidFeature/getValidFeatureUnitForId', options));
    return x.value;
  }

  async getValidFeatureNameForId(id: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('id', id)
    };
    const x = await lastValueFrom(this.http.get<StringResponse>('api/ValidFeature/getValidFeatureNameForId', options));
    return x.value;
  }

  async getValidSystemNameForId(id: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('id', id)
    };
    const x = await lastValueFrom(this.http.get<StringResponse>('api/ValidFeature/GetValidSystemNameForId', options));
    return x.value;
  }

  getSystems(customerId: string, force = true) {
    if (!force && this.systemsMap.has(customerId)) {
      return of(this.systemsMap.get(customerId));
    }

    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };

    return this.http
      .get<ValidFeatureSystem[]>('api/ValidFeature/GetSystems', options)
      .pipe(tap((featureSystems) => this.systemsMap.set(customerId, featureSystems)));
  }

  writeSystem(vfs: ValidFeatureSystem): Observable<ValidFeatureSystem> {
    return this.http.post('api/ValidFeature/AddSystem', vfs);
  }

  getFeatures(validFeatureSystemId: string, customerId: string, force = true): Observable<ValidFeature[]> {
    if (!force && this.featuresMap.has(validFeatureSystemId)) {
      return of(this.featuresMap.get(validFeatureSystemId));
    }

    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('validFeatureSystemId', validFeatureSystemId)
    };

    return this.http
      .get<ValidFeature[]>('api/ValidFeature/GetFeatures', options)
      .pipe(tap((features) => this.featuresMap.set(validFeatureSystemId, features)));
  }

  setValidIds(customerId: string, featureSystem: FeatureSystem): Observable<FeatureSystem> {
    featureSystem.customerId = customerId;
    return this.http.post<FeatureSystem>('api/ValidFeature/SetValidIds', featureSystem);
  }

  getValues(validFeatureId: string, customerId: string): Observable<ValidFeatureValue[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('validFeatureId', validFeatureId)
    };
    return this.http.get<ValidFeatureValue[]>('api/ValidFeature/GetValues', options) as any;
  }

  getCatalogSystems(catalogId: string, customerId: string): Observable<ValidFeatureSystem[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('catalogId', catalogId).append('customerId', customerId)
    };
    return this.http.get<ValidFeatureSystem[]>('api/ValidFeature/GetCatalogSystems', options) as any;
  }

  getCatalogFeatures(catalogId: string, customerId: string): Observable<ValidFeature[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('catalogId', catalogId).append('customerId', customerId)
    };
    return this.http.get<ValidFeature[]>('api/ValidFeature/GetCatalogFeatures', options) as any;
  }

  getCatalogFeaturesOfSystem(
    catalogId: string,
    customerId: string,
    validFeatureSystem: ValidFeatureSystem
  ): Observable<ValidFeature[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('catalogId', catalogId).append('customerId', customerId)
    };
    return this.http.post<ValidFeature[]>(
      'api/ValidFeature/GetCatalogFeaturesOfSystem',
      validFeatureSystem,
      options
    ) as any;
  }

  updateValidFeature(data: ValidFeature, customerId: string): Promise<void> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId)
    };
    return lastValueFrom(this.http.post<void>('api/ValidFeature/UpdateValidFeature', data, options));
  }

  getUsageReport(customerId: string, validFeatureSystemId: string): Observable<ValidFeatureUsageReport[]> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('customerId', customerId).append('validFeatureSystemId', validFeatureSystemId)
    };
    return this.http.get<ValidFeatureUsageReport[]>('api/ValidFeature/GetUsageReport', options) as any;
  }
}

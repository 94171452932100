import { Input, Component, OnInit } from "@angular/core";
import { Product } from "../../Model/Catalog/product";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { LoginService } from "../../Services/login.service";
import { MediaService } from "../../Services/media.service";

@Component({
  selector: "shopView",
  templateUrl: "shopView.component.html",
  styleUrls: ["shopView.component.css"]
})
export class ShopView implements OnInit {
  @Input() model: Product;

  constructor(public mediaService: MediaService, public catalogService: CatalogService, public loginService: LoginService) { }

  ngOnInit(): void {       
    this.model.prepareView();
  }


  get customerId() {
    return this.loginService.currentCustomer.id;
  }

  get catalogId() {
    return this.catalogService.selectedCatalogId;
  }
  
  get mimes() {  
    return this.model.productImagesNormal;
  }

  get features() {
    return this.model.features;
  }

  get showIndicator() {
    if (this.model.productImagesNormal.length == 1) {
      return false;
    }
    return true;
  }

  get marketingClaim() {
    return this.model.marketingClaim;
  }

  get ean() {
    if (this.model.internationalPidType == 'gtin' && this.model.internationalPid.length == 13) {
      return this.model.internationalPid;
    }
    return "";
  }

  get hasPrice() {
    if (this.model.price != null && this.model.price.prouctPrice != "") {
      return true;
    }
    return false;
  }

  get downloadLink(): string {
    return "/Cloud/false/" + this.model.catalogMappings[0].catalogId + "/" + this.model.customerId + "/"; // .catalogMappings[0]. ist hier OK
  }

  get price() {
    return this.model.price;
  }

  forLoopArray(elements): Array<any> {
    return new Array(parseInt(elements));
  }
}

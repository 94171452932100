import { Template } from '../../Model/Catalog/template';

export class MassDataChangeParameter {

  constructor(
    public fullCatalog: boolean,
    public categoryIds: string[],
    public productIds: string[],
    public template?: Template,
    public deleteItems?: boolean,
    public deleteEmptyCategories?: boolean
  ) {

  }
}

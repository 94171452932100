
import { PackingUnit } from "./PackingUnit";

export class OrderDetail {

  constructor(
    public contentUnit?: string,
    public deliveryTime?: string,
    public noCuPerOu?: string,
    public orderUnit?: string,
    public packingUnits?: PackingUnit[],
    public priceQuantity?: string,
    public quantityInterval?: string,
    public quantityMax?: string,
    public quantityMin?: string,
    public contentQuantity?: string,
    public contentPackage?: string,
    public basicQuantity?: string,
    public hasBasicPriceDuty?: boolean,
    public isBulkyGood?: boolean,
    public isTruckageCompanyGood?: boolean,
    public costOfLivingAdjustment?: number,
    public alloySurcharge?: number,
    public isDurabilityProduct?: boolean,
    public durabilityDays?:number,
  ) {
    this.packingUnits = [];
  }
}

<np-dialog
  [popupWidth]="1400"
  [popupHeight]="800"
  [popupTitle]="'Export Einstellungen' | translate"
  [(popupVisible)]="visible"
>
  <np-help-button>
    <np-help-option
      text="YouTube"
      icon="images/YouTube.svg"
      link="https://www.youtube.com/watch?v=I_H6bf3kB98"
    ></np-help-option>
  </np-help-button>

  <div slot="content" [ngStyle]="{ height: 'inherit' }">
    <exportParameter
      [(config)]="config"
      [catalogId]="this.modelService.catalogService.selectedCatalogId"
      [ngStyle]="{ height: 'inherit' }"
      class="d-flex flex-column"
    >
      <div header>
        <div class="dx-field">
          <dx-select-box
            id="catalogsBox"
            displayExpr="name"
            label="{{ 'Export Konfigurationen' | translate }}"
            valueExpr="id"
            [dataSource]="configurations"
            [value]="config?.id"
            [searchEnabled]="false"
            [showClearButton]="false"
            (onSelectionChanged)="onConfigChanged($event)"
          >
            <div *dxTemplate="let data of 'item'">
              <div *ngIf="data.isDefault">{{ data.name }} (Standard)</div>
              <div *ngIf="!data.isDefault">
                {{ data.name }}
              </div>
            </div>
          </dx-select-box>

          <dx-button (onClick)="showAddConfig()" icon="plus"></dx-button>
          <dx-button *ngIf="config.id" (onClick)="showEditConfig()" icon="edit"></dx-button>
          <dx-button *ngIf="config.id" (onClick)="updateConfig()" icon="save"></dx-button>
          <dx-button *ngIf="config.id" (onClick)="deleteConfig()" icon="trash"></dx-button>
        </div>
      </div>

      <dx-button
        (onClick)="export()"
        stylingMode="text"
        type="default"
        text="{{ 'Exportieren' | translate }}"
      ></dx-button>
    </exportParameter>
  </div>
</np-dialog>

<dx-popup
  [(visible)]="isPopupAddVisible"
  [showTitle]="true"
  [dragEnabled]="true"
  title="Neue Export Konfiguration"
  [width]="600"
  [height]="400"
  [showCloseButton]="true"
>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Name:</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="newConfigName"></dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Standard:</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="newConfigIsDefault"></dx-switch>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-value">
        <dx-button text="Erstellen" (onClick)="addConfig()"></dx-button>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="isPopupEditVisible"
  [showTitle]="true"
  [dragEnabled]="true"
  title="Export Konfiguration Editor"
  [width]="600"
  [height]="400"
  [showCloseButton]="true"
>
  <div class="dx-fieldset">
    <div class="dx-field">
      <div class="dx-field-label">Name:</div>
      <div class="dx-field-value">
        <dx-text-box [(value)]="config.name"></dx-text-box>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-label">Standard:</div>
      <div class="dx-field-value">
        <dx-switch [(value)]="config.isDefault"></dx-switch>
      </div>
    </div>
    <div class="dx-field">
      <div class="dx-field-value">
        <dx-button text="Updaten" (onClick)="updateConfig()"></dx-button>
      </div>
    </div>
  </div>
</dx-popup>

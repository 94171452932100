import { NextPimField } from "../Catalog/NextPimField";

export class ExcelElement {
  constructor(
    public pimFields: NextPimField[] = [],
    public id: string = "",
    public title: string = "",
    public row1: string = "",
    public row2: string = "",
    public calculation: string = "",
    public numberformat: string = "",
    public seperator: string = "",
    public mapping: string = "",
    public remove: string = "",
    public inUse: boolean = false,
    public addprev: string = "",
    public addfoll: string = "",
    public defaultValue: string = "",
    public preview: string = "",
    public displayName: string = "",
    public isDisplayNameRequested: boolean = false,
    public maxCharacters: number = 0,
    public section: number = 1,
    public keys?: string[],
    public languageCode?: string,
    public factorOperator: string = '',
    public factor: number = 1,
    public prefix: string = '',
    public suffix: string = '',
    public catName: string = '',
    public name: string = ''
  ) {}
}

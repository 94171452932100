import { Input, Output, EventEmitter, Component, OnInit } from "@angular/core";
import { Mime } from "../../Model/Catalog/Mime";
import { ViewService } from '../../Services/view.service';
import { Product } from '../../Model/Catalog/product';
import { ModelService } from '../../Services/model.service';
import { TranslateService } from '@ngx-translate/core';
import { Message } from '../../Model/System/Message';
import { custom } from 'devextreme/ui/dialog';
import { TemplateService } from "../../Services/template.service";
import { UserManagementService } from "../../Services/userManagment.service";
import { Functions } from '../../Services/functions';
import { ValidationService } from "../../Services/Validation/validation.service";
import { MediaService } from "../../Services/media.service";



@Component({
  selector: "mimes",
  templateUrl: "mimes.html",
  styleUrls: ["./mimes.css", "template.css"]
})
export class Mimes implements OnInit {
  @Input() model: Mime[] = [];
  @Input() product: Product = null;
  @Input() showLinkButtons: Boolean = false;
  @Input() catalogId: string = "";
  @Input() customerId: string = "";
  @Input() exportMode: Boolean = false;
  @Input() modelService: ModelService;
  @Input() isTemplate: Boolean = false;
  @Output() onValueChanged = new EventEmitter<any>();
  @Output() reloadNedded = new EventEmitter<any>();

  @Input() templateView: Boolean = false;
  @Input() selectedElements: string[] = [""];
  @Output() elementSelected = new EventEmitter<string>();

  @Input() showDragHandle: boolean = false;
  @Input() dragFilter: string = "noDrag";

  @Output() excelElementDropped = new EventEmitter<any>();
  @Output() elementDeleted = new EventEmitter<any>();

  txtExportReportProductDatasheetShort: string;
  updateLock: boolean = false;
  newMimeOrder = 0;

  constructor(public templateService: TemplateService, public validationService: ValidationService, public translate: TranslateService,
    public userManagement: UserManagementService, public mediaService: MediaService) {
    this.addMime = this.addMime.bind(this);
    this.showImportMime = this.showImportMime.bind(this);
    this.reload = this.reload.bind(this);
  }

  ngOnInit(): void {
    this.txtExportReportProductDatasheetShort = this.translate.instant("ReportProductDatasheetShort");

    this.model.forEach(x => {
      x.guid = Functions.newGuid();
    });

    for (const mime of this.model) {
      if (mime.mimeOrder > this.newMimeOrder && mime.mimeOrder < 1000) {
        this.newMimeOrder = mime.mimeOrder;
      }
    }
    this.newMimeOrder += 1;
  }

  customizeColumns(columns) {
    columns.forEach(column => {
      column.showEditorAlways = true;
    });
  }

  onGridToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'reportButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'newMimeButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'importButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate'
    });
  }

  isVirtualCatalog(): boolean {
    return this.modelService?.catalogService?.catalog?.isVirtual;
  }

  update(event = null, mimeChange = null, data?, field?: string, system: string = "-1", element: string = "-1") {
    //Order wird bereits benutzt
    if (field == "MIME_ORDER") {
      let fs = this.model.filter(x => x.guid == data.guid)[0];
      let originalOrder = event.previousValue;
      let change = event.value > event.previousValue ? 1 : -1;
      while (this.model.filter(x => x.mimeOrder == event.value).length > 1) {
        event.value += change;
        fs.mimeOrder = event.value;
      }
      if (event.value < 1) {
        fs.mimeOrder = originalOrder;
        return;
      }


      if (fs.mimeOrder >= this.newMimeOrder) {
        for (const mime of this.model) {
          if (mime.mimeOrder > this.newMimeOrder && mime.mimeOrder < 1000) {
            this.newMimeOrder = mime.mimeOrder;
          }
        }
        this.newMimeOrder += 1;
      }
    }


    if ((mimeChange != null && mimeChange) || (event != null && event.previousValue !== event.value)) {

      this.model.forEach((mime: Mime) => {
        mime.lastChanged = new Date();
      });

    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;
    }

    this.onValueChanged.emit(event);
  }

  updateFromAutocomplete(event = null, field?: string, system: string = "-1", element: string = "-1") {
    if (event.event == undefined) {
      event.event = new Object(); // für den template editor erforderlich, da sonst keine default-items erstellt werden
    }

    // für den template editor erforderlich:
    if (event) {
      event.dField = field;
      event.dSystem = system;
      event.dElement = element;
      event.createTemplateItem = true;

      event.value = event.selectedItem; // value ist in diesem fall nicht gesetzt, darauf baut aber der template editor
    }

    this.onValueChanged.emit(event);
  }

  delete(event = null) {

    let orderString = event.data.mimeOrder.toString();

    // Prüfen, ob irgend ein Feld dieses MIMEs gelinked ist
    for (let mimeField of this.templateService.allMimeFields) {
      if (this.product != null && this.product.isFieldLinked(mimeField, orderString, "-1")) {
        this.product.toggleLinkedField(mimeField, orderString, "-1");
      }
    }

    this.onValueChanged.emit(event);

    // für den template editor erforderlich:
    event.dFields = this.templateService.allMimeFields;
    event.dDetailFields = null;
    event.dSystem = orderString;
    event.dElement = -1;
    this.elementDeleted.emit(event);
  }

  reload() {
    this.reloadNedded.emit();
  }

  addMime() {
    this.updateLock = true;
    var mime = new Mime();
    mime.lastChanged = new Date();
    mime.mimeOrder = this.newMimeOrder++;
    this.model.push(mime);
    this.update();
  }

  onContentReady() {
    this.updateLock = false;
  }

  get allowDeleting() {
    //return !this.templateView;
    return true;
  }

  get mimeTypes() {
    return ViewService.dropDownMimeTypes;
  }

  get mimePurposes() {
    return ViewService.dropDownMimePurposes;
  }

  get mimeDescr() {
    return this.modelService.viewService.dropDownMimeDescr;
  }



  public importMimeVisible: boolean = false;
  showImportMime() {
    this.importMimeVisible = true;
  }
  hideImportMime() {
    this.importMimeVisible = false;
  }



  public createReportProductDatasheetVisible: boolean = false;
  showCreateReportProductDatasheet() {
    this.createReportProductDatasheetVisible = true;
  }

  hideCreateReportProductDatasheet() {
    this.createReportProductDatasheetVisible = false;
  }



  translateMessage(msg: string) {
    return this.translate.instant(msg);
  }




  public onRowRemoving(e) {
    let orderString = e.data.mimeOrder.toString();

    if (this.product != null && this.product.isChild && this.hasMimeFieldLinked(orderString)) {
      // Löschen nicht erlaubt, INFO

      let txtNotAllowed = "";
      this.translate.get("DeleteLinkedFieldNotAllowed").subscribe((text: string) => {
        txtNotAllowed = text;
      });

      this.modelService.systemService.notify(new Message(txtNotAllowed, "info"), 3000);
      e.cancel = true;
      return;
    }

    // Löschen erlaubt, Benutzerabfrage, ggf. Abbruch
    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("Wollen Sie die Mime wirklich löschen").subscribe((text: string) => {
      txtMsg = text;
      if (!txtMsg.endsWith("?")) {  // Übersetzungen sid nicht einheitlich gepflegt, werden aber auch an anderen Stellen so verwendet.
        txtMsg = txtMsg + "?";
      }
    });
    this.translate.get("Wirklich löschen?").subscribe((text: string) => {
      txtTitle = text;
    });

    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [
        {
          text: txtYes,
          onClick: (e) => { return false; }
        },
        {
          text: txtNo,
          onClick: (e) => { return true; }
        }
      ]
    });

    // Hier wird ein Promise zurück geliefert. Dies enthält den Return Value des (erst später!!)
    // ausgeführten onClick-Handlers des gedrückten Buttons im Dialog.
    // https://supportcenter.devexpress.com/ticket/details/t978828/datagrid-how-to-cancel-the-rowremoving-event
    e.cancel = myDialog.show();
  }



  //ExcelTemplate

  templateSelect(identifier: string, orderString: string) {
    this.elementSelected.emit(`${identifier}_${orderString}`);
  }



  public excelElementDrop(e) {
    this.excelElementDropped.emit(e);
  }



  public hasMimeFieldLinked(system?: string): boolean {
    // Prüfen, ob irgend ein Feld dieses MIMEs gelinked ist
    for (let mimeField of this.templateService.allMimeFields) {
      if (this.product != null && this.product.isFieldLinked(mimeField, system, "-1")) {
        return true;
      }
    }
    return false;
  }






  public readOnlyForLinkedFields(field: string, system: string = "-1", element: string = "-1"): boolean {
    // Wenn dies ein vom Master vererbtes Feld ist, soll es ReadOnly sein:
    if (this.product != null && field != null) {
      var isLinked = this.product.isFieldLinked(field, system, element);
      if (isLinked && this.product.isChild != null && this.product.isChild) { // Für einen Master darf es nicht ReadOnly werden!!
        return true;
      }
    }

    //return this.readOnly(productId);

    return false;
  }

  public hasMasterOrChildOrIsTranslated(): boolean {
    if (this.product != null) {
      return this.product.hasMasterOrChild();
    }
    return false;
  }

  public isFieldLinked(field: string, system: string = "-1", element: string = "-1"): boolean {
    if (this.product != null) {
      return this.product.isFieldLinked(field, system, element);
    }
    return false;
  }

  public toggleLinkedField(field: string, system: string = "-1", element: string = "-1", event = null) {
    var changed = this.product.toggleLinkedField(field, system, element);
    if (!changed) {
      return;
    }



    var isNowLinked = this.isFieldLinked(field, system, element);
    event.target.className = this.product.getClassForLinkedField(isNowLinked);

    if (this.product.isChild && isNowLinked) {
      event.forceReload = true;
    }



    if (field == "MIME_PURPOSE" || field == "MIME_SOURCE") {
      // Etwas schmutzig, aber sonst können wir den Refresh am Tree nicht antriggern.
      event.forceTreeRefresh = true;
    }



    // SAVE
    this.update(event, true);
  }

  public getInitialClassForLinkedField(field: string, system: string = "-1", element: string = "-1"): string {
    var isNowLinked = this.isFieldLinked(field, system, element);
    return this.product.getClassForLinkedField(isNowLinked);
  }



  canView(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.userManagement.canView(name);
  }
  readOnly(name: string): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product != null && this.isVirtualCatalog()) || !this.userManagement.canEdit(name);
  }

  renderNewMimeButton(): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return (this.product == null || !this.isVirtualCatalog()) && this.canView('mimeNew');
  }

  renderImportMimeButton(): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return !this.templateView && (this.product == null || !this.isVirtualCatalog()) && this.canView('mimeImport');
  }

  renderReportButton(): boolean {
    // Wenn product == null befinden wir uns im Katalog oder einer Kategorie.
    return !this.isVirtualCatalog() && this.product && this.templateView == false && this.modelService?.loginService?.hasAddonREPORTPRODUCTDATASHEET && this.canView('mimeProductSheetCreate');
  }

  hasShopware6Addon(): boolean {
    return this.modelService?.loginService?.hasAddonShopware6;
  }
}



//nexmart Informationen:
//Diesem sind zwar 3 Produkt-Bilder zugewiesen im Eingangsfile. Aber es wird standardmäßig immer das Produktbild mit der höchsten Auflösung genommen und dann verdreifacht – das Eingangsfile wird zum Zoombild, daraus wird zusätzlich ein Produktbild und ein Thumbnail erstellt. Alle anderen Produktbilder, wenn sie nicht als weitere, Anwendungsbilder deklariert sind, verfallen.
//Als die „3“ Produktbilder wird immer eine Basis genutzt.Die Nutzung von 2 verschiedenen Bildern als Produktbild für einen Artikel geht nicht.



import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { CatalogDataQuality } from '../../Model/validation/CatalogDataQuality';

@Component({
    selector: "dataQualityGroups",
    templateUrl: "dataQualityGroups.component.html",
    styleUrls: []
})

export class DataQualityGroupsComponent implements OnInit {
  @Input() modelChangedEvent: EventEmitter<CatalogDataQuality>;

  dataSource: any[];
  colorPalette: string[];

  constructor(modelService: ModelService, public translate: TranslateService) {
    this.reload = this.reload.bind(this);
    this.reload(modelService.dataQualityService.catalogDataQuality);
  }

  ngOnInit(): void {
    this.modelChangedEvent.subscribe((model) => this.reload(model));
  }

  reload(model: CatalogDataQuality) {
    this.initDataSource(model);
  }

  initDataSource(value: CatalogDataQuality) {
    this.dataSource = [];
    this.colorPalette = [];
    if (value.productsCountInGroups != undefined) {
      value.productsCountInGroups.forEach(x => {
        this.dataSource.push({ category: x.validationGroup.name, val: x.productCount });
        this.colorPalette.push(x.validationGroup.backgroundColor);
      });
    }
  }
    
  customizeText = (arg: any) => {
    return arg.valueText + " %";
  }

  customizeLabel = (arg: any) => {
    if (arg.valueText == "1")
        return "1 Produkt";

    return arg.valueText + " Produkte";
  }
}

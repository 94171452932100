import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { AppUser } from "../Model/user/AppUser";
import isString from "lodash.isstring";
import { Message } from "../Model/System/Message";
import { CustomerWiki } from "../Model/System/CustomerWiki";

import { TranslateService } from "@ngx-translate/core";
import { ModelService } from "../Services/model.service";
import CustomStore from "devextreme/data/custom_store";
import { DxFileUploaderComponent } from "devextreme-angular";
import { EdeSortimentService } from "../Services/edeSortiment.service";
import { EdeSortiment } from "../Model/edeSortimentsAbo/EdeSortiment";
import { LoginService } from "../Services/login.service";
import { StartAction } from "../Model/Dto/StartAction";


@Component({
  selector: "edeSortimentsList",
  templateUrl: "edeSortimentsList.component.html",
  styleUrls: ["edeSortimentsList.component.css"]
})
export class EdeSortimentsListComponent implements  OnDestroy {
  set edeSortiments(edeSortiments: EdeSortiment[]) {
    this.archivedEdeSortiments = edeSortiments.filter(x => !x.owned);
    this.currentEdeSortiments = edeSortiments.filter(x => x.owned);
  }

  archivedEdeSortiments: EdeSortiment[] = [];
  currentEdeSortiments: EdeSortiment[] = [];

  loggedInSubscription: any = null;
  public isArchive: boolean = false;


  constructor(public modelService: ModelService, public translate: TranslateService, public edeSortimentsService: EdeSortimentService) {
    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Ede Sortiments Liste");

    this.getAll = this.getAll.bind(this);
    this.parseFtp = this.parseFtp.bind(this);
    this.isInstalled = this.isInstalled.bind(this);
    this.isNewest = this.isNewest.bind(this);
    this.onLoggedIn = this.onLoggedIn.bind(this);
    this.getDataSource = this.getDataSource.bind(this);
    this.toogleArchive = this.toogleArchive.bind(this);

    if (this.modelService.loginService.loggedIn)
      this.onLoggedIn();
    else
      this.loggedInSubscription = this.modelService.loginService.onLoggedIn.subscribe(() => this.onLoggedIn());
  }

  getDataSource(): EdeSortiment[] {
    if (this.isArchive) {
      return this.archivedEdeSortiments;
    }
    return this.currentEdeSortiments;
  }

  toogleArchive() {
    this.isArchive = !this.isArchive;
  }

  onLoggedIn() {
    this.edeSortimentsService.getAll(this.modelService.loginService.currentCustomer.id).subscribe((result: EdeSortiment[]) => {
      this.edeSortiments = result;
    });
  }

  ngOnDestroy() {
    try {
      if (this.loggedInSubscription != null)
        this.loggedInSubscription.unsubscribe();
    }
    catch { }
  }

  onContentToolbarPreparing(e) {
    if (this.modelService.loginService.isAdmin) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        locateInMenu: 'auto',
        template: 'startGenerateEdeInvoiceJobTemplate',
      });
    }
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'getAllButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'archiveTemplate',
    });
    if (this.modelService.loginService.isAdmin) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        locateInMenu: 'auto',
        template: 'parseFtpButtonTemplate',
      });
    }
  }

  public getAll() {
    this.edeSortimentsService.getAll(this.modelService.loginService.currentCustomer.id).subscribe((result: EdeSortiment[]) => {
      this.edeSortiments = result;
      this.modelService.systemService.notifyInfo(this.translate.instant("Ede Sortimente wurden geladen"));
    });
  }

  public delete(data) {
    let i = 0;
    this.edeSortimentsService.delete(data.data.katalogIdent, this.modelService.loginService.currentCustomer.id).subscribe(() => {
      this.edeSortimentsService.getAll(this.modelService.loginService.currentCustomer.id).subscribe((result: EdeSortiment[]) => {
        this.modelService.systemService.notifyInfo(this.translate.instant("Der Verweis auf den Katalog wurde gelöscht"));
        this.edeSortiments = result;
      });
    });
  }

  public parseFtp() {
    this.modelService.systemService.notifyInfo(this.translate.instant("Claims werden geparst"));
    this.edeSortimentsService.parseFtpClaims().subscribe(() => {
      this.modelService.systemService.notifyInfo(this.translate.instant("Ede Ftp Claims wurden geparst. Die Sortimente werden jetzt geparst"));
      this.edeSortimentsService.parseFtp().subscribe(() => {
        this.modelService.systemService.notifyInfo(this.translate.instant("Ede Ftp Sortimente wurde geparst"));
      });
    });
  }

  public isInstalled(data) {
    return data.data.installedVersion >= 0;
  }

  public isNewest(data) {
    return data.data.installedVersion == data.data.versions.length - 1;
  }

  startGenerateEdeInvoiceJob() {
    this.modelService.catalogService.showUserAction = false;
    let startAction = new StartAction();
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionType = "EDEInvoice";
    startAction.actionParameter = "";

    this.modelService.jobService.startJob(startAction).subscribe();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginService } from './../login.service';
import { StringResponse } from '../../Model/Dto/StringResponse';
import { TransferCatalogItem } from '../../Model/Dto/CombineCatalog/TransferCatalogItem';

@Injectable({
  providedIn: 'root'
})
export class CombineCatalogService {
  constructor(private http: HttpClient, public loginService: LoginService) { }

  public referenceItems(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/ReferenceItems", model);
  }

  public referenceProducts(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/ReferenceProducts", model);
  }

  public moveItems(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/MoveItems", model);
  }

  public moveProducts(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/MoveProducts", model);
  }

  public copyItems(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/CopyItems", model);
  }

  public copyCategories(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/CopyCategories", model);
  }

  public copyProducts(model: TransferCatalogItem): Observable<StringResponse> {
    return this.http.post<StringResponse>("api/CombineCatalog/CopyProducts", model);
  }
}

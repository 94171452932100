import { NextPimField } from "./NextPimField";
import { TemplateItem } from "./TemplateItem";
import { TemplateWorksheet } from "./TemplateWorksheet";


export class Template {

  constructor(
    public id?: string,
    public customerId?: string,
    public name?: string,
    public remarks?: string,
    public created?: Date,
    public lastUsed?: Date,
    public lastChange?: Date,
    public isUseable: boolean = true,
    public templateItems?: TemplateItem[],
    public columnCount?: number,
    public isExportTemplate: boolean = true,
    public deleteEmptyFeatures: boolean = false,
    public isAdvancedTemplate: boolean = false,
    public worksheetItems: TemplateWorksheet[] = []
  ) {
    this.name = "Neues Template";
    this.created = new Date();
    this.lastUsed = new Date();
    this.templateItems = [];
    this.columnCount = 0;
  }

  public addSupplierAID() {
    this.templateItems.push(new TemplateItem([new NextPimField("1", "1", "SUPPLIER_PID")],1, "Artikelnummer", "", "", ""));
  }

  static createDeafaultMainInfo(): Template {
    var template = new Template();
    /* template.name="Produkt Informationen"
     template.addSupplierAID();
     template.templateItems.push(new TemplateItem(2, "Kurzbeschreibung", "DESCRIPTION_SHORT", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(3, "Langbeschreibung", "DESCRIPTION_LONG", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(4, "alternantive Produktnummer", "SUPPLIER_ALT_PID", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(5, "Internationale ID", "INTERNATIONAL_PID", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(6, "Internationale ID Type", "INTERNATIONAL_PID_TYPE", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(7, "Herstellerbezeichnung", "MANUFACTURER_TYPE_DESCR", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(8, "Bemerkung", "REMARKS", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(9, "Herstellernummer", "MANUFACTURER_PID", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(10, "Herstellername", "MANUFACTURER_NAME", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(11, "Suchbegriffe", "KEYWORDS", 1, 1, "|", "", ""));
     template.templateItems.push(new TemplateItem(12, "Sortiernummer", "PRODUCT_ORDER", 1, 1, "", "", ""));
     template.templateItems.push(new TemplateItem(13, "Kategorie", "GROUP_STRING", 1, 1, "", "", ""));*/
    return template;
  }

  static createDeafaultLogistic(): Template {
    var template = new Template();
    template.name = "Logistik Informationen"
    template.addSupplierAID();
    /*  template.templateItems.push(new TemplateItem(2, "Kurzbeschreibung", "DESCRIPTION_SHORT", 1, 1, "", "", ""));
      template.templateItems.push(new TemplateItem(3, "Zolltarifnummer", "CUSTOMS_NUMBER", 1, 1, "", "", ""));
      template.templateItems.push(new TemplateItem(4, "Ursprungsland", "COUNTRY_OF_ORIGIN", 1, 1, "", "", ""));
      template.templateItems.push(new TemplateItem(5, "Breite", "WIDTH", 1, 1, ",", "m", ""));
      template.templateItems.push(new TemplateItem(6, "Länge", "LENGTH", 1, 1, ",", "m", ""));
      template.templateItems.push(new TemplateItem(7, "Höhe", "DEPTH", 1, 1, ",", "m", ""));
      template.templateItems.push(new TemplateItem(8, "Gewicht", "WEIGHT", 1, 1, ",", "kg", ""));*/
    return template;
  }
}

import { Input, Output, EventEmitter, Component } from "@angular/core";
import { Category } from '../../../Model/Catalog/category';
import { UserManagementService } from "../../../Services/userManagment.service";
import { ModelService } from '../../../Services/model.service';


@Component({
  selector: "seoComponent",
  templateUrl: "seo.component.html",
  styleUrls: ["./seo.component.css"]
})
export class SeoComponent {
  

  constructor(public userManagement: UserManagementService, public modelService: ModelService) { }

  get model(): Category {
    return this.modelService.catalogService.category;
  }

  set model(category: Category) {
    this.modelService.catalogService.category = category;
  }

  categoryUpdate(event = null) {
    this.modelService.catalogService.updateCategory();
  }
 




  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}

<dx-tab-panel [dataSource]="pages"
              [selectedIndex]="0"
              [swipeEnabled]="false"
              [animationEnabled]="true"
              [showNavButtons]="true"
              [loop]="true"
              (onSelectionChanged)="onTitleClick($event)">
  <!-- <div *dxTemplate="let company of 'title'">
    <span>{{company.title}}</span>
    <span *ngIf="company.type=='MAIN' && model.hintsActiv && !model.productInfosValid" class="glyphicon glyphicon-info-sign color1Text" style="margin-left: 8px;"></span>
    <span *ngIf="company.type=='PRICE' && model.hintsActiv && !model.productPriceValid" class="glyphicon glyphicon-info-sign color1Text" style="margin-left: 8px;"></span>
    <span *ngIf="company.type=='ORDER' && model.hintsActiv && (!validationService.validationResult.productOrderDetailsValid)" class="glyphicon glyphicon-info-sign color1Text" style="margin-left: 8px;"></span>
    <span *ngIf="company.type=='MIME' && model.hintsActiv && !model.productMediaValid" class="glyphicon glyphicon-info-sign color3Text" style="margin-left: 8px;"></span>
    <span *ngIf="company.type=='FEAT' && model.hintsActiv && !model.productFeaturesValid" class="glyphicon glyphicon-info-sign color4Text" style="margin-left: 8px;"></span>
    <span *ngIf="company.type=='LOGI' && model.hintsActiv && !model.productLogiticValid" class="glyphicon glyphicon-info-sign color5Text" style="margin-left: 8px;"></span>
    <span *ngIf="company.type=='REFE' && model.hintsActiv && !model.productReferenceValid" class="glyphicon glyphicon-info-sign color5Text" style="margin-left: 8px;"></span>
  </div> -->
  <!--INFOS-->
  <div *dxTemplate="let company of 'productTemplate'">
    <infos [(model)]="model"
           [(showToolTips)]="showToolTips"
           [(selectedElements)]="selectedElements"
           [templateView]="true"
           [(hasAddonEDENUMBERS)]="modelService.loginService.hasAddonEDENUMBERS"
           [(hasAddonPRODUCT_DETAILS)]="modelService.loginService.hasAddonPRODUCT_DETAILS"
           [showHTMLSwitch]="false"
           (onValueChanged)="update($event)"
           [(validationService)]="validationService"
           (elementSelected)="elementSelectFunction($event)"
           [showDragHandle]="showDragHandle"
           [dragFilter]="'> *'"
           (excelElementDropped)="excelElementDrop($event)">
    </infos>
  </div>

  <!--MIMES-->
  <div *dxTemplate="let company of 'medienTemplate'">
    <mimes [(model)]="model.mimes"
           [(product)]="model"
           [(showToolTips)]="showToolTips"
           [(selectedElements)]="selectedElements"
           [templateView]="true"
           [(exportMode)]="exportMode"
           (onValueChanged)="update($event)"
           (elementSelected)="elementSelectFunction($event)"
           [(modelService)]="modelService"
           [showDragHandle]="showDragHandle"
           [dragFilter]="'> *'"
           (excelElementDropped)="excelElementDrop($event)"
           (elementDeleted)="elementDelete($event)">
    </mimes>
  </div>

  <!--FEATURES-->
  <div *dxTemplate="let company of 'featuresTemplate'">
    <editfeaturesystemlist [(featureSystems)]="model.featureSystems"
                           [(model)]="model"
                           [(showToolTips)]="showToolTips"
                           [(selectedElements)]="selectedElements"
                           [templateView]="true"
                           [displayMode]="exportMode ? 'export' : 'import'"
                           [(hasAddonECOMMERCE)]="modelService.loginService.hasAddonECOMMERCE"
                           [(modelService)]="modelService"
                           (addEcommerceFeatures)="addEcommerceFeatures($event)"
                           (onValueChanged)="update($event)"
                           (elementSelected)="elementSelectFunction($event)"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="'> *'"
                           (excelElementDropped)="excelElementDrop($event)"
                           (elementDeleted)="elementDelete($event)">
    </editfeaturesystemlist>
  </div>

  <!--OrderDetail-->
  <div *dxTemplate="let company of 'orderDetailTemplate'">
    <orderDetail [(model)]="model"
                 [(showToolTips)]="showToolTips"
                 [(selectedElements)]="selectedElements"
                 [templateView]="true"
                 (onValueChanged)="update($event)"
                 [(hasAddonNordwest)]="modelService.loginService.hasAddonNordwest"
                 [(hasAddonLEGALITY)]="modelService.loginService.hasAddonLEGALITY"
                 (elementSelected)="elementSelectFunction($event)"
                 [showDragHandle]="showDragHandle"
                 [dragFilter]="'> *'"
                 (excelElementDropped)="excelElementDrop($event)">
    </orderDetail>
  </div>

  <!--Preise-->
  <div *dxTemplate="let company of 'preislistenTemplate'">
    <prices [(model)]="model"
            [(showToolTips)]="showToolTips"
            [(selectedElements)]="selectedElements"
            [templateView]="true"
            (onValueChanged)="update($event)"
            [(hasAddonNordwest)]="modelService.loginService.hasAddonNordwest"
            [(hasAddonLEGALITY)]="modelService.loginService.hasAddonLEGALITY"
            [(priceLists)]="modelService.catalogService.catalog.priceLists"
            (elementSelected)="elementSelectFunction($event)"
            [showDragHandle]="showDragHandle"
            [dragFilter]="'> *'"
            (excelElementDropped)="excelElementDrop($event)"
            (elementDeleted)="elementDelete($event)">
    </prices>
  </div>

  <!--Logistic-->
  <div *dxTemplate="let company of 'logistikTemplate'">
    <logistic [(model)]="model"
              [(showToolTips)]="showToolTips"
              [(modelService)]="modelService"
              [(selectedElements)]="selectedElements"
              [templateView]="true"
              (onValueChanged)="update($event)"
              (elementSelected)="elementSelectFunction($event)"
              [showDragHandle]="showDragHandle"
              [dragFilter]="'> *'"
              (excelElementDropped)="excelElementDrop($event)">
    </logistic>
  </div>

  <!--Referenzen-->
  <div *dxTemplate="let company of 'referenceTemplate'">
    <references [(model)]="model"
                [(showToolTips)]="showToolTips"
                [(lastImageUpload)]="modelService.catalogService.catalog.lastImageUpload"
                [(selectedElements)]="selectedElements"
                [templateView]="true"
                (onValueChanged)="update($event)"
                (onReferencePrepare)="onReferencePrepare($event)"
                [(modelService)]="modelService"
                (elementSelected)="elementSelectFunction($event)"
                [showDragHandle]="showDragHandle"
                [dragFilter]="'> *'"
                (excelElementDropped)="excelElementDrop($event)"
                (elementDeleted)="elementDelete($event)">
    </references>
  </div>

  <!--UDXs-->
  <div *dxTemplate="let company of 'udxTemplate'">
    <udx [(model)]="model.udxs"
         [(product)]="model"
         [(showToolTips)]="showToolTips"
         [(selectedElements)]="selectedElements"
         [templateView]="true"
         (onValueChanged)="update($event)"
         (elementSelected)="elementSelectFunction($event)"
         [showDragHandle]="showDragHandle"
         [dragFilter]="'> *'"
         (excelElementDropped)="excelElementDrop($event)"
         (elementDeleted)="elementDelete($event)">
    </udx>
  </div>

  <!-- Electronic Sales -->
  <div *dxTemplate="let company of 'electronicSalesTemplate'">
    <electronicSales [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(selectedElements)]="selectedElements"
          [templateView]="true"
          (onValueChanged)="update($event)"
          (elementSelected)="elementSelectFunction($event)"
          [showDragHandle]="showDragHandle"
          [dragFilter]="'> *'"
          (excelElementDropped)="excelElementDrop($event)">
    </electronicSales>
  </div>

  <!--SEO - SEA-->
  <div *dxTemplate="let company of 'seoTemplate'">
    <seo [(model)]="model"
         [(showToolTips)]="showToolTips"
         [(selectedElements)]="selectedElements"
         [templateView]="true"
         (onValueChanged)="update($event)"
         (elementSelected)="elementSelectFunction($event)"
         [showDragHandle]="showDragHandle"
         [dragFilter]="'> *'"
         (excelElementDropped)="excelElementDrop($event)">
    </seo>
  </div>

  <!--WAWI-->
  <div *dxTemplate="let company of 'wawiTemplate'">
    <wawi [(model)]="model"
          [(selectedElements)]="selectedElements"
          [templateView]="true"
          (onValueChanged)="update($event)"
          (elementSelected)="elementSelectFunction($event)"
          [showDragHandle]="showDragHandle"
          [dragFilter]="'> *'"
          (excelElementDropped)="excelElementDrop($event)">
    </wawi>
  </div>

  <div *dxTemplate="let company of 'legalTemplate'">
    <productLegal [(model)]="model"
                  [(showToolTips)]="showToolTips"
                  [(warrantyClasses)]="modelService.warrantyClassService.warrantyClasses"
                  [(hazmatClasses)]="modelService.hazmatClassService.hazmatClasses"
                  [(selectedElements)]="selectedElements"
                  [templateView]="true"
                  (onValueChanged)="update($event)"
                  (elementSelected)="elementSelectFunction($event)"
                  [showDragHandle]="showDragHandle"
                  [dragFilter]="'> *'"
                  (excelElementDropped)="excelElementDrop($event)">
    </productLegal>

  </div>

<!--ProductSuppliers-->
<div *dxTemplate="let company of 'productSuppliersTemplate'">
  <productSuppliers [(model)]="model.suppliers"
                    [(product)]="model"
                    [(showToolTips)]="showToolTips"
                    [(selectedElements)]="selectedElements"
                    [templateView]="true"
                    [(exportMode)]="exportMode"
                    (onValueChanged)="update($event)"
                    (elementSelected)="elementSelectFunction($event)"
                    [(modelService)]="modelService"
                    [showDragHandle]="showDragHandle"
                    [dragFilter]="'> *'"
                    (excelElementDropped)="excelElementDrop($event)"
                    (elementDeleted)="elementDelete($event)">
  </productSuppliers>
</div>

  <!--sonstiges -->
  <div *dxTemplate="let company of 'miscTemplate'">
    <misc [(model)]="model"
          [(showToolTips)]="showToolTips"
          [(selectedElements)]="selectedElements"
          [(exportMode)]="exportMode"
          (onValueChanged)="update($event)"
          (elementSelected)="elementSelectFunction($event)"
          [showDragHandle]="showDragHandle"
          [dragFilter]="'> *'"
          (excelElementDropped)="excelElementDrop($event)"
          [excelModel]="excelModel">
    </misc>
  </div>

</dx-tab-panel>

<dx-toolbar>
  <dxi-item *ngIf="!readOnlyUser('dataqualitystatsexport')" location="before" widget="dxButton" [options]="{ type: 'default', text: 'Details exportieren' | translate, onClick: exportDetails }"></dxi-item>
</dx-toolbar>

<div class="dx-fieldset">
  <div *ngFor="let table of model.catalogDataQualityTable">
    <h5 style="color:#0093D6;">{{table.headline}}</h5>
    <dx-data-grid [dataSource]="table.lines"
                  [showBorders]="false"
                  [showColumnHeaders]="false"
                  [showColumnLines]="false"
                  [rowAlternationEnabled]="true">
      <dxi-column dataField="name" cellTemplate="cellTemplateName"></dxi-column>
      <div *dxTemplate="let cell of 'cellTemplateName'">
        <div style="font-weight:bold">{{cell.text}}</div>
      </div>
      <dxi-column dataField="hits" width="70" cellTemplate="cellTemplateHits"></dxi-column>
      <div *dxTemplate="let cell of 'cellTemplateHits'">
        <div><span style="color:#00994B;">{{cell.text}}</span> |</div>
      </div>
      <dxi-column dataField="total" width="70" cellTemplate="cellTemplateTotal"></dxi-column>
      <div *dxTemplate="let cell of 'cellTemplateTotal'">
        <div style="color:#0093D6;">{{cell.text}}</div>
      </div>
    </dx-data-grid>
  </div>
</div>

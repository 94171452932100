<dx-scroll-view showScrollbar="always" height="inherit">
  <dx-tile-view
    class="dx-tile-content excelFields"
    height="inherit"
    [baseItemWidth]="285"
    direction="vertical"
    baseItemHeight="15"
  >
    <dxi-item *ngFor="let group of groups" [widthRatio]="1" [heightRatio]="getHeightRatio(group.name)">
      <div class="p-1 pb-3">
        <dx-check-box
          [value]="group.active"
          (onValueChanged)="selectGroup($event, group.name)"
          style="float: left; padding-right: 10px"
        ></dx-check-box>
        <b>{{ group.name }}</b>
      </div>
      <div *ngFor="let field of fields">
        <div *ngIf="field.group == group.name" style="height: 35px">
          <div style="width: 40px; float: left; padding-left: 10px">
            <dx-check-box [(value)]="field.active" (onValueChanged)="selectField($event, field)"></dx-check-box>
          </div>
          <label class="exportLabel">{{ field.field }}</label>
          <div style="width: calc(100% - 30px); margin-left: 40px" class="exportText">
            {{ field.field | translate }}
          </div>
        </div>
      </div>
    </dxi-item>
  </dx-tile-view>
</dx-scroll-view>


<div *ngIf="!featuresLoaded" style="left: 30%; top: 30%;">
  <div class="loader">
  </div>
</div>
<div *ngIf="featuresLoaded" style="overflow-y: auto;">
  <div class="dx-field">
    <div class="dx-field-label">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <dx-tag-box [items]="features"
                  [(value)]="selectedFeatures"
                  [showSelectionControls]="true"
                  applyValueMode="instantly"
                  [showClearButton]="true"
                  [searchEnabled]="true"
                  placeholder=""
                  displayExpr="name">
      </dx-tag-box>
    </div>
  </div>
</div>

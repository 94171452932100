import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from "devextreme-angular";
import { ModelService } from "../../Services/model.service";
import { BackgroundJob } from "../../Model/Dto/BackgroundJob";
import { ValidateExportJob } from "../../Model/Dto/ValidateExportJob";
import { JobService } from "../../Services/job.service";
import { BackgroundJobEvent } from "../../Model/Dto/BackgroundJobEvent";
import { ExportParameter } from "../../Model/exportParameter/ExportParameter";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { Message } from "../../Model/System/Message";


@Component({
  selector: "jobDetail",
  templateUrl: "./jobDetail.component.html",
  styleUrls: ["../jobList.component.css"]
})
export class JobDetailComponent implements OnInit, OnDestroy {
  @Input() job: BackgroundJob;
  @Input() dataGrid: DxDataGridComponent;
  @Input() isVisible: boolean;

  @Output() onInitialized = new EventEmitter();
  public events: BackgroundJobEvent[] = [];
  public timer: NodeJS.Timeout = null;
  public originialFileLink = "";
  firstViewInit = true;
  isPopupVisible: boolean;
  public exportParam: ExportParameter;

  catalogName: string = "";
  resultCatalogName: string = "";


  constructor(public modelService: ModelService, public translate: TranslateService, public jobService: JobService, public catalogService: CatalogService) {
    this.isValidatable = this.isValidatable.bind(this);
    this.validateExport = this.validateExport.bind(this);
    this.getCatalogNameById = this.getCatalogNameById.bind(this);
    this.time = this.time.bind(this);
    this.getIconClass = this.getIconClass.bind(this);
    this.getOrignalFile = this.getOrignalFile.bind(this);
    this.getEventMessage = this.getEventMessage.bind(this);
    this.ngOnDestroy = this.ngOnDestroy.bind(this);
    this.ngOnInit = this.ngOnInit.bind(this);
    this.openCatalogById = this.openCatalogById.bind(this);
    this.hasExportParameter = this.hasExportParameter.bind(this);
    this.updateCatalogNamesIfNeeded = this.updateCatalogNamesIfNeeded.bind(this);
    this.updateEvents = this.updateEvents.bind(this);

    this.isPopupVisible = false;
  }


  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  async ngOnInit() {
    this.getOrignalFile()
    this.updateEvents();

    await this.updateEvents();

    if (this.hasExportParameter(this.job)) {
      this.exportParam = Object.assign(new ExportParameter(), await this.jobService.getExportJobParameter(this.job.id, this.modelService.loginService.currentUser.customerId).toPromise());
      let i = 0;
    }
  }

  togglePopup(): void {
    this.isPopupVisible = !this.isPopupVisible;
  }

  async updateEvents() {
    if (!(this.isVisible && this.dataGrid.instance.isRowExpanded(this.job.id))) {
      setTimeout(this.updateEvents, 1750);
      return;
    }

    await this.updateCatalogNamesIfNeeded();
    let newEvents = await this.jobService.getJobEvents(this.job.id).toPromise();
    let originalCount = this.events.length;

    this.events = newEvents;

    if (this.events.length != originalCount) {
      this.onInitialized.emit();
    }

    //stop refreshing if state is Finished, Error or DataProblem
    switch (this.job.state) {
      case 2:
      case 3:
      case 10:
        setTimeout(this.updateEvents, 5000);
        return;
    }
    setTimeout(this.updateEvents, 1750);

  }

  async updateCatalogNamesIfNeeded() {
    if (this.job.catalogId && (!this.catalogName || this.catalogName == "")) {
      let catalogName = await this.getCatalogNameById(this.job.catalogId);
      this.catalogName = catalogName;
    }

    if (this.job.resultCatalogId && (!this.resultCatalogName || this.resultCatalogName == "")) {
      let result = await this.getCatalogNameById(this.job.resultCatalogId)
      this.resultCatalogName = result;

    }
  }

  isValidatable(job: BackgroundJob): boolean {
    if (!job.resultFile)
      return false;

    return (job.typeText === 'Export' && job.state === 2 && job.resultFile.endsWith('.zip'));
  }

  hasExportParameter(job: BackgroundJob): boolean {
    return job.typeText === 'Export' || job.typeText === 'IntershopExport';
  }

  export() {
    this.exportParam.customerId = this.modelService.loginService.currentCustomer.id;
    this.exportParam.catalogId = this.job.catalogId;
    this.modelService.catalogService.exportVisible = false;
    this.modelService.catalogService.createExportJob(this.exportParam).subscribe(
      () => {
        this.modelService.systemService.notify(new Message(this.translate.instant("Der Export wird als Job ausgeführt")));
      });
    this.isPopupVisible = false;
  }

  validateExport(data: BackgroundJob) {
    let dto = new ValidateExportJob();
    dto.customerId = this.modelService.loginService.currentUser.customerId;
    dto.jobId = data.id;
    this.modelService.jobService.validateExportJob(dto)
      .subscribe(success => {
        if (success) {
          let i = 1;
        }
      });
  }

  async getCatalogNameById(id: string): Promise<string> {
    return await this.catalogService.getCatalogName(id, this.modelService.loginService.currentUser.customerId);
  }

  time(started, ended) {
    // TODO: SAME in AdminComponent ==> move into a service!!
    var time = (Date.parse(ended) - Date.parse(started)) / 1000;
    if (time < 600) {
      let text = this.translate.instant("Sekunden");
      return Math.floor(time * 10) / 10 + " " + text;
    }
    let text = this.translate.instant("Minuten");
    time = time / 60;
    return Math.floor(time) + " " + text;
  }

  getIconClass(icon) {
    return JobService.getIconClass(icon);
  }

  getEventMessage(e: BackgroundJobEvent) {
    let result = "";
    if (e.parameter) {
      e.parameter = e.parameter.replace("\n", ""); //removing line breaks
      let param = JSON.parse(e.parameter);
      result = this.translate.instant(e.message, param);
    } else {
      result = this.translate.instant(e.message);
    }
    return result;
  }

  getOrignalFile() {
    if (this.job.filenameBlobUri != undefined) {
      this.originialFileLink = this.job.filenameBlobUri;
    }
    if (this.job.filename) {
      this.modelService.mediaService.getJobDataElement(this.modelService.loginService.currentUser.customerId, this.job.id.toString())
        .subscribe((result: any) => {
          if (result.items.length >= 1)
            this.originialFileLink = result.items[0].blobUri;
        });
    }
  }

  getHelpMaillink(id: string) {
    return "mailto:support@nextpim.de?subject=Support%20f%C3%BCr%20Job%20" + id + "&body=Sehr%20geehrte%20Damen%20und%20Herren%2C%0A%0Aich%20ben%C3%B6tige%20Hilfe%20beim%20Job%20mit%20der%20ID%20" + id;
  }


  async openCatalogById(catalogId) {
    await this.modelService.router.navigateByUrl('/', { skipLocationChange: true });
    //this.modelService.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
    await this.modelService.router.navigate(["/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + catalogId]);
  }
}

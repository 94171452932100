import { Component, Input, OnInit } from '@angular/core';
import { SearchByListParameter } from '../../../../../../Model/Dto/SearchByListParameter';
import { CatalogFilter } from '../../../../../../Model/exportParameter/CatalogFilter';
import { ExportParameter } from '../../../../../../Model/exportParameter/ExportParameter';

@Component({
  selector: 'searchByListFilter',
  templateUrl: './searchByListFilter.component.html',
  styleUrls: ['./searchByListFilter.component.css']
})
export class SearchByListFilterComponent implements OnInit {
  @Input() exportParameter: ExportParameter;


  searchValues = ["SupplierPID", "EAN", "gtin", "Hersteller", "SupplierAltPID", "Lieferanten-Artikelnummer"];

  constructor() {
    this.onValueChanged = this.onValueChanged.bind(this);

  }

  ngOnInit(): void {

  }

  get parameter(): SearchByListParameter {
    if (!this.exportParameter.searchByListParameter)
      this.exportParameter.searchByListParameter = new CatalogFilter<SearchByListParameter>(new SearchByListParameter());
    if (this.exportParameter.searchByListParameter.filterKey == null)
      this.exportParameter.searchByListParameter.filterKey = new SearchByListParameter();
    if (this.exportParameter.searchByListFilterAdds == null)
      this.exportParameter.searchByListFilterAdds = false;
    if (this.exportParameter.searchByListParameter.filterKey.key == null || this.exportParameter.searchByListParameter.filterKey.key == "")
      this.exportParameter.searchByListParameter.filterKey.key = this.searchValues[0]

    return this.exportParameter.searchByListParameter.filterKey;
  }

  set parameter(value: SearchByListParameter) {
    this.exportParameter.searchByListParameter.filterKey = value
  }

  onValueChanged(e) {
    let text = e.value;
    this.parameter.list = this.formatListIgnoreSpace(text);
  }

  formatListIgnoreSpace(value) {
    var tmp: string = value;
    tmp = tmp.split(";").join("\n");
    tmp = tmp.split("\r").join("\n");
    tmp = tmp.split(",").join("\n");
    while (tmp.includes("\n\n")) {
      tmp = tmp.replace("\n\n", "\n");
    }
    if (tmp.substr(0, 1) == "\n") {
      tmp = tmp.substr(1);
    }
    if (tmp.substr(-1, 1) == "\n") {
      tmp = tmp.substr(0, tmp.length - 1);
    }
    return tmp;
  }

}

<div class="dx-fieldset">
  <!--GROUP_STRING-->
  <div class="np-field">
    <np-inputControl [(model)]="model"
                      [showLinkButtons]="false"
                      [showPlaceLabel]="model?.groupString?.length > 0"
                      [(selectedElements)]="selectedElements"
                      (elementSelected)="elementSelectFunction($event)"
                      (onValueChanged)="update($event)"
                      labelText="{{ 'Kategorie' | translate }}"
                      field="GROUP_STRING"
                      [showDragHandle]="showDragHandle"
                      [dragFilter]="dragFilter"
                      (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.groupString"
                      [readOnly]="false"
                      [showClearButton]="true"
                      (onValueChanged)="update($event)" class="hint">
          <dx-validator>
            <dxi-validation-rule type="required" message="{{ 'Bitte geben Sie eine Kategorie an' | translate }}!"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>

  <!--MASTER-->
  <div class="np-field">
    <np-inputControl [(model)]="model"
                      [showLinkButtons]="false"
                      [showPlaceLabel]="model?.master?.length > 0"
                      [(selectedElements)]="selectedElements"
                      (elementSelected)="elementSelectFunction($event)"
                      (onValueChanged)="update($event)"
                      labelText="{{ 'Variantenzuordnung' | translate }}"
                      field="MASTER"
                      [showDragHandle]="showDragHandle"
                      [dragFilter]="dragFilter"
                      (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box [(value)]="model.master"
                      [readOnly]="false"
                      [showClearButton]="true"
                      (onValueChanged)="update($event)" class="hint">
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>

  <!--DESCWITHOUTHTML-->
  <div *ngIf="exportMode" class="np-field">
    <np-inputControl [(model)]="model"
                      [showLinkButtons]="false"
                      [showPlaceLabel]="model?.descWithoutHTML?.length > 0"
                      [(selectedElements)]="selectedElements"
                      (elementSelected)="elementSelectFunction($event)"
                      (onValueChanged)="update($event)"
                      labelText="{{ 'Langbeschreibung ohne HTML' | translate }}"
                      field="DESC_WITHOUT_HTML"
                      [showDragHandle]="showDragHandle"
                      [dragFilter]="dragFilter"
                      (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-area [(value)]="model.descWithoutHTML" [height]="130" (onValueChanged)="update($event)">
        </dx-text-area>
      </div>

    </np-inputControl>
  </div>

  <!--FEATURE_LIST-->
  <div *ngIf="exportMode" class="np-field">
    <np-inputControl [(model)]="model"
                      [showLinkButtons]="false"
                      [showPlaceLabel]="model?.featurelist?.length > 0"
                      [(selectedElements)]="selectedElements"
                      (elementSelected)="elementSelectFunction($event)"
                      (onValueChanged)="update($event)"
                      labelText="{{ 'Merkmalstabelle' | translate }}"
                      field="FEATURE_LIST"
                      [showDragHandle]="showDragHandle"
                      [dragFilter]="dragFilter"
                      (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-area [(value)]="model.featurelist" [height]="130" (onValueChanged)="update($event)">
        </dx-text-area>
      </div>

    </np-inputControl>
  </div>



  <div *ngIf="!exportMode" class="np-field">
    <np-inputControl [showPlaceLabel]="mimeUpdateMode?.length > 0"
                      labelText="{{ 'MIME_UPDATE_MODE' }}"
                      field="MIME_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsND"
                        [(value)]="mimeUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event, 'MIME_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>



  <div *ngIf="!exportMode" class="np-field">
    <np-inputControl [showPlaceLabel]="featureUpdateMode?.length > 0"
                      labelText="{{ 'FEATURE_UPDATE_MODE' }}"
                      field="FEATURE_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsNDI"
                        [(value)]="featureUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event,'FEATURE_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>



  <div *ngIf="!exportMode" class="np-field">
    <np-inputControl [showPlaceLabel]="priceUpdateMode?.length > 0"
                      labelText="{{ 'PRICE_UPDATE_MODE' }}"
                      field="PRICE_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsNDI"
                        [(value)]="priceUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event, 'PRICE_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>

  <div *ngIf="!exportMode" class="np-field">
    <np-inputControl [showPlaceLabel]="supplierUpdateMode?.length > 0"
                      labelText="{{ 'SUPPLIER_UPDATE_MODE' }}"
                      field="SUPPLIER_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsNDI"
                        [(value)]="supplierUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event, 'SUPPLIER_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>

  <div *ngIf="!exportMode && modelService.loginService.hasAddonUDX" class="np-field">
    <np-inputControl [showPlaceLabel]="udxUpdateMode?.length > 0"
                      labelText="{{ 'UDX_UPDATE_MODE' }}"
                      field="UDX_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsND"
                        [(value)]="udxUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event, 'UDX_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>

  <div *ngIf="!exportMode" class="np-field">
    <np-inputControl [showPlaceLabel]="categoryUpdateMode?.length > 0"
                      labelText="{{ 'CATEGORY_UPDATE_MODE' }}"
                      field="CATEGORY_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsND"
                        [(value)]="categoryUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event, 'CATEGORY_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>

  <div *ngIf="!exportMode" class="np-field">
    <np-inputControl [showPlaceLabel]="statusUpdateMode?.length > 0"
                      labelText="{{ 'STATUS_UPDATE_MODE' }}"
                      field="STATUS_UPDATE_MODE">

      <div slot="content" class="contentSlot">
        <dx-select-box #selectBox
                        [items]="selectItemsND"
                        [(value)]="statusUpdateMode"
                        (onValueChanged)="onUpdateModeChanged($event, 'STATUS_UPDATE_MODE')">
        </dx-select-box>
      </div>

    </np-inputControl>
  </div>




  <!-- LINK -->
  <div class="np-field">
    <np-inputControl [(model)]="model"
                      [showLinkButtons]="false"
                      [(selectedElements)]="selectedElements"
                      (elementSelected)="elementSelectFunction($event)"
                      (onValueChanged)="update($event)"
                      labelText="{{ 'Link zum Produkt' | translate }}"
                      field="PRODUCT_LINK"
                      [showDragHandle]="showDragHandle"
                      [showPlaceLabel]="true"
                      [dragFilter]="dragFilter"
                      (excelElementDropped)="excelElementDrop($event)">

      <div slot="content" class="contentSlot">
        <dx-text-box value="https://app.nextpim.de/catalog/dc20c3eb-54cf-45b1-d321-08d9ced17bb0/62cfe77209291b7c9f9486f6/product/62cfe77309291b7c9f9486fa"
                      [readOnly]="true"
                      [showClearButton]="false">
        </dx-text-box>
      </div>

    </np-inputControl>
  </div>
</div>

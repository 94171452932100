import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '../Model/Catalog/template';
import { Message } from '../Model/System/Message';
import { ModelService } from '../Services/model.service';
import { TemplateService } from '../Services/template.service';
import { NewTemplateEditorComponent } from './template-editor.component';

@Component({
  selector: 'template-editor-dialog',
  templateUrl: './template-editor-dialog.component.html',
  styleUrls: ['./template-editor-dialog.component.css']
})
export class TemplateEditorDialogComponent implements OnInit, OnDestroy {

  @ViewChild(NewTemplateEditorComponent) editor: NewTemplateEditorComponent;

  @Input() showSelector: boolean = false;
  @Input() exportMode: boolean = false;

  private titleExport: string = "";
  private titleImport: string = "";
  private notificationSimilar: string = "";

  private _visible: boolean = false;
  private _popupVisible: boolean = false;

  private similarityRatio: number = 0;

  private _templates = new Array<Template>();
  public selectedTemplateId: string = "";

  public keepDefaultValues: boolean = true;



  public get visible() {
    return this._visible;
  }

  @Input()
  public set visible(val: boolean) {
    if (this._visible !== val) {
      this._visible = val;
      this.visibleChange.emit(val);
    }
  }

  @Output() public visibleChange = new EventEmitter<boolean>();



  public get popupVisible() {
    return this._popupVisible;
  }

  public set popupVisible(val: boolean) {
    if (this._popupVisible !== val) {
      this._popupVisible = val;
    }
  }

  public get showPopupButtonVisible() {
    return this.templates.length > 0;
  }

  public get showNotification() {
    return this.similarityRatio >= 75;
  }



  public get title() {
    let title = this.exportMode ? this.titleExport : this.titleImport;

    if (this.templateService.template) {
      title = title + " - " + this.templateService.template.name;
    }

    return title;
  }



  get templates(): Template[] {
    return this._templates;
  }



  constructor(public modelService: ModelService, public templateService: TemplateService, public translate: TranslateService) {
    this.startImport = this.startImport.bind(this);

    this.sync = this.sync.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  ngOnInit(): void {
    this.titleExport = this.translate.instant('Export Template Editor');
    this.titleImport = this.translate.instant('Import Template Editor');
    this.notificationSimilar = this.translate.instant('Es sind passende Templates zur Übernahme vorhanden.');

    if (this.templateService.importJob != null) {

      if (this.templateService.template == null || this.templateService.template == undefined || this.templateService.template.id != this.templateService.importJob.templateId) {
        this.templateService.getTemplateById(this.templateService.importJob.templateId)
          .subscribe(() => {
            this.prepareDropdownValuesAndDoNotification();
          })
      } else {
        this.prepareDropdownValuesAndDoNotification();
      }

    }
  }

  ngOnDestroy(): void {
    this.titleExport = "";
    this.titleImport = "";

    this.similarityRatio = 0;

    this._templates = new Array<Template>();
    this.selectedTemplateId = "";

    this.keepDefaultValues = true;
  }



  public startImport() {
    this.templateService.startImport();
  }



  private prepareDropdownValuesAndDoNotification() {
    this.templateService.getTemplatesReturn()
      .subscribe((templates: Template[]) => {
        this._templates = templates.filter(x => x.isUseable);

        this._templates.forEach(template => {
          if (template.id != this.templateService.template.id) {

            let similar = 0;
            this.templateService.template.templateItems.forEach(templateItem => {
              let hasSimilar = template.templateItems.filter(t => t.key == templateItem.key).length > 0;
              if (hasSimilar) {
                similar++;
              }
            });

            let prefix = "[ " + similar + " ]";
            prefix = (prefix + "                ").slice(0, 10);  // "rechtsbündig"

            if (template.isExportTemplate) {
              prefix = prefix + " [Export] "
            } else {
              prefix = prefix + " [Import] "
            }

            template.name = prefix + template.name;

            if (similar > 0) {
              this._templates.push(template);

              let ratio = this.templateService.calcSimilarityRatio(template.templateItems, this.templateService.template.templateItems);
              if (ratio > this.similarityRatio) {
                this.similarityRatio = ratio;
              }
            }

          }
        });

        if (this.showNotification) {
          this.modelService.systemService.notify(new Message(this.notificationSimilar), 2500);
        }
      });
  }

  public showPopup() {
    this.popupVisible = true;
  }

  public sync() {
    this.popupVisible = false;

    if (this.selectedTemplateId == "") {
      return;
    }

    let template = this._templates.filter(t => t.id == this.selectedTemplateId)[0];

    this.templateService.template.templateItems = this.templateService.syncTemplateItems(template.templateItems, this.templateService.template.templateItems, this.keepDefaultValues);
    this.templateService.updateTemplate(this.templateService.template);

    this.editor.templateSelected(null);

    this.selectedTemplateId = "";
    this.keepDefaultValues = true;
  }
}

import { Component, EventEmitter, Input, OnInit } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { DataQualityFacts } from '../../Model/Catalog/DataQualityFacts';
import { UserManagementService } from "../../Services/userManagment.service";
import { ValidationService } from "../../Services/Validation/validation.service";

@Component({
  selector: "dataQualityFacts",
  templateUrl: "dataQualityFacts.component.html",
  styleUrls: []
})
export class DataQualityFactsComponent implements OnInit {
  timeSpans: string[];
  accesses: string[];
  formats: string[];

  constructor(public modelService: ModelService, public translate: TranslateService, public validationService: ValidationService, public userManagement: UserManagementService) {
    if (this.modelService.catalogService.catalog.dataQualityFacts == null) {
      this.model  = new DataQualityFacts();
    }}

  ngOnInit(): void {
    this.reset();
  }

  reset() {

    this.timeSpans = new Array<string>();
    this.timeSpans.push("Minuten");
    this.timeSpans.push("Stunden");
    this.timeSpans.push("Tage");
    this.timeSpans.push("Wochen");

    this.accesses = new Array<string>();
    this.accesses.push("Portal");
    this.accesses.push("FTP");
    this.accesses.push("Link");
    this.accesses.push("Email");
    this.accesses.push("Datenträger");
    this.accesses.push("Sonstiges");

    this.formats = new Array<string>();
    this.formats.push("BMEcat");
    this.formats.push("Excel");
    this.formats.push("Sonstiges");
  }

  get model(): DataQualityFacts {
    return this.modelService.catalogService.catalog.dataQualityFacts;
  }

  set model(value: DataQualityFacts) {
    this.modelService.catalogService.catalog.dataQualityFacts = value;
  }

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }

}

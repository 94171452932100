
<div></div>
<dx-accordion [dataSource]="unitCodeDataSource"
              [collapsible]="true" style="top: 20px;">
  <div *dxTemplate="let company of 'item'">
    <supplierPackingUnits [product]="product"
                          [(selectedElements)]="selectedElements"
                          [templateView]="templateView"
                          [(exportMode)]="exportMode"
                          (onValueChanged)="update($event)"
                          [showDragHandle]="showDragHandle"
                          [showLinkButtons]="showLinkButtons"
                          [dragFilter]="'> *'"
                          (elementSelected)="elementSelected.emit($event)"
                          (excelElementDropped)="excelElementDrop($event)"
                          (elementDeleted)="elementDeleted.emit($event)"></supplierPackingUnits>
  </div>
</dx-accordion>


<div></div>

<dx-data-grid (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [dataSource]="model"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Lieferanten' | translate }}"
              [showBorders]="true"
              (onRowRemoving)="onRowRemoving($event)"
              (onRowRemoved)="delete($event)"
              [masterDetail]="{ enabled: true, template: 'detail' }"
              (onContentReady)="onContentReady()"
              [customizeColumns]="customizeColumns">
  <div *dxTemplate="let toolbaritem of 'newSupplierButtonTemplate'">
    <dx-button *ngIf="renderNewSupplierButton()" stylingMode="text" [disabled]="updateLock" type="default" text="{{ 'Neuer Lieferant' | translate }}" (onClick)="addSupplier()"></dx-button>
  </div>


  <dxo-editing mode="cell" [allowUpdating]="!readOnly('supplierUpdate')" [allowDeleting]="allowDeleting && !readOnly('supplierDelete')" [useIcons]="true">
    <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
  </dxo-editing>

  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="suppliersStateNewUIV2"></dxo-state-storing>

  <dxo-toolbar>
    <dxi-item *ngIf="!templateView || showDragHandle" location="after">
      <div *dxTemplate>
        <div class="d-flex flex-row">
          <np-inputControl [(model)]="product"
                           [showLinkButtons]="showLinkButtons"
                           [showPlaceLabel]="true"
                           [suppressTranslation]="true"
                           [suppressLabel]="true"
                           [suppressToolTip]="true"
                           [(selectedElement)]="selectedElement"
                           (elementSelected)="null"
                           (onValueChanged)="update($event)"
                           labelText="{{ 'HauptLieferant' | translate }}"
                           field="MAINSUPPLIER_ID"
                           [showDragHandle]="showDragHandle"
                           [dragFilter]="dragFilter"
                           (excelElementDropped)="excelElementDrop($event)">
            <div slot="content" class="contentSlot">
              <dx-select-box width="225"
                             [items]="supplierNames"
                             [value]="mainSupplierName"
                             (onSelectionChanged)="mainSupplierChanged($event)">
              </dx-select-box>
            </div>
          </np-inputControl>
        </div>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <dxi-column dataField="order" caption="{{ 'Order' | translate }}" [width]="70" [allowEditing]="true" cellTemplate="orderTemplate" editCellTemplate="orderTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'orderTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.order != null"
                       [suppressTranslation]="true"
                       [suppressLabel]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="null"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Order' | translate }}"
                       field="SUPPLIER_ORDER"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="false"
                       [dragFilter]="dragFilter">

        <div slot="content" class="contentSlot">
          <dx-number-box *ngIf="showLinkButtons"
                         class="inDataGrid"
                         [(value)]="supplier.data.order"
                         [min]="1"
                         [showSpinButtons]="!hasSupplierFieldLinked(supplier.data.order.toString())"
                         [readOnly]="hasSupplierFieldLinked(supplier.data.order.toString()) || readOnly('SUPPLIER_ORDER')"
                         [showClearButton]="false"
                         valueChangeEvent="focusout"
                         (onValueChanged)="update($event, null, supplier.data, 'SUPPLIER_ORDER', supplier.data.order.toString())">
          </dx-number-box>
          <dx-number-box *ngIf="!showLinkButtons"
                         class="inDataGrid"
                         [(value)]="supplier.data.order"
                         [min]="1"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly('SUPPLIER_ORDER')"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, supplier.data, 'SUPPLIER_ORDER', supplier.data.order.toString())">
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

  </div>



  <dxi-column dataField="supplierId" caption="{{ 'Nummer' | translate }}" [allowEditing]="true" cellTemplate="supplierIdTemplate" editCellTemplate="supplierIdTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'supplierIdTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.supplierId?.length > 0"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, supplier.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Nummer' | translate }}"
                       field="SUPPLIER_ID"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="supplier.data.supplierId"
                       [readOnly]="readOnlyForLinkedFields('SUPPLIER_ID', supplier.data.order.toString()) || readOnly('SUPPLIER_ID')"
                       [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_ID', supplier.data.order.toString())"
                       (onValueChanged)="update($event, null, null, 'SUPPLIER_ID', supplier.data.order.toString())">
            <dx-validator *ngIf="!templateView" validationGroup="SUPP">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_ID', supplier.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

  </div>

  <dxi-column dataField="supplierName" caption="{{ 'Name' | translate }}" [allowEditing]="true" cellTemplate="supplierNameTemplate" editCellTemplate="supplierNameTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'supplierNameTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.supplierName?.length > 0"
                       [suppressLabel]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, supplier.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Name' | translate }}"
                       field="SUPPLIER_NAME"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="supplier.data.supplierName"
                       [readOnly]="readOnlyForLinkedFields('SUPPLIER_NAME', supplier.data.order.toString()) || readOnly('SUPPLIER_NAME')"
                       [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_NAME', supplier.data.order.toString())"
                       (onValueChanged)="update($event, null, null, 'SUPPLIER_NAME', supplier.data.order.toString())">
            <dx-validator *ngIf="!templateView" validationGroup="SUPP">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_NAME', supplier.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

  </div>

  <dxi-column dataField="deliveryTime" caption="{{ 'Lieferzeit' | translate }}" [allowEditing]="true" cellTemplate="deliveryTimeTemplate" editCellTemplate="deliveryTimeTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'deliveryTimeTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.deliveryTime  != null"
                       [suppressLabel]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, supplier.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Lieferzeit' | translate }}"
                       field="SUPPLIER_DELIVERY_TIME"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box class="inDataGrid"
                         [(value)]="supplier.data.deliveryTime"
                         [showSpinButtons]="!templateView"
                         [readOnly]="readOnly('SUPPLIER_DELIVERY_TIME')"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, supplier.data, 'SUPPLIER_DELIVERY_TIME', supplier.data.order.toString())">
            <dx-validator *ngIf="!templateView" validationGroup="SUPP">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_DELIVERY_TIME', supplier.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

  </div>

  <dxi-column dataField="price" caption="{{ 'Preis' | translate }}" [allowEditing]="true" cellTemplate="priceTemplate" editCellTemplate="priceTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'priceTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.price  != null"
                       [suppressLabel]="true"
                       [suppressToolTip]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, supplier.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Preis' | translate }}"
                       field="SUPPLIER_PRICE"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box class="inDataGrid"
                         format="#0.00"
                         [(value)]="supplier.data.price"
                         [readOnly]="readOnly('SUPPLIER_PRICE')"
                         [showClearButton]="false"
                         (onValueChanged)="update($event, null, supplier.data, 'SUPPLIER_PRICE', supplier.data.order.toString())">
            <dx-validator *ngIf="!templateView" validationGroup="SUPP">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PRICE', supplier.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

  </div>

  <dxi-column dataField="validFrom" caption="{{ 'gültig ab' | translate }}" [allowEditing]="true" cellTemplate="validFromTemplate" editCellTemplate="validFromTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'validFromTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.validFrom  != null"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, supplier.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'gültig ab' | translate }}"
                       field="SUPPLIER_VALID_FROM"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-date-box [(value)]="supplier.data.validFrom"
                       type="date"
                       [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_VALID_FROM', supplier.data.order.toString())"
                       [readOnly]="readOnlyForLinkedFields('SUPPLIER_VALID_FROM', supplier.data.order.toString())  || readOnly('SUPPLIER_VALID_FROM')"
                       displayFormat="dd.MM.yyyy"
                       (onValueChanged)="update($event, null, null, 'SUPPLIER_VALID_FROM', supplier.data.order.toString())">
            <dx-validator *ngIf="!templateView" validationGroup="SUPP">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_VALID_FROM', supplier.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </np-inputControl>
    </div>
  </div>


  <dxi-column dataField="validUntil" caption="{{ 'gültig bis' | translate }}" [allowEditing]="true" cellTemplate="validUntilTemplate" editCellTemplate="validUntilTemplate"></dxi-column>
  <div *dxTemplate="let supplier of 'validUntilTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="supplier?.data?.validUntil  != null"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, supplier.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'gültig bis' | translate }}"
                       field="SUPPLIER_VALID_UNTIL"
                       [system]="supplier.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-date-box [(value)]="supplier.data.validUntil"
                       type="date"
                       [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_VALID_UNTIL', supplier.data.order.toString())"
                       [readOnly]="readOnlyForLinkedFields('SUPPLIER_VALID_UNTIL', supplier.data.order.toString())  || readOnly('SUPPLIER_VALID_UNTIL')"
                       displayFormat="dd.MM.yyyy"
                       (onValueChanged)="update($event, null, null, 'SUPPLIER_VALID_UNTIL', supplier.data.order.toString())">
            <dx-validator *ngIf="!templateView" validationGroup="SUPP">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_VALID_UNTIL', supplier.data.order.toString())"></dxi-validation-rule>
            </dx-validator>
          </dx-date-box>
        </div>
      </np-inputControl>
    </div>
  </div>



  <dxi-column [calculateCellValue]="calculateCurrentValue"
              caption="{{ 'Aktuell' | translate }}"
              [allowEditing]="false"
              [allowSorting]="true"
              [allowFiltering]="true"></dxi-column>

  <div *dxTemplate="let supplier of 'detail'">
    <div class="d-flex flex-row">
      <div class="flex-fill">
        <form>
          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.pid?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Artikelnummer' | translate }}"
                             field="PID"
                             [system]="supplier.data.order"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.pid"
                             [readOnly]="readOnlyForLinkedFields('PID', supplier.data.order.toString()) || readOnly('PID')"
                             [showClearButton]="!readOnlyForLinkedFields('PID', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'PID', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PID', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.supplierProductDescription?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Lieferantenbezeichnung' | translate }}"
                             field="SUPPLIER_PRODUCT_DESC"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.supplierProductDescription"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_PRODUCT_DESC', supplier.data.order.toString()) || readOnly('SUPPLIER_PRODUCT_DESC')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_PRODUCT_DESC', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_PRODUCT_DESC', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PRODUCT_DESC', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.supplierProductDescriptionShort?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Kurzbezeichnung' | translate }}"
                             field="SUPPLIER_DESC_SHORT"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.supplierProductDescriptionShort"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_DESC_SHORT', supplier.data.order.toString()) || readOnly('SUPPLIER_DESC_SHORT')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_DESC_SHORT', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_DESC_SHORT', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_DESC_SHORT', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>
          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.discountGroupCode?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Rabattgruppencode' | translate }}"
                             field="SUPPLIER_DISCOUNT_GROUP_CODE"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.discountGroupCode"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_DISCOUNT_GROUP_CODE', supplier.data.order.toString()) || readOnly('SUPPLIER_DISCOUNT_GROUP_CODE')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_DISCOUNT_GROUP_CODE', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_DISCOUNT_GROUP_CODE', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_DISCOUNT_GROUP_CODE', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.pricingUnitCode?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Preismenge' | translate }}"
                             field="SUPPLIER_PRICE_UNIT_CODE"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.pricingUnitCode"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_PRICE_UNIT_CODE', supplier.data.order.toString()) || readOnly('SUPPLIER_PRICE_UNIT_CODE')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_PRICE_UNIT_CODE', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_PRICE_UNIT_CODE', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PRICE_UNIT_CODE', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>


          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.originalPriceAllocationUnitCode?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Originale Bestelleinheit' | translate }}"
                             field="SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.originalPriceAllocationUnitCode"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL', supplier.data.order.toString()) || readOnly('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE_ORIGINAL', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.priceAllocationUnitCode?.length > 0"
                             [suppressTranslation]="true"
                             [suppressToolTip]="true"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="null"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'Bestelleinheit' | translate }}"
                             field="SUPPLIER_PRICE_ALLOCATION_UNIT_CODE"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.priceAllocationUnitCode"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE', supplier.data.order.toString()) || readOnly('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_PRICE_ALLOCATION_UNIT_CODE', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PRICE_ALLOCATION_UNIT_CODE', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>




          <div class="d-flex flex-row">

            <div class="np-field w-75">
              <np-inputControl [(model)]="product"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="supplier?.data?.internationalId > 0"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               labelText="{{ 'Internationale ID' | translate }}"
                               field="SUPPLIER_INTERNATIONAL_ID"
                               [system]="supplier.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-number-box *ngIf="showLinkButtons"
                                 class="inDataGrid"
                                 [(value)]="supplier.data.internationalId"
                                 [min]="1"
                                 [showSpinButtons]="!hasSupplierFieldLinked(supplier.data.order.toString())"
                                 [readOnly]="hasSupplierFieldLinked(supplier.data.order.toString()) || readOnly('SUPPLIER_INTERNATIONAL_ID')"
                                 [showClearButton]="false"
                                 valueChangeEvent="focusout"
                                 (onValueChanged)="update($event, null, supplier.data, 'SUPPLIER_INTERNATIONAL_ID', supplier.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_INTERNATIONAL_ID', supplier.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                  <dx-number-box *ngIf="!showLinkButtons"
                                 class="inDataGrid"
                                 [(value)]="supplier.data.internationalId"
                                 [min]="1"
                                 [showSpinButtons]="!templateView"
                                 [readOnly]="readOnly('SUPPLIER_INTERNATIONAL_ID')"
                                 [showClearButton]="false"
                                 (onValueChanged)="update($event, null, supplier.data, 'SUPPLIER_INTERNATIONAL_ID', supplier.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_INTERNATIONAL_ID', supplier.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-number-box>
                </div>
              </np-inputControl>
            </div>

            <div class="np-field w-25 pl-3">
              <np-inputControl [(model)]="product"
                               [showLinkButtons]="showLinkButtons"
                               [showPlaceLabel]="supplier?.data?.internationalIdType"
                               [suppressLabel]="true"
                               [(selectedElements)]="selectedElements"
                               (elementSelected)="elementSelectFunction($event)"
                               (onValueChanged)="update($event)"
                               field="SUPPLIER_INTERNATIONAL_ID_TYP"
                               [system]="supplier.data.order.toString()"
                               [showDragHandle]="showDragHandle"
                               [dragFilter]="dragFilter"
                               (excelElementDropped)="excelElementDrop($event)">

                <div slot="content" class="contentSlot">
                  <dx-autocomplete [minSearchLength]="0"
                                   [dataSource]="internationalPidType"
                                   [readOnly]="readOnlyForLinkedFields('SUPPLIER_INTERNATIONAL_ID_TYP', supplier.data.order.toString()) || readOnly('SUPPLIER_INTERNATIONAL_ID_TYP')"
                                   [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_INTERNATIONAL_ID_TYP', supplier.data.order.toString())"
                                   [showDropDownButton]="!readOnlyForLinkedFields('SUPPLIER_INTERNATIONAL_ID_TYP', supplier.data.order.toString())"
                                   [(value)]="supplier.data.internationalIdType"
                                   (onValueChanged)="update($event, null, null, 'SUPPLIER_INTERNATIONAL_ID_TYP', supplier.data.order.toString())"
                                   (onSelectionChanged)="updateFromAutocomplete($event, 'SUPPLIER_INTERNATIONAL_ID_TYP', supplier.data.order.toString())">
                    <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                      <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_INTERNATIONAL_ID_TYP', supplier.data.order.toString())"></dxi-validation-rule>
                    </dx-validator>
                  </dx-autocomplete>
                </div>
              </np-inputControl>
            </div>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.contentUnitCode"
                             labelText="{{ 'Inhaltseinheit' | translate }}"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             field="SUPPLIER_CONTENT_UNIT_CODE"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.contentUnitCode"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_CONTENT_UNIT_CODE', supplier.data.order.toString()) || readOnly('SUPPLIER_CONTENT_UNIT_CODE')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_CONTENT_UNIT_CODE', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_CONTENT_UNIT_CODE', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_CONTENT_UNIT_CODE', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.packingAmount"
                             labelText="{{ 'Verpackungmenge' | translate }}"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             field="SUPPLIER_PACKING_AMOUNT"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.packingAmount"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_PACKING_AMOUNT', supplier.data.order.toString()) || readOnly('SUPPLIER_PACKING_AMOUNT')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_PACKING_AMOUNT', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_PACKING_AMOUNT', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PACKING_AMOUNT', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>


          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.orderIntervall"
                             labelText="{{ 'Bestellintervall' | translate }}"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             field="SUPPLIER_ORDER_INTERVALL"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.orderIntervall"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_ORDER_INTERVALL', supplier.data.order.toString()) || readOnly('SUPPLIER_ORDER_INTERVALL')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_ORDER_INTERVALL', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_ORDER_INTERVALL', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_ORDER_INTERVALL', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.minOrderAmount"
                             labelText="{{ 'Mindestbestellmenge' | translate }}"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             field="SUPPLIER_MIN_ORDER_AMOUNT"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="supplier.data.minOrderAmount"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_MIN_ORDER_AMOUNT', supplier.data.order.toString()) || readOnly('SUPPLIER_MIN_ORDER_AMOUNT')"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_MIN_ORDER_AMOUNT', supplier.data.order.toString())"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_MIN_ORDER_AMOUNT', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_MIN_ORDER_AMOUNT', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="false"
                             [suppressPlaceLabel]="true"
                             [suppressContentWidth]="templateView"
                             labelText="{{ 'Auslaufprodukt' | translate }}"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             field="SUPPLIER_DISCONTINUED_PRODUCT"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot np-others" style="width: 300px; height: 49px; padding-top: 12px; padding-left: 10px;">
                <np-booleanRadioGroup [(value)]="supplier.data.discontinuedProduct"
                                      [templateView]="templateView"
                                      [fieldName]="validationService.fieldNames.supplierDiscontinuedProduct"
                                      [readOnly]="readOnlyForLinkedFields('SUPPLIER_DISCONTINUED_PRODUCT', supplier.data.order.toString()) || readOnly('SUPPLIER_DISCONTINUED_PRODUCT')"
                                      (onValueChanged)="update($event, null, null, 'SUPPLIER_DISCONTINUED_PRODUCT', supplier.data.order.toString())"
                                    validatorValidationGroup="SUPP">
                </np-booleanRadioGroup>
              </div>
            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="product"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="supplier?.data?.discontinuedDate"
                             labelText="{{ 'Auslaufdatum' | translate }}"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             field="SUPPLIER_DISCONTINUED_DATE"
                             [system]="supplier.data.order.toString()"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-date-box [(value)]="supplier.data.discontinuedDate"
                             type="date"
                             [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_DISCONTINUED_DATE', supplier.data.order.toString())"
                             [readOnly]="readOnlyForLinkedFields('SUPPLIER_DISCONTINUED_DATE', supplier.data.order.toString())  || readOnly('SUPPLIER_DISCONTINUED_DATE')"
                             displayFormat="dd.MM.yyyy"
                             (onValueChanged)="update($event, null, null, 'SUPPLIER_DISCONTINUED_DATE', supplier.data.order.toString())">
                  <dx-validator *ngIf="!templateView" validationGroup="SUPP">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_DISCONTINUED_DATE', supplier.data.order.toString())"></dxi-validation-rule>
                  </dx-validator>
                </dx-date-box>
              </div>
            </np-inputControl>
          </div>
          <div></div>		<!-- DUMMY; last DIV gets no margin -->
        </form>
      </div>
    </div>
  </div>


</dx-data-grid>






<div class="dx-fieldset" *ngIf="model">
  <div class="dx-field">
    <div class="dx-field-label dx-field-label200">{{ 'Kategorie Layout' | translate }}</div>
    <div class="dx-field-value" >
      <dx-select-box [dataSource]="shopwareCmsPages"
                     displayExpr="name"
                     valueExpr="id"
                     (onValueChanged)="categoryUpdate($event)"
                     [(value)]="model.shopware6CategoryMapping.cmsPageId"
                     [readOnly]="readOnlyUser('shopware6CategorieLayout')"
                     showClearButton="true">
      </dx-select-box>
    </div>
  </div>
</div>




<div class="dx-fieldset">
  <dx-validation-group id="SEO">
    <!--MetaTitle-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.metaTitel?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Meta Titel' | translate }}"
                       field="META_TITLE"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.metaTitel"
                       [readOnly]="readOnlyForLinkedFields('META_TITLE') || readOnly('seoTitle')"
                       [showClearButton]="!readOnlyForLinkedFields('META_TITLE')"
                       valueChangeEvent="keyup"
                       (onValueChanged)="update($event, 'META_TITLE')">
            <dx-validator *ngIf="!templateView" validationGroup="SEO">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('META_TITLE')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
          <seo_component *ngIf="model?.metaTitel?.length >0"   [metaTitel]="model.metaTitel"></seo_component>
        </div>
      </np-inputControl>
    </div>

    <!--MetaDescription-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.metaDescription?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Meta Beschreibung' | translate }}"
                       field="META_DESCRIPTION"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-area [(value)]="model.metaDescription"
                        [readOnly]="readOnlyForLinkedFields('META_DESCRIPTION') || readOnly('seoDescription')"
                        [height]="130"
                        (onValueChanged)="update($event, 'META_DESCRIPTION')">
            <dx-validator *ngIf="!templateView" validationGroup="SEO">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('META_DESCRIPTION')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
          <seo_component *ngIf="model?.metaDescription?.length > 0" [metaDescription]="model.metaDescription"></seo_component>
        </div>
      </np-inputControl>
    </div>
    <div>
      <snippet *ngIf="model?.metaTitel?.length >0 || model?.metaDescription?.length > 0" [metaTitel]="model.metaTitel" [metaDescription]="model.metaDescription"></snippet>
    </div>
  </dx-validation-group>
</div>


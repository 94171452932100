import { Component, Input, OnInit } from "@angular/core";

import { DxTreeListComponent } from "devextreme-angular";
import { Output, EventEmitter } from '@angular/core';
import { MassGenerateKITextsParameter } from "../../../Model/MassGenerateKITextsParameter";
import { ModelService } from "../../../Services/model.service";
import { TreeItem } from "../../../Model/Dto/TreeItem";
import { InvoiceService } from "../../../Services/invoice.service";
import { InvoiceSettingDto } from "../../../Model/Dto/InvoiceSettingDto";
import { TeachingSet } from "../../../Model/OpenAi/teachingset";
import { TeachingSetService } from "../../../Services/teachingSet.service";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'massGenerateKIText',
  templateUrl: './massGenerateKIText.component.html'
})
export class MassGenerateKITextComponent implements OnInit {
  @Input() treeList: DxTreeListComponent;
  @Output() cancel = new EventEmitter();

  productCount: number = 0;
  genVariantTexts: boolean = false;
  price = 0.10;
  confirmVisible = false;
  invoiceSetting: InvoiceSettingDto;
  teachingSet: TeachingSet;
  teachingSets: TeachingSet[];
  loading: boolean = false;
  generateHTML = false;

  constructor(public modelService: ModelService, public invoiceService: InvoiceService, public teachingSetService: TeachingSetService, public translate: TranslateService) {
    this.ngOnInit = this.ngOnInit.bind(this);
    this.calculateProds = this.calculateProds.bind(this);
    this.openConfirm = this.openConfirm.bind(this);
    this.confirmGenerateText = this.confirmGenerateText.bind(this);
    this.back = this.back.bind(this);

    this.invoiceSetting = new InvoiceSettingDto();
    teachingSetService.getAllTrained().subscribe(sets => {
      this.teachingSets = sets;
      this.teachingSets.push({ name: this.translate.instant("Standard"), id: "", customerId: this.modelService.loginService.currentCustomer.id.toString(), samples: [], state: 2 } as TeachingSet);

      if (this.teachingSets.length > 0)
        this.teachingSet = this.teachingSets[0];
    });

  }

  ngOnInit(): void {
/*    this.calculateProds();
    this.treeList.onSelectionChanged.subscribe(() => {
      this.calculateProds();
    });*/
  }

  calculateProds() {
    this.invoiceService.getTotalProducts(this.modelService.loginService.currentCustomer.id, this.buildParam()).subscribe((count:
      number) => {
       this.productCount = count
    });
  }

  buildParam(): MassGenerateKITextsParameter {
    const selectedData: TreeItem[] = this.treeList.instance.getSelectedRowsData();
    const teachingSetID = this.teachingSet != null ? this.teachingSet.id : null;
    const generateHTML = this.generateHTML;

    const cat = selectedData.filter(t => t.type == "cat");
    if (cat.length > 0)
      return new MassGenerateKITextsParameter(
        cat[0].id.toString(), 
        cat.length > 0, 
        null, 
        null, 
        this.genVariantTexts, 
        teachingSetID,
        generateHTML
      );
    const grp = selectedData.filter(t => t.type == "grp");
    const pro = selectedData.filter(t => t.type == "pro");

    const param = new MassGenerateKITextsParameter(
      this.modelService.catalogService.catalog.id, 
      cat.length > 0, 
      grp.map(x => x.id.toString()), 
      pro.map(x => x.id.toString()), 
      this.genVariantTexts, 
      teachingSetID,
      generateHTML
    );
    return param;
  }

  openConfirm() {
    this.loading = true;
    this.invoiceService.getTotalProducts(this.modelService.loginService.currentCustomer.id, this.buildParam()).subscribe((count:
      number) => {
      this.productCount = count;
      this.loading = false;
    });
    this.invoiceService.GetInvoiceSetting(this.modelService.loginService.currentCustomer.id).subscribe(result => {
      this.invoiceSetting = result;
      this.confirmVisible = true;
    });
  }

  confirmGenerateText() {
    //start job
    this.invoiceService.startMassGenerateOpenAITextsJob(this.modelService.loginService.currentCustomer.id, this.buildParam()).subscribe(() => {
      this.modelService.systemService.notifyInfo(this.translate.instant("Der Job wurde gestartet"), 2);
    });
    this.confirmVisible = false;
    this.cancel.emit();
  }
  back() {
    this.cancel.emit();
  }
}

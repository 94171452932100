export class StartShopWareExport {
    

  constructor(
    public customerId?: string,
    public catalogId?: string,
    public sendPrices?: boolean,
    public isVersion6?: boolean,
    public includeHazmatClassesInDescription? : boolean,
  ) {
  }
}

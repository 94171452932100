import { Functions } from '../../Services/functions';

export class ProductPriceDetail {

  constructor(
    public lowerBound?: string,
    public order?: number,
    public priceAmount?: string,
    public priceCurrency?: string,
    public priceFactor?: string,
    public priceType?: string,
    public tax?: string,
    public taxCategory?: string,
    public territory?: string,
    public guid: string = Functions.newGuid()
  ) {
    this.priceCurrency = "EUR";
    this.priceFactor = "1";
    this.tax = "0.19";
    this.territory = "DE";
    this.lowerBound = "1";
  }

  public clearPrice() {
    this.priceCurrency = "";
    this.priceFactor = "";
    this.tax = "";
    this.territory = "";
    this.lowerBound = "";
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Product } from '../Model/Catalog/product';
import { NextPimField } from '../Model/Catalog/NextPimField';
import { FieldNames } from '../Model/FieldNames';
import { Message } from '../Model/System/Message';
import { ExcelElement } from '../Model/ui/ExcelElement';
import { Functions } from '../Services/functions';
import { ModelService } from '../Services/model.service';
import { SystemService } from '../Services/system.service';
import { TemplateService } from '../Services/template.service';
import { ValidFeatureService } from '../Services/validFeature.service';

@Component({
  selector: 'template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css']
})
export class NewTemplateEditorComponent implements OnInit, OnDestroy {
  private fieldNames: FieldNames;

  @Input() showSelector: boolean = false;
  @Input() exportMode: boolean = false;
  @Input() templateChange: Observable<string>;

  selectedElements: string[];
  //_wait: boolean;
  _product: Product;
  _backupProduct: Product;
  excelModel: ExcelElement[];

  messageSpidIntPidDefaultNotAllowed: string = '';
  messageSelectTemplate: string = '';

  private _isVisible: boolean = true;
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get template() {
    return this.templateService.template;
  }

  get catalog() {
    return this.modelService.catalogService.catalog;
  }

  get customerId() {
    return this.modelService.loginService.currentUser.customerId;
  }

  constructor(
    public modelService: ModelService,
    public systemService: SystemService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public validFeatureService: ValidFeatureService
  ) {
    this.fieldNames = new FieldNames();

    this.messageSpidIntPidDefaultNotAllowed = this.translate.instant('messageSpidIntPidDefaultNotAllowed');
    this.messageSelectTemplate = this.translate.instant('Bitte ein Template auswählen') + '.';
    this.clearPimField = this.clearPimField.bind(this);
    this.templateService.clearPimFieldEmitter.subscribe(async (x) => await this.clearPimField(x));
  }

  ngOnInit(): void {
    if (this.templateChange != null) {
      this.templateChange.subscribe((templateId: string) => {
        if (templateId != null) {
          this.templateService.getTemplateById(templateId).subscribe(() => {
            this.templateSelected(null);
          });
        } else {
          this.templateSelected(null);
        }
      });
    }
    this.selectedElements = [''];

    //this._wait = false;
    this._product = null;
    this._backupProduct = null;

    this.excelModel = [];

    // fallback, wenn noch kein katalog besucht wurde
    if (
      this.modelService.catalogService.catalog == null ||
      this.modelService.catalogService.catalog == undefined ||
      this.modelService.catalogService.catalog.id == null ||
      this.modelService.catalogService.catalog.id == undefined ||
      this.modelService.catalogService.catalog.id == ''
    ) {
      this.modelService.catalogService.catalog = this.modelService.catalogService.catalogs[0];
    }

    if (this.templateService.importJob != null) {
      //this.showSelector = false;  // @Input()
      //this.exportMode = false;    // @Input()

      this.templateService.getTemplateById(this.templateService.importJob.templateId).subscribe(() => {
        this.templateSelected(null);
      });
    } else {
      //this.showSelector = true;   // @Input()
      if (this.templateService.template != null && this.templateService.template != undefined) {
        this.exportMode = this.templateService.template.isExportTemplate; // wird hier bei export NICHT gesetzt, da kein template
      }

      if (this.exportMode) {
        // (bei export @Input())
        this.getFirstProduct();
      } else {
        this.templateSelected(null);
      }
    }
  }
  //ngAfterViewInit(): void {
  //  this.isVisible = false;
  //}
  ngOnDestroy(): void {
    this.templateService.importJob = null;
    this.templateService.template = null;
    this.selectedElements = [''];

    //this._wait = false;
    this._product = null;
    this._backupProduct = null;

    this.excelModel = [];
    this.isVisible = true;
  }

  public elementSelectFunction(e) {
    this.selectedElements = [e];
  }

  public excelRowSelected(e) {
    if (e == '') {
      this.selectedElements = [''];
      return;
    }

    var element = this.excelModel.filter((element) => element.id == e).shift();
    if (element == null) {
      return;
    }

    this.selectedElements = this.templateService.createIdentifiers(element.pimFields);
  }

  public get product() {
    if (this._product) {
      if (this.exportMode) {
        this.buildListsAndTables();
      }
      // this.modelService.productService.createWawiIfNeeded(this._product, this.modelService.loginService.wawiSettings);
      return this._product;
    }

    if (!this.exportMode && this._product == null) {
      this._product = this.modelService.productService.getProductFromJson(
        new Product(),
        this.modelService.loginService.wawiSettings
      );
      this._backupProduct = this.modelService.productService.getProductFromJson(
        new Product(),
        this.modelService.loginService.wawiSettings
      );

      //this.buildListsAndTables(); // nur im exportMode benötigt
      return this._product;
    }

    if (this.modelService.catalogService.product) {
      this._product = this.modelService.productService.getProductFromJson(
        this.modelService.catalogService.product,
        this.modelService.loginService.wawiSettings
      ); // **
      this._product.linkedFields = [];
      this._backupProduct = this.modelService.productService.getProductFromJson(
        this.modelService.catalogService.product,
        this.modelService.loginService.wawiSettings
      ); // **
      this._backupProduct.linkedFields = [];
    }
    //else {                                    // machen wir in OnInit
    //  if (!this._wait) {                      // machen wir in OnInit
    //    this._wait = true;                    // machen wir in OnInit
    //    this.getFirstProduct().subscribe();   // machen wir in OnInit
    //  }                                       // machen wir in OnInit
    //}                                         // machen wir in OnInit

    this.buildListsAndTables();
    return this._product;
  }

  public getFirstProduct() {
    let customerId = '';

    customerId = this.modelService.loginService.currentUser.customerId;

    this.modelService.catalogService.getFirstProduct(customerId).subscribe((result: Product) => {
      this.setProduct(result);
      this.templateSelected(null);
    });
  }

  public setProduct(product: Product): Product {
    this._product = this.modelService.productService.getProductFromJson(
      product,
      this.modelService.loginService.wawiSettings
    ); // **
    this._product.linkedFields = [];
    this._backupProduct = this.modelService.productService.getProductFromJson(
      product,
      this.modelService.loginService.wawiSettings
    ); // **
    this._backupProduct.linkedFields = [];

    this.buildListsAndTables();
    return this._product;
  }

  public onDropAdd(e) {
    if (e.itemData.source == 'excelModel') {
      return;
    }

    this.addElement(e.itemData.dField, e.itemData.dSystem, e.itemData.dElement);
  }

  public onDropRemove(e) {
    if (e.itemData.source != 'excelModel') {
      return;
    }

    this.removeElement(e.itemData.dField, e.itemData.dSystem, e.itemData.dElement, e.fromIndex);
  }

  public excelElementDrop(e) {
    let field = e.newField;
    let system = e.newSystem;
    let element = e.newElement;
    this.addElementFromExcel(field, system, element, e.fromIndex);
  }

  public elementDelete(e) {
    let fields = e.dFields;
    let detailFields = e.dDetailFields;
    let system = e.dSystem;
    let element = e.dElement;

    let needsUpdate = false;

    for (let field of fields) {
      let found = this.excelModel.find(
        (i) => i.pimFields.findIndex((x) => x.field == field && x.systemKey == system && x.elementKey == element) != -1
      );
      needsUpdate = this.removeOrClearExcelElement(found, needsUpdate);
    }

    if (detailFields != null) {
      for (let field of detailFields) {
        let found = this.excelModel.filter(
          (i) =>
            i.pimFields.findIndex(
              (x) => x.field == field && x.systemKey == system && (x.elementKey == element || element == '-1')
            ) != -1
        );

        for (let item of found) {
          needsUpdate = this.removeOrClearExcelElement(item, needsUpdate);
        }
      }
    }

    if (needsUpdate) this.updateTemplate(null);
  }

  public removeOrClearExcelElement(excelElement: ExcelElement, needsUpdate: boolean): boolean {
    let index = this.excelModel.indexOf(excelElement);
    if (index >= 0) {
      if (
        excelElement.title == null ||
        excelElement.title == undefined ||
        excelElement.title == '' ||
        this.exportMode
      ) {
        this.excelModel.splice(index, 1);
      } else {
        excelElement.pimFields = [];
      }

      needsUpdate = true;
    }

    return needsUpdate;
  }

  public async addDefaultElement(e) {
    if (e == null || e.event == undefined) return;

    let field = e.dField;
    let system = e.dSystem;
    let element = e.dElement;
    let value = e.value;

    if (field == null || field == undefined) return;

    if (
      field == this.fieldNames.supplierPid ||
      field == this.fieldNames.internatonalPid ||
      field == this.fieldNames.supplierInternationalId ||
      field == this.fieldNames.pid
    ) {
      this.systemService.notify(new Message(this.messageSpidIntPidDefaultNotAllowed, 'info'), 3000);
      return;
    }

    if (field == this.fieldNames.featureSystemOrder || field == this.fieldNames.fOrder) {
      if (system.length != 24) {
        this.templateService.changeKey(
          this._product,
          e.previousValue,
          e.value,
          system,
          element,
          field,
          this.excelModel
        );
        this.updateTemplate(null);
        return;
      }
    }

    if (
      field == this.fieldNames.featureSystemName ||
      field == this.fieldNames.fName ||
      field == this.fieldNames.fUnit
    ) {
      this.templateService.changeKey(this._product, e.previousValue, e.value, system, element, field, this.excelModel);
    }

    if (field == 'MIME_ORDER' || field == 'PRICE_LIST_ORDER' || field == 'PRICE_ORDER') {
      this.templateService.changeKey(this._product, e.previousValue, e.value, system, element, field, this.excelModel);
      this.updateTemplate(null);
      return;
    }

    if (!e.createTemplateItem) {
      return;
    }

    this.templateService.refresExcelElementhOrder(this.excelModel);

    let count = this.excelModel.length;

    let excelElement = new ExcelElement();

    if (this.templateService.hasExcelElement(this.excelModel, field, system, element)) {
      excelElement = this.templateService.getExcelElement(this.excelModel, field, system, element);

      if (this.exportMode) this.templateService.clearPreviewRow1Row2(excelElement);

      this.templateService.clearOptions(excelElement);
    } else {
      excelElement.id = (count + 1).toString();
      excelElement.pimFields.push(new NextPimField(element, system, field));

      if (this.exportMode) {
        excelElement.title = field;
        if (system != null && system >= 0) {
          excelElement.title += '_' + system;
          if (field != null && field >= 0) {
            excelElement.title += '_' + field;
          }
        }
      }

      this.templateService.clearPreviewRow1Row2(excelElement);
      this.excelModel.push(excelElement);
    }

    if (field == this.fieldNames.descriptionLong) {
      // bei dem HTML Editor kommt es nicht in e.value mit...
      value = await this.templateService.getValueFromProduct(
        this._product,
        false,
        false,
        excelElement,
        field,
        system,
        element,
        '|'
      );
    } else if (!this.isUpdateModeField(field)) {
      if (value == null || value == undefined) {
        value = '';
      } else {
        value = await this.templateService.getValueFromProduct(
          this._product,
          false,
          false,
          excelElement,
          field,
          system,
          element,
          '|'
        );
      }
    }

    this.templateService.setOptions(excelElement, value, '', '', '', '|');
    this.updateTemplate(null);
  }

  public async addElement(field: string, system: string, element: string) {
    if (!this.templateService.template) {
      this.systemService.notify(new Message(this.messageSelectTemplate, 'info'), 3000);
      return;
    }

    if (!this.exportMode) {
      return;
    }

    if (field == this.fieldNames.fName) {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('FeatureNameDroppedError'), 'error'),
        3000
      );
      return;
    }

    if (field == this.fieldNames.featureSystemName) {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('FeatureSystemNameDroppedError'), 'error'),
        3000
      );
      return;
    }

    if (field == this.fieldNames.fUnit) {
      this.modelService.systemService.notify(
        new Message(this.translate.instant('FeatureUnitDroppedError'), 'error'),
        3000
      );
      return;
    }

    this.templateService.refresExcelElementhOrder(this.excelModel);
    let count = this.excelModel.length;
    let excelElement = new ExcelElement();
    excelElement.id = (count + 1).toString();

    excelElement.title = field;
    let result = await lastValueFrom(
      this.templateService.getHeadline(field, element, system, this.modelService.loginService.currentCustomer.id)
    );
    excelElement.title = result.value;
    var existing = this.excelModel.filter(
      (x) =>
        x.pimFields &&
        x.pimFields.some(
          (pimfied) => pimfied.field == field && pimfied.systemKey == system && pimfied.elementKey == element
        )
    );
    if (existing && existing.length > 1) {
      excelElement.title = excelElement.title + ' (' + existing.length + ')';
    }

    excelElement.pimFields = [new NextPimField(element, system, field)];

    if (this.exportMode) {
      let value = await this.templateService.getValueFromProduct(
        this._product,
        true,
        true,
        excelElement,
        field,
        system,
        element /*, excelElement.seperator*/
      );
      excelElement.preview = value;
      excelElement.row1 = value;
    }

    this.excelModel.push(excelElement);
    this.updateTemplate(null);
  }

  public async addElementFromExcel(field: string, systemKey: string, elementKey: string, index: number) {
    let item = this.excelModel[index];

    await this.clearPimField(new NextPimField(elementKey, systemKey, field), false);

    if (item.defaultValue != '') {
      if (!this.isUpdateModeField(field)) {
        this.templateService.setValueOnProduct(
          this._product,
          false,
          false,
          item,
          field,
          systemKey,
          elementKey,
          item.defaultValue,
          this.excelModel,
          null,
          true
        );
      }
    } else {
      this.templateService.setValueOnProduct(
        this._product,
        false,
        false,
        item,
        field,
        systemKey,
        elementKey,
        item.row1,
        this.excelModel,
        null,
        true
      );
    }

    item.pimFields.push(new NextPimField(elementKey, systemKey, field));

    if (this.exportMode) {
      this.templateService.clearPreviewRow1Row2(this.excelModel[index]);
    }

    this.templateService.clearOptions(this.excelModel[index]);
    await this.updateTemplate(null);

    this.selectedElements = this.templateService.createIdentifiers(item.pimFields);
  }

  public removeElement(field: string, system: string, element: string, fromIndex: number) {
    if (field == undefined && system == undefined && element == undefined) {
      this.excelModel.splice(fromIndex, 1);
    } else {
      let found = this.excelModel.find(
        (i) => i.pimFields.findIndex((x) => x.field == field && x.systemKey == system && x.elementKey == element) != -1
      );

      let indexRight = this.excelModel.indexOf(found);
      if (indexRight >= 0) {
        this.excelModel.splice(indexRight, 1);
      }
    }

    this.updateTemplate(null);
  }

  public async clearItem(e) {
    let matching = this.excelModel.filter((element) => element.id == e);

    for (let item of matching) {
      if (this.exportMode) {
        this.templateService.clearPreviewRow1Row2(item);

        for await (const x of item.pimFields) {
          let oldValue = await this.templateService.getValueFromProduct(
            this._backupProduct,
            false,
            true,
            item,
            x.field,
            x.systemKey,
            x.elementKey,
            item.seperator
          );
          this.templateService.setValueOnProduct(
            this._product,
            false,
            true,
            item,
            x.field,
            x.systemKey,
            x.elementKey,
            oldValue,
            this.excelModel,
            item.seperator,
            true
          );
        }
      } else {
        for (const x of item.pimFields) {
          this.templateService.setValueOnProduct(
            this._product,
            false,
            false,
            item,
            x.field,
            x.systemKey,
            x.elementKey,
            null,
            this.excelModel,
            null,
            true
          );
        }
      }
      item.pimFields = [];
      this.templateService.clearOptions(item);
      this.updateTemplate(null);
    }
  }

  public async clearPimField(pimField: NextPimField, needRefresh: boolean = true) {
    let isFeatureKey =
      pimField.field == this.fieldNames.fName ||
      pimField.field == this.fieldNames.featureSystemName ||
      pimField.field == this.fieldNames.fUnit;

    let matchingExcelItem = this.excelModel.filter((x) =>
      x.pimFields.some(
        (y) =>
          (isFeatureKey || y.field == pimField.field) &&
          y.elementKey == pimField.elementKey &&
          y.systemKey == pimField.systemKey
      )
    );

    for (let excelItem of matchingExcelItem) {
      let pimFieldsToRemove = excelItem.pimFields.filter(
        (y) =>
          (isFeatureKey || y.field == pimField.field) &&
          y.elementKey == pimField.elementKey &&
          y.systemKey == pimField.systemKey
      );

      if (this.exportMode) {
        this.templateService.clearPreviewRow1Row2(excelItem);

        for await (const x of pimFieldsToRemove) {
          let oldValue = await this.templateService.getValueFromProduct(
            this._backupProduct,
            false,
            true,
            excelItem,
            x.field,
            x.systemKey,
            x.elementKey,
            excelItem.seperator
          );
          await this.templateService.setValueOnProduct(
            this._product,
            false,
            true,
            excelItem,
            x.field,
            x.systemKey,
            x.elementKey,
            oldValue,
            this.excelModel,
            excelItem.seperator,
            true
          );
        }
      } else {
        for (const x of pimFieldsToRemove) {
          await this.templateService.setValueOnProduct(
            this._product,
            false,
            false,
            excelItem,
            x.field,
            x.systemKey,
            x.elementKey,
            null,
            this.excelModel,
            null,
            true
          );
        }
      }

      excelItem.pimFields = excelItem.pimFields.filter(
        (y) =>
          !(
            (isFeatureKey || y.field == pimField.field) &&
            y.elementKey == pimField.elementKey &&
            y.systemKey == pimField.systemKey
          )
      );
      this.templateService.clearOptions(excelItem);
    }

    this.excelModel = this.excelModel.filter((x) => x.pimFields.length > 0 || (x.title != null && x.title != ''));

    this.templateService.clearPimFieldEmitterDone.emit();

    if (needRefresh) await this.updateTemplate(null);
  }

  public async replaceItem(e) {
    let i = e.toIndex;
    if (i == this.excelModel.length) {
      i--; // beim letzten item sowohl DROP davor als auch danach zulassen
    }

    if (
      this.templateService.hasExcelElement(this.excelModel, e.itemData.dField, e.itemData.dSystem, e.itemData.dElement)
    ) {
      let existing = this.templateService.getExcelElement(
        this.excelModel,
        e.itemData.dField,
        e.itemData.dSystem,
        e.itemData.dElement
      );
      let index = this.excelModel.indexOf(existing);

      this.excelModel[index].pimFields = this.excelModel[index].pimFields.filter(
        (x) =>
          !(x.elementKey == e.itemData.dElement && x.systemKey == e.itemData.dSystem && x.field == e.itemData.dField)
      );

      if (this.exportMode) {
        this.templateService.clearPreviewRow1Row2(this.excelModel[index]);
      }

      this.templateService.clearOptions(this.excelModel[index]);
    }

    this.excelModel[i].pimFields.push(new NextPimField(e.itemData.dElement, e.itemData.dSystem, e.itemData.dField));

    if (this.exportMode) this.templateService.clearPreviewRow1Row2(this.excelModel[i]);

    this.templateService.clearOptions(this.excelModel[i]);
    this.updateTemplate(null);
  }

  public reorderModel(e) {
    if (!this.exportMode) {
      return;
    }

    this.excelModel.splice(e.fromIndex, 1);
    this.excelModel.splice(e.toIndex, 0, e.itemData);

    this.updateTemplate(null);
  }

  public async updateTemplate(e) {
    if (this.exportMode) {
      // nur im EXPORTMODE!! SONST KOMMT DAS AUS DEM EXCEL-TEMPLATE!!

      for (let item of this.excelModel) {
        // "datenschrott" verhindern:
        // tritt auf, wenn ein geleertes oder gefülltes feld ohne seperator nun mit diesen feldern gefüllt wird
        // die array-elemente werden dann mit "" gejoined, aus der nummer kommen wir dann später nicht mehr 'raus...
        // sollte das der falsche seperator sein, kommt man an den ursprünglichen wert immer noch aus dem _backupProduct 'ran
        item.pimFields.forEach(async (field) => {
          if (this.templateService.needsStandardSeperator(field.field)) {
            if (item.seperator == null || item.seperator == undefined || item.seperator == '') {
              item.seperator = '|';
            }
          }

          // diese sind noch nicht aktualisiert, werden in getValueFromProduct aber verwendet!!
          this.templateService.template.templateItems = this.templateService.createTemplateItems(
            this.excelModel,
            this.exportMode
          );

          let value = await this.templateService.getValueFromProduct(
            this._product,
            true,
            true,
            item,
            field.field,
            field.systemKey,
            field.elementKey,
            item.seperator
          );

          item.preview = value;
          item.row1 = value;
        });
      }
    }

    this.templateService.template.isExportTemplate = this.exportMode;
    this.templateService.template.templateItems = this.templateService.createTemplateItems(
      this.excelModel,
      this.exportMode
    );
    this.templateService.updateTemplate(this.templateService.template);

    // Das ist nicht nötig (wir haben daraus grade das Template erzeugt & gespeichert)
    // UND es führt zu dem unerwünschten Verhalten s. Ticket https://nextpim.myjetbrains.com/youtrack/issue/nPIM-789
    //this.excelModel = this.templateService.createExcelElements(this.templateService.template.templateItems, this.templateService.template.isUseable);

    for (let item of this.excelModel) {
      if (this.exportMode) {
        // hier nicht - produkt im exportmode nicht verändern
        //this.templateService.setValueOnProduct(this._product, true, !this.exportMode, item, item.field, item.system, item.element, item.row1, item.seperator, true);
        //this.templateService.setValueOnProduct(this._product, false, this.exportMode, item, item.field, item.system, item.element, item.row1, item.seperator, true);
      } else {
        if (item.defaultValue != '') {
          // brauchen wir gar nicht. wert passt ja schon.
          // bei einigen Controls wird eine Endlosschleife ausgelöst...
          //let value = await this.templateService.getValueFromProduct(this._product, true, this.exportMode, item, item.field, item.system, item.element, item.seperator);
          //if (item.defaultValue != value) {
          //  this.templateService.setValueOnProduct(this._product, true, this.exportMode, item, item.field, item.system, item.element, item.defaultValue, item.seperator, true);
          //}
          // UPDATEMODE TODO HERE ????
        } else {
          // Bei neuen Elementen wurde der Seperator (naturgemäß) noch gar nicht gesetzt
          // Strings werden bei "" gesplittet:
          // Aus 1 z.B.Status "blau" werden 4 Status "b" "l" "a" "u"

          for (const x of item.pimFields) {
            if (this.templateService.needsStandardSeperator(x.field)) {
              if (item.seperator == null || item.seperator == undefined || item.seperator == '') {
                item.seperator = '|';
              }
            }
            this.templateService.setValueOnProduct(
              this._product,
              true,
              this.exportMode,
              item,
              x.field,
              x.systemKey,
              x.elementKey,
              item.row1,
              this.excelModel,
              item.seperator,
              true
            );
          }
        }

        //this.templateService.setValueOnProduct(this._product, true, this.exportMode, item, item.field, item.system, item.element, item.row1, item.seperator, true);
      }
    }
  }

  public templateSelected(e) {
    this.isVisible = true;
    // product reset
    if (this.exportMode) {
      this._product = this.modelService.productService.getProductFromJson(
        this._backupProduct,
        this.modelService.loginService.wawiSettings
      ); // **
      //this._product.linkedFields = [];  //hier eig. nicht, wenn am _backupProduct schon geleert ??
    } else {
      this._product = this.modelService.productService.getProductFromJson(
        new Product(),
        this.modelService.loginService.wawiSettings
      );
      this._backupProduct = this.modelService.productService.getProductFromJson(
        new Product(),
        this.modelService.loginService.wawiSettings
      );
    }

    if (this.templateService.template) {
      this.excelModel = this.templateService.createExcelElements(
        this.templateService.template.templateItems,
        this.templateService.template.isUseable
      );

      this.excelModel.forEach((item) => {
        item.pimFields.forEach((x) => {
          if (item.defaultValue != '') {
            if (!this.isUpdateModeField(x.field)) {
              this.templateService.setValueOnProduct(
                this._product,
                true,
                this.exportMode,
                item,
                x.field,
                x.systemKey,
                x.elementKey,
                item.defaultValue,
                this.excelModel,
                item.seperator,
                true
              );
            }
          } else {
            this.templateService.setValueOnProduct(
              this._product,
              true,
              this.exportMode,
              item,
              x.field,
              x.systemKey,
              x.elementKey,
              item.row1,
              this.excelModel,
              item.seperator,
              true
            );
            this.templateService.template.isUseable = true;
          }
        });
      });
    } else {
      this.excelModel = [];
    }
    this.isVisible = false;
  }

  private isUpdateModeField(field: string): boolean {
    if (
      field == this.fieldNames.featureUpdateMode ||
      field == this.fieldNames.mimeUpdateMode ||
      field == this.fieldNames.priceUpdateMode ||
      field == this.fieldNames.udxUpdateMode ||
      field == this.fieldNames.supplierUpdateMode ||
      field == this.fieldNames.statusUpdateMode
    ) {
      return true;
    }
    return false;
  }

  private buildListsAndTables() {
    if (this._product == null || this._product == undefined) {
      return;
    }

    this._product.featureSystems.forEach((item) => {
      item.featureSystemList = this.templateService.createListForSystem(item);
      item.featureSystemTable = this.templateService.createTableForSystem(item);
    });

    this._product.featurelist = this.templateService.createTableForAllSystems(this._product.featureSystems);
    this._product.descWithoutHTML = Functions.stripHTML(this._product.descriptionLong);
    this._product.groupString = this.templateService.createGroupString(this.excelModel);
  }
}

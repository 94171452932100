import { Component, OnInit } from "@angular/core";
import { Customer } from "../../Model/user/Customer";
import { OAuthService } from "angular-oauth2-oidc";
import { ValidationMethod } from "../../Model/validation/ValidationMethod";
import { ValidationService } from "../../Services/Validation/validation.service";
import { DxButtonTypes } from "devextreme-angular/ui/button";
import { Router } from "@angular/router";
import { CatalogService } from "../../Services/CatalogManagement/catalog.service";
import { LoginService } from "../../Services/login.service";
import { Catalog } from "../../Model/Catalog/Catalog";
import { SystemService } from "../../Services/system.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "dscInfos",
  templateUrl: "dscInfos.component.html"
})
export class DscInfosComponent implements OnInit {
  value: any[];
  uploadHeaders: any;
  msg: string = "";
  shareableValidationMethods: ValidationMethod[] = [];
  shareableCatalogs: Catalog[] = [];
  generatedRegistrationLink: string;

  generateRegistrationLinkButtonOptions: DxButtonTypes.Properties = {
    icon: 'link',
    disabled: false,
    onClick: async () => {
      this.generateRegistrationLink();
      navigator.clipboard.writeText(this.generatedRegistrationLink).then(() =>
        this.systemService.notifyInfo(this.translate.instant("Der Link wurde in die Zwischenablage kopiert")
      ));
    },
  };

  constructor(public loginService: LoginService, private oAuthService: OAuthService,
    private validationService: ValidationService, private router: Router, private catalogService: CatalogService,
    private systemService: SystemService, private translate: TranslateService) {
    this.onBeforeSendUpload = this.onBeforeSendUpload.bind(this);
    this.reset();
  }

  ngOnInit(): void {
    if (this.loginService.currentUser == null) {
      this.router.navigate(["/"]);
      return;
    }
    this.getShareableValidationMethods();
    this.getShareableCatalogs();
  }

  reset() {
    this.uploadHeaders = {
      CustomerId: this.model.id
    }
    this.value = [];
  }

  onBeforeSendUpload(e: { request: XMLHttpRequest }) {
    e.request.withCredentials = true;
    e.request.setRequestHeader('Authorization', 'Bearer ' + this.oAuthService.getAccessToken());
  }

  onUploaded(e) {
    this.reset();
    this.loginService.customerService.getCustomer(this.model.id).subscribe((result: Customer) => {
      this.model = result;
    });
  }

  getShareableValidationMethods() {
    this.validationService.getSharedValidationMethodsCreatedByCustomer().then(x => this.shareableValidationMethods = x);
  }

  getShareableCatalogs() {
    this.catalogService.getCatalogs(this.loginService.currentCustomer.id).subscribe(x => {
      var catalogWithCatalogId = x.filter(y => y.catalogId);
      this.shareableCatalogs = catalogWithCatalogId.filter((catalog, index, self) =>
        index === self.findIndex((t) => (
          t.catalogId === catalog.catalogId
        )));
    });
  }

  isManager(): boolean {
    if (this.loginService.currentUser.currentClaim == "USER") return false;
    return true;
  }

  get model(): Customer {
    return this.loginService.currentCustomer;
  }

  set model(value: Customer) {
    this.loginService.currentCustomer = value;
  }

  update() {
    this.loginService.customerService.update(this.model).subscribe((result: Customer) => {
      this.model = result;
    });
  }

  generateRegistrationLink() {
    var params = this.router.createUrlTree(['/register'], {
      queryParams: {
        customerId: this.model.id,
        contactName: this.model.contactName,
        contactEmail: this.model.contactEmail,
        contactPhone: this.model.contactPhone,
        domain: this.model.domain,
        logo: this.model.logoUrl,
        validationMethodId: this.model.sharedValidationMethodId,
        catalogId: this.model.sharedCatalogId
      }
    });
    this.generatedRegistrationLink = location.origin + params;
  }

  get showPlaceHolders() {
    return this.loginService.showPlaceHolders;
  }
}



import { OnInit, Directive } from '@angular/core';
import { ModelService } from "../../../../Services/model.service";
import { UserActionBaseComponent } from '../userAction-base.component';
import { KeyValueItem } from '../../../../Model/System/KeyValueItem';
import { ValidFeatureSystem } from '../../../../Model/ValidFeatureSystem';
import { ValidFeatureService } from '../../../../Services/validFeature.service';
import { lastValueFrom } from 'rxjs';


@Directive()
export abstract class UserActionFeatureSystemsComponent extends UserActionBaseComponent implements OnInit {
  featureSystems: ValidFeatureSystem[] = [];
  featureSystemsLoaded = false;
  featureSystemsLoading = false;
  systemsFromTemplate: KeyValueItem[];

  constructor(public modelService: ModelService, public validFeatureService: ValidFeatureService) {
    super(modelService);
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.loadFeatureSystems();
  }

  async loadFeatureSystems() {
    if (!this.featureSystemsLoaded) {
      this.featureSystemsLoading = true;
      this.featureSystems = await lastValueFrom(this.validFeatureService.getCatalogSystems(
        this.modelService.catalogService.selectedCatalogId,
        this.modelService.loginService.currentUser.customerId));
      this.featureSystemsLoading = false;
      this.featureSystemsLoaded = true;
    }
  }

  async loadFeatures(featureSystem: ValidFeatureSystem) {
    this.featureSystemsLoading = true;

    let features = await lastValueFrom(this.validFeatureService.getCatalogFeaturesOfSystem(
      this.modelService.catalogService.selectedCatalogId,
      this.modelService.loginService.currentUser.customerId, featureSystem));

    this.featureSystemsLoading = false;
    return features;
  }

  startMacro() {
    throw new Error("this is a abstract method");
  }
}

import { AfterViewInit, Component } from '@angular/core';
import { ModelService } from '../../Services/model.service';
import { FtpServer } from '../../Model/ftpServer';
import { Catalog } from '../../Model/Catalog/catalog';
import CustomStore from 'devextreme/data/custom_store';
import { TemplateService } from '../../Services/template.service';
import { CatalogService } from '../../Services/CatalogManagement/catalog.service';
import { Template } from '../../Model/Catalog/Template';
import { lastValueFrom } from 'rxjs';
import { Message } from '../../Model/System/Message';
import { FtpServerService } from '../../Services/ftp/ftpServer.service';
import { FtpExportJobService } from '../../Services/ftp/ftpExportJob.service';
import { FtpExportJobSettings } from '../../Model/recurringJobs/ftp/ftpExportJobSettings';
import { ExportConfigurationService } from '../../Services/exportConfiguration.service';
import { ExportParameter } from '../../Model/exportParameter/ExportParameter';
import { RowInsertedEvent, RowRemovedEvent, RowUpdatedEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'ftpExport',
  templateUrl: 'ftpExport.component.html'
})
export class FtpExportComponent {
  dataSource: any = {};
  catalogs: Catalog[] = [];
  ftpServers: FtpServer[] = [];
  excelTemplates: Template[] = [];
  exportConfigs: ExportParameter[] = [];
  exportFormats: string[] = ['Bme', 'Excel'];

  ftpExportSettings: FtpExportJobSettings[] = [];

  constructor(
    public modelService: ModelService,
    public ftpExportJobService: FtpExportJobService,
    public ftpServerService: FtpServerService,
    public templateService: TemplateService,
    public catalogService: CatalogService,
    public exportConfigurationService: ExportConfigurationService
  ) {
    this.loadSettings = this.loadSettings.bind(this);
    this.addSetting = this.addSetting.bind(this);
    this.updateSetting = this.updateSetting.bind(this);
    this.deleteSetting = this.deleteSetting.bind(this);
    this.run = this.run.bind(this);
    this.onRowRemoved = this.onRowRemoved.bind(this);
    this.onRowUpdated = this.onRowUpdated.bind(this);
    this.onRowInserted = this.onRowInserted.bind(this);

    this.loadSettings();

    this.ftpServerService.getAllServer().subscribe((ftpServer: FtpServer[]) => {
      this.ftpServers = ftpServer;
    });
    this.templateService.getTemplatesReturn().subscribe((templates: Template[]) => {
      this.excelTemplates = templates;
    });
    this.catalogService
      .getCatalogs(this.modelService.loginService.currentCustomer.id)
      .subscribe((catalogs: Catalog[]) => {
        this.catalogs = catalogs;
      });
    this.exportConfigurationService
      .getAll(this.modelService.loginService.currentCustomer.id)
      .subscribe((configs: ExportParameter[]) => {
        this.exportConfigs = configs.filter((x) => !x.isUpdateFilter);
      });

    if (this.modelService.loginService.hasAddonCSV) this.exportFormats.push('csv');
    if (this.modelService.loginService.hasAddonGevis) this.exportFormats.push('GEVIS');
    if (this.modelService.loginService.hasAddonDATA_QUALITY) this.exportFormats.push('Qualitydetails');
    if (this.modelService.loginService.hasAddonDATA_QUALITY) this.exportFormats.push('Ebay');
  }

  async loadSettings() {
    this.ftpExportSettings = await lastValueFrom(this.ftpExportJobService.getAllFtpImportJobs());
    this.ftpExportSettings.forEach((f: FtpExportJobSettings) => {
      f.time = new Date(f.time);
    });
  }

  async addSetting(setting: FtpExportJobSettings) {
    setting.id = (await lastValueFrom(this.ftpExportJobService.create(setting))).id;
  }

  async updateSetting(setting: FtpExportJobSettings) {
    await lastValueFrom(this.ftpExportJobService.update(setting));
  }

  async deleteSetting(setting: FtpExportJobSettings) {
    await lastValueFrom(this.ftpExportJobService.delete(setting.id));
  }

  async onRowInserted(event: RowInsertedEvent<FtpExportJobSettings>) {
    var setting = event.data;
    await this.addSetting(setting);
  }

  async onRowUpdated(event: RowUpdatedEvent<FtpExportJobSettings>) {
    var setting = event.data;
    await this.updateSetting(setting);
  }

  async onRowRemoved(event: RowRemovedEvent<FtpExportJobSettings>) {
    var setting = event.data;
    await this.deleteSetting(setting);
  }

  isManager(): boolean {
    if (
      this.modelService.loginService.currentUser.currentClaim == 'MANAGER' ||
      this.modelService.loginService.currentUser.currentClaim == 'ADMIN'
    )
      return true;
    return false;
  }

  getTime(rowData): string {
    if (rowData.time instanceof String) rowData.time = new Date(rowData.time);

    return rowData.time;
  }

  getLastRun(rowData): string {
    if (rowData.lastRun instanceof String) rowData.lastRun = new Date(rowData.lastRun);

    return rowData.lastRun;
  }
  run(d) {
    this.ftpExportJobService.run(d.id).subscribe(
      () => {
        this.modelService.systemService.notify(new Message('Export Job wurde gestartet.'));
      },
      (error) => {
        let msg = new Message();
        msg.type = 'Error';
        msg.message = 'Fehler beim manuellen starten';
        this.modelService.systemService.notify(msg);
      }
    );
  }
}

import { Product } from "../Catalog/product"

export class DragInfo {
  constructor(
    public dModel?: Product,
    public dField?: string,
    public dSystem?: string,
    public dElement?: string,
    public dDisplayName?: string,
  ) {
    }
 

}

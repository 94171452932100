<dx-toolbar *ngIf="(isMaster() || modelService.loginService.hasAddonWawiErp) && !templateView">
  <dxi-item *ngIf="isMaster() && !readOnly('productLinkAll')" location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Alle Felder verknüpfen' | translate, onClick: linkAllFields }"></dxi-item>
  <dxi-item *ngIf="modelService.loginService.hasAddonWawiErp && !readOnly('productUniqueSID')" location="before" widget="dxButton" locateInMenu="auto" [options]="{  type: 'default', text: 'Eindeutige SupplierPid zuweisen' | translate, onClick: getSupplierPid }"></dxi-item>
</dx-toolbar>
<div class="dx-fieldset">
  <dx-validation-group id="MAIN">

    <div class="dx-field" *ngIf="productTranslation!=null">
      <div class="dx-field-label"></div>
      <div class="dx-field-value d-flex flex-row">
        <div class="flex-fill align-self-center"><img src="images/flags/de.gif" class="flag"><b>{{ 'Deutsch' | translate }}</b></div>
        <div class="flex-fill pl-2 align-self-center"><img src="images/flags/{{getLanguageFlag(selectedTranslateLanguage)}}" class="flag"><b>{{translateLanguage(selectedTranslateLanguage)}}</b></div>
      </div>
    </div>

    <!--DescriptionShort-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.descriptionShort?.length > 0"
                       [showTranslationPlaceLabel]="productTranslation?.descriptionShort?.length > 0"
                       [suppressTranslation]="false"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Produktbeschreibung' | translate }}"
                       field="DESCRIPTION_SHORT"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.descriptionShort"
                       [readOnly]="readOnlyForLinkedFields('DESCRIPTION_SHORT') || readOnly('productDescShort')"
                       [showClearButton]="!readOnlyForLinkedFields('DESCRIPTION_SHORT')"
                       (onValueChanged)="update($event, 'DESCRIPTION_SHORT')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DESCRIPTION_SHORT')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

        <div *ngIf="hasTranslation" slot="translation" class="translationSlot">
          <dx-text-box [(value)]="productTranslation.descriptionShort"
                       [readOnly]="readOnly('productDescShort')"
                       [showClearButton]="true"
                       (onValueChanged)="translationUpdate(model.descriptionShort,$event.value)">
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--SupplierPid-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.supplierPid?.length > 0"
                       [suppressLinkButton]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Produktnummer' | translate }}"
                       field="SUPPLIER_PID"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.supplierPid"
                       [readOnly]="readOnly('productId')"
                       [showClearButton]="true"
                       (onValueChanged)="update($event, 'SUPPLIER_PID')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_PID')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--SupplierAltAid-->
    <div *ngIf="!isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.supplierAltAid?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'alternative Produktnummer' | translate }}"
                       field="SUPPLIER_ALT_PID"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.supplierAltAid"
                       [readOnly]="readOnlyForLinkedFields('SUPPLIER_ALT_PID') || readOnly('productAltId')"
                       [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_ALT_PID')"
                       (onValueChanged)="update($event, 'SUPPLIER_ALT_PID')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('SUPPLIER_ALT_PID')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--internationalPid-->
    <div class="d-flex flex-row">

      <div class="np-field w-75">
        <np-inputControl [(model)]="model"
                         [(productTranslation)]="productTranslation"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="model?.internationalPid?.length > 0"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'Internationale ID' | translate }}"
                         field="INTERNATIONAL_PID"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="model.internationalPid"
                         [readOnly]="readOnlyForLinkedFields('INTERNATIONAL_PID') || readOnly('productIntId')"
                         [showClearButton]="!readOnlyForLinkedFields('INTERNATIONAL_PID')"
                         (onValueChanged)="update($event, 'INTERNATIONAL_PID')">
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('INTERNATIONAL_PID')"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>

        </np-inputControl>
      </div>

      <div class="np-field w-25 pl-3">
        <np-inputControl [(model)]="model"
                         [(productTranslation)]="productTranslation"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="model?.internationalPidType?.length > 0"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         field="INTERNATIONAL_PID_TYPE"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-autocomplete [minSearchLength]="0"
                             [dataSource]="internationalPidType"
                             [readOnly]="readOnlyForLinkedFields('INTERNATIONAL_PID_TYPE') || readOnly('productIntIdType')"
                             [showClearButton]="!readOnlyForLinkedFields('INTERNATIONAL_PID_TYPE')"
                             [showDropDownButton]="!readOnlyForLinkedFields('INTERNATIONAL_PID_TYPE')"
                             [(value)]="model.internationalPidType"
                             (onValueChanged)="update($event, 'INTERNATIONAL_PID_TYPE')">
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('INTERNATIONAL_PID_TYPE')"></dxi-validation-rule>
              </dx-validator>
            </dx-autocomplete>
          </div>

        </np-inputControl>
      </div>

      <div></div>		<!-- DUMMY; last DIV gets no margin -->
    </div>

    <!--ManufacturerTypeDescr-->
    <div *ngIf="!isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.manufacturerTypeDescr?.length > 0"
                       [showTranslationPlaceLabel]="productTranslation?.manufacturerTypeDescr?.length > 0"
                       [suppressTranslation]="false"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Herstellerbezeichnung' | translate }}"
                       field="MANUFACTURER_TYPE_DESCR"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-area [(value)]="model.manufacturerTypeDescr"
                        [height]="57"
                        [readOnly]="readOnlyForLinkedFields('MANUFACTURER_TYPE_DESCR') || readOnly('productPName')"
                        (onValueChanged)="update($event, 'MANUFACTURER_TYPE_DESCR')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('MANUFACTURER_TYPE_DESCR')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-area>
        </div>

        <div *ngIf="hasTranslation" slot="translation" class="translationSlot">
          <dx-text-area [(value)]="productTranslation.manufacturerTypeDescr"
                        [height]="57"
                        [readOnly]="readOnly('productPName')"
                        (onValueChanged)="translationUpdate(model.manufacturerTypeDescr,$event.value)">
          </dx-text-area>
        </div>

      </np-inputControl>
    </div>

    <!--DescriptionLong-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [suppressTranslation]="false"
                       [suppressPlaceLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Langbeschreibung' | translate }}"
                       field="DESCRIPTION_LONG"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)"
                       [isPrint]="isPrint"
                       [showHTMLSwitch]="showHTMLSwitch"
                       [(showHTMLEditor)]="showHTMLEditor"
                       (showHTMLEditorChange)="showHTMLEditorChanged()">

        <div slot="content" class="contentSlot">


          <div *ngIf="showHTMLEditor &&  !isPrint" class="w-100">
            <dx-html-editor class="w-100"
                            [(value)]="model.descriptionLong"
                            placeholder="DESCRIPTION_LONG"
                            [readOnly]="readOnlyForLinkedFields('DESCRIPTION_LONG') || readOnly('productDescLong')"
                            (onFocusOut)="update($event, 'DESCRIPTION_LONG')"
                            allowSoftLineBreak="true">
              <dxo-toolbar>
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
              </dxo-toolbar>
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DESCRIPTION_LONG')"></dxi-validation-rule>
              </dx-validator>
            </dx-html-editor>
          </div>

          <div *ngIf="!showHTMLEditor || isPrint">
            <label class="placeLabel {{ model?.descriptionLong?.length>0 ? 'placeLabelSmall' : '' }}">DESCRIPTION_LONG</label>
            <dx-text-area [(value)]="model.descriptionLong"
                          [height]="130"
                          [readOnly]="readOnlyForLinkedFields('DESCRIPTION_LONG') || readOnly('productDescLong')"
                          (onValueChanged)="update($event, 'DESCRIPTION_LONG')">
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DESCRIPTION_LONG')"></dxi-validation-rule>
              </dx-validator>
            </dx-text-area>
          </div>


        </div>

        <div *ngIf="hasTranslation" slot="translation" class="translationSlot">


          <div *ngIf="showHTMLEditor">
            <dx-html-editor [(value)]="productTranslation.descriptionLong"
                            placeholder="DESCRIPTION_LONG"
                            (onValueChanged)="translationUpdate(model.descriptionLong,$event.value)"
                            [readOnly]="readOnly('productDescLong')">
              <dxo-toolbar>
                <dxi-item name="undo"></dxi-item>
                <dxi-item name="redo"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="bold"></dxi-item>
                <dxi-item name="italic"></dxi-item>
                <dxi-item name="strike"></dxi-item>
                <dxi-item name="underline"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="alignLeft"></dxi-item>
                <dxi-item name="alignCenter"></dxi-item>
                <dxi-item name="alignRight"></dxi-item>
                <dxi-item name="alignJustify"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="orderedList"></dxi-item>
                <dxi-item name="bulletList"></dxi-item>
                <dxi-item name="separator"></dxi-item>
                <dxi-item name="link"></dxi-item>
              </dxo-toolbar>
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="custom" [validationCallback]="validationService.validateField('DESCRIPTION_LONG')"></dxi-validation-rule>
              </dx-validator>
            </dx-html-editor>
          </div>

          <div *ngIf="!showHTMLEditor">
            <label class="placeLabel {{ productTranslation?.descriptionLong?.length>0 ? 'placeLabelSmall' : '' }}">DESCRIPTION_LONG</label>
            <dx-text-area [(value)]="productTranslation.descriptionLong"
                          [height]="130"
                          (onValueChanged)="translationUpdate(model.descriptionLong,$event.value)"
                          [readOnly]="readOnly('productDescLong')">
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DESCRIPTION_LONG')"></dxi-validation-rule>
              </dx-validator>
            </dx-text-area>
          </div>


        </div>

      </np-inputControl>
    </div>

    <!--Remarks-->
    <div *ngIf="!isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.remarks?.length > 0"
                       [showTranslationPlaceLabel]="productTranslation?.remarks?.length > 0"
                       [suppressTranslation]="false"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Bemerkung' | translate }}"
                       field="REMARKS"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.remarks"
                       [readOnly]="readOnlyForLinkedFields('REMARKS') || readOnly('productTranslation')"
                       [showClearButton]="!readOnlyForLinkedFields('REMARKS')"
                       (onValueChanged)="update($event, 'REMARKS')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('REMARKS')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

        <div *ngIf="hasTranslation" slot="translation" class="translationSlot">
          <dx-text-box [(value)]="productTranslation.remarks"
                       [readOnly]="readOnly('productTranslation')"
                       [showClearButton]="true"
                       (onValueChanged)="translationUpdate(model.remarks,$event.value)">
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--ManufacturerAid-->
    <div *ngIf="!isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.manufacturerAid?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Herstellerartikelnummer' | translate }}"
                       field="MANUFACTURER_PID"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.manufacturerAid"
                       [readOnly]="readOnlyForLinkedFields('MANUFACTURER_PID') || readOnly('productManuAid')"
                       [showClearButton]="!readOnlyForLinkedFields('MANUFACTURER_PID')"
                       (onValueChanged)="update($event, 'MANUFACTURER_PID')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('MANUFACTURER_PID')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!--ManufacturerName-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.manufacturerName?.length > 0"
                       [showTranslationPlaceLabel]="productTranslation?.manufacturerName?.length > 0"
                       [suppressTranslation]="false"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Herstellername' | translate }}"
                       field="MANUFACTURER_NAME"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.manufacturerName"
                       [readOnly]="readOnlyForLinkedFields('MANUFACTURER_NAME') || readOnly('productManuName')"
                       [showClearButton]="!readOnlyForLinkedFields('MANUFACTURER_NAME')"
                       (onValueChanged)="update($event, 'MANUFACTURER_NAME')"
                       class="validationBlue">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('MANUFACTURER_NAME')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

        <div *ngIf="hasTranslation" slot="translation" class="translationSlot">
          <dx-text-box [(value)]="productTranslation.manufacturerName"
                       [readOnly]="readOnly('productManuName')"
                       [showClearButton]="true"
                       (onValueChanged)="translationUpdate(model.manufacturerName,$event.value)"
                       class="validationBlue">
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>



    <!--EDENumbers-->
    <div *ngIf="hasAddonEDENUMBERS" class="dx-fieldset">

      <dx-accordion [dataSource]="edeNumbersDummy"
                    [collapsible]="true"
                    [multiple]="false"
                    [animationDuration]="300">

        <div *dxTemplate="let t of 'title'" class="dx-fieldset-header">
          {{ 'EDE Nummern' | translate }}
        </div>

        <div *dxTemplate="let i of 'item'">

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.edeNumber1?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'EDE Nummer' | translate }} 1"
                             field="EDE_NUMBER_1"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.edeNumber1"
                             [readOnly]="readOnlyForLinkedFields('EDE_NUMBER_1') || readOnly('productEDENumber1')"
                             [showClearButton]="!readOnlyForLinkedFields('EDE_NUMBER_1')"
                             (onValueChanged)="update($event, 'EDE_NUMBER_1')">
                  <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('EDE_NUMBER_1')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.edeNumber2?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'EDE Nummer' | translate }} 2"
                             field="EDE_NUMBER_2"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.edeNumber2"
                             [readOnly]="readOnlyForLinkedFields('EDE_NUMBER_2') || readOnly('productEDENumber2')"
                             [showClearButton]="!readOnlyForLinkedFields('EDE_NUMBER_2')"
                             (onValueChanged)="update($event, 'EDE_NUMBER_2')">
                  <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('EDE_NUMBER_2')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.edeNumber3?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'EDE Nummer' | translate }} 3"
                             field="EDE_NUMBER_3"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.edeNumber3"
                             [readOnly]="readOnlyForLinkedFields('EDE_NUMBER_3') || readOnly('productEDENumber3')"
                             [showClearButton]="!readOnlyForLinkedFields('EDE_NUMBER_3')"
                             (onValueChanged)="update($event, 'EDE_NUMBER_3')">
                  <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('EDE_NUMBER_3')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.edeNumber4?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'EDE Nummer' | translate }} 4"
                             field="EDE_NUMBER_4"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.edeNumber4"
                             [readOnly]="readOnlyForLinkedFields('EDE_NUMBER_4') || readOnly('productEDENumber4')"
                             [showClearButton]="!readOnlyForLinkedFields('EDE_NUMBER_4')"
                             (onValueChanged)="update($event, 'EDE_NUMBER_4')">
                  <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('EDE_NUMBER_4')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

          <div class="np-field">
            <np-inputControl [(model)]="model"
                             [(productTranslation)]="productTranslation"
                             [showLinkButtons]="showLinkButtons"
                             [showPlaceLabel]="model?.edeNumber5?.length > 0"
                             [(selectedElements)]="selectedElements"
                             (elementSelected)="elementSelectFunction($event)"
                             (onValueChanged)="update($event)"
                             labelText="{{ 'EDE Nummer' | translate }} 5"
                             field="EDE_NUMBER_5"
                             [showDragHandle]="showDragHandle"
                             [dragFilter]="dragFilter"
                             (excelElementDropped)="excelElementDrop($event)">

              <div slot="content" class="contentSlot">
                <dx-text-box [(value)]="model.edeNumber5"
                             [readOnly]="readOnlyForLinkedFields('EDE_NUMBER_5') || readOnly('productEDENumber5')"
                             [showClearButton]="!readOnlyForLinkedFields('EDE_NUMBER_5')"
                             (onValueChanged)="update($event, 'EDE_NUMBER_5')">
                  <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                    <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('EDE_NUMBER_5')"></dxi-validation-rule>
                  </dx-validator>
                </dx-text-box>
              </div>

            </np-inputControl>
          </div>

        </div>

      </dx-accordion>

    </div>



    <!--Keywords-->
    <div *ngIf="!isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.keywords?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Suchbegriffe' | translate }}"
                       field="KEYWORDS"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-tag-box [(value)]="model.keywords"
                      placeholder=""
                      [readOnly]="readOnlyForLinkedFields('KEYWORDS') || readOnly('productKeywords')"
                      (onValueChanged)="update($event, 'KEYWORDS')"
                      [acceptCustomValue]="true"
                      noDataText=""
                      class="validationOrange">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('KEYWORDS')"></dxi-validation-rule>
            </dx-validator>
          </dx-tag-box>
        </div>

      </np-inputControl>
    </div>

    <!--Order-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.productOrder != null"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Sortiernummer' | translate }}"
                       field="PRODUCT_ORDER"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="model.productOrder"
                         [min]="0"
                         placeholder=""
                         [showSpinButtons]="!readOnlyForLinkedFields('PRODUCT_ORDER')"
                         [showClearButton]="!readOnlyForLinkedFields('PRODUCT_ORDER')"
                         [readOnly]="readOnlyForLinkedFields('PRODUCT_ORDER')|| readOnly('productSortnumber')"
                         (onValueChanged)="update($event, 'PRODUCT_ORDER') ">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('PRODUCT_ORDER')"></dxi-validation-rule>
            </dx-validator>
          </dx-number-box>
        </div>

      </np-inputControl>
    </div>

    <!--PIM Status-->
    <div class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.states?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Produkt Status' | translate }}"
                       field="STATUS"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-tag-box [(value)]="model.states"
                      [items]="productStates"
                      (onValueChanged)="updateState($event, 'STATUS')"
                      [acceptCustomValue]="true"
                      [readOnly]="readOnlyForLinkedFields('STATUS') || readOnly('productState')"
                      noDataText=""
                      placeholder="">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('STATUS')"></dxi-validation-rule>
            </dx-validator>
          </dx-tag-box>
        </div>

      </np-inputControl>
    </div>

    <!-- ERP_GROUP_Buyer-->
    <div *ngIf="hasAddonPRODUCT_DETAILS && !isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.erpGroupBuyer?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'ERP Gruppe Käufer' | translate }}"
                       field="ERP_GROUP_BUYER"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.erpGroupBuyer"
                       [readOnly]="readOnlyForLinkedFields('ERP_GROUP_BUYER') || readOnly('productEDEGroupBuyer')"
                       [showClearButton]="!readOnlyForLinkedFields('ERP_GROUP_BUYER')"
                       (onValueChanged)="update($event, 'ERP_GROUP_BUYER')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('ERP_GROUP_BUYER')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!-- ERP_GROUP_Supplier-->
    <div *ngIf="hasAddonPRODUCT_DETAILS && !isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.erpGroupSupplier?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'ERP Gruppe Verkäufer' | translate }}"
                       field="ERP_GROUP_SUPPLIER"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.erpGroupSupplier"
                       [readOnly]="readOnlyForLinkedFields('ERP_GROUP_SUPPLIER') || readOnly('productEDEGroupSupplier')"
                       [showClearButton]="!readOnlyForLinkedFields('ERP_GROUP_SUPPLIER')"
                       (onValueChanged)="update($event, 'ERP_GROUP_SUPPLIER')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('ERP_GROUP_SUPPLIER')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!-- BUYER_AID -->
    <div *ngIf="hasAddonPRODUCT_DETAILS && !isPrint" class="np-field">
      <np-inputControl [(model)]="model"
                       [(productTranslation)]="productTranslation"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="model?.buyerAid?.length > 0"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="elementSelectFunction($event)"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Käufer Artikelnummer' | translate }}"
                       field="BUYER_AID"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="model.buyerAid"
                       [readOnly]="readOnlyForLinkedFields('BUYER_AID') || readOnly('productBuyerAid')"
                       [showClearButton]="!readOnlyForLinkedFields('BUYER_AID')"
                       (onValueChanged)="update($event, 'BUYER_AID')">
            <dx-validator *ngIf="!templateView" validationGroup="MAIN">
              <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('BUYER_AID')"></dxi-validation-rule>
            </dx-validator>
          </dx-text-box>
        </div>

      </np-inputControl>
    </div>

    <!-- IS_DISCONTINUED und DISCONTINUED_DATE -->
    <!-- *ngIf="!templateView"  -->
    <div class="d-flex flex-row">

      <div class="np-field w-75">
        <np-inputControl [(model)]="model"
                         [(productTranslation)]="productTranslation"
                         [showLinkButtons]="showLinkButtons"
                         [suppressPlaceLabel]="true"
                         [suppressContentWidth]="templateView"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         labelText="{{ 'Auslaufprodukt' | translate }}"
                         field="IS_DISCONTINUED"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <!-- (valueChange)="update($event, 'IS_DISCONTINUED', '-1', '-1')" -->
          <div slot="content" class="contentSlot np-others" style="width: 300px; height: 49px; padding-top: 12px; padding-left: 10px;">
            <np-booleanRadioGroup [(value)]="model.isDiscontinued"
                                  [templateView]="templateView"
                                  [fieldName]="validationService.fieldNames.isDiscontinued"
                                  [readOnly]="readOnlyForLinkedFields('IS_DISCONTINUED') || readOnly('productDiscontinued')"
                                  (onValueChanged)="update($event, 'IS_DISCONTINUED')"
                                  validatorValidationGroup="MAIN">
            </np-booleanRadioGroup>
          </div>

        </np-inputControl>
      </div>

      <div *ngIf="model.isDiscontinued == true" class="np-field w-25 pl-3">
        <np-inputControl [(model)]="model"
                         [(productTranslation)]="productTranslation"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="model?.discontinuedDate != null"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="elementSelectFunction($event)"
                         (onValueChanged)="update($event)"
                         field="DISCONTINUED_DATE"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-date-box id="date"
                         [(value)]="model.discontinuedDate"
                         displayFormat="dd.MM.yyyy"
                         type="date"
                         valueChangeEvent="focusout"
                         [showClearButton]="!readOnlyForLinkedFields('DISCONTINUED_DATE')"
                         [readOnly]="readOnlyForLinkedFields('DISCONTINUED_DATE') || readOnly('productDiscontinuedDate')"
                         (onValueChanged)="update($event, 'DISCONTINUED_DATE')">
              <dx-validator *ngIf="!templateView" validationGroup="MAIN">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('DISCONTINUED_DATE')"></dxi-validation-rule>
              </dx-validator>
            </dx-date-box>
          </div>

        </np-inputControl>
      </div>
      <div></div>		<!-- DUMMY; last DIV gets no margin -->
    </div>



  </dx-validation-group>
</div>

import { Feature } from "./Feature";
import { Functions } from '../../../Services/functions';

export class FeatureSystem {
  featureSystemTable: string;
  featureSystemList: string;
  private _validFeatureSystemId?: string;
  constructor(
    public order?: number,
    public referenceFeatureGroupId?: string,
    public referenceFeatureGroupId2?: string,
    public referenceFeatureSystemName?: string,
    public referenceFeatureGroupName?: string,
    validFeatureSystemId?: string,
    public oldValidFeatureSystemId?: string,
    public guid: string = Functions.newGuid(),
    public features: Feature[] = [],
    public customerId?: string,
    public updateLinkedFields: boolean = false
  ) {
    this._validFeatureSystemId = validFeatureSystemId;
    this.referenceFeatureSystemName = "";
}

 public get validFeatureSystemId(): string | undefined {
    return this._validFeatureSystemId;
  }


  public set validFeatureSystemId(newId: string | undefined) {
    if (this._validFeatureSystemId && newId != this._validFeatureSystemId) {
      this.oldValidFeatureSystemId = this._validFeatureSystemId;
    }
    this._validFeatureSystemId = newId;
  }

  toJSON(): any {
    return {
      ...this,
      validFeatureSystemId: this._validFeatureSystemId // Include private property in JSON
    };
  }
}

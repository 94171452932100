
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { lastValueFrom, Observable } from "rxjs";
import { ValidFeatureSystem } from "../Model/ValidFeatureSystem";
import { ValidFeature } from "../Model/ValidFeature";
import { ValidFeatureValue } from "../Model/ValidFeatureValue";
import { FeatureSystem } from "../Model/Catalog/FeatureModels/FeatureSystem";
import { custom } from "devextreme/ui/dialog";
import { Catalog } from "../Model/Catalog/catalog";
import { StringResponse } from "../Model/dto/StringResponse";
import { ValidFeatureUsageReport } from "../Model/ValidFeatureUsageReport";

@Injectable()
export class ValidFeatureService {
  constructor(private http: HttpClient) {

  }

  getValidIdForFeature(name: string, unit: string, validFeatureSystemId: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("customerId", customerId)
        .append("name", name)
        .append("unit", unit)
        .append("validFeatureSystemId", validFeatureSystemId)
    };
    return lastValueFrom(this.http.get<StringResponse>("api/ValidFeature/GetValidIdForFeature", options)).then(x => { return x.value });
  }

  getValidIdForSystemName(name: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("name", name)
    };
    return lastValueFrom(this.http.get<StringResponse>("api/ValidFeature/GetValidIdForSystemName", options)).then(x => { return x.value });
  }

  getValidFeatureUnitForId(id: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("customerId", customerId)
        .append("id", id)                                      
    };
    return lastValueFrom(this.http.get<StringResponse>("api/ValidFeature/getValidFeatureUnitForId", options)).then(x => { return x.value });
  }

  getValidFeatureNameForId(id: string,  customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams()
        .append("customerId", customerId)
        .append("id", id)                                     
    };
    return lastValueFrom(this.http.get<StringResponse>("api/ValidFeature/getValidFeatureNameForId", options)).then(x => { return x.value });
  }

  getValidSystemNameForId(id: string, customerId: string): Promise<string> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("id", id)
    };
    return lastValueFrom(this.http.get<StringResponse>("api/ValidFeature/GetValidSystemNameForId", options)).then(x => { return x.value });
  }

  public getSystems(customerId: string): Observable<ValidFeatureSystem[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    };
    return (this.http.get<ValidFeatureSystem[]>("api/ValidFeature/GetSystems", options)) as any;

  }

  public writeSystem(vfs: ValidFeatureSystem): Observable<ValidFeatureSystem> {

    return (this.http.post("api/ValidFeature/AddSystem", vfs)) as any;

  }

  public getFeatures(validFeatureSystemId : string, customerId: string): Observable<ValidFeature[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("validFeatureSystemId", validFeatureSystemId)
    };
    return (this.http.get<ValidFeature[]>("api/ValidFeature/GetFeatures", options)) as any;

  }

  public setValidIds(customerId: string, featureSystem: FeatureSystem): Observable<FeatureSystem> {
    featureSystem.customerId = customerId;

    return (this.http.post("api/ValidFeature/SetValidIds", featureSystem)) as any;
  }

  public getValues(validFeatureId: string, customerId: string): Observable<ValidFeatureValue[]> {

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("validFeatureId", validFeatureId)
    };
    return (this.http.get<ValidFeatureValue[]>("api/ValidFeature/GetValues", options)) as any;

  }

  public getCatalogSystems(catalogId: string, customerId: string): Observable<ValidFeatureSystem[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    };
    return (this.http.get<ValidFeatureSystem[]>("api/ValidFeature/GetCatalogSystems", options)) as any;
  }

  public getCatalogFeatures(catalogId: string, customerId: string): Observable<ValidFeature[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    };
    return (this.http.get<ValidFeature[]>("api/ValidFeature/GetCatalogFeatures", options)) as any;
  }

  public getCatalogFeaturesOfSystem(catalogId: string, customerId: string, validFeatureSystem: ValidFeatureSystem): Observable<ValidFeature[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    };
    return (this.http.post<ValidFeature[]>("api/ValidFeature/GetCatalogFeaturesOfSystem", validFeatureSystem, options)) as any;
  }

  updateValidFeature(data: ValidFeature, customerId: string): Promise<object>{
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    };
    return lastValueFrom(this.http.post("api/ValidFeature/UpdateValidFeature", data, options));
  }
  public getUsageReport(customerId: string, validFeatureSystemId: string): Observable<ValidFeatureUsageReport[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("validFeatureSystemId", validFeatureSystemId)
    };
    return (this.http.get<ValidFeatureUsageReport[]>("api/ValidFeature/GetUsageReport", options)) as any;
  }


}

<div class="d-flex flex-row">
  <div class="flex-fill pr-3 w-50">
    <div class="dx-fieldset" *ngIf="model">
      <div class="dx-fieldset-header">{{ 'Ihr Ansprechpartner' | translate }}</div>
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Ansprechpartner' | translate }}</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="model.contactName" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
        </div>
      </div>

      <div class="dx-field">
        <div class="dx-field-label">{{ 'Email' | translate }}</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="model.contactEmail" [showClearButton]="true" (onValueChanged)="update()">
            <dx-validator>

              <dxi-validation-rule type="email"
                                   message="{{ 'Bitte geben Sie eine gültige Email-Adresse ein' | translate }}"></dxi-validation-rule>

            </dx-validator>
          </dx-text-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Telefon' | translate }}</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="model.contactPhone" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Domain' | translate }}</div>
        <div class="dx-field-value">
          <dx-text-box [(value)]="model.domain" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
        </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Datenqualitäts-Set' | translate }}</div>
        <div class="dx-field-value">
          <div class="d-flex flex-row">
            <dx-select-box [items]="shareableValidationMethods"
                           displayExpr="name"
                           valueExpr="id"
                           [(value)]="model.sharedValidationMethodId"
                           (onValueChanged)="update()">
            </dx-select-box>
          </div>
        </div>
      </div>
      <div class="dx-field">
        <div id="demoCatalogChooser" class="dx-field-label">{{ 'Demo-Katalog' | translate }}</div>
        <dx-tooltip target="#demoCatalogChooser" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
          <div *dxTemplate="let data = data; of: 'content'">{{ 'dataSharingDemoCatalogTooltip' | translate }}</div>
        </dx-tooltip>
        <div class="dx-field-value">
          <div class="d-flex flex-row">
            <dx-select-box [items]="shareableCatalogs"
                            displayExpr="catalogName"
                            valueExpr="catalogId"
                            [(value)]="model.sharedCatalogId"
                            (onValueChanged)="update()"
                            [searchEnabled]="true">
            </dx-select-box>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="flex-fill pl-3 w-50">
    <div class="dx-fieldset">
      <div class="dx-fieldset-header">{{ 'Ihr Logo' | translate }}</div>
      <div style="height: 150px">
        <span *ngIf="!model.logoUrl" class="glyphicon glyphicon-picture" style="font-size: 100px;"></span>
        <img *ngIf="model.logoUrl" src="{{model.logoUrl}}" style="height: 100%;" />
      </div>
      <div>
        <dx-file-uploader uploadUrl="api/Import/ImportCustomerLogo"
                          [uploadHeaders]="uploadHeaders"
                          [multiple]="false"
                          accept="*"
                          (onUploaded)="onUploaded($event)"
                          (onBeforeSend)="onBeforeSendUpload($event)"
                          [(value)]="value"
                          uploadMode="instantly">
        </dx-file-uploader>
        <b>{{ msg }}</b>
      </div>
    </div>
  </div>
</div>
<div class="d-flex flex-row">
  <div class="flex-fill pr-3 w-50">
    <div class="dx-fieldset" *ngIf="model">
      <div class="dx-field">
        <div class="dx-field-label">{{ 'Registrierungslink' | translate }}</div>
        <div class="dx-field-value">
          <div class="d-flex flex-row w-100">
            <dx-text-box style="width: -webkit-fill-available;"
                         [value]="generatedRegistrationLink"
                         [readOnly]="true">
              <dxi-button location="after"
                          name="generateRegistrationLinkButton"
                          [options]="generateRegistrationLinkButtonOptions">
              </dxi-button>
            </dx-text-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>






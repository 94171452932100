import { Component } from "@angular/core";
import { ModelService } from "../../Services/model.service";
import { TranslateService } from '@ngx-translate/core';
import { DataQualityFacts } from '../../Model/Catalog/DataQualityFacts';
import { UserManagementService } from "../../Services/userManagment.service";


@Component({
  selector: "dataQualityExample",
  templateUrl: "dataQualityExample.component.html",
  styleUrls: []
})
export class DataQualityExampleComponent {

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    if (this.modelService.catalogService.catalog.dataQualityFacts == null) {
      this.model  = new DataQualityFacts();
    }
  }

  get model(): DataQualityFacts {
    return this.modelService.catalogService.catalog.dataQualityFacts;
  }

  set model(value: DataQualityFacts) {
    this.modelService.catalogService.catalog.dataQualityFacts = value;
  }

  catalogChanged(e) {
    this.modelService.catalogService.catalogChanged(e);
  }

  catalogUpdate(e = null) {
    this.modelService.catalogService.updateCatalog(e);
  }

  v: boolean[] = [false, false, false, false, false, false, false, false, false, false, false, false, false];

  ttt(id) {
    if (this.modelService.loginService.showToolTips) {
      if (!this.v[id]) {
        this.v = [false, false, false, false, false, false, false, false, false, false, false, false, false];
      }
      this.v[id] = !this.v[id];
    }
  }

  get showToolTips(): boolean {
    return this.modelService.loginService.showToolTips;
  }

  get showProToolTips(): boolean {
    return this.modelService.loginService.showProToolTips;
  }

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }

}

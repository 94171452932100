<dx-validation-group id="UDX">
  <dx-data-grid (onToolbarPreparing)="onToolbarPreparing($event)"
                [dataSource]="model"
                [remoteOperations]="false"
                [allowColumnReordering]="true"
                [rowAlternationEnabled]="true"
                [allowColumnResizing]="true"
                noDataText="{{ 'keine UDX Felder' | translate }}"
                [showBorders]="true"
                (onRowUpdated)="update($event)"
                (onRowRemoving)="onRowRemoving($event)"
                (onRowRemoved)="delete($event)"
                [customizeColumns]="customizeColumns">
    <div *dxTemplate="let toolbaritem of 'addButtonTemplate'">
      <dx-button *ngIf="canView('udxAdd')" stylingMode="text" type="default" text="{{ 'Neues UDX' | translate }}" (onClick)="addUDX()"></dx-button>
    </div>

    <dxo-editing mode="cell" [allowUpdating]="!readOnlyUser('udxDelete')" [allowDeleting]="!readOnlyUser('udxDelete')" [useIcons]="true">
      <dxo-texts confirmDeleteMessage="" confirmDeleteTitle=""></dxo-texts>
    </dxo-editing>

    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="UdxStateNewUIV2"></dxo-state-storing>

    <dxi-column dataField="category" caption="{{ 'Gruppe' | translate }}" [allowEditing]="true" cellTemplate="categoryTemplate" editCellTemplate="categoryTemplate"></dxi-column>
    <div *dxTemplate="let data of 'categoryTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="product"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.category?.length > 0"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.order.toString())"
                         (onValueChanged)="update($event)"
                         field="UDX_CATEGORY"
                         [system]="data.data.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="data.data.category"
                         [showClearButton]="!readOnlyForLinkedFields('UDX_CATEGORY', data.data.order.toString())"
                         [readOnly]="readOnlyForLinkedFields('UDX_CATEGORY', data.data.order.toString()) || readOnlyUser('udxCategory')"
                         (onValueChanged)="update($event, 'UDX_CATEGORY', data.data.order.toString())">
              <dx-validator *ngIf="!templateView && product" validationGroup="UDX">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('UDX_CATEGORY', data.data.order.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>

        </np-inputControl>
      </div>

    </div>



    <dxi-column dataField="name" caption="{{ 'Name' | translate }}" [allowEditing]="true" cellTemplate="nameTemplate" editCellTemplate="nameTemplate"></dxi-column>
    <div *dxTemplate="let data of 'nameTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="product"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.name?.length > 0"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.order.toString())"
                         (onValueChanged)="update($event)"
                         field="UDX_NAME"
                         [system]="data.data.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="data.data.name"
                         [showClearButton]="!readOnlyForLinkedFields('UDX_NAME', data.data.order.toString())"
                         [readOnly]="readOnlyForLinkedFields('UDX_NAME', data.data.order.toString()) || readOnlyUser('udxName')"
                         (onValueChanged)="update($event, 'UDX_NAME', data.data.order.toString())">
              <dx-validator *ngIf="!templateView && product" validationGroup="UDX">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('UDX_NAME', data.data.order.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>

        </np-inputControl>
      </div>

    </div>



    <dxi-column dataField="value" caption="{{ 'Wert' | translate }}" [allowEditing]="true" cellTemplate="valueTemplate" editCellTemplate="valueTemplate"></dxi-column>
    <div *dxTemplate="let data of 'valueTemplate'" class="d-flex flex-row">

      <div class="np-field w-100">
        <np-inputControl [(model)]="product"
                         [showLinkButtons]="showLinkButtons"
                         [showPlaceLabel]="data?.data?.value?.length > 0"
                         [suppressLabel]="true"
                         [(selectedElements)]="selectedElements"
                         (elementSelected)="templateSelect($event, data.data.order.toString())"
                         (onValueChanged)="update($event)"
                         field="UDX_VALUE"
                         [system]="data.data.order.toString()"
                         [showDragHandle]="showDragHandle"
                         [dragFilter]="dragFilter"
                         (excelElementDropped)="excelElementDrop($event)">

          <div slot="content" class="contentSlot">
            <dx-text-box [(value)]="data.data.value"
                         [showClearButton]="!readOnlyForLinkedFields('UDX_VALUE', data.data.order.toString())"
                         [readOnly]="readOnlyForLinkedFields('UDX_VALUE', data.data.order.toString()) || readOnlyUser('udxValue')"
                         (onValueChanged)="update($event, 'UDX_VALUE', data.data.order.toString())">
              <dx-validator *ngIf="!templateView && product" validationGroup="UDX">
                <dxi-validation-rule type="async" [validationCallback]="validationService.validateField('UDX_VALUE', data.data.order.toString())"></dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>

        </np-inputControl>
      </div>

    </div>

  </dx-data-grid>
</dx-validation-group>

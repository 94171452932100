export class DataQualityFacts {

  constructor(
    public access?: string,
    public assortmentSize?: number,
    public customerSpezificPrice?: boolean,
    public email?: string,
    public format?: string,
    public pidForPackingSize?: boolean,
    public position?: string,
    public processingPeriod?: string,
    public productCount?: number,
    public receiveDate?: Date,
    public requestDate?: Date,
    public supplierName?: string,
    public waitingPeriod?: string
  ) {
  }
}

       
      
     
       
       
    

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExportParameter } from '../../../../../Model/exportParameter/ExportParameter';
import { ModelService } from '../../../../../Services/model.service';

@Component({
  selector: 'exportConfiguration',
  templateUrl: './exportConfiguration.component.html',
  styleUrls: ['./exportConfiguration.component.css']
})
export class ExportConfigurationComponent implements OnInit {
  @Input() exportParameter: ExportParameter;

  bmeDialects = ['NexMart', 'E/D/E Multishop', 'E/D/E Multishop no root', 'Onventis', 'Scireum', 'HUBWOO'];
  imageTypes = ['jpg', 'png'];

  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.bmeDialectChanged = this.bmeDialectChanged.bind(this);
  }

  ngOnInit(): void {}

  public bmeDialectChanged() {
    if (this.exportParameter.bmeDialect == 'Scireum') {
      this.exportParameter.useMediaUrl = true;
    }
  }
}

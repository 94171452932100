import { CatalogFilter } from './CatalogFilter';
import { SearchByListParameter } from '../Dto/SearchByListParameter';
import { TranslateService } from '@ngx-translate/core';
import { FieldListInterface } from '../ui/FieldListInterface';

export class ExportParameter implements FieldListInterface {
  public id: string;
  public name: string;
  public isDefault: boolean;
  public catalogId: string;
  public customerId: string;
  public includeUdxs: boolean;
  public format: string;
  public version: string;
  public orignialSize: boolean = true;
  public maxResolution: number;
  public imageType: string;
  public imageSquared: boolean;
  public imageBgColor: string;
  public ignoreUdxFields: boolean;
  public ignoreEmptyCategories: boolean;
  public includeMime: boolean;
  public includeLegal: boolean;
  public onlyMasters: boolean;
  public onlyVariants: boolean;
  public includeHazmatClassesInDescription: boolean;
  public isBackup: boolean;
  public keepRootCategory: boolean;
  public maxMediaSize: number;
  public isLive: boolean;
  public bmeDialect: string;
  public useMediaUrl: boolean;
  public generateNMVariants: boolean;
  public useClassification: boolean;
  public featureSystem: string;
  public generateLinks: boolean;
  public searchByListFilterAdds: boolean;
  public templateId: string;
  public fields: string[];
  public fieldsToMappingIds: { [field: string]: string };
  public dateFilter: CatalogFilter<Date | null>;
  public expiredDateFilter: CatalogFilter<Date | null>;
  public categoryFilter: CatalogFilter<string[]>;
  public featureSystemFilter: CatalogFilter<string[]>;
  public productStatesFilter: CatalogFilter<string[]>;
  public udxsFilter: CatalogFilter<string[]>;
  public manufacturerFilter: CatalogFilter<string[]>;
  public supplierFilter: CatalogFilter<string[]>;
  public featureSystemContentFilter: CatalogFilter<string[]>;
  public udxsContentFilter: CatalogFilter<string[]>;
  public priceListsContentFilter: CatalogFilter<string[]>;
  public searchByListParameter: CatalogFilter<SearchByListParameter>;
  public useFeatureOrder: boolean;
  public useTextQualifier: boolean;
  public delimiter: string;
  public textQualifier: string;
  public isUpdateFilter: boolean;
  public exportMasterOfFilteredChilds: boolean;

  constructor() {
    this.format = 'bme';
    this.includeUdxs = true;
    this.includeMime = false;
    this.useFeatureOrder = false;
    this.categoryFilter = new CatalogFilter([]);
    this.productStatesFilter = new CatalogFilter([]);
    this.udxsFilter = new CatalogFilter([]);
    this.productStatesFilter = new CatalogFilter([]);
    this.manufacturerFilter = new CatalogFilter([]);
    this.supplierFilter = new CatalogFilter([]);
    this.dateFilter = new CatalogFilter(null);
    this.searchByListParameter = new CatalogFilter(new SearchByListParameter());
    this.featureSystemFilter = new CatalogFilter([]);
    this.featureSystemContentFilter = new CatalogFilter([]);
    this.priceListsContentFilter = new CatalogFilter([]);
    this.udxsContentFilter = new CatalogFilter([]);
    this.fields = ['SUPPLIER_PID'];
    this.delimiter = ';';
    this.textQualifier = "'";
    this.isUpdateFilter = false;
    this.exportMasterOfFilteredChilds = true;
  }

  getSummary(translate: TranslateService): string {
    let summary = '';
    if (this.categoryFilter && this.categoryFilter.useFilter) {
      summary += translate.instant('Kateogiefilter aktiviert;');
    }
    if (this.productStatesFilter && this.productStatesFilter.useFilter) {
      summary += translate.instant('Produktstatus-Filter aktiviert;');
    }
    if (this.udxsFilter && this.udxsFilter.useFilter) {
      summary += translate.instant('UDX-Filter aktiviert;');
    }
    if (this.manufacturerFilter && this.manufacturerFilter.useFilter) {
      summary += translate.instant('Herstellerfilter aktiviert;');
    }
    if (this.supplierFilter && this.supplierFilter.useFilter) {
      summary += translate.instant('Lieferantenfilter aktiviert;');
    }
    if (this.dateFilter && this.dateFilter.useFilter) {
      summary += translate.instant('Filter Änderungsdatum aktiv;');
    }
    if (this.searchByListParameter && this.searchByListParameter.useFilter) {
      summary += translate.instant('Listenfilter aktiv;');
    }
    if (this.featureSystemContentFilter && this.featureSystemContentFilter.useFilter) {
      summary += translate.instant('Inhalt FeatureSysteme eingeschränkt;');
    }
    if (this.priceListsContentFilter && this.priceListsContentFilter.useFilter) {
      summary += translate.instant('Inhalt Preise eingeschränkt;');
    }
    if (!this.includeUdxs) {
      summary += translate.instant('UDX-Inhalte deaktiviert;');
    }
    if (this.includeUdxs && this.udxsContentFilter && this.udxsContentFilter.useFilter) {
      summary += translate.instant('UDX-Inhalte eingeschränkt;');
    }
    if (this.fieldsToMappingIds && Object.entries(this.fieldsToMappingIds).length > 0) {
      summary += translate.instant('Mappings aktiviert;');
    }
    if (summary == '') return translate.instant('Standard');

    return summary;
  }

  isDifferent(otherConfig: ExportParameter): boolean {
    return (
      otherConfig.categoryFilter.useFilter != this.categoryFilter.useFilter ||
      otherConfig.productStatesFilter.useFilter != this.productStatesFilter.useFilter ||
      otherConfig.udxsFilter.useFilter != this.udxsFilter.useFilter ||
      otherConfig.manufacturerFilter.useFilter != this.manufacturerFilter.useFilter ||
      otherConfig.supplierFilter.useFilter != this.supplierFilter.useFilter ||
      otherConfig.dateFilter.useFilter != this.dateFilter.useFilter ||
      otherConfig.searchByListParameter.useFilter != this.searchByListParameter.useFilter ||
      otherConfig.featureSystemContentFilter.useFilter != this.featureSystemContentFilter.useFilter ||
      otherConfig.priceListsContentFilter.useFilter != this.priceListsContentFilter.useFilter ||
      otherConfig.includeUdxs != this.includeUdxs ||
      otherConfig.udxsContentFilter.useFilter != this.udxsContentFilter.useFilter ||
      this.isDifferentFieldsToMappingsIds(otherConfig)
    );
  }

  isDifferentFieldsToMappingsIds(otherConfig: ExportParameter): boolean {
    if (!otherConfig.fieldsToMappingIds || !this.fieldsToMappingIds) return false;
    if (Object.entries(otherConfig.fieldsToMappingIds).length != Object.entries(this.fieldsToMappingIds).length)
      return true;
    return false;
  }
}

import { Product } from '../catalog/product';
import { DataQualityFacts } from '../catalog/DataQualityFacts';


export class ValidationRequestCatalog {

  constructor(
    public field?: string,
    public value?: string,
    public customerId?: string,
    public catalogId?: string,
    public isActivId?: boolean

  ) {

  }
}

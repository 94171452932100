import { Component, ViewChild, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DxTreeViewComponent, DxSelectBoxComponent } from "devextreme-angular";
import { custom } from "devextreme/ui/dialog";
import { lastValueFrom, Observable, Subject } from "rxjs";
import { TemplateItemTemplate } from "../../../../../Model/Catalog/TemplateItemTemplate";
import { CatalogFilter } from "../../../../../Model/exportParameter/CatalogFilter";
import { ExportFormatWithCustomers } from "../../../../../Model/exportParameter/ExportFormatWithCustomers";
import { ExportFormatWithVersions } from "../../../../../Model/exportParameter/ExportFormatWithVersions";
import { ExportParameter } from "../../../../../Model/exportParameter/ExportParameter";
import { Message } from "../../../../../Model/System/Message";
import { Customer } from "../../../../../Model/User/Customer";
import { CatalogService } from "../../../../../Services/CatalogManagement/catalog.service";
import { ExportConfigurationService } from "../../../../../Services/exportConfiguration.service";
import { JobService } from "../../../../../Services/job.service";
import { LoginService } from "../../../../../Services/login.service";
import { ModelService } from "../../../../../Services/model.service";
import { TemplateService } from "../../../../../Services/template.service";
import { UpdateTemplateService } from "../../../../../Services/updateTemplate.service";
import { WawiListService } from "../../../../../Services/wawiList.service";

@Component({
  selector: 'exportParameter',
  templateUrl: 'exportParameter.component.html',
  styleUrls: ['exportParameter.component.css']
})
export class ExportParameterComponent implements OnInit {
  @Input() catalogId: string;
  @Input() onlyUpdateFilter: boolean = false;

  _config: ExportParameter;
  @Input() set config(value) {
    this._config = value;
    this.initConfig();
  }

  get config() {
    return this._config;
  }

  interfaceWithConfigs = ['INTERSHOP', 'ODOO', 'SHOPWARE5', 'SHOPWARE6', 'ELECTRONIC_SALES', 'OSG'];
  contentIndex = 0;
  filterIndex = 0;
  onTemplateChange: EventEmitter<string> = new EventEmitter<string>();
  exportFormats: string[] = [];
  exportVersions: string[] = [];
  exportToCustomers: Customer[] = [];
  wawiMappingFields: TemplateItemTemplate[] = [];
  fieldGroups: string[] = [];
  private exportFormatWithVersions: ExportFormatWithVersions[] = [];
  private exportFormatWithCustomers: ExportFormatWithCustomers;
  private prevConfig: ExportParameter;

  constructor(
    public loginService: LoginService,
    public templateService: TemplateService,
    public translate: TranslateService,
    public exportConfigurationService: ExportConfigurationService,
    public jobService: JobService,
    public catalogService: CatalogService,
    public updateTemplateService: UpdateTemplateService,
    public wawiListService: WawiListService
  ) {
    if (loginService.currentUser.customerId == null)
      loginService.currentUser.customerId = loginService.currentCustomer.id;
  }

  async ngOnInit() {
    this.catalogService.loadFields();
    this.fieldGroups = this.updateTemplateService.GetFieldGroups();
    await this.initExportFormats();
    this.setExportVersions();
    this.initConfig();
    this.wawiMappingFields = await lastValueFrom(this.wawiListService.getWawiMappingFields(this.loginService.currentUser.customerId));
  }

  async initExportFormats() {
    this.exportFormatWithVersions = await lastValueFrom(this.exportConfigurationService.getExportFormatWithVersions(this.loginService.currentUser.customerId));
    this.exportFormats = this.exportFormatWithVersions.map(x => x.exportFormat);
    this.exportFormatWithCustomers = await this.exportConfigurationService.getExportFormatForSendToCustomers(this.loginService.currentUser.customerId);
    if (this.exportFormatWithCustomers?.value && this.exportFormatWithCustomers?.value.length > 0) {
      this.exportFormats.push(this.exportFormatWithCustomers.key);
    }
    this.fixExportFormat();
  }

  initConfig() {
    if (this.prevConfig != null && this.prevConfig?.id == this._config.id) return;

    this.prevConfig = JSON.parse(JSON.stringify(this._config));
    this._config.customerId = this.loginService.currentUser.customerId;
    this._config.catalogId = this.catalogId;

    if (this._config.templateId != null) {
      this.templateService.getExportTemplates().then(() => {
        this.templateService.setCurrentExportTemplate(this._config.templateId);
      });
    } else {
      this.templateService.setCurrentExportTemplate(null);
    }
    this.onTemplateChange.emit();
  }

  getConfigSummary(): string {
    var summary = this.translate.instant('Es sind folgende Einstellungen gesetzt:');
    return summary + this._config.getSummary(this.translate);
  }

  getClass() {
    return this._config.isDifferent(this.prevConfig) ? 'orange' : '';
  }

  fixExportFormat() {
    if (!this.exportFormats.some((x) => x == this._config.format)) {
      this._config.format = this.exportFormats[0];
    }
  }

  setExportVersions() {
    this.exportVersions = this.exportFormatWithVersions.find(x => x.exportFormat == this.config.format)?.exportVersions;
    if (!this.exportVersions) {
      this.exportVersions = [];
      if (this.exportFormatWithCustomers.key == this.config.format) {
        this.exportToCustomers = this.exportFormatWithCustomers.value;
        this.fixExportToCustomers();
      }
    } else {
      this.exportToCustomers = [];
      this.fixExportVersion();
    }
  }

  fixExportVersion() {
    if (!this.exportVersions?.some((x) => x == this._config.version)) {
      this._config.version = this.exportVersions[0];
    }
  }

  fixExportToCustomers() {
    if (!this.exportToCustomers?.some(x => x == this._config.version)) {
      this._config.version = this.exportToCustomers[0]?.id;
    }
  }
}

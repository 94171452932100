<fullscreen-page>
  <content-column *ngIf="template" look="dark" helpSwitch="true">
    <div slot="content" class="h-100 p-2">
      <div class="d-flex m-3 gap-2">
        <dx-button
          *ngIf="catalogId"
          stylingMode="text"
          type="default"
          text="{{ 'Zurück zum Katalog' | translate }}"
          [routerLink]="['/catalog', customerId, catalogId]"
        ></dx-button>
        <dx-button
          *ngIf="!catalogId"
          stylingMode="text"
          type="default"
          text="{{ 'Zurück zur Template Liste' | translate }}"
          routerLink="/templates"
        ></dx-button>
        <dx-button
          text="{{ 'Worksheet hinzufügen' | translate }}"
          icon="add"
          type="default"
          (onClick)="onAddWorksheet($event)"
        ></dx-button>
      </div>
      <dx-tab-panel
        width="100%"
        height="calc(100% - 68px)"
        [dataSource]="worksheets"
        tabsPosition="top"
        stylingMode="secondary"
        (onTitleClick)="onSelectWorksheet($event)"
      >
        <div *dxTemplate="let data of 'title'; let index = index">
          <dx-text-box
            *ngIf="sheetNameEditorIndex == index"
            style="min-width: 215px"
            [(value)]="data.name"
            (onValueChanged)="onSaveWorksheetRename($event)"
          ></dx-text-box>
          <div
            *ngIf="sheetNameEditorIndex != index"
            style="min-width: 215px; padding: 17px 16px 14px; line-height: 24px; font-size: 16px; text-transform: none"
          >
            {{ data.name }}
            ({{ data.templateItems.length }})
          </div>
          <dx-button
            *ngIf="sheetNameEditorIndex == index"
            icon="close"
            [data-prevValue]="data.name"
            (onClick)="onCancelWorksheetRename(index)"
          ></dx-button>
          <dx-button
            *ngIf="sheetNameEditorIndex != index"
            icon="edit"
            (onClick)="sheetNameEditorIndex = index"
          ></dx-button>
          <dx-button
            *ngIf="sheetNameEditorIndex != index"
            icon="remove"
            (onClick)="onRemoveWorksheet(index)"
          ></dx-button>
        </div>

        <div
          [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column' }"
          *dxTemplate="let sheet of 'item'; let index = index"
        >
          <np-advanced-template-datagrid
            [ngStyle]="{ height: 'inherit', display: 'flex', 'flex-direction': 'column', 'height': '100%' }"
            [customerId]="customerId"
            [sheetIndex]="index"
            [template]="template"
            [wawiListMapping]="wawiListMapping"
            [languageFlags]="languageFlags"
            [warrantyClasses]="warrantyClasses"
            [hazmatClasses]="hazmatClasses"
            [validFeatureSystems]="validFeatureSystems"
            (onUpdate)="update($event)"
          ></np-advanced-template-datagrid>
        </div>
      </dx-tab-panel>
    </div>
  </content-column>
</fullscreen-page>

import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { BackgroundJob } from "../Model/Dto/BackgroundJob";
import { Message } from "../Model/System/Message";
import { JobService } from "../Services/job.service";
import { TranslateService } from '@ngx-translate/core';
import { BackgroundJobEvent } from "../Model/Dto/BackgroundJobEvent";
import { custom, confirm } from 'devextreme/ui/dialog';
import { RestartJob } from '../Model/Dto/RestartJob';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ValidateExportJob } from "../Model/Dto/ValidateExportJob";
import { TemplateService } from "../Services/template.service";
import { DxDataGridComponent } from "devextreme-angular";
import { OAuthService } from "angular-oauth2-oidc";
import { UserManagementService } from "../Services/userManagment.service";
import CustomStore from "devextreme/data/custom_store";
import { MaintenanceModeService } from "../Services/MaintenanceModeService";
import { MaintenanceMode } from "../Model/Dto/MaintenanceMode";
import { DateFilter } from "../Model/ui/DateFilter";

@Component({
  selector: "job-list",
  templateUrl: "./jobList.component.html",
  styleUrls: ["./jobList.component.css"]
})
export class JobListComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  @Input() jobListMode: boolean = true;
  @Input() catalogMode: boolean = false;
  @Input() adminMode: boolean = false;
  @Input() storeName: string = "JobListStateNewUI_V4";

  maintenanceModeActive: boolean = false;

  filterValue: Array<any>;
  startRefresherFirstTime: boolean = true;
  itemCount: any;
  dateFilterValue: number;

  dateFilterDataSource: DateFilter[];
  dataSource: CustomStore<any, any>;

  constructor(public modelService: ModelService, public templateService: TemplateService, public translate: TranslateService, public authService: OAuthService, public userManagementService: UserManagementService, public maintenanceModeService: MaintenanceModeService) {
    this.onExpandAll = this.onExpandAll.bind(this);
    this.onCollapseAll = this.onCollapseAll.bind(this);
    this.translateType = this.translateType.bind(this);

    this.onDetailInitialized = this.onDetailInitialized.bind(this);
    this.isVisible = this.isVisible.bind(this);
    this.startRefreshing = this.startRefreshing.bind(this);

    this.dateFilterDataSource = new Array<DateFilter>();
    this.dateFilterDataSource.push(new DateFilter("Heute", 0));
    this.dateFilterDataSource.push(new DateFilter("Letzten 7 Tage", -6));
    this.dateFilterDataSource.push(new DateFilter("Letzten 30 Tage", -29));
    this.dateFilterDataSource.push(new DateFilter("Alle", 1));
    this.dateFilterValue = this.dateFilterDataSource[1].dayDelta;
    
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.getCurrentState();
    }

    if (this.catalogMode) {
      this.setCatalogModeDataSource();
    }
    if (this.adminMode) {
      this.setAdminModeDataSource();
    }
    if (this.jobListMode) {
      this.setJobListModeDataSource();
    }

    this.dataSource.totalCount(null).then(count => this.itemCount = count);
  }

  ngOnDestroy(): void {
    this.jobListMode = false;
    this.catalogMode = false;
    this.adminMode = false;
  }

  get isAdmin() {
    return this.modelService.loginService.isAdmin;
  }

  setAdminModeDataSource() {
    var accessToken = this.authService.getAccessToken();
    this.dataSource = AspNetData.createStore({
      key: "id",
      loadUrl: "api/job/GetJobsForAdminMode",
      loadParams: { 'dateDelta': this.dateFilterValue },
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true },
          ajaxOptions.headers = {
            "Authorization": `Bearer ${accessToken}`
          };
      }
    });
  }

  setCatalogModeDataSource() {
    var catalogId = this.modelService.catalogService.selectedCatalogId;
    var accessToken = this.authService.getAccessToken();
    this.dataSource = AspNetData.createStore({
      key: "id",
      loadUrl: "api/job/GetJobsForCatalogMode",
      loadParams: { 'catalogId': catalogId, 'customerId': this.modelService.loginService.currentCustomer.id },
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true },
          ajaxOptions.headers = {
            "Authorization": `Bearer ${accessToken}`
          };
      }
    });
  }

  setJobListModeDataSource() {
    var accessToken = this.authService.getAccessToken();
    this.dataSource = AspNetData.createStore({
      key: "id",
      loadUrl: "api/job/GetJobsForJobListMode",
      onBeforeSend(method, ajaxOptions) {
        ajaxOptions.xhrFields = { withCredentials: true },
          ajaxOptions.headers = {
            "Authorization": `Bearer ${accessToken}`
          };
      }
    });
  }

  onGridToolbarPreparing(e) {
    // links:
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'filterReset'
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'filterPinned'
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'filterUser'
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'minGroupsButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'maxGroupsButtonTemplate',
    });
    // rechts:
    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'dateFilterTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'helpButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'maintenanceModeButtonTemplate'
    });

    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'deleteButtonTemplate'
    });
  }

  onExpandAll() {
    this.dataGrid.instance.expandAll();
  }

  onCollapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  onDateFilterValueChanged() {
    this.setAdminModeDataSource();
  }

  public translateType(cellInfo) {
    if (!cellInfo.value)
      return undefined;
    var result = this.translate.instant(cellInfo.value);
    return result;
  }

  startRefresher() {
    if (this.startRefresherFirstTime) {
      this.startRefresherFirstTime = false;
      this.dataGrid.instance.option('loadPanel.enabled', false);
      var delay = Math.max(2000, this.itemCount / 100);
      setTimeout(this.startRefreshing, delay, delay);
    }
  }

  startRefreshing(delay) {
    if (this.isVisible()) {

      if (this.isAdmin) {
        this.getCurrentState();
      }

      this.dataGrid.instance.refresh(true).then(() => {
        setTimeout(this.startRefreshing, delay, delay);
      }).catch(() => {
        setTimeout(this.startRefreshing, delay * 2, delay);
      });
    }
    else {
      setTimeout(this.startRefreshing, delay, delay);
    }
  }

  isVisible() {
    return (this.jobListMode && this.modelService.viewService.jobsVisible) || (this.catalogMode && this.modelService.viewService.catalogJobsVisible) || this.adminMode;
  }

  applyFilterUser() {
    this.dataGrid.instance.clearFilter();

    let user = this.modelService.loginService.currentUser;
    let query = "";
    if (user.lastName && user.firstName) {
      query = user.lastName + ", " + user.firstName;
    }
    else {
      query = user.email;
    }

    this.filterValue = [
      ['userName', '=', query],
    ];
  }

  applyFilterPinned() {
    this.dataGrid.instance.clearFilter();

    this.filterValue = [
      ['isPinned', '=', true],
    ];
  }

  applyFilterReset() {
    this.dataGrid.instance.clearFilter();
  }

  jobService() {
    return this.modelService.jobService;
  }

  time(started, ended) {
    // TODO: SAME in AdminComponent ==> move into a service!!
    var time = (Date.parse(ended) - Date.parse(started)) / 1000;
    if (time < 600) {
      let text = this.translate.instant("Sekunden");
      return Math.floor(time * 10) / 10 + " " + text;
    }
    let text = this.translate.instant("Minuten");
    time = time / 60;
    return Math.floor(time) + " " + text;
  }

  getCatalogNameById(id: string): string {
    let cat = this.modelService.catalogService.catalogs.filter(catalog => catalog.id == id).shift();
    if (cat) {
      return cat.catalogName;
    }
    return "";
  }

  isValidatable(job: BackgroundJob): boolean {
    if (!job.resultFile)
      return false;

    return (job.type === 'Export' && job.state === 2 && job.resultFile.endsWith('.zip'));
  }

  onEditClicked(catalog: BackgroundJob) {
    if (catalog.resultCatalogId) {
      this.modelService.catalogService.selectedCatalogId = catalog.resultCatalogId;
    } else {
      this.modelService.catalogService.selectedCatalogId = catalog.catalogId;
    }
    if (this.modelService.router.url == "/catalog") {
      this.modelService.router.navigate(["/catalogdetails"]);
    } else {
      this.modelService.router.navigate(["/catalog/" + this.modelService.loginService.currentCustomer.id + "/" + this.modelService.catalogService.selectedCatalogId]);
    }
  }

  userCanEdit() {
    return this.userManagementService.canEdit("jobListDelete");
  }

  onAdminDeleteClicked(e) {
    this.modelService.jobService.delete(e.id);
  }

  onDeleteClicked(e) {
    let message, title;
    message = this.translate.instant("Wollen Sie den Job wirklich löschen");
    title = this.translate.instant("Job löschen");
    let result = confirm(message, title);
    result.then((dialogResult) => {
      if (dialogResult) {
        this.modelService.jobService.setIsDeleted(e.id).subscribe(
          (res: any) => {
            let result = res.value;
            if (result != "0:0") {
              this.showWarningForDeleteResult(result);
            }
            else {
              this.translate.get("Jobs erfolgreich gelöscht").subscribe((text: string) => {
                this.modelService.systemService.notify(new Message(text));
              });
            }
          }
        );
      }
    });
  }

  deleteReadyJobs() {
    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("Sollen wirklich alle beendeten Jobs gelöscht werden?").subscribe((text: string) => {
      txtMsg = text;
    });
    this.translate.get("Wirklich löschen?").subscribe((text: string) => {
      txtTitle = text;
    });
    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [{
        text: txtYes,
        onClick: (e) => {
          return { buttonText: true };
        }
      },
      {
        text: txtNo,
        onClick: (e) => {
          return { buttonText: false };
        }
      }
      ]
    });
    myDialog.show().then((dialogResult) => {
      if (dialogResult.buttonText == true) {
        this.modelService.jobService.setIsDeletedForFinishedJobs(this.modelService.loginService.currentUser.customerId).subscribe(
          (res: any) => {
            let result = res.value;
            if (result != "0:0") {
              this.showWarningForDeleteResult(result);
            }
            else {
              this.translate.get("Jobs erfolgreich gelöscht").subscribe((text: string) => {
                this.modelService.systemService.notify(new Message(text));
              });
            }
          }
        );
      }
    });
  }

  deleteSelectedJobs(selectedRowsData: Array<BackgroundJob>) {
    let txtYes = "";
    let txtNo = "";
    let txtMsg = "";
    let txtTitle = "";
    let myDialog: any;

    this.translate.get("Ja").subscribe((text: string) => {
      txtYes = text;
    });
    this.translate.get("Nein").subscribe((text: string) => {
      txtNo = text;
    });
    this.translate.get("Sollen wirklich alle gewählten Jobs gelöscht werden").subscribe((text: string) => {
      txtMsg = text;
    });
    this.translate.get("Wirklich löschen?").subscribe((text: string) => {
      txtTitle = text;
    });
    myDialog = custom({
      title: txtTitle,
      messageHtml: txtMsg,
      buttons: [{
        text: txtYes,
        onClick: (e) => {
          return { buttonText: true };
        }
      },
      {
        text: txtNo,
        onClick: (e) => {
          return { buttonText: false };
        }
      }
      ]
    });
    myDialog.show().then((dialogResult) => {
      if (dialogResult.buttonText == true) {

        let jobIds: Array<number> = [];
        selectedRowsData.forEach(e => jobIds.push(e.id));

        this.modelService.jobService.setIsDeletedForSelectedJobs(jobIds).subscribe(
          (res: any) => {
            let result = res.value;
            if (result != "0:0") {
              this.showWarningForDeleteResult(result);
            }
            else {
              this.translate.get("Jobs erfolgreich gelöscht").subscribe((text: string) => {
                this.modelService.systemService.notify(new Message(text));
              });
            }
          }
        );
      }
    });
  }

  showWarningForDeleteResult(result: string) {
    let values = result.split(":");

    let text = this.translate.instant("Einige Jobs konnten nicht gelöscht werden.");
    if (values[0] != "0") {
      let textPinned = this.translate.instant("Verhindert da angeheftet");
      text += '\n' + textPinned + ': ' + values[0];
    }
    if (values[1] != "0") {
      let textStatus = this.translate.instant("Verhindert durch Status");
      text += '\n' + textStatus + ': ' + values[1];
    }

    let message = new Message(text, 'warning');

    this.modelService.systemService.notify(message, 3000);
  }

  startDownload(source: string) {
    window.open(source, '_self', 'location=yes');
  }

  sendMail(source: string) {
    const subject = "Angeforderte Produktdaten";
    const linkUrl = source;

    const body = `Sehr geehrte Damen und Herren,\n\nSie können die angeforderten Produktdaten unter folgender Adresse herunterladen:\n\n${linkUrl}`;

    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoUrl;
  }

  showImportTemplate(data) {
    this.templateService.importTemplateEditorVisible = true;
    this.templateService.importJob = data.data;
  }

  getIconClass(icon) {
    return JobService.getIconClass(icon);
  }

  restartJob(data: BackgroundJob) {
    let dto = new RestartJob();
    dto.jobId = data.id;
    this.modelService.jobService.restartJob(dto)
      .subscribe(success => {
        if (success) {
          let i = 1;
        }
      });
  }



  _pindataVisible: boolean = false;
  get pindataVisible(): boolean {
    return this._pindataVisible;
  }
  set pindataVisible(value: boolean) {
    if (!value) {
      this.pinJobId = -1;
      this.pinJobIsPinned = false;
      this.pinJobRemarks = "";
      this.pinJobPinExpires = null;
    }
    this._pindataVisible = value;
  }

  showPindata(data: BackgroundJob) {

    this.pinJobId = data.id;
    this.pinJobIsPinned = data.isPinned;
    this.pinJobRemarks = data.remarks;
    this.pinJobPinExpires = this.addDays(new Date(), 7);
    if (data.pinExpires) {
      this.pinJobPinExpires = data.pinExpires;
    }

    this.pindataVisible = true;
  }

  pinJobId: number = -1;
  pinJobIsPinned: boolean = false;
  pinJobRemarks: string = "";
  pinJobPinExpires?: Date = null;

  pinJob(e) {
    let id = this.pinJobId;
    let remarks = e.remarks;
    let pinExpires = e.pinExpires;

    // Aus unerklärlichen Gründen liefert die Date Box meistens string...?
    // https://stackoverflow.com/a/17772086
    if (Object.prototype.toString.call(pinExpires) === "[object String]") {
      pinExpires = new Date(pinExpires);
    }

    this.modelService.jobService.setIsPinned(id, remarks, pinExpires).subscribe();

    this.pindataVisible = false;
  }

  unpinJob(e) {
    let id = this.pinJobId;

    this.modelService.jobService.setIsNotPinned(id).subscribe();

    this.pindataVisible = false;
  }

  // https://stackoverflow.com/a/19691491
  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }



  validateExport(data: BackgroundJob) {
    let dto = new ValidateExportJob();
    dto.customerId = this.modelService.loginService.currentUser.customerId;
    dto.jobId = data.id;
    this.modelService.jobService.validateExportJob(dto)
      .subscribe(success => {
        if (success) {
          let i = 1;
        }
      });
  }

  getEventMessage(e: BackgroundJobEvent) {
    let result = "";
    if (e.parameter) {
      let param = JSON.parse(e.parameter);
      result = this.translate.instant(e.message, param);
    } else {
      result = this.translate.instant(e.message);
    }
    return result;
  }

  onDetailInitialized(e) {
    this.dataGrid.instance.updateDimensions();
  }

  getCurrentState() {
    this.maintenanceModeService.getCurrentState().subscribe((result: MaintenanceMode) => {
      this.maintenanceModeActive = result.active;
    });
  }

  enableMaintenanceMode() {
    this.maintenanceModeService.enableMaintenanceMode().subscribe((result: MaintenanceMode) => {
      this.maintenanceModeActive = result.active;
    });
  }

  disableMaintenanceMode() {
    this.maintenanceModeService.disableMaintenanceMode().subscribe((result: MaintenanceMode) => {
      this.maintenanceModeActive = result.active;
    });
  }

  getDisplayValue(data: any): string {
    if (data.catalogName !== null && data.catalogName !== '') {
      return data.catalogName;
    } else if (data.resultCatalogName !== null && data.resultCatalogName !== '') {
      return data.resultCatalogName;
    } else {
      return ''; 
    }
  }
}


<dx-sortable group="dragDropGroup"
             dropFeedbackMode="indicate"
             [allowReordering]="false"
             [data]="dragInfo"
             [filter]="dragFilter"
             dragTemplate="dragTemplate"
             [cursorOffset]="{ x: 20, y: 20 }"
             (onDragStart)="onDragStart($event)"
             (onAdd)="onDropAdd($event)">

  <!-- template for the dragged item -->
  <div *dxTemplate="let dragInfo of 'dragTemplate'"
       class="item dx-card dx-theme-text-color dx-theme-background-color shadow-lg p-3 bg-body rounded dragTemplate">
    {{ dragInfo.itemData.dDisplayName }}
  </div>

  <div class="dx-field {{ fieldValueClasses() }}" (click)="elementSelectFunction()">



    <div *ngIf="!suppressLabel" class="dx-field-label dx-field-label-np d-flex flex-column" (mouseenter)="toggleToolTip(true)" (mouseleave)="toggleToolTip(false)">
      <div>{{ labelText }}</div>
      <div *ngIf="!isPrint && showHTMLSwitch" class="d-flex flex-row pt-2">
        <div class="dx-field-label switch-left">{{ "Text" | translate }}</div>
        <dx-switch [(value)]="showHTMLEditor"
                   (onValueChanged)="switchValueChanged($event)"
                   [readOnly]="readOnlyUser('htmlToggle')">
        </dx-switch>
        <div class="dx-field-label switch-right">{{ "html" | translate }}</div>
      </div>
    </div>

    <div class="dx-field-value dx-field-value-np d-flex flex-row w-100">



      <div *ngIf="showDragHandle && !suppressDragHandle" class="pr-2 align-self-center">
        <i class="handle dx-icon dx-icon-dragvertical"></i>
      </div>
      <div *ngIf="showDragHandle && suppressDragHandle" class="pr-2 align-self-center">
        <div class="link-placeholder"></div>
      </div>


      <div *ngIf="hasMasterOrChildOrIsTranslated() && showLinkButtons && !suppressLinkButton" class="pr-2 align-self-center">

        <div *ngIf="!isVirtualCatalog()"
             class="{{ getInitialClassForLinkedField(field, system, element) }}"
             (click)="toggleLinkedField(field, system, element, $event)"></div>

        <div *ngIf="isVirtualCatalog()"
             class="{{ getInitialClassForLinkedField(field, system, element) }}"></div>

      </div>
      <div *ngIf="hasMasterOrChildOrIsTranslated() && showLinkButtons && suppressLinkButton" class="pr-2 align-self-center">
        <div class="link-placeholder"></div>
      </div>

      <div class="{{ hasTranslation && !suppressTranslation ? 'w-50' : '' }} flex-fill {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt_{{ toolTipTargetId }}">

        <label *ngIf="!suppressPlaceLabel" class="placeLabel {{ showPlaceLabel ? 'placeLabelSmall' : '' }}">{{ field.toUpperCase() }}</label>
        <ng-content select="[slot='content']"></ng-content>

      </div>



      <div *ngIf="hasTranslation && !suppressTranslation" class="w-50 flex-fill pl-2 {{ showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">

        <label *ngIf="!suppressPlaceLabel" class="placeLabel {{ showTranslationPlaceLabel ? 'placeLabelSmall' : '' }}">{{ field.toUpperCase() }}</label>
        <ng-content select="[slot='translation']"></ng-content>

      </div>

      <div *ngIf="showRemoveFromTemplateItem" class="pr-2 align-self-center">
        <dx-button icon="trash" type="text" (onClick)="removeFromTemplateItem($event)"> </dx-button>
      </div>


      <!-- ################################ TESTING ################################ -->
      <!--<div>{{ editValue }}</div>-->
      <!--<dx-text-box [(value)]="editValue" (onValueChanged)="update($event)"></dx-text-box>-->
      <!-- ################################ TESTING ################################ -->



    </div>



    <dx-tooltip *ngIf="toolTipContent().length > 0" target="#tt_{{ toolTipTargetId }}" class="toolTip toolTipField" [(visible)]="toolTipVisible">
      <div *dxTemplate="let data = data of 'content'">
        <div [innerHtml]="toolTipContent()"></div>
      </div>
    </dx-tooltip>



  </div>

</dx-sortable>

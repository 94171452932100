import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OrderDetail } from "../../Model/Catalog/OrderDetail";
import { Product } from "../../Model/Catalog/product";
import { Agreement } from "../../Model/Catalog/Agreement";
import { FeatureSystem } from "../../Model/Catalog/FeatureModels/FeatureSystem";
import { WawiIntegration } from "../../Model/Catalog/WawiIntegration";
import { WawiSettings } from "../../Model/User/WawiSettings";
import { CustomWawiField } from "../../Model/Catalog/CustomWawiField";
import { CreateReportProductDatasheet } from "../../Model/Dto/CreateReportProductDatasheet";
import { ProductSupplier } from "../../Model/Catalog/ProductSupplier";
import { LinkedField } from "../../Model/Catalog/LinkedField";
import { ElectronicSalesDetail } from "../../Model/Catalog/ElectronicSalesDetail";


@Injectable()
export class ProductService {
  constructor(private http: HttpClient) {}

  getProductFromJson(product: Product, wawiSettings: WawiSettings) {
    var productObj = new Product();
    productObj.id = product.id;
    productObj.parentProductId = product.parentProductId;
    productObj.isChild = product.isChild;
    productObj.customerId = product.customerId;
    productObj.articleOrder = product.articleOrder;
    productObj.buyerAid = product.buyerAid;
    productObj.buyerAidType = product.buyerAidType;
    productObj.descriptionLong = product.descriptionLong;
    productObj.descriptionShort = product.descriptionShort;
    productObj.erpGroupBuyer = product.erpGroupBuyer;
    productObj.erpGroupSupplier = product.erpGroupSupplier;
    productObj.internationalPid = product.internationalPid;
    productObj.internationalPidType = product.internationalPidType;
    productObj.lastChange = product.lastChange;
    productObj.manufacturerAid = product.manufacturerAid;
    productObj.manufacturerName = product.manufacturerName;
    productObj.manufacturerTypeDescr = product.manufacturerTypeDescr;
    productObj.remarks = product.remarks;
    productObj.state = product.state;
    productObj.supplierPid = product.supplierPid;
    productObj.supplierAltAid = product.supplierAltAid;
    productObj.productLogistic = product.productLogistic;
    productObj.wawi = this.getWawiFromJson(product.wawi, wawiSettings);
    productObj.orderDetail = product.orderDetail;
    productObj.electronicSales = product.electronicSales;
    if (productObj.orderDetail != null) {
      if (productObj.orderDetail.hasBasicPriceDuty == null) {
        productObj.orderDetail.hasBasicPriceDuty = undefined;
      }
      if (productObj.orderDetail.isBulkyGood == null) {
        productObj.orderDetail.isBulkyGood = undefined;
      }
      if (productObj.orderDetail.isTruckageCompanyGood == null) {
        productObj.orderDetail.isTruckageCompanyGood = undefined;
      }
    }
    if (!productObj.electronicSales) {
      productObj.electronicSales = new ElectronicSalesDetail();
    }
    if (productObj.electronicSales.dateCreated === null && product.electronicSales?.dateCreated) {
      productObj.electronicSales.dateCreated = new Date(product.electronicSales.dateCreated);
    }

    productObj.legalCharacteristic = product.legalCharacteristic;
    productObj.edeNumber1 = product.edeNumber1;
    productObj.edeNumber2 = product.edeNumber2;
    productObj.edeNumber3 = product.edeNumber3;
    productObj.edeNumber4 = product.edeNumber4;
    productObj.edeNumber5 = product.edeNumber5;
    productObj.productCount = product.productCount;
    productObj.suppliers = product.suppliers;
    productObj.mainSupplierId = product.mainSupplierId;

    productObj.isDiscontinued = product.isDiscontinued;
    productObj.discontinuedDate = product.discontinuedDate;
    if (productObj.orderDetail == null) {
      productObj.orderDetail = new OrderDetail();
    }
    productObj.mimes = product.mimes;
    productObj.catalogMappings = product.catalogMappings;
    productObj.udxs = product.udxs;
    productObj.sourceProductId = product.sourceProductId;
    productObj.products = [];
    if (product.products) {
      for (let slaveProduct of product.products) {
        productObj.products.push(this.getProductFromJson(slaveProduct, wawiSettings));
      };
    }
    productObj.featureSystems = new Array<FeatureSystem>();
    if (product.featureSystems) {
      product.featureSystems.forEach(system => {
        let fs = Object.assign(new FeatureSystem(), system);
        productObj.featureSystems.push(fs);

      });
    }

    productObj.keywords = product.keywords;
    productObj.states = product.states;
    productObj.priceLists = product.priceLists;
    productObj.references = product.references;
    productObj.productOrder = product.productOrder;
    if (productObj.agreement == null) {
      productObj.agreement = new Agreement();
    }
    productObj.metaTitel = product.metaTitel;
    productObj.metaDescription = product.metaDescription;
    if (product.changeInfo != null) {
      productObj.changeInfo = product.changeInfo;
    }
    productObj.reportRuns = product.reportRuns;
    productObj.linkedFields = product.linkedFields;
    return productObj;
  }

  getWawiFromJson(wawi: WawiIntegration, wawiSettings: WawiSettings) {
    if (wawi == null) {
      wawi = new WawiIntegration();
      wawi.customFields = new Array<CustomWawiField>();
    }


    let result = new WawiIntegration(wawi.wawiId, wawi.line1, wawi.line2, wawi.line3, wawi.line4, wawi.customFields);

    wawiSettings.customFields.forEach(field => {
      const found = result.customFields.find(x => x.order === field.order);
      if (!found) {
        let customField = new CustomWawiField();
        customField.order = field.order;
        customField.listId = field.listId;
        result.customFields.push(customField);
      }
    });

    return result;

  }

  createWawiIfNeeded(product: Product, wawiSettings: WawiSettings) {
    if (product) {
      if (product.wawi == null) {
        product.wawi = new WawiIntegration();
        product.wawi.customFields = new Array<CustomWawiField>();
      }

      if (wawiSettings) {
        wawiSettings.customFields.forEach(field => {
          const found = product.wawi.customFields.find(x => x.order === field.order);
          if (!found) {
            let customField = new CustomWawiField();
            customField.order = field.order;
            customField.listId = field.listId;
            product.wawi.customFields.push(customField);
          }
        });
      }

    }
  }

  createLines(product: Product): Observable<Product> {
    return (this.http.post("api/product/createLines", product) as any);
  }

  createReportProductDatasheet(createDto: CreateReportProductDatasheet): Observable<any> {
    return (this.http.post("api/Reporting/CreateReportProductDatasheet", createDto) as any);
  }

  generateMissingPackingCodes(productID, customerId): Observable<any> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("productId", productID)
    }
    return (this.http.post("api/Product/GenerateMissingPackingCodes",null, options)) as any;
  }

  getSuppliersInAccount(customerId): Observable<ProductSupplier[]>  {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    };

    return this.http.get<ProductSupplier[]>("api/product/getSuppliersWithIdsInAccount", options);
  }

  getSupplierNamesInAccount(customerId): Observable<string[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    };

    return this.http.get<string[]>("api/product/getSupplierNamesInAccount", options);
  }

  getOrderUnitList(): Observable<string[]> { 
    return this.http.get<string[]>("api/product/GetOrderUnitList");
  }

  getLinkedFields(productId, customerId): Observable<LinkedField[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId).append("productId", productId)
    }
    return this.http.get<LinkedField[]>("api/product/GetLinkedFields", options);
  }
}

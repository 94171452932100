
<div class="d-flex flex-row">
  <div class="flex-fill w-25" style="margin-top:6px;">
    <div class="dx-field-label dx-field-label200">{{ 'Suchmodus' | translate }}</div>
  </div>
  <div class="flex-column w-75" style="margin-top:6px;margin-left:6px;">
    <div class="dx-field-label dx-field-label200">{{ 'Durchsuchte Felder' | translate }}</div>
  </div>
</div>
<div class="d-flex flex-row">
  <div class="flex-fill w-25" style="margin-top:6px;">
    <dx-select-box [(value)]="model.searchMode"
                   [items]="searchModes"
                   displayExpr="name"
                   (onSelectionChanged)="onSearchModeSelectionChanged()"
                   valueExpr="value"></dx-select-box>
  </div>
  <div class="flex-column w-75" style="margin-top:6px;margin-left:6px;">
    <dx-drop-down-box [(value)]="selectedSearchFields"
                      valueExpr="value"
                      displayExpr="name"
                      [showClearButton]="true"
                      [dataSource]="searchFields">
      <div *dxTemplate="let data of 'content'">
        <dx-data-grid [dataSource]="searchFields"
                      keyExpr="value"
                      [closeOnOutsideClick]="false"
                      [selection]="{ mode: 'multiple' }"
                      [hoverStateEnabled]="true"
                      (onSelectionChanged)="onSearchFieldsSelectionChanged()"
                      [(selectedRowKeys)]="selectedSearchFields">
        </dx-data-grid>
      </div>
    </dx-drop-down-box>
  </div>
</div>



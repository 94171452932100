import { Component, OnInit } from "@angular/core";
import { ModelService } from "../Services/model.service";
import { TranslateService } from '@ngx-translate/core';

import { OsgSenderService } from "../Services/osgSender.service";
import { Catalog } from "../Model/Catalog/catalog";
import { OsgConnection } from "../Model/Dto/OsgConnection";


@Component({
  selector: "osgSender",
  templateUrl: "osgSender.component.html"
})
export class OsgSenderComponent implements OnInit {

  _catalogs: Catalog[];
  hasTestConnection: boolean;

  get catalogs(): Catalog[] {
    return this._catalogs;
  }

  set catalogs(value: Catalog[]) {
    this._catalogs = value;
  }


  constructor(public modelService: ModelService, public osgSenderService: OsgSenderService, public translate: TranslateService) {

    this.generate = this.generate.bind(this);

  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.refresh();
    this.modelService.osgConnectionService.getConnection(this.modelService.loginService.currentCustomer.id, true).subscribe(
      (result: OsgConnection) => {
        if (result != null && result.shopUrl.length > 0) {
          this.hasTestConnection = true;
        }
      });

  }

  refresh(): void {
    this.modelService.catalogService.getCatalogs(this.modelService.loginService.currentCustomer.id)
      .subscribe((result: Catalog[]) => {
        this.catalogs = result;
      });


  }


  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }

  get generateEnabled() {
    return this.osgSenderService.selectedSet.catalogs.length > 0;
  }
  generateTest() {
    this.osgSenderService.startGenerationJob(this.osgSenderService.selectedSet, true).subscribe(result => {
      if (this.osgSenderService.selectedSet.catalogs.length == 1) {
        this.modelService.systemService.notifyInfo(this.translate.instant("Ein Job wurde erzeugt"));
      } else {
        this.modelService.systemService.notifyInfo(this.translate.instant("Jobs wurden erzeugt"));
      }
    });
  }

  generate() {
    this.osgSenderService.startGenerationJob(this.osgSenderService.selectedSet, false).subscribe(result => {
      if (this.osgSenderService.selectedSet.catalogs.length == 1) {
        this.modelService.systemService.notifyInfo(this.translate.instant("Ein Job wurde erzeugt"));
      } else {
        this.modelService.systemService.notifyInfo(this.translate.instant("Jobs wurden erzeugt"));
      }
    });
  }



  updateName() {
    this.update();
  }


  isUpdating: boolean;

  update() {
    if (this.isUpdating)
      return;
    this.isUpdating = true;

    this.osgSenderService.updateSingle(this.osgSenderService.selectedSet).subscribe(
      result => {
        this.osgSenderService.selectedSet = result;
        this.isUpdating = false;
      });
  }

}

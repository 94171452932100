
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { WarrantyClass } from '../Model/Catalog/WarrantyClass';
import { LoginService } from './login.service';
import { Product } from '../Model/Catalog/product';




@Injectable()
export class WarrantyClassService {

  warrantyClasses: WarrantyClass[];


  selectedClass: WarrantyClass;
  isClassificationVisible: boolean = false;
  _selectedClassId: string = "";

  get selectedClassId(): string {
    return this._selectedClassId;
  }

  set selectedClassId(value: string) {
    if (value != this.selectedClassId && value != "") {
      this.getClass(value).subscribe(
        (cs: WarrantyClass) => {
          this.selectedClass = cs;
        });
    }
    this._selectedClassId = value;
  }


  constructor(private http: HttpClient, public loginService: LoginService) {

  }


  getClass(id: string): Observable<WarrantyClass> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("warrantyClassId", id).append("customerId", this.loginService.currentUser.customerId)

    }
    return (this.http.get<WarrantyClass>("api/WarrantyClass/GetWarrantyClass", options)) as any;
  }

  getClasses(): Observable<WarrantyClass[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId)
    }
    return (this.http.get<WarrantyClass[]>("api/WarrantyClass/GetWarrantyClasses", options)) as any;
  }

  deleteClass(element: WarrantyClass) {
    {
      const options = {
        headers: new HttpHeaders().append("Content-Type", "application/json"),
        params: new HttpParams().append("id", element.id)
      }

      this.http.delete("api/WarrantyClass/DeleteWarrantyClass", options)
        .subscribe(
          result => { },
          err => console.error(err)
        );
    }
  }

  updateClass(selectedClass: WarrantyClass): Observable<WarrantyClass> {
    return (this.http.post("api/WarrantyClass/UpdateWarrantyClass", selectedClass)) as any;
  }

  addClass(selectedClass: WarrantyClass): Observable<WarrantyClass> {
    return (this.http.post("api/WarrantyClass/AddWarrantyClass", selectedClass)) as any;
  }

  loadProductWarranty(product: Product) {
    if (product.productLogistic.warrantyClassId != "") {
      this.getClass(product.productLogistic.warrantyClassId).subscribe(
        (result: WarrantyClass) => {
          if (result.descriptionHtml != "" && result.descriptionHtml != null) {
            product.warrantyClass = result;
            product.addWarranty(result.name);
          }
        });
    }
  }
}

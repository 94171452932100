import { Component, OnInit, ViewChild } from "@angular/core";

import { AppUser } from "../Model/user/AppUser";
import isString from "lodash.isstring";
import { Message } from "../Model/System/Message";
import { CustomerWiki } from "../Model/System/CustomerWiki";

import { TranslateService } from "@ngx-translate/core";
import { ModelService } from "../Services/model.service";
import { SupplierPidMapping } from "../Model/supplierPidMapping/SupplierPidMapping";
import { ProductService } from "../Services/CatalogManagement/product.service";
import { ProductSupplier } from "../Model/Catalog/ProductSupplier";
import { SupplierPidMappingInfo } from "../Model/supplierPidMapping/SupplierPidMappingSupplierInfo";
import { DxDataGridComponent } from "devextreme-angular";
import * as dialog from "devextreme/ui/dialog";
import { SystemService } from "../Services/system.service";


@Component({
  selector: "supplierPidMappings",
  templateUrl: "supplierPidMapping.component.html",
  styleUrls: ["./supplierPidMapping.component.css"]
})
export class SupplierPidMappingComponent implements OnInit {
  @ViewChild("mappingsGrid") mappingsGrid: DxDataGridComponent;
  @ViewChild("suppliersGrid") suppliersGrid: DxDataGridComponent;

  user: AppUser;
  supplierPidMappings: SupplierPidMapping[];
  selectedSupplier: ProductSupplier;
  suppliers: ProductSupplier[] = [];
  selectedSupplierKey = [0];

  importSupplierPidMappingsVisible: boolean = false;
  suppliersLoading: boolean = false;
  popupDeleteTranslateVisible: boolean = false;

  get showPlaceHolders() {
    return this.modelService.loginService.showPlaceHolders;
  }


  constructor(public modelService: ModelService, public translate: TranslateService, public productService: ProductService, public systemService: SystemService) {
    this.exportExcel = this.exportExcel.bind(this);
    this.showImport = this.showImport.bind(this);
    this.addSupplierPidMapping = this.addSupplierPidMapping.bind(this);
    this.loadData = this.loadData.bind(this);
    this.supplierSelectionChanged = this.supplierSelectionChanged.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);

    this.modelService.systemService.currentNavigationTitle = this.translate.instant("Artikelnummern Mapping");
    this.suppliersLoading = true;
    productService.getSuppliersInAccount(this.modelService.loginService.currentUser.customerId).toPromise().then(data => {
      data.forEach(supplier => {
        if (!this.suppliers.some(x => x.supplierName == supplier.supplierName && x.supplierId == supplier.supplierId))
          this.suppliers.push(supplier);
      });
    }).finally(() =>
      this.suppliersLoading = false
    );
  }

  ngOnInit(): void {
    if (this.modelService.loginService.currentUser == null) {
      this.modelService.router.navigate(["/"]);
      return;
    }
    this.user = this.modelService.loginService.currentUser;
    this.loadData();
  }

  supplierIsUsed(supplier: ProductSupplier): boolean {
    if (!this.mappingsGrid)
      return false;

    let visibleMapppings = this.mappingsGrid.instance.getVisibleRows().map(x => x.data);
    return visibleMapppings.some(x => x.supplierInfos.some(s => s.id == supplier.supplierId && (s.artikelNumber || s.gtin)));
  }

  supplierSelectionChanged(e) {
    if (e.selectedRowsData && e.selectedRowsData.length > 0) {
      this.selectedSupplier = e.selectedRowsData[0];
      this.supplierPidMappings = this.supplierPidMappings.map(x => {
        x.gtin = this.getSupplierGtin(x);
        x.artikelNumber = this.getSupplierArtikelnumber(x);
        return x;
      });
      this.mappingsGrid.instance.repaint();
    }
  }

  onRowPrepared(e) {
    if (e.rowType == "data") {
      if (this.supplierIsUsed(e.data)) {
        e.rowElement.style.backgroundColor = "#D6F5F3";
        e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
      }
    }
  }


  onContentToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelImportButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'excelExportButtonTemplate',
    });

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'deleteAllButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addMappingButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'refreshButtonTemplate',
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'supplierSelectTemplate',
    });
  }

  loadData() {
    this.modelService.supplierPidMappingService.getSupplierPidMappings().subscribe((result: SupplierPidMapping[]) => {
      this.supplierPidMappings = result;
      this.supplierPidMappings.forEach(x => {
        x.supplierInfos.forEach(supplierInfo => {
          if (!this.suppliers.some(supplier => supplier.supplierId == supplierInfo.id && supplier.supplierName == supplierInfo.name)) {
            var newSupplier = new ProductSupplier();
            newSupplier.supplierName = supplierInfo.name;
            newSupplier.supplierId = supplierInfo.id;
            this.suppliers.push(newSupplier);
          }
        });
      });

      if (this.selectedSupplier) {
        this.supplierPidMappings = this.supplierPidMappings.map(x => {
          x.gtin = this.getSupplierGtin(x);
          x.artikelNumber = this.getSupplierArtikelnumber(x);
          return x;
        });
      }
    });

  }

  showImport() {

    this.importSupplierPidMappingsVisible = true;
  }

  update(event) {
    var pidMapping;

    if (isString(event)) {
      pidMapping = this.supplierPidMappings.find(item => item.id == event);
    } else {
      if (event.key == null)
        return;
      pidMapping = this.supplierPidMappings.find(item => item.id == event.key.id);
    }
    this.updateSupplierPidMapping(pidMapping);

  }

  private updateSupplierPidMapping(mapping: SupplierPidMapping) {
    this.modelService.supplierPidMappingService.updateSupplierPidMapping(mapping).subscribe((result: SupplierPidMapping) => {
      mapping.usedSuppliers = result.usedSuppliers;
      if (this.suppliersGrid)
        this.suppliersGrid.instance.refresh();
    });
  }

  addSupplierPidMapping() {
    this.modelService.supplierPidMappingService.addSupplierPidMapping().subscribe((result: SupplierPidMapping) => {
      this.supplierPidMappings.push(result);
    });
  }

  deleteSupplierPidMapping(event: MessageEvent<{ id: string }>) {
    this.modelService.supplierPidMappingService.deleteSupplierPidMapping(event.data.id).subscribe(() => {
      const index = this.supplierPidMappings.findIndex(spm => spm.id === event.data.id);
      if (index > -1) {
        this.supplierPidMappings.splice(index, this.supplierPidMappings.length);
      }
    });
  }

  async deleteAllSupplierPidMapping() {
    try {
      const userConfirm = await dialog.confirm(this.translate.instant("Sind Sie sicher, dass Sie alle Artikelnummern löschen möchten?"), this.translate.instant("Alle Artikelnummern löschen"));
      if (userConfirm) {
        this.modelService.supplierPidMappingService.deleteAllSupplierPidMapping().subscribe(() => {
          this.supplierPidMappings.splice(0, this.supplierPidMappings.length);
          this.systemService.notifyInfo(this.translate.instant('Alle Artikelnummern wurden gelöscht.'));
        });
      }
    } catch (e) {
      this.systemService.notifyWarning(this.translate.instant('Der Löschvorgang konnte nicht durchgeführt werden. Laden Sie die Seite neu und probieren Sie es erneut.'));
    }
  }

  exportExcel() {
    this.modelService.supplierPidMappingService.exportSupplierPidMappings(this.modelService.loginService.currentUser.customerId);
    this.modelService.systemService.notify(new Message("Der Export wird als Job ausgeführt."));
  }

  deleteAllTranslations() {
    this.popupDeleteTranslateVisible = true;
  }

  deleteAllTranslationsClose() {
    this.popupDeleteTranslateVisible = false;
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }


  getUsedSuppliers(data) {
    return data.data.usedSuppliers;
  }

  getSupplierGtin(data) {
    let supplierInfo = data.supplierInfos.find((s: SupplierPidMappingInfo) => s.id == this.selectedSupplier.supplierId && s.name == this.selectedSupplier.supplierName);
    if (supplierInfo == null) {
      supplierInfo = new SupplierPidMappingInfo(this.selectedSupplier.supplierId, this.selectedSupplier.supplierName);
      data.supplierInfos.push(supplierInfo);
    }

    return supplierInfo.gtin || "";
  }

  getSupplierArtikelnumber(data) {
    let supplierInfo = data.supplierInfos.find((s: SupplierPidMappingInfo) => s.id == this.selectedSupplier.supplierId && s.name == this.selectedSupplier.supplierName);
    if (supplierInfo == null) {
      supplierInfo = new SupplierPidMappingInfo(this.selectedSupplier.supplierId, this.selectedSupplier.supplierName);
      data.supplierInfos.push(supplierInfo);
    }

    return supplierInfo.artikelNumber || "";
  }



  onRowUpdating(event) {
    let supplierPidMapping = event.key;
    if (event.newData.gtin) {
      let supplierInfo = supplierPidMapping.supplierInfos.find((s: SupplierPidMappingInfo) => s.id == this.selectedSupplier.supplierId && s.name == this.selectedSupplier.supplierName);
      if (supplierInfo == null) {
        supplierInfo = new SupplierPidMappingInfo(this.selectedSupplier.supplierId, this.selectedSupplier.supplierName);
        supplierPidMapping.supplierInfos.push(supplierInfo);
      }
      supplierInfo.gtin = event.newData.gtin;
      this.updateSupplierPidMapping(supplierPidMapping);
    }
    if (event.newData.artikelNumber) {
      let supplierInfo = supplierPidMapping.supplierInfos.find((s: SupplierPidMappingInfo) => s.id == this.selectedSupplier.supplierId && s.name == this.selectedSupplier.supplierName);
      if (supplierInfo == null) {
        supplierInfo = new SupplierPidMappingInfo(this.selectedSupplier.supplierId, this.selectedSupplier.supplierName);
        supplierPidMapping.supplierInfos.push(supplierInfo);
      }
      supplierInfo.artikelNumber = event.newData.artikelNumber;
      this.updateSupplierPidMapping(supplierPidMapping);
    }
  }

  updateReferenceGtin(event, mapping) {
    console.log("updateReferenceGtin" + event);
    mapping.data.refernceGtins = event.value;
    this.updateSupplierPidMapping(mapping.data);
  }




}

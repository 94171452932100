import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LoginService } from './../login.service';
import { MassDataChangeParameter } from "../../Model/Dto/MassDataChangeParameter";
import { Product } from "../../Model/Catalog/product";

@Injectable({
  providedIn: 'root'
})
export class MassDataChangeService {
  constructor(private http: HttpClient, public loginService: LoginService) { }

  public startJob(catalogId: string, param: MassDataChangeParameter): Observable<any> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId).append("catalogId", catalogId)
    };

    return this.http.post<MassDataChangeParameter>("api/MassDataChange/startJob", param, options);
  }

  public getEmtpyProductByCatalog(catalogId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", this.loginService.currentUser.customerId)
    };

    return this.http.get<Product>("api/MassDataChange/getEmptyProductByCatalog", options);
  }

  public getEmtpyProductByCategory(catalogId: string, categoryId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("categoryId", categoryId).append("customerId", this.loginService.currentUser.customerId)
    };

    return this.http.get<Product>("api/MassDataChange/getEmptyProductByCategory", options);
  }

  public getEmtpyProductByMasterProduct(catalogId: string, masterId: string): Observable<Product> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("masterId", masterId).append("customerId", this.loginService.currentUser.customerId)
    };

    return this.http.get<Product>("api/MassDataChange/getEmptyProductByMasterProduct", options);
  }

  public startCreateVariantsJob(catalogId: string, productIds: string[]) : Promise<any> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId).append("catalogId", catalogId)
    };

    return lastValueFrom(this.http.post<MassDataChangeParameter>("api/MassDataChange/startCreateVariantsJob", productIds, options));
  }

  public createVariants(catalogId: string, productIds: string[]): Promise<Product> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", this.loginService.currentUser.customerId).append("catalogId", catalogId)
    };

    return lastValueFrom(this.http.post<Product>("api/MassDataChange/createVariants", productIds, options));
  }
}

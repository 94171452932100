import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Item as dxListItem, ItemClickEvent } from 'devextreme/ui/list';
import { ValueChangedEvent as NumberBoxValueChangeEvent } from 'devextreme/ui/number_box';

import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { fieldNames } from 'app/Model/FieldNames';
import { TemplateService } from 'app/Services/template.service';
import { TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { comparePimFields, isNextPimField, parsePimField } from 'app/Services/templateItemHelpers';
import { HazmatClass } from 'app/Model/Catalog/HazmatClass';
import { WarrantyClass } from 'app/Model/Catalog/WarrantyClass';

@Component({
  selector: 'np-advanced-template-legal',
  templateUrl: './advanced-template-legal.component.html',
  styleUrls: ['./advanced-template-legal.component.css']
})
export class AdvancedTemplateLegalComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input() templateItem: TemplateItem;
  @Input() customerId: string;
  @Input() warrantyClasses: WarrantyClass[] = [];
  @Input() hazmatClasses: HazmatClass[] = [];
  @Input() fieldList: dxListItem[] = [];
  @Output() onUpdate = new EventEmitter<void>();

  get pimField() {
    if (!this.templateItem.pimFields || this.templateItem.pimFields.length === 0) {
      this.templateItem.pimFields = [new NextPimField()];
    }
    return this.templateItem.pimFields[0];
  }

  get isRadioGroupItem() {
    return this.pimField.field !== fieldNames.hazmatClass && this.pimField.field !== fieldNames.warrantyClass;
  }

  get isHazmatClass() {
    return this.pimField.field === fieldNames.hazmatClass;
  }

  get isWarrantyClass() {
    return this.pimField.field === fieldNames.warrantyClass;
  }

  constructor(private templateService: TemplateService) {}

  ngOnInit() {
    this.pimField.systemKey = '-1';
    this.pimField.elementKey = '-1';
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getFieldDisplayExpr(item: dxListItem) {
    return item?.text === item?.key ? item?.key ?? '' : `${item.text} (${item.key})`;
  }

  onFieldItemClick(event: ItemClickEvent<dxListItem>) {
    this.pimField.field = event.itemData.key;
    this.update();
  }

  onOrderChanged(event: NumberBoxValueChangeEvent) {
    this.update();
  }

  triggerKeyGeneration() {
    this.templateItem.key = '';
    this.update();
  }

  private update() {
    const { field, systemKey, elementKey } = this.pimField;
    const parsedKey = parsePimField(this.templateItem.key);
    if (parsedKey !== null && (comparePimFields(parsedKey, this.pimField) || !isNextPimField(parsedKey.field))) {
      this.onUpdate.emit();
      return;
    }
    this.templateService.getHeadline(field, elementKey, systemKey, this.customerId).subscribe((headline) => {
      this.templateItem.key = headline.value;
      this.onUpdate.emit();
    });
  }
}

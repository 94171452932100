import { Component } from "@angular/core";
import { ModelService } from "./Services/model.service";
import { TranslateService } from '@ngx-translate/core';

import deMessages from "./localization/dx.messages.de.json";
import enMessages from "./localization/dx.messages.en.json";
import nlMessages from "./localization/dx.messages.nl.json";

import { locale, loadMessages } from "devextreme/localization";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./dx.custom.nextpim.css", "./app.component.css"]
})
export class AppComponent {

  constructor(private oauthService: OAuthService, private modelService: ModelService, translate: TranslateService) {
    loadMessages(deMessages);
    loadMessages(enMessages);
    loadMessages(nlMessages);
    locale(navigator.language);

    translate.addLangs(['de', 'en', 'nl']);
    translate.setDefaultLang('de');

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/de|en|nl/) ? browserLang : 'de');
    translate.getTranslation(browserLang.match(/de|en|nl/) ? browserLang : 'de');

    this.oauthService.tokenEndpoint = window.location.origin + "/connect/token";
    this.oauthService.userinfoEndpoint = window.location.origin + "/connect/userinfo";
    this.oauthService.issuer = window.location.origin + "/";
    this.oauthService.clientId = "nextpim";
    this.oauthService.scope = "profile offline_access";
    this.oauthService.responseType = "token";
    this.oauthService.oidc = false;
    this.oauthService.setStorage(localStorage);
  }

  //Style

  get getViewPort() {
    return this.modelService.loginService.getViewPort();
  }   
}

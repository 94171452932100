import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private loginService: LoginService) {
    this.canActivate = this.canActivate.bind(this)
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise((resolve, reject) => {
      if (this.loginService.loggedIn) {
        resolve(true);
      } else {
        this.loginService.silentlogin("").subscribe((success) => {
          
          if (success)
            resolve(true);
          else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            reject(false);
          }          
        }, error => {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          reject(false);
        });
      }
    });
  }

}

import { map } from 'rxjs/operators';
import { Input, Component, OnInit, ViewChild } from "@angular/core";
import { ModelService } from '../Services/model.service';
import { CustomerWiki } from '../Model/System/CustomerWiki';
import { MappingProfile } from '../Model/featureMapping/MappingProfile';
import { TranslateService } from '@ngx-translate/core';
import { MappingInput } from '../Model/featureMapping/MappingInput';
import { Customer } from '../Model/user/Customer';
import { Catalog } from '../Model/Catalog/catalog';
import { StartAction } from '../Model/Dto/StartAction';
import { Message } from '../Model/System/Message';
import { MappingResult } from '../Model/featureMapping/MappingResult';
import { DxDataGridComponent } from "devextreme-angular";


@Component({
  selector: "featureMappingDetail",
  templateUrl: "featureMappingDetail.component.html"
})
export class FeatureMappingDetailComponent implements OnInit {

  @ViewChild('inputGrid') inputGrid: DxDataGridComponent;
  @ViewChild('resultGrid') resultGrid: DxDataGridComponent;
  @Input() showToolTips: Boolean = true;


  constructor(public modelService: ModelService, public translate: TranslateService) {
    this.model = new MappingProfile();
    this.update = this.update.bind(this);
    this.refresh = this.refresh.bind(this);
    this.addMapping = this.addMapping.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onShowAnalyseCatalog = this.onShowAnalyseCatalog.bind(this);
    this.startAnalyse = this.startAnalyse.bind(this);
    this.onAddResult = this.onAddResult.bind(this);
    this.onShowMapCatalog = this.onShowMapCatalog.bind(this);
    this.startMapping = this.startMapping.bind(this);
    this.saveMappingResult = this.saveMappingResult.bind(this);
    this.createRule = this.createRule.bind(this);
    this.closeMappingResultPopUp = this.closeMappingResultPopUp.bind(this);
    this.onExportProfil = this.onExportProfil.bind(this);
    this.onImportProfil = this.onImportProfil.bind(this);

    this.txtStarted = this.translate.instant("Die Analyse wurde gestartet");

    this.txtMapStarted = this.translate.instant("Das Mapping wurde gestartet");

    this.txtExportStarted = this.translate.instant("Der Export wurde gestartet");
  }

  ngOnInit(): void {
    this.mappingResults = new Array<MappingResult>();
    this.refresh();
  }
  selectedMappingResult: MappingResult;
  showEditResult: boolean = false;
  startEditing(e) {

    e.cancel = true;
    this.selectedMappingResult = e.data;
    this.showEditResult = true;
  }

  saveMappingResult() {
    this.showEditResult = false;

    this.modelService.featureMappingService.addOrUpdateMappingResult(this.selectedMappingResult, this.modelService.loginService.currentCustomer.id).subscribe((mappingResult: MappingResult) => {

    });
  }

  closeMappingResultPopUp() {
    this.showEditResult = false;
    this.refreshInputsForResult(this.selectedMappingResult);
  }


  createRule() {

    this.modelService.featureMappingService.createRule(this.selectedMappingResult, this.modelService.loginService.currentCustomer.id).subscribe((mappingResult: MappingResult) => {

      this.selectedMappingResult = mappingResult;
      this.refreshInputsForResult(mappingResult);
      this.refreshInputs();
    });
  }


  public showPlaceHolders: boolean = true;

  public model: MappingProfile = new MappingProfile();
  public inputs: MappingInput[] = new Array<MappingInput>();
  public analyseCatalogVisible: boolean = false;
  public mapCatalogVisible: boolean = false;

  mappingResults: MappingResult[];
  _catalogs: Catalog[];
  _customers: Customer[];
  _replacePrice: boolean = false;
  _selectedCustomer: string;
  _sourceCatalog: string = "";

  useManufacturer: boolean;
  useFeatureSystem: boolean;

  txtStarted: string;
  txtMapStarted: string;
  txtExportStarted : string;
  catalogToAnalyseId: string;
  catalogToMapId: string;

  get catalogs(): Catalog[] {
    return this._catalogs;
  }

  set catalogs(value: Catalog[]) {
    this._catalogs = value;
  }

  get customers(): Customer[] {
    return this._customers;
  }

  set customers(value: Customer[]) {
    this._customers = value;
  }

  get selectedCustomer(): string {
    return this._selectedCustomer;
  }

  set selectedCustomer(value: string) {
    this._selectedCustomer = value;
    this.modelService.catalogService.getCatalogs(this._selectedCustomer).subscribe((result: Catalog[]) => this.catalogs = result);;
  }

  onShowAnalyseCatalog() {
    this.catalogToAnalyseId = null;
    this.modelService.loginService.customerService.getAllCustomerByUser(this.modelService.loginService.currentUser.id).subscribe((customers: Customer[]) => {
      this.customers = customers;
      this.selectedCustomer = this.modelService.loginService.currentCustomer.id;
    });


    this.analyseCatalogVisible = true;
  }

  onShowMapCatalog() {
    this.catalogToMapId = null;
    this.modelService.loginService.customerService.getAllCustomerByUser(this.modelService.loginService.currentUser.id).subscribe((customers: Customer[]) => {
      this.customers = customers;
      this.selectedCustomer = this.modelService.loginService.currentCustomer.id;
    });
    this.mapCatalogVisible = true;
  }

  onDeleteMappingClicked(e) {
    let inputMapping = e.data;
    inputMapping.resultId = null;
    this.modelService.featureMappingService
      .addOrUpdateMappingInput(inputMapping, this.modelService.loginService.currentCustomer.id).subscribe(
        (mappingResult: MappingInput) => {
          this.inputs.push(mappingResult);
        });
  }


  onAdd(e) {
    let rows = e.toComponent.getVisibleRows();
    let row = rows[e.toIndex];
    let dragTarget = row.data;
    let draggedInput = e.itemData;
    draggedInput.resultId = dragTarget.resultId;
    let dataSource = e.toComponent.getDataSource();
    dataSource._store._array.push(draggedInput);

    this.modelService.featureMappingService
      .addOrUpdateMappingInput(draggedInput, this.modelService.loginService.currentCustomer.id).subscribe(
        (mappingResult: MappingInput) => {
          this.modelService.featureMappingService.getMappingInput(this.modelService.featureMappingService.selectedMappingId, this.modelService.loginService.currentCustomer.id).subscribe(
            (result: MappingInput[]) => {
              this.inputs = result;
            });
        });

  }




  onAddResult(e) {

    let rows = this.resultGrid.instance.getVisibleRows();
    let row = rows[e.toIndex];
    if (!row) {
      this.createResultFromInput(e.itemData);
      return;
    }


    let dragTarget = row.data;
    let draggedInput = e.itemData;
    draggedInput.resultId = dragTarget.id;
    if (!dragTarget.mappingInputs) {
      dragTarget.mappingInputs = new Array<MappingInput>();
    }
    dragTarget.mappingInputs.push(draggedInput);
    this.modelService.featureMappingService
      .addOrUpdateMappingInput(draggedInput, this.modelService.loginService.currentCustomer.id).subscribe(
        (mappingResult: MappingInput) => {
        });
    this.modelService.featureMappingService.getMappingInput(this.modelService.featureMappingService.selectedMappingId, this.modelService.loginService.currentCustomer.id).subscribe(
      (result: MappingInput[]) => {
        this.inputs = result;
      });

  }

  createResultFromInput(itemData: MappingInput) {
    let result = new MappingResult();
    result.profileId = this.modelService.featureMappingService.selectedMappingId;
    result.name = itemData.name;
    result.unit = itemData.unit;
    result.value = itemData.value;
    result.mappingInputs = new Array<MappingInput>();
    result.mappingInputs.push(itemData);

    this.modelService.featureMappingService.addOrUpdateMappingResult(result, this.modelService.loginService.currentCustomer.id).subscribe((mappingResult: MappingResult) => {
      itemData.resultId = mappingResult.id;
      this.modelService.featureMappingService
        .addOrUpdateMappingInput(itemData, this.modelService.loginService.currentCustomer.id).subscribe(
          (mappingResult: MappingInput) => {

            this.modelService.featureMappingService.getMappingInput(this.modelService.featureMappingService.selectedMappingId, this.modelService.loginService.currentCustomer.id).subscribe(
              (result: MappingInput[]) => {
                this.inputs = result;
              });
          });
      this.mappingResults.push(mappingResult);
    });
  }


  addMapping() {
    let result = new MappingResult();
    result.profileId = this.modelService.featureMappingService.selectedMappingId;

    this.modelService.featureMappingService.addOrUpdateMappingResult(result, this.modelService.loginService.currentCustomer.id).subscribe((mappingResult: MappingResult) => {
      this.mappingResults.push(mappingResult);

    });

  }


  contentReady(e, mappingResult: MappingResult) {

    if (!(mappingResult.mappingInputs.length > 0)) {
      this.refreshInputsForResult(mappingResult);
    }
  }

  refreshInputsForResult(mappingResult: MappingResult) {
    this.modelService.featureMappingService.getMappingInputsByResultId(mappingResult.id, mappingResult.profileId, this.modelService.loginService.currentCustomer.id).subscribe((mappingInputs: MappingInput[]) => {
      mappingResult.mappingInputs = mappingInputs;
    });
  }

  onUpdateMappingResult(e) {
    this.modelService.featureMappingService.addOrUpdateMappingResult(e.data, this.modelService.loginService.currentCustomer.id).subscribe((mappingResult: MappingResult) => {

    });
  }

  private emptyArray = [false, false];
  public v: boolean[] = this.emptyArray;

  ttt(id) {
    if (this.showToolTips) {
      if (!this.v[id]) {
        this.v = this.emptyArray;
      }
      this.v[id] = !this.v[id];
    }
  }


  refresh() {
    this.modelService.featureMappingService.getMappingProfile(this.modelService.featureMappingService.selectedMappingId, this.modelService.loginService.currentCustomer.id).subscribe(
      (result: MappingProfile) => {
        this.model = result;
      });

    this.refreshInputs();


    this.modelService.featureMappingService.getMappingResults(this.modelService.featureMappingService.selectedMappingId, this.modelService.loginService.currentCustomer.id).subscribe(
      (result: MappingResult[]) => {
        this.mappingResults = result;
      });
  }

  refreshInputs() {
    this.modelService.featureMappingService.getMappingInput(this.modelService.featureMappingService.selectedMappingId, this.modelService.loginService.currentCustomer.id).subscribe(
      (result: MappingInput[]) => {
        this.inputs = result;
      });
  }

  isIgnoredClick(itemObj) {
    itemObj.data.isIgnored = !itemObj.data.isIgnored;
    this.modelService.featureMappingService.addOrUpdateMappingInput(itemObj.data, this.modelService.loginService.currentCustomer.id).subscribe(
      (result) => {

      });
  }

  onResultGridToolbarPreparing(e) {

    e.toolbarOptions.items.unshift({
      location: 'before',
      locateInMenu: 'auto',
      template: 'addMappingResultTemplate'
    });
  }

  update() {
    this.modelService.featureMappingService.updateMappingProfile(this.model, this.modelService.loginService.currentCustomer.id).subscribe(
      (result: MappingProfile) => {
      });
  }

  toggleHelp() {
    this.modelService.systemService.showHelp = !this.modelService.systemService.showHelp;
    if (this.modelService.systemService.showHelp) {
      this.modelService.systemService.loadHelp(this.modelService.loginService.currentUser.customerId).subscribe((result: CustomerWiki) => this.modelService.systemService.help = result.entries);
    }
  }


  startAnalyse() {
    let startAction = new StartAction();
    startAction.catalogId = this.catalogToAnalyseId;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `FeatureMappingAnalyse(${this.catalogToAnalyseId}:${this.model.id}:${this.useFeatureSystem}:${this.useManufacturer})`;
    this.analyseCatalogVisible = false;
    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.catalogToAnalyseId = "";
        this.modelService.systemService.notifyInfo(this.txtStarted);

      });

  }

  startMapping() {
    let startAction = new StartAction();
    startAction.catalogId = this.catalogToMapId;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `FeatureMapping(${this.catalogToMapId}:${this.model.id})`;
    this.mapCatalogVisible = false;
    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.catalogToAnalyseId = "";
        this.modelService.systemService.notifyInfo(this.txtMapStarted);

      });

  }
  onExportProfil() {
    let startAction = new StartAction();
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = `ExportMappingProfil(${this.model.id})`;

    this.modelService.jobService.startJob(startAction)
      .subscribe(next => {
        this.modelService.systemService.notifyInfo(this.txtExportStarted);

      });
  }
  importProfileVisible: boolean;

  onImportProfil() {
    this.importProfileVisible = true;
  }




}



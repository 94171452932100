import 'devextreme/data/odata/store';
import { Column, ColumnCellTemplateData, ToolbarPreparingEvent } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';

import { Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { fieldNames } from 'app/Model/FieldNames';
import { Template } from 'app/Model/Catalog/template';
import { FieldType, TemplateItem } from 'app/Model/Catalog/TemplateItem';
import { NextPimField } from 'app/Model/Catalog/NextPimField';
import { ValidFeatureSystem } from 'app/Model/ValidFeatureSystem';
import { isFeatureField, isMimeField, isNextPimField } from 'app/Services/templateItemHelpers';
import { LanguageFlag } from 'app/Model/Dto/LanguageFlag';
import { WawiList } from 'app/Model/wawi/WawiList';

import { AdvancedTemplateDefaultFieldComponent } from '../advanced-template-defaultfield/advanced-template-defaultfield.component';
import { AdvancedTemplatePriceComponent } from '../advanced-template-price/advanced-template-price.component';
import { AdvancedTemplateMimeComponent } from '../advanced-template-mime/advanced-template-mime.component';
import { AdvancedTemplateFeatureComponent } from '../advanced-template-feature/advanced-template-feature.component';
import { AdvancedTemplateListFieldComponent } from '../advanced-template-listfield/advanced-template-listfield.component';
import { WarrantyClass } from 'app/Model/Catalog/WarrantyClass';
import { HazmatClass } from 'app/Model/Catalog/HazmatClass';
import { AdvancedTemplateLegalComponent } from '../advanced-template-legal/advanced-template-legal.component';
import { TemplateFieldsService } from 'app/Services/templateFields.service';
import { ItemClickEvent } from 'devextreme/ui/list';
import { ValueChangedEvent } from 'devextreme/ui/drop_down_box';
import { DxButtonTypes } from 'devextreme-angular/ui/button';

type DropdownFieldSource = { id: string; display: string; translate?: string[] };
type AutocompleteValues = { groupId: string; value: string };

@Component({
  selector: 'np-advanced-template-datagrid',
  templateUrl: './advanced-template-datagrid.component.html',
  styleUrls: ['./advanced-template-datagrid.component.css']
})
export class AdvancedTemplateDatagridComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private readonly MAX_LEVEL = 5;

  @ViewChildren(AdvancedTemplateDefaultFieldComponent)
  defaultFieldComponents: QueryList<AdvancedTemplateDefaultFieldComponent>;

  @ViewChildren(AdvancedTemplateListFieldComponent)
  listFieldComponents: QueryList<AdvancedTemplateListFieldComponent>;

  @ViewChildren(AdvancedTemplateMimeComponent)
  mimeComponents: QueryList<AdvancedTemplateMimeComponent>;

  @ViewChildren(AdvancedTemplateFeatureComponent)
  featureComponents: QueryList<AdvancedTemplateFeatureComponent>;

  @ViewChildren(AdvancedTemplatePriceComponent)
  priceComponents: QueryList<AdvancedTemplatePriceComponent>;

  @ViewChildren(AdvancedTemplateLegalComponent)
  legalComponents: QueryList<AdvancedTemplateLegalComponent>;

  @Input() customerId: string;
  @Input() template: Template;
  @Input() sheetIndex: number;
  @Input() wawiListMapping: WawiList[] = [new WawiList(null, '', null, null, null, null)];
  @Input() warrantyClasses: WarrantyClass[] = [];
  @Input() hazmatClasses: HazmatClass[] = [];
  @Input() languageFlags: LanguageFlag[] = [];
  @Input() validFeatureSystems: ValidFeatureSystem[] = [];
  @Output() onUpdate = new EventEmitter<Template>();

  dataSource: TemplateItem[];

  // Das Popover wird in der registrierten Grid Zelle ausgelöst. Hier wird der State für die Darstellung
  // festgehalten und Elemente, die nicht mehr gebraucht werden, werden automatisch über den Garbage Collector
  // bereinigt.
  popoverView = new WeakMap<ColumnCellTemplateData<TemplateItem>, { target: HTMLElement; visible: boolean }>();

  // Die Property wird verwendet, um Seiteneffekte mit Kontrollelementen (wie z.B. ein drag handle)
  // zu berücksichtigen. Das ist speziell notwendig, da die Ebenen einfach gezählt werden und der
  // offset dann entsprechend mit einkalkuliert werden muss.
  columnOffset = 1;

  levelDepth = 0;
  levelChoices: AutocompleteValues[] = [];
  levelChoiceGroups: { [key: string]: string[] };
  showLevelDeleteButton = false;

  typeFieldSource: DropdownFieldSource[] = [
    {
      id: 'standard',
      display: 'Standard',
      translate: [
        fieldNames.descriptionShort,
        fieldNames.descriptionLong,
        fieldNames.descriptionLongWithoutHtml,
        fieldNames.manufacturerTypeDescription,
        fieldNames.remarks,
        fieldNames.manufacturerName
      ]
    },
    {
      id: 'mime',
      display: 'Medien',
      translate: [fieldNames.mimeAlt, fieldNames.mimeDescription]
    },
    {
      id: 'feature',
      display: 'Feature',
      translate: [
        fieldNames.featureSystemGroupName,
        fieldNames.featureSystemGroupName,
        fieldNames.fName,
        fieldNames.fUnit,
        fieldNames.fValue
      ]
    },
    { id: 'orderdetail', display: 'OrderDetails' },
    { id: 'price', display: 'Preis' },
    { id: 'logistics', display: 'Logistik' },
    { id: 'reference', display: 'Referenzen' },
    { id: 'udx', display: 'UDX' },
    { id: 'seo', display: 'SEO - SEA' },
    { id: 'legal', display: 'Recht' },
    { id: 'misc', display: 'MISC' },
    { id: 'supplier', display: 'Lieferant' },
    { id: 'fixed', display: 'Festwert' }
  ];

  columns: Column[] = [];
  columnOrderList: { column: string; order: number }[] = [];
  isMaxLevel: boolean = false;

  constructor(
    private translate: TranslateService,
    public fieldService: TemplateFieldsService
  ) {}

  ngOnInit() {
    this.isMaxLevel = this.levelDepth === this.MAX_LEVEL;
    this.levelChoiceGroups = {};

    // An dieser Stelle sind noch keine Level definiert, also kann ich
    // ganz einfach die Translate Funktion ausführen.
    for (const column of this.columns) {
      column.caption = this.translate.instant(column.caption);
    }
    for (const fieldSource of this.typeFieldSource) {
      fieldSource.display = this.translate.instant(fieldSource.display);
    }

    this.handleLoadedTemplate();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onToolbarPreparing(event: ToolbarPreparingEvent<Template>) {
    event.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      template: 'newTemplateButtonTemplate'
    });
  }

  async onAddNewRow(event: DxButtonTypes.ClickEvent) {
    console.log('onAddNewRow', event);
    const item = new TemplateItem([new NextPimField('-1', '-1', fieldNames.supplierPid)], 1, fieldNames.supplierPid);
    item.fieldType = 'standard';
    item.isNewEditorVersion = true;
    item.languageCode = 'deu';
    item.calculation = '';
    item.numberformat = '';
    item.seperator = '';
    item.mapping = '';
    item.maxCharacters = 0;
    item.section = 1;
    item.keys = [];
    item.order = this.dataSource.length + 1;

    this.dataSource.push(item);
    this.template.worksheetItems[this.sheetIndex].templateItems = this.dataSource;

    this.updateDataSource();
    this.updateColumnOrderList();
    this.onUpdate.emit(this.template);
  }

  onTriggerKeyGeneration(cellData: ColumnCellTemplateData<TemplateItem>) {
    switch (cellData.data.fieldType) {
      case 'standard':
      case 'orderdetail':
      case 'logistics':
      case 'seo':
      case 'misc':
        this.defaultFieldComponents.find((x) => x.templateItem === cellData.data)?.triggerKeyGeneration();
        break;
      case 'reference':
      case 'udx':
      case 'supplier':
        this.listFieldComponents.find((x) => x.templateItem === cellData.data)?.triggerKeyGeneration();
        break;
      case 'mime':
        this.mimeComponents.find((x) => x.templateItem === cellData.data)?.triggerKeyGeneration();
        break;
      case 'feature':
        this.featureComponents.find((x) => x.templateItem === cellData.data)?.triggerKeyGeneration();
        break;
      case 'price':
        this.priceComponents.find((x) => x.templateItem === cellData.data)?.triggerKeyGeneration();
        break;
      case 'legal':
        this.legalComponents.find((x) => x.templateItem === cellData.data)?.triggerKeyGeneration();
        break;
    }
  }

  onOpenFieldSettings(event: ClickEvent, cellData: ColumnCellTemplateData<TemplateItem>) {
    const target = event.element;
    const visible = true;
    this.popoverView.set(cellData, { target, visible });
  }

  onCloseFieldSettings(cellData: ColumnCellTemplateData<TemplateItem>) {
    this.popoverView.delete(cellData);
  }

  onLevelNameChanged(cellData: ColumnCellTemplateData<TemplateItem>) {
    const index = cellData.columnIndex - this.columnOffset;
    this.updateLevelChoices(cellData.data.keys, index);

    cellData.data.keys = cellData.data.keys.filter((x) => x?.trim());
    this.update(cellData);
  }

  async onOrderItemChanged(event: ValueChangedEvent, cellData: ColumnCellTemplateData<TemplateItem>) {
    const oldOrder = event.previousValue;
    const newOrder = this.fieldService.excelColumnToNumber(event.value);
    cellData.data.order = oldOrder;

    if (newOrder !== oldOrder) {
      this.updateOrders(oldOrder, newOrder);
    }

    this.dataSource.sort((a, b) => a.order - b.order);
    this.update(cellData);
  }

  async onOrderItemClick(
    event: ItemClickEvent<{ order: number; column: string }>,
    cellData: ColumnCellTemplateData<TemplateItem>
  ) {
    const newOrder = event.itemData.order;
    const oldOrder = cellData.data.order;
    if (newOrder !== oldOrder) {
      this.updateOrders(oldOrder, newOrder);
    }

    this.dataSource.sort();
    this.update(cellData);
  }

  updateColumnOrderList() {
    this.columnOrderList = [];
    for (let i = 0; i < this.dataSource.length; i++) {
      this.columnOrderList.push({ order: i + 1, column: this.fieldService.numberToExcelColumn(i + 1) });
    }
  }

  update(cellData: ColumnCellTemplateData<TemplateItem>) {
    if (cellData.data.pimFields.length > 0) this.sanitizePimField(cellData.data.fieldType, cellData.data.pimFields[0]);

    this.template.columnCount = 0;
    for (const sheet of this.template.worksheetItems) {
      this.template.columnCount += sheet.templateItems.length;
    }

    this.updateDataSource();
    this.updateColumnOrderList();
    this.onUpdate.emit(this.template);
  }

  addColumn(showLevelDeleteButton = true) {
    if (this.isMaxLevel) return;
    const leadingColumns = this.columns.splice(0, this.columnOffset);

    const columns = this.columns.splice(0, this.levelDepth);
    this.levelDepth++;
    this.isMaxLevel = this.levelDepth === this.MAX_LEVEL;

    const level = this.levelDepth;
    columns.push({
      dataField: `level_${level}`,
      caption: this.translate.instant('LevelHeadline', { level }),
      allowEditing: false,
      cellTemplate: 'cellLevelTemplate',
      headerCellTemplate: 'headerCellLevelTemplate',
      minWidth: 200,
      width: 200
    });

    this.columns.unshift(...leadingColumns, ...columns);

    if (showLevelDeleteButton) {
      // Wenn eine Spalte hinzugefügt wird, ist die Spalte in jeder Zeile leer.
      // Das heißt, es ist safe den Löschbutton darzustellen.
      this.showLevelDeleteButton = true;
    }
  }

  removeColumn() {
    if (this.levelDepth === 0) return;
    const index = this.levelDepth - 1;
    const level = this.levelDepth;
    const items = this.dataSource
    if (items.some((x) => x.keys[index]?.trim())) {
      // There is still content!
      return;
    }

    this.columns.splice(this.columnOffset + index, 1);
    for (const source of items) {
      if (level === source.keys.length) {
        source.keys.pop();
      }
    }

    if (this.levelChoices[index]) this.levelChoices.pop();
    this.levelDepth--;
    this.isMaxLevel = this.levelDepth === this.MAX_LEVEL;

    this.recalculateLevelDepth();
  }

  hasTemplateItemSettings(templateItem: TemplateItem) {
    if (!templateItem) return false;
    const hasCalculation = templateItem.calculation !== '';
    const hasNumberFormat = templateItem.numberformat !== '';
    const hasSeparator = templateItem.seperator !== '';
    const hasMapping = templateItem.mapping !== '';
    const hasAutomaticSeparation = templateItem.maxCharacters > 0 && templateItem.section >= 1;
    const hasPrefixSuffix = templateItem.prefix?.trim() || templateItem.suffix?.trim();
    const hasFactor = templateItem.factorOperator && templateItem.calculation === 'FACTOR';
    return (
      hasCalculation ||
      hasNumberFormat ||
      hasSeparator ||
      hasMapping ||
      hasAutomaticSeparation ||
      hasPrefixSuffix ||
      hasFactor
    );
  }

  isTranslatable(templateItem: TemplateItem) {
    const [pimField] = templateItem.pimFields;
    const typeField = this.typeFieldSource.find((x) => x.id === templateItem.fieldType);
    return !typeField?.translate || typeField?.translate.includes(pimField.field);
  }

  getLevelChoiceDataSource(cellData: ColumnCellTemplateData<TemplateItem>) {
    const index = cellData.columnIndex - this.columnOffset;
    const keys = cellData.data.keys;
    return this.getLevelChoice(keys, index);
  }

  private updateDataSource() {
    const items = this.dataSource;
    items.sort((a, b) => a.order - b.order);
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      item.order = i + 1;
    }
  }

  private updateOrders(oldOrder: number = -1, newOrder: number = -1) {
    const items = this.dataSource

    // Finde das zu aktualisierende Item
    const updatedItem = items.find((item) => item.order === oldOrder);
    if (!updatedItem) return;

    if (newOrder < 1) newOrder = 1;
    if (newOrder > items.length) newOrder = items.length;

    if (oldOrder < newOrder) {
      // Verschiebung nach hinten
      for (let item of items) {
        if (item.order > oldOrder && item.order <= newOrder) {
          item.order--;
        }
      }
    } else if (oldOrder > newOrder) {
      // Verschiebung nach vorne
      for (let item of items) {
        if (item.order >= newOrder && item.order < oldOrder) {
          item.order++;
        }
      }
    }

    // Setze die neue Order für das aktualisierte Item
    updatedItem.order = newOrder;
    this.updateDataSource();
  }

  private recalculateLevelDepth() {
    const index = this.levelDepth - 1;
    this.showLevelDeleteButton = !this.dataSource.some((x) => x.keys[index]?.trim());
  }

  private setLevelChoice(keys: string[], value: string, index: number) {
    const groupId = keys.slice(0, index - 2).join('|');
    if (!this.levelChoiceGroups[groupId]) {
      this.levelChoiceGroups[groupId] = [];
    }
    if (value && !this.levelChoiceGroups[groupId].includes(value)) {
      this.levelChoiceGroups[groupId].push(value);
    }
  }

  private getLevelChoice(keys: string[], index: number) {
    let groupId = '';
    if (keys[index]) {
      groupId = keys.slice(0, index - 1).join('|');
    } else {
      groupId = keys.join('|');
    }
    return this.levelChoiceGroups[groupId] ?? [];
  }

  private updateLevelChoices(keys: string[], index: number) {
    const groupId = keys.slice(0, index - 2).join('|');
    if (!this.levelChoiceGroups[groupId]) {
      this.levelChoiceGroups[groupId] = [];
    }

    this.levelChoiceGroups[groupId] = this.dataSource
      .filter((x) => x.keys[index] !== undefined && groupId === x.keys.join('|'))
      .map((x) => x.keys[index]);
  }

  private sanitizePimField(type: FieldType, pimField: NextPimField) {
    const field = pimField.field;
    if (
      ('feature' === type && !isFeatureField(field)) ||
      ('mime' === type && !isMimeField(field)) ||
      !isNextPimField(field)
    ) {
      pimField.elementKey = '-1';
      pimField.systemKey = '-1';
      pimField.field = '';
    }
  }

  private handleLoadedTemplate() {
    this.columns = [
      {
        dataField: 'order',
        caption: this.translate.instant('Sortiernummer'),
        allowEditing: false,
        cellTemplate: 'cellOrderTemplate',
        minWidth: 80,
        width: 150
      },
      {
        dataField: 'key',
        caption: this.translate.instant('Überschrift'),
        allowEditing: false,
        cellTemplate: 'cellKeyTemplate',
        headerCellTemplate: 'headerCellHeadlineTemplate',
        minWidth: 200,
        width: 300
      },
      {
        caption: this.translate.instant('Verwendetes Feld'),
        allowEditing: false,
        cellTemplate: 'cellUsedFieldTemplate'
      }
    ];

    if (this.languageFlags.length) {
      this.columns.push({
        dataField: 'language',
        caption: this.translate.instant('Sprache'),
        allowEditing: false,
        cellTemplate: 'cellLanguageTemplate',
        minWidth: 100,
        width: 100
      });
    }

    this.columns.push({
      dataField: 'pimFields',
      caption: this.translate.instant('Feldeinstellungen'),
      cellTemplate: 'cellPimFieldTemplate',
      allowEditing: false,
      minWidth: 150,
      width: 150
    });

    const items = this.template.worksheetItems[this.sheetIndex].templateItems;
    for (const item of items) {
      const [pimField] = item.pimFields?.length ? item.pimFields : [{ field: null }];
      item.fieldType = this.fieldService.getFieldType(pimField);
      item.keys = item.keys.filter((x) => x);

      for (let i = 0; i < item.keys.length; i++) {
        const value = item.keys[i];
        this.setLevelChoice(item.keys, value, i);
      }
    }

    const levelHeight = Math.max(...items.map((x) => x.keys.length));
    for (let i = 0; i < levelHeight; i++) {
      this.addColumn(false);
    }

    items.sort((a, b) => a.order - b.order);
    this.dataSource = items;
  }
}

<div class="dx-fieldset">
  <div class="dx-field">
    <h4>{{ 'Katalogauswahl' | translate }}</h4>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ osgSenderService.selectedSet.name?.length>0 ? 'placeLabelSmall' : '' }}">NAME</label>
      <dx-text-box [(value)]="osgSenderService.selectedSet.name" [showClearButton]="true" (onValueChanged)="updateName()"></dx-text-box>
    </div>
  </div>

 

  <dx-toolbar>
    <dxi-item *ngIf="generateEnabled" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Export starten' , onClick: generate }"></dxi-item>
    <dxi-item *ngIf="generateEnabled && hasTestConnection" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Export zum Testshop starten' , onClick: generateTest }"></dxi-item>
  </dx-toolbar>

  <div class="dx-field">
    <dx-data-grid [dataSource]="catalogs"
                  keyExpr="id"
                  [hoverStateEnabled]="true"
                  [filterRow]="{ visible: true }"
                  (onSelectionChanged)="update()"
                  [(selectedRowKeys)]="osgSenderService.selectedSet.catalogs">
      <dxo-group-panel [visible]="true"></dxo-group-panel>
      <dxo-load-panel [enabled]="true"></dxo-load-panel>
      <dxo-paging [pageSize]="1000"> </dxo-paging>
      <dxo-selection mode="multiple"></dxo-selection>
      <dxi-column dataField="catalogName" caption="{{ 'Name' | translate }}"></dxi-column>
      <dxi-column dataField="catalogVersion" caption="{{ 'Version' | translate }}" [width]="100"></dxi-column>
      <dxi-column dataField="date" caption="{{ 'Datum' | translate }}" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [width]="105"></dxi-column>
      <dxi-column dataField="lastUpdate" caption="{{ 'Änderung' | translate }}" dataType="date" [format]="{ type: 'dd.MM.yyyy' }" [width]="105"></dxi-column>
      <dxi-column dataField="categoryCount" caption="{{ 'Kategorien' | translate }}" [width]="100"></dxi-column>
      <dxi-column dataField="productCount" caption="{{ 'Produkte' | translate }}" [width]="100"></dxi-column>
      <dxi-column dataField="pimGroup" caption="{{ 'Gruppe' | translate }}"></dxi-column>


    </dx-data-grid>
  </div>
</div>



import { Component, OnInit } from "@angular/core";


import { TranslateService } from '@ngx-translate/core';
import { Catalog } from '../../../Model/Catalog/catalog';
import { ModelService } from '../../../Services/model.service';
import { AddClassification } from '../../../Model/classification/AddClassification';
import { FeatureSystem } from '../../../Model/Catalog/FeatureModels/FeatureSystem';
import { Functions } from '../../../Services/functions';
import { Message } from "../../../Model/System/Message";
import { StartAction } from '../../../Model/Dto/StartAction';
import { FeatureValueContainer } from '../../../Model/classification/FeatureValueContainer';
import { UserManagementService } from "../../../Services/userManagment.service";
import { Product } from "../../../Model/Catalog/product";


@Component({
  selector: "catalogTemplates",
  templateUrl: "catalogTemplates.component.html",
  styleUrls: []
})
export class CatalogTemplatesComponent implements OnInit {
  wawiDummyProduct: Product;

  constructor(public modelService: ModelService, public translate: TranslateService, public userManagement: UserManagementService) {
    this.applyTemplate = this.applyTemplate.bind(this);

    this.wawiDummyProduct = new Product();
    this.wawiDummyProduct.wawi = this.model.wawiTemplate;
  }

  ngOnInit(): void {
    this.translateTexts();
    this.reset();
  }


  txtFeatureTemplate: string;
  txtMediaTemplate: string;
  txtMakroGestarted : string;

  get model(): Catalog {
    return this.modelService.catalogService.catalog;
  }

  set model(catalog: Catalog) {
    this.modelService.catalogService.catalog = catalog;
  }

  catalogUpdate(event = null) {
    this.modelService.catalogService.updateCatalog();
  }
  reload() {
    this.modelService.catalogService.treeRefresh();
    this.modelService.catalogService.getCatalog(this.modelService.catalogService.catalog.id, this.modelService.loginService.currentUser.customerId).subscribe();
    this.wawiDummyProduct.wawi = this.model.wawiTemplate;
  }

  private translateTexts() {

    this.txtFeatureTemplate = this.translate.instant("Features");
    this.txtMediaTemplate = this.translate.instant("Medien");
    this.txtMakroGestarted = this.translate.instant("Die Aktualisierung wurde als Job gestartet");

    
  }

  pages: { id: number; title: string; template: string }[] = [];
  reset() {
    this.pages = [
      { id: 1, title: this.txtFeatureTemplate, template: "featureTemplate" },
      { id: 2, title: this.txtMediaTemplate, template: "mediaTemplate" },
      { id: 3, title: this.translate.instant("Wawi"), template: "wawiTemplate" },
    ];

  }


  updateWawi(event = null) {
    this.model.wawiTemplate = this.wawiDummyProduct.wawi;
    this.catalogUpdate();
  }

  addEcommerceFeatures() {
    this.modelService.catalogService.addEcommerceFeaturesToCatalog(this.model).subscribe(
      (catalog: Catalog) => {
        this.model.featureTemplate = catalog.featureTemplate;

      });
  }

  applyTemplate() {

    let startAction = new StartAction();
    startAction.catalogId = this.modelService.catalogService.catalog.id;
    startAction.customerId = this.modelService.loginService.currentUser.customerId;
    startAction.actionId = "ApplyProductTemplate()";

    this.modelService.jobService.startUserAction(startAction)
      .subscribe(next => {
        this.modelService.systemService.notify(new Message(this.txtMakroGestarted));
      });
  }


  public classificationFeatureValues: FeatureValueContainer = {};

  public loadClassificationFeatures(params) {
    this.modelService.classificationService.getFeatureValues(params.name, params.valueSource).subscribe(result => {
      this.classificationFeatureValues[params.name] = result;
    });
  }

  addClassificationFeatures(event) {
    if (this.modelService.classificationService.selectedClassId == "") return;
    let dto = new AddClassification();
    dto.classificationClassId = this.modelService.classificationService.selectedClassId;
    dto.classificationSystemId = this.modelService.classificationService.selectedSystem.id;
    dto.customerId = this.modelService.catalogService.catalog.customerId;
    dto.catalogId = this.modelService.catalogService.catalog.id;

    this.modelService.classificationService.addClassification(dto).subscribe(
      (result: FeatureSystem[]) => {
        this.modelService.catalogService.catalog.featureTemplate = new Array<FeatureSystem>();

        let that = this;
        result.forEach(function (r) {
          let fs = Object.assign(new FeatureSystem(), r);
          that.modelService.catalogService.catalog.featureTemplate.push(fs);
        });
      });
  }

  canView(name: string): boolean {
    return this.userManagement.canView(name);
  }
  readOnlyUser(name: string): boolean {
    return !this.userManagement.canEdit(name);
  }
}

<div class="dx-fieldset">
  <div class="dx-field">
    <h4>{{ 'Training' | translate }}</h4>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Name' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ teachingSetService.selectedSet.name?.length>0 ? 'placeLabelSmall' : '' }}">NAME</label>
      <dx-text-box [(value)]="teachingSetService.selectedSet.name" [showClearButton]="true" (onValueChanged)="update()"></dx-text-box>
    </div>
  </div>

  <div class="dx-field">
    <div class="dx-field-label jobListTD">{{ 'Status' | translate }}</div>
    <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}">
      <label class="placeLabel {{ teachingSetService.selectedSet.name?.length>0 ? 'placeLabelSmall' : '' }}">STATUS</label>
      <dx-text-box [value]="getStatus()" [showClearButton]="true" [readOnly]="true" (onValueChanged)="update()"></dx-text-box>
    </div>
  </div>

  <dx-toolbar>
    <dxi-item location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Muster hinzufügen' | translate, onClick: addSample }"></dxi-item>
    <dxi-item location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Produkt aus Zwischenablage einfuegen' | translate, onClick: paste }"></dxi-item>
    <dxi-item *ngIf="generateEnabled" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Generieren' | translate, onClick: generate }"></dxi-item>
  </dx-toolbar>

  <div class="dx-field">
    <dx-tab-panel [dataSource]="teachingSetService.selectedSet.samples" [(selectedItem)]="teachingSetService.selectedSample" class="w-100" [swipeEnabled]="false">
      <div *dxTemplate="let sample of 'title'">
        <span>{{ sample.descriptionShort }}</span>
      </div>
      <div *dxTemplate="let sample of 'item'">
        <dx-tab-panel [dataSource]="samplePages" [selectedIndex]="0" [swipeEnabled]="false">

          <div *dxTemplate="let detail of 'sampleDetailTemplate'">
            <div class="dx-fieldset mainInfos" *ngIf="sample">

              <!--descriptionShort-->
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200">{{ 'Produktbeschreibung' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt0">
                  <label class="placeLabel {{ sample.descriptionShort?.length>0 ? 'placeLabelSmall' : '' }}">DESCRIPTION_SHORT</label>
                  <dx-text-box id="descriptionShort" [readOnly]="false" [(value)]="sample.descriptionShort" [showClearButton]="true" (onValueChanged)="update()">
                    <dx-validator validationGroup="MAIN">
                      <dxi-validation-rule type="custom" [validationCallback]="validationService.validateDescriptionShort"></dxi-validation-rule>
                    </dx-validator>
                  </dx-text-box>
                </div>
              </div>

              <!--groupString-->
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200">{{ 'Kategorien' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt0">
                  <label class="placeLabel {{ sample.descriptionShort?.length>0 ? 'placeLabelSmall' : '' }}">GROUPSTRING</label>
                  <dx-text-box id="descriptionShort" [readOnly]="false" [(value)]="sample.groupString" [showClearButton]="true" (onValueChanged)="update()">
                  </dx-text-box>
                </div>
              </div>

              <!--keywords-->
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200">{{ 'Suchbegriffe' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt0">
                  <label class="placeLabel {{ sample.descriptionShort?.length>0 ? 'placeLabelSmall' : '' }}">KEYWORDS</label>
                  <dx-tag-box [(value)]="sample.keyWords" placeholder="" [readOnly]="false" (onValueChanged)="update()" [acceptCustomValue]="true" noDataText="">
                  </dx-tag-box>
                </div>
              </div>

              <!--manufacturer-->
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200">{{ 'Hersteller' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt0">
                  <label class="placeLabel {{ sample.descriptionShort?.length>0 ? 'placeLabelSmall' : '' }}">MANUFACTURER</label>
                  <dx-text-box id="descriptionShort" [readOnly]="false" [(value)]="sample.manufacturer" [showClearButton]="true" (onValueChanged)="update()">
                  </dx-text-box>
                </div>
              </div>
            </div>
          </div>


          <div *dxTemplate="let features of 'sampleFeaturesTemplate'">
            <div class="dx-fieldset mainInfos" *ngIf="sample">


              <!-- Featurevalues -->
              <dx-toolbar>
                <dxi-item *ngIf="!teachingSetService.selectedSample?.featureSystem" location="before" widget="dxButton" locateInMenu="auto" [options]="{ type: 'default', text: 'Neue Merkmalsgruppe' | translate, onClick: addFeatureSystem }"></dxi-item>
              </dx-toolbar>
              <div class="dx-field" *ngIf="sample.featureSystem">
                <editfeaturevaluelist [(modelService)]="modelService"
                                      [(featureSystem)]="sample.featureSystem"
                                      (model)="sample"
                                      displayMode="edit"
                                      [showPlaceHolders]="showPlaceHolders"
                                      [showLinkButtons]="false"
                                      [hasAddonCLASSIFICATION]="false"
                                      (onValueChanged)="update($event)"
                                      (elementDeleted)="update($event)">
                </editfeaturevaluelist>
              </div>

            </div>
          </div>
          <div *dxTemplate="let result of 'sampleResultTemplate'">
            <div class="dx-fieldset mainInfos" *ngIf="sample">

              <!-- Memo-->
              <div class="dx-field">
                <div class="dx-field-label dx-field-label200">{{ 'Langbeschreibung' | translate }}</div>
                <div class="dx-field-value {{showPlaceHolders ? 'inputWithPlaceHolder' : 'inputWithOutPlaceHolder' }}" id="tt14" style="border: 1px solid #949494;">

                  <dx-html-editor [(value)]="sample.descriptionLong"
                                  placeholder="DESCRIPTION_LONG"
                                  (onValueChanged)="update()"
                                  [readOnly]="false">
                    <dxo-toolbar>
                      <dxi-item name="undo"></dxi-item>
                      <dxi-item name="redo"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="bold"></dxi-item>
                      <dxi-item name="italic"></dxi-item>
                      <dxi-item name="underline"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="alignLeft"></dxi-item>
                      <dxi-item name="alignCenter"></dxi-item>
                      <dxi-item name="alignRight"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="header" [acceptedValues]="[1, 2, 3, 4, 5, false]"></dxi-item>
                      <dxi-item name="separator"></dxi-item>
                      <dxi-item name="orderedList"></dxi-item>
                      <dxi-item name="bulletList"></dxi-item>
                    </dxo-toolbar>
                  </dx-html-editor>
                </div>
              </div>
            </div>
          </div>

        </dx-tab-panel>
      </div>
    </dx-tab-panel>
  </div>
</div>



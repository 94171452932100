
import { map } from 'rxjs/operators';

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";

import { BackgroundJob } from "../Model/Dto/BackgroundJob";
import { StartAction } from "../Model/Dto/StartAction";
import { TranslateService } from '@ngx-translate/core';
import { RestartJob } from '../Model/Dto/RestartJob';
import { ValidateExportJob } from '../Model/Dto/ValidateExportJob';
import { BackgroundJobEvent } from '../Model/Dto/BackgroundJobEvent';
import { ExportParameter } from '../Model/exportParameter/ExportParameter';
import { LoginService } from './login.service';
import { SignalrService } from './signalr.service';

interface JobState {
  running: boolean;
  changed: boolean;
}

@Injectable()
export class JobService {
  private refreshingSubject = new BehaviorSubject<JobState>({ running: false, changed: false });
  private catalogChangingSubject = new BehaviorSubject<{ status: 'idle' | 'running' | 'ended' }>({ status: 'idle' });
  refreshJobs$ = this.refreshingSubject.asObservable();
  refreshJobFeedbackText: string = null;

  catalogChangingJobs$ = this.catalogChangingSubject.asObservable();

  constructor(private http: HttpClient, private signalr: SignalrService, private login: LoginService, public translate: TranslateService) {
    this.signalr.startConnection('jobs');
    this.signalr.hubConnection.on('RefreshJobs', (running: boolean, changed: boolean) => {
      this.refreshingSubject.next({ running, changed });
    });
    this.signalr.hubConnection.on('CatalogChangingJobs', (status: 'idle' | 'running' | 'ended') => {
      this.catalogChangingSubject.next({ status });
    });
  }

  delete(id) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("jobId", id)
    }

    this.http.delete("api/job/delete", options)
      .subscribe(
        result => { },
        err => console.error(err)
      );
  }

  deleteFinishedJobs(customerId: string) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return this.http.delete("api/job/deleteFinishedJobs", options);
  }



  setIsDeleted(id) {
    let i = 1;

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("jobId", id)
    };
    return this.http.post("api/job/SetIsDeleted", null, options);
  }

  setIsDeletedForFinishedJobs(customerId: string) {
    let i = 1;

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    };
    return this.http.post("api/job/SetIsDeletedForFinishedJobs", null, options);
  }

  setIsDeletedForSelectedJobs(jobIds: Array<number>) {
    let i = 1;

    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
    };
    return this.http.post("api/job/SetIsDeletedForSelectedJobs", jobIds, options);
  }



  setIsPinned(jobId: number, remarks: string, pinExpires: Date) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("jobId", jobId.toString()).append("remarks", remarks)
    };

    return this.http.post("api/Job/SetIsPinned", pinExpires, options)
      .pipe(map(response => {
        return true;
      }));
  }

  setIsNotPinned(jobId: number) {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("jobId", jobId.toString())
    };

    return this.http.post("api/Job/SetIsNotPinned", null, options)
      .pipe(map(response => {
        return true;
      }));
  }

  getExportJobParameter(jobId: number, customerId: string): Observable<ExportParameter> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("jobId", jobId).append("customerId", customerId)
    }
    return (this.http.get<ExportParameter>("api/job/GetExportJobParameter", options)) as any;
  }

  getJobs(customerId: string): Observable<BackgroundJob[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get<BackgroundJob[]>("api/job/GetJobs", options)) as any;
  }

  private currentOpenedCatalogId: string = null;
  async openCatalogFeed(customerId: string, catalogId: string) {
    await this.closeCatalogFeed(customerId, catalogId);
    await this.signalr.send('OpenCatalog', customerId, catalogId);
    this.currentOpenedCatalogId = catalogId;
  }

  async closeCatalogFeed(customerId: string, catalogId: string) {
    if (this.currentOpenedCatalogId && this.currentOpenedCatalogId !== catalogId) {
      await this.signalr.send('CloseCatalog', customerId, this.currentOpenedCatalogId);
      this.currentOpenedCatalogId = null;
    }
  }

  async feedRefreshJobs(customerId: string, catalogId: string) {
    await this.openCatalogFeed(customerId, catalogId);
    return this.signalr.send('RefreshJobs', customerId, catalogId);
  }

  async feedCatalogChangingJobs(customerId: string) {
    return this.signalr.send('CatalogChangingJobs', customerId);
  }

  isRefreshJobRunningInCatalog(catalogId: string, customerId: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get<BackgroundJob[]>("api/job/IsRefreshJobRunningInCatalog", options)) as any;
  }

  isJobRunningInCatalog(catalogId: string, customerId: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("customerId", customerId)
    }
    return (this.http.get<BackgroundJob[]>("api/job/IsJobRunningInCatalog", options)) as any;
  }

  isJobRunningThatChangesCatalogList(customerId: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get<BackgroundJob[]>("api/job/IsJobRunningThatChangesCatalogList", options)) as any;
  }

  isJobRunningThatChangesWawiList(customerId: string): Observable<boolean> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("customerId", customerId)
    }
    return (this.http.get<BackgroundJob[]>("api/job/IsJobRunningThatChangesWawiList", options)) as any;
  }

  getJobEvents(jobId: number): Observable<BackgroundJobEvent[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("jobId", jobId.toString())
    }
    return (this.http.get<BackgroundJobEvent[]>("api/job/GetJobEvents", options)) as any;
  }

  getJobsByCatalogId(catalogId: string, onlyNotDeleted: boolean): Observable<BackgroundJob[]> {
    const options = {
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      params: new HttpParams().append("catalogId", catalogId).append("onlyNotDeleted", onlyNotDeleted.toString())
    }
    return (this.http.get<BackgroundJob[]>("api/job/GetJobsByCatalogId", options)) as any;
  }



  startUserAction(startAction: StartAction) {

    return this.http.post("api/Job/StartCatalogAction", startAction).pipe(map(response => {
      this.feedRefreshJobs(startAction.customerId, startAction.catalogId).catch(console.error);
      return true;
    }));

  }

  startJob(startAction: StartAction) {

    const result = this.http.post("api/Job/StartJob", startAction).pipe(map(response => {
      this.feedRefreshJobs(startAction.customerId, startAction.catalogId).catch(console.error);
      return true;
    }));
    return result;
  }


  restartJob(dto: RestartJob) {
    return this.http.post("api/Job/RestartJob", dto)
      .pipe(map(response => {
        return true;
      }));
  }

  validateExportJob(dto: ValidateExportJob) {
    return this.http.post("api/Job/ValidateExportJob", dto)
      .pipe(map(response => {
        return true;
      }));
  }

  static getIconClass(icon) {
    switch (icon) {
      case "Undefined":
        return "dx-icon-preferences";
      case "MissingTemplate":
        return "dx-icon-preferences";
      case "FileContentNotValid":
        return "dx-icon-preferences";
      case "WrongVersion":
        return "dx-icon-preferences";
      case "WrongFormat":
        return "dx-icon-preferences";
      case "Error":
        return "dx-icon-warning";
      case "Finished":
      case "Done":
        return "dx-icon-todo";
      case "Running":
        return "dx-icon-runner";
      case "Info":
        return "dx-icon-chevronnext";
    }
    return "dx-icon-chevronnext";
  }

}

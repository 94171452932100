import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModelService } from '../../../../Services/model.service';
import { UserActionBaseComponent } from '../userAction-base.component';
import { InvoiceService } from '../../../../Services/invoice.service';
import { TeachingSet } from '../../../../Model/OpenAi/teachingset';
import { InvoiceSettingDto } from '../../../../Model/Dto/InvoiceSettingDto';
import { MassGenerateKITextsParameter } from '../../../../Model/MassGenerateKITextsParameter';
import { TeachingSetService } from '../../../../Services/teachingSet.service';

@Component({
  selector: 'userAction-generateAITexts',
  templateUrl: './userAction-generateAITexts.component.html',
})
export class UserActionGenerateAITextsComponent extends UserActionBaseComponent {
  teachingSet: TeachingSet;
  teachingSets: TeachingSet[];
  productCount = 0;
  invoiceSetting: InvoiceSettingDto;
  generateHTML = false;
  genVariantTexts = false;
  generateWithMassDataChange = false;
  balanceIsFilled = false;
  loading = true;

  constructor(public modelService: ModelService, public translateService: TranslateService, public invoiceService: InvoiceService, public teachingSetService: TeachingSetService) {
    super(modelService);
    this.calculateProds = this.calculateProds.bind(this);
    this.invoiceSetting = new InvoiceSettingDto();
    teachingSetService.getAllTrained().subscribe(sets => {
      this.teachingSets = sets;
      this.teachingSets.push({ name: this.translateService.instant("Standard"), id: "", customerId: this.modelService.loginService.currentCustomer.id.toString(), samples: [], state: 2 } as TeachingSet);

      if (this.teachingSets.length > 0)
        this.teachingSet = this.teachingSets[0];
    });
    this.invoiceService.GetInvoiceSetting(this.modelService.loginService.currentCustomer.id).subscribe(result => {
      this.invoiceSetting = result;
      this.calculateProds();
    });
  }

  calculateProds() {
    this.loading = true;
    this.invoiceService.getTotalProducts(this.modelService.loginService.currentCustomer.id, this.buildParam()).subscribe((count:
      number) => {
      this.productCount = count;
      const { limit, usedLimit, pricePerOpenAi } = this.invoiceSetting;
      const total = this.productCount * pricePerOpenAi;
      this.balanceIsFilled = limit - (usedLimit + total) >= 0;
      this.loading = false;
      this.userActionToggleStartButton.emit(this.balanceIsFilled);
    });
  }

  buildParam(): MassGenerateKITextsParameter {
    return new MassGenerateKITextsParameter(
      this.modelService.catalogService.catalog.id,
      true,
      null,
      null,
      this.genVariantTexts,
      this.teachingSet != null ? this.teachingSet.id : null,
      this.generateHTML
    );
  }

  startMacro() {
    if (this.generateWithMassDataChange) {
      this.modelService.catalogService.startMassGenerateKITexts.emit();
    } else {
      this.invoiceService.startMassGenerateOpenAITextsJob(this.modelService.loginService.currentCustomer.id, this.buildParam()).subscribe(() => {
        this.modelService.systemService.notifyInfo(this.translateService.instant("Der Job wurde gestartet"), 2);
      });
    }
    this.modelService.catalogService.showUserAction = false;
  }
}

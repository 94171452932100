<dx-data-grid [(dataSource)]="generatedUnits"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true">
  <dxo-editing mode="cell"
               [allowUpdating]="false"
               [allowDeleting]="false"
               [allowAdding]="false"
               [useIcons]="true">
  </dxo-editing>

  <dxi-column dataField="packingUnitDescr" caption="{{ 'EinheitenCodeBeschreibung' | translate }}"></dxi-column>
  <dxi-column dataField="packingUnitCode" caption="{{ 'EinheitenCode' | translate }}"></dxi-column>
  <dxi-column dataField="amountContentUnit" caption="{{ 'Menge Einheitencode' | translate }}"></dxi-column>


</dx-data-grid>



<dx-data-grid (onToolbarPreparing)="onGridToolbarPreparing($event)"
              [(dataSource)]="product.orderDetail.packingUnits"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              noDataText="{{ 'keine Einheiten gepflegt' | translate }}"
              [showBorders]="true"
              (onContentReady)="onContentReady()"
              (onRowRemoved)="delete($event)">

  <dxo-editing mode="cell"
               [allowUpdating]="true"
               [allowDeleting]="true"
               [allowAdding]="false"
               [useIcons]="true">
    <dxo-texts confirmDeleteMessage="{{ 'Wirklich löschen' | translate }}" confirmDeleteTitle="{{ 'EinheitenCode' | translate }}"></dxo-texts>
  </dxo-editing>

  <div *dxTemplate="let toolbaritem of 'newSupplierUnitButtonTemplate'">
    <dx-button *ngIf="canView('AddSupplierUnit')" stylingMode="text" [disabled]="updateLock" type="default" text="{{ 'Neuer Einheitencode' | translate }}" (onClick)="add()"></dx-button>
  </div>

  <div *dxTemplate="let toolbaritem of 'generateMissingCodesButtonTemplate'">
    <dx-button *ngIf="canView('GenerateMissingUnits') && modelService.loginService.hasAddonGevis" stylingMode="text"
               [disabled]="updateLock" type="default" text="{{ 'Fehlende Codes Generieren' | translate }}" (onClick)="generateMissingCodes()"></dx-button>
  </div>


  <dxo-state-storing [enabled]="false" type="localStorage" storageKey="SupplierPackingUnits"></dxo-state-storing>

  <dxi-column dataField="order" dataType="number" caption="{{ 'Order' | translate }}" [allowEditing]="false" [sortIndex]="0"></dxi-column>


  <dxi-column dataField="packingUnitCode" caption="{{ 'EinheitenCode' | translate }}" cellTemplate="packingUnitCodeTemplate" editCellTemplate="packingUnitCodeTemplate"></dxi-column>
  <div *dxTemplate="let unit of 'packingUnitCodeTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="unit.data.packingUnitCode?.length > 0"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, unit.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'EinheitenCode' | translate }}"
                       field="PACKING_UNIT_CODE"
                       [system]="unit.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-text-box [(value)]="unit.data.packingUnitCode"
                       [readOnly]="readOnlyForLinkedFields('PACKING_UNIT_CODE',unit.data.order.toString()) || readOnly('PACKING_UNIT_CODE')"
                       [showClearButton]="!readOnlyForLinkedFields('SUPPLIER_ID', unit.data.order.toString())"
                       (onValueChanged)="update($event, 'PACKING_UNIT_CODE', unit.data.order.toString())">
          </dx-text-box>
        </div>
      </np-inputControl>
    </div>
  </div>


  <dxi-column caption="{{ 'GevisMapping' | translate}}" cellTemplate="gevisMappingTemplate" editCellTemplate="gevisMappingTemplate"></dxi-column>
  <div *dxTemplate="let unit of 'gevisMappingTemplate'">
    <dx-text-box [value]="getGevisMappedCode(unit.data.packingUnitCode)" [readOnly]="true">
    </dx-text-box>
  </div>


  <dxi-column dataField="amountContentUnit" caption="{{ 'Menge Basiseinheit' | translate }}" cellTemplate="amountContentUnitTemplate" editCellTemplate="amountContentUnitTemplate"></dxi-column>
  <div *dxTemplate="let unit of 'amountContentUnitTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="unit.data.amountContentUnit != null"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, unit.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'EinheitenCode' | translate }}"
                       field="PACKING_UNIT_AMOUNT_CONTENT_UNIT"
                       [system]="unit.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="unit.data.amountContentUnit"
                         [readOnly]="readOnlyForLinkedFields('PACKING_UNIT_AMOUNT_CONTENT_UNIT',unit.data.order.toString()) || readOnly('PACKING_UNIT_AMOUNT_CONTENT_UNIT')"
                         [showClearButton]="!readOnlyForLinkedFields('PACKING_UNIT_AMOUNT_CONTENT_UNIT', unit.data.order.toString())"
                         (onValueChanged)="update($event, 'PACKING_UNIT_AMOUNT_CONTENT_UNIT', unit.data.order.toString())">
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>

  <dxi-column dataField="quantityMin" caption="{{ 'Mindestmenge' | translate }}" cellTemplate="quantityMinTemplate" editCellTemplate="quantityMinTemplate"></dxi-column>
  <div *dxTemplate="let unit of 'quantityMinTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="unit.data.quantityMin != null"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, unit.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Mindestmenge' | translate }}"
                       field="PACKING_UNIT_QUANTITY_MIN"
                       [system]="unit.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="unit.data.quantityMin"
                         [readOnly]="readOnlyForLinkedFields('PACKING_UNIT_QUANTITY_MIN',unit.data.order.toString()) || readOnly('PACKING_UNIT_QUANTITY_MIN')"
                         [showClearButton]="!readOnlyForLinkedFields('PACKING_UNIT_QUANTITY_MIN', unit.data.order.toString())"
                         (onValueChanged)="update($event, 'PACKING_UNIT_QUANTITY_MIN', unit.data.order.toString())">
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>

  <dxi-column dataField="quantityMax" caption="{{ 'Höchtsmenge' | translate }}" cellTemplate="quantityMaxTemplate" editCellTemplate="quantityMaxTemplate"></dxi-column>
  <div *dxTemplate="let unit of 'quantityMaxTemplate'">

    <div class="np-field w-100">
      <np-inputControl [(model)]="product"
                       [showLinkButtons]="showLinkButtons"
                       [showPlaceLabel]="unit.data.quantityMax != null"
                       [suppressToolTip]="true"
                       [suppressLabel]="true"
                       [(selectedElements)]="selectedElements"
                       (elementSelected)="templateSelect($event, unit.data.order.toString())"
                       (onValueChanged)="update($event)"
                       labelText="{{ 'Mindestmenge' | translate }}"
                       field="PACKING_UNIT_QUANTITY_MAX"
                       [system]="unit.data.order.toString()"
                       [showDragHandle]="showDragHandle"
                       [dragFilter]="dragFilter"
                       (excelElementDropped)="excelElementDrop($event)">

        <div slot="content" class="contentSlot">
          <dx-number-box [(value)]="unit.data.quantityMax"
                         [readOnly]="readOnlyForLinkedFields('PACKING_UNIT_QUANTITY_MAX',unit.data.order.toString()) || readOnly('PACKING_UNIT_QUANTITY_MAX')"
                         [showClearButton]="!readOnlyForLinkedFields('PACKING_UNIT_QUANTITY_MAX', unit.data.order.toString())"
                         (onValueChanged)="update($event, 'PACKING_UNIT_QUANTITY_MAX', unit.data.order.toString())">
          </dx-number-box>
        </div>
      </np-inputControl>
    </div>
  </div>

</dx-data-grid>

import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public hubConnection: signalR.HubConnection;

  constructor(private loginService: LoginService) { }

  startConnection(urlPath: string) {
    const url = `${location.origin}/${urlPath}`;
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl(url, {
      accessTokenFactory: () => this.loginService.accessToken,
    }).build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err));
  }

  send(method: string, ...args: unknown[]) {
    return this.hubConnection.invoke(method, ...args);
  }
}


import { map } from 'rxjs/operators';
import { CatalogDataQuality } from "../Model/validation/CatalogDataQuality";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";


import { StartAction } from "../Model/Dto/StartAction";



@Injectable()
export class DataQualityService {

    catalogDataQuality: CatalogDataQuality;



    constructor(private http: HttpClient) {

    }


    startUserAction(startAction: StartAction) {

        return this.http.post("api/Job/StartCatalogAction", startAction).pipe(map(response => {
            return true;
        }));

    }
    CalculateCatalogDataQuality(startAction: StartAction) {

        return this.http.post("api/DataQuality/CalculateCatalogDataQuality", startAction);
    }

    getDataQuality(customerId: string, catalogId: string): Observable<CatalogDataQuality> {
      const options = {
          headers: new HttpHeaders().append("Content-Type", "application/json"),
          params: new HttpParams()
              .append("customerId", customerId)
              .append("catalogId", catalogId)

      }
      return this.http.get<CatalogDataQuality>("api/DataQuality/GetDataQuality", options);
    }

}
